import React, { useEffect, useState } from "react";
import { Row, Col, Image } from "react-bootstrap";

const Gallery1 = ({isPreview, holderState, selectedField}) => {
  const [gal1, setGal1] = useState({
    showImg1: holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
    imgLink1: holderState?.imgLink1 !== undefined ? holderState.imgLink1 : "",
    imgSrc1:
      holderState?.imgSrc1 !== undefined
        ? holderState.imgSrc1
        : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt1: "Placeholder Img",
  });

  useEffect(() => {
    setGal1((prevGal1) => ({
      ...prevGal1,
      showImg1:
        holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
      imgLink1: holderState?.imgLink1 !== undefined ? holderState.imgLink1 : "",
      imgSrc1:
        holderState?.imgSrc1 !== undefined
          ? holderState.imgSrc1
          : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgAlt1: "Placeholder Img",
    }));
  }, [
    holderState?.showImg1,
    holderState?.imgLink1,
    holderState?.imgSrc1,
  ]); 

  return (
    <Row
      sm={12}
      md={10}
      className={`d-flex justify-content-center bg-white ${
        isPreview ? "px-3 pt-3" : "p-5"
      }`}
    >
      <Col
        xs={12}
        md={12}
        lg={12}
        xl={12}
        className="d-flex align-items-center justify-content-center mb-4"
      >
        {gal1.showImg1 && (
          <div
            className={`text-center ${
              selectedField === "imgSrc1" ? "focus-field" : ""
            }`}
          >
            {gal1.imgLink1 !== "" && (
              <a
                href={"https://" + gal1.imgLink1}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image src={gal1.imgSrc1} alt={gal1.imgAlt1} fluid />
              </a>
            )}
            {gal1.imgLink1 === "" && (
              <Image src={gal1.imgSrc1} alt={gal1.imgAlt1} fluid />
            )}
          </div>
        )}
      </Col>
    </Row>
  );
};

export default Gallery1;
