import React from 'react'
import { Row, Col, Form, Button, InputGroup } from 'react-bootstrap';

const Form9 = () => {
  return (
    <Row sm={12} md={12} lg={10} xl={10} xxl={8} style={{"--bs-gutter-x":'0'}} className='d-flex justify-content-center p-5 bg-white'>
        <Col className='d-flex flex-row justify-content-center flex-wrap'>
            <Col sm={12} md={6} xxl={4} className='p-5 border-end'>
            <h5>Medium length headline</h5>
            <p>By creating an Account on our service, you agree to subscribe to newsletters.</p>
            <Form>
            <InputGroup>
            <Form.Control
                aria-describedby="basic-addon2"
                varient='dark'
                className='bg-light'
                size='md'
                placeholder="Placeholder"
            />
                <Button id="button-addon2">
                    Action
                </Button>
            </InputGroup>
            </Form>
            </Col>
            <Col sm={12} md={6} xxl={4} className='p-5'>
            <h5>Medium length headline</h5>
            <p className='mb-0'>By creating an Account on our service, you agree to subscribe to newsletters, marketing or promotional materials and other information we may send. However, you may opt out.</p>
            </Col>
        </Col>
    </Row>
  )
}

export default Form9