import React from "react";
import '../Components/ApplicationComponents/ServiceComponents/serviceComponents.css';
import ForgetPwCard from "../Components/ApplicationComponents/ServiceComponents/ForgetPwCard";

const ForgetPwPage = () => {
  return (
    // Needs handlers come integration

    <>
      <ForgetPwCard />
    </>
  );
};

export default ForgetPwPage;
