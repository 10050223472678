import React from 'react'
import LoginCard from '../Components/ApplicationComponents/ServiceComponents/LoginCard';
import '../Components/ApplicationComponents/ServiceComponents/serviceComponents.css';

const LoginPage = () => {
  return (
    // Need handlers and cognito integrated
    <>
      <LoginCard />
    </>
  );
};

export default LoginPage