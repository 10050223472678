import React from 'react';
import { useState, useEffect } from 'react';
import { Row, Col, Nav, Container } from 'react-bootstrap';
import { Facebook, Twitter, Linkedin, CCircle, Pinterest, Instagram, Tiktok } from 'react-bootstrap-icons';

const Footer5 = ({ isPreview, holderState, selectedField }) => {
  const [footer5, setFooter5] = useState({
    companyName: `ACME`,
    link1Title: "First Page",
    link1: "testhome",
    link1T1: "First Page",
    link1T1L: "testhome",
    link1T2: "Second Page",
    link1T2L: "testhome",
    link1T3: "Third Page",
    link1T3L: "testhome",
    link1T4: "Fourth Page",
    link1T4L: "testhome",
    link2Title: "Second Page",
    link2: "",
    link2T1: "First Page",
    link2T1L: "testhome",
    link2T2: "Second Page",
    link2T2L: "testhome",
    link2T3: "Third Page",
    link2T3L: "testhome",
    link3Title: "Third Page",
    link3: "",
    link3T1: "First Page",
    link3T1L: "testhome",
    link3T2: "Second Page",
    link3T2L: "testhome",
    link3T3: "Third Page",
    link3T3L: "testhome",
    streetAddress:
      holderState?.streetValue !== undefined
        ? holderState.streetValue
        : "Street Address",
    cityStateZip:
      holderState?.cityValue !== undefined
        ? holderState.cityValue
        : "City, State, Zip",
    phoneNumber:
      holderState?.phoneValue !== undefined
        ? holderState.phoneValue
        : "800-555-5555",
    email:
      holderState?.emailValue !== undefined
        ? holderState.emailValue
        : "info@email.com",
    privacyPolicy: "Privacy Policy",
    privacyPolicyLink:
      holderState?.privacyLink !== undefined ? holderState.privacyLink : "",
    termsOfService: "Terms of Service",
    termsOfServiceLink:
      holderState?.tosLink !== undefined ? holderState.tosLink : "",
    socialLinkIG: holderState?.igLink !== undefined ? holderState.igLink : "",
    socialLinkFB: holderState?.fbLink !== undefined ? holderState.fbLink : "",
    socialLinkX: holderState?.xLink !== undefined ? holderState.xLink : "",
    socialLinkPIN:
      holderState?.pinLink !== undefined ? holderState.pinLink : "",
    socialLinkTik:
      holderState?.tikLink !== undefined ? holderState.tikLink : "",
    socialLinkLI: holderState?.liLink !== undefined ? holderState.liLink : "",
    companyFooter:
      holderState?.companyFooterValue !== undefined
        ? holderState.companyFooterValue
        : "2018 Acme. All rights reserved.",
    showSocials:
      holderState?.socialIconsChecked !== undefined
        ? holderState.socialIconsChecked
        : true,
    showIG:
      holderState?.showIGChecked !== undefined
        ? holderState.showIGChecked
        : true,
    showFB:
      holderState?.showFbChecked !== undefined
        ? holderState.showFbChecked
        : true,
    showX:
      holderState?.showXChecked !== undefined ? holderState.showXChecked : true,
    showPIN:
      holderState?.showPINChecked !== undefined
        ? holderState.showPINChecked
        : true,
    showTik:
      holderState?.showTikChecked !== undefined
        ? holderState.showTikChecked
        : true,
    showLI:
      holderState?.showLIChecked !== undefined
        ? holderState.showLIChecked
        : true,
    showPrivacy:
      holderState?.showPrivacy !== undefined ? holderState.showPrivacy : true,
    showCompanyFooter:
      holderState?.showCompanyFooter !== undefined
        ? holderState.showCompanyFooter
        : true,
    showTos: holderState?.showTos !== undefined ? holderState.showTos : true,
    showLegal:
      holderState?.showLegal !== undefined ? holderState.showLegal : true,
    showSiteMap:
      holderState?.showSiteMap !== undefined ? holderState.showSiteMap : true,
    showAddress:
      holderState?.showAddress !== undefined ? holderState.showAddress : true,
    showPhone:
      holderState?.showPhone !== undefined ? holderState.showPhone : true,
    showEmail:
      holderState?.showEmail !== undefined ? holderState.showEmail : true,
  });

  useEffect(() => {
    setFooter5((prevFooter5) => ({
      ...prevFooter5,
      showSocials:
        holderState?.socialIconsChecked !== undefined
          ? holderState.socialIconsChecked
          : true,
      showIG:
        holderState?.showIGChecked !== undefined
          ? holderState.showIGChecked
          : true,
      showFB:
        holderState?.showFbChecked !== undefined
          ? holderState.showFbChecked
          : true,
      showX:
        holderState?.showXChecked !== undefined
          ? holderState.showXChecked
          : true,
      showPIN:
        holderState?.showPINChecked !== undefined
          ? holderState.showPINChecked
          : true,
      showTik:
        holderState?.showTikChecked !== undefined
          ? holderState.showTikChecked
          : true,
      showLI:
        holderState?.showLIChecked !== undefined
          ? holderState.showLIChecked
          : true,
      showPrivacy:
        holderState?.showPrivacy !== undefined ? holderState.showPrivacy : true,
      showCompanyFooter:
        holderState?.showCompanyFooter !== undefined
          ? holderState.showCompanyFooter
          : true,
      showTos: holderState?.showTos !== undefined ? holderState.showTos : true,
      showLegal:
        holderState?.showLegal !== undefined ? holderState.showLegal : true,
      showSiteMap:
        holderState?.showSiteMap !== undefined ? holderState.showSiteMap : true,
      showAddress:
        holderState?.showAddress !== undefined ? holderState.showAddress : true,
      showPhone:
        holderState?.showPhone !== undefined ? holderState.showPhone : true,
      showEmail:
        holderState?.showEmail !== undefined ? holderState.showEmail : true,
      streetAddress:
        holderState?.streetValue !== undefined
          ? holderState.streetValue
          : "Street Address",
      cityStateZip:
        holderState?.cityValue !== undefined
          ? holderState.cityValue
          : "City, State, Zip",
      phoneNumber:
        holderState?.phoneValue !== undefined
          ? holderState.phoneValue
          : "800-555-5555",
      email:
        holderState?.emailValue !== undefined
          ? holderState.emailValue
          : "info@email.com",
      privacyPolicyLink:
        holderState?.privacyLink !== undefined ? holderState.privacyLink : "",
      termsOfServiceLink:
        holderState?.tosLink !== undefined ? holderState.tosLink : "",
      socialLinkIG: holderState?.igLink !== undefined ? holderState.igLink : "",
      socialLinkFB: holderState?.fbLink !== undefined ? holderState.fbLink : "",
      socialLinkX: holderState?.xLink !== undefined ? holderState.xLink : "",
      socialLinkPIN:
        holderState?.pinLink !== undefined ? holderState.pinLink : "",
      socialLinkTik:
        holderState?.tikLink !== undefined ? holderState.tikLink : "",
      socialLinkLI: holderState?.liLink !== undefined ? holderState.liLink : "",
      companyFooter:
        holderState?.companyFooterValue !== undefined
          ? holderState.companyFooterValue
          : "2018 Acme. All rights reserved.",
    }));
  }, [
    holderState?.socialIconsChecked,
    holderState?.showIGChecked,
    holderState?.showFbChecked,
    holderState?.showXChecked,
    holderState?.showPINChecked,
    holderState?.showTikChecked,
    holderState?.showLIChecked,
    holderState?.showPrivacy,
    holderState?.showCompanyFooter,
    holderState?.showTos,
    holderState?.showLegal,
    holderState?.showSiteMap,
    holderState?.showAddress,
    holderState?.showPhone,
    holderState?.showEmail,
    holderState?.companyFooterValue,
    holderState?.privacyLink,
    holderState?.tosLink,
    holderState?.streetValue,
    holderState?.cityValue,
    holderState?.phoneValue,
    holderState?.emailValue,
    holderState?.igLink,
    holderState?.fbLink,
    holderState?.xLink,
    holderState?.pinLink,
    holderState?.tikLink,
    holderState?.liLink,
  ]);
  return (
    <Container fluid className="border bg-white">
      <Row className="bg-white py-4 d-flex justify-content-center">
        <Col
          xs={12}
          sm={12}
          md={3}
          lg={2}
          className={`${isPreview ? "preview-scaling" : ""}`}
        >
          <h3 className={`${isPreview ? "h6" : ""}`}>{footer5.companyName}</h3>
          {footer5.showAddress && (
            <>
              <p className="mb-0">
                <small
                  className={`${
                    selectedField === "Address" ? "focus-field" : ""
                  }`}
                >
                  {footer5.streetAddress}
                </small>
              </p>
              <p className="mb-0">
                <small
                  className={`${
                    selectedField === "Address" ? "focus-field" : ""
                  }`}
                >
                  {footer5.cityStateZip}
                </small>
              </p>
            </>
          )}
          {footer5.showPhone && (
            <p className="mb-0">
              <small
                className={`${
                  selectedField === "Phone Number" ? "focus-field" : ""
                }`}
              >
                Ph#: {footer5.phoneNumber}
              </small>
            </p>
          )}
          {footer5.showEmail && (
            <p className="mb-0">
              <small
                className={`${selectedField === "Email" ? "focus-field" : ""}`}
              >
                {footer5.email}
              </small>
            </p>
          )}
          {footer5.showSocials &&
            (footer5.showIG ||
              footer5.showFB ||
              footer5.showX ||
              footer5.showLI ||
              footer5.showPIN ||
              footer5.showTik) && (
              <Col
                className={`d-flex gap-3 mt-3 mb-2 ${
                  isPreview ? "preview-scaling" : ""
                } ${
                  selectedField === "Social Media Icons" ? "focus-field" : ""
                }`}
              >
                {footer5.showX && (
                  <Nav.Link
                    href={"https://" + footer5.socialLinkX}
                    target="_blank"
                    className="text-black"
                  >
                    <Twitter
                      className={`${
                        selectedField === "X" ? "focus-field" : ""
                      }`}
                    />
                  </Nav.Link>
                )}
                {footer5.showFB && (
                  <Nav.Link
                    href={"https://" + footer5.socialLinkFB}
                    target="_blank"
                    className="text-black"
                  >
                    <Facebook
                      className={`${
                        selectedField === "Facebook" ? "focus-field" : ""
                      }`}
                    />
                  </Nav.Link>
                )}
                {footer5.showIG && (
                  <Nav.Link
                    href={"https://" + footer5.socialLinkIG}
                    target="_blank"
                    className="text-black"
                  >
                    <Instagram
                      className={`${
                        selectedField === "Instagram" ? "focus-field" : ""
                      }`}
                    />
                  </Nav.Link>
                )}
                {footer5.showPIN && (
                  <Nav.Link
                    href={"https://" + footer5.socialLinkPIN}
                    target="_blank"
                    className="text-black"
                  >
                    <Pinterest
                      className={`${
                        selectedField === "Pinterest" ? "focus-field" : ""
                      }`}
                    />
                  </Nav.Link>
                )}
                {footer5.showTik && (
                  <Nav.Link
                    href={"https://" + footer5.socialLinkTik}
                    target="_blank"
                    className="text-black"
                  >
                    <Tiktok
                      className={`${
                        selectedField === "TikTok" ? "focus-field" : ""
                      }`}
                    />
                  </Nav.Link>
                )}
                {footer5.showLI && (
                  <Nav.Link
                    href={"https://" + footer5.socialLinkLI}
                    target="_blank"
                    className="text-black"
                  >
                    <Linkedin
                      className={`${
                        selectedField === "LinkedIn" ? "focus-field" : ""
                      }`}
                    />
                  </Nav.Link>
                )}
              </Col>
            )}
        </Col>
        <Col
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className="d-flex justify-content-center"
        >
          <Col
            xs={4}
            sm={4}
            lg={3}
            className={`gap-2 px-2 ${isPreview ? "preview-scaling" : ""}`}
          >
            <Nav.Link href={footer5.link1}>
              <strong>{footer5.link1Title}</strong>
            </Nav.Link>
            <Nav.Link href={footer5.link1T1L}>{footer5.link1T1}</Nav.Link>
            <Nav.Link href={footer5.link1T2L}>{footer5.link1T2}</Nav.Link>
            <Nav.Link href={footer5.link1T3L}>{footer5.link1T3}</Nav.Link>
            <Nav.Link href={footer5.link1T4L}>{footer5.link1T4}</Nav.Link>
          </Col>
          <Col
            xs={4}
            sm={4}
            lg={3}
            className={`gap-2 px-2 ${isPreview ? "preview-scaling" : ""}`}
          >
            <Nav.Link href={footer5.link2}>
              <strong>{footer5.link2Title}</strong>
            </Nav.Link>
            <Nav.Link href={footer5.link2T1L}>{footer5.link2T1}</Nav.Link>
            <Nav.Link href={footer5.link2T2L}>{footer5.link2T2}</Nav.Link>
            <Nav.Link href={footer5.link2T3L}>{footer5.link2T3}</Nav.Link>
          </Col>
          <Col
            xs={4}
            sm={4}
            lg={3}
            className={`gap-2 px-2 ${isPreview ? "preview-scaling" : ""}`}
          >
            <Nav.Link href="#">
              <strong>{footer5.link3Title}</strong>
            </Nav.Link>
            <Nav.Link href={footer5.link3T1L}>{footer5.link3T1}</Nav.Link>
            <Nav.Link href={footer5.link3T2L}>{footer5.link3T2}</Nav.Link>
            <Nav.Link href={footer5.link3T3L}>{footer5.link3T3}</Nav.Link>
          </Col>
        </Col>
      </Row>
      <Row
        className={`d-flex justify-content-center mt-5 mb-2 ${
          isPreview ? "preview-scaling" : ""
        }`}
      >
        {footer5.showCompanyFooter && (
          <Col xs={6} sm={6} md={3} lg={4}>
            <small
              className={`${
                selectedField === "Company Footer" ? "focus-field" : ""
              }`}
            >
              <CCircle size={12} /> {footer5.companyFooter}
            </small>
          </Col>
        )}
        {(footer5.showTos || footer5.showPrivacy) && (
          <Col
            xs={6}
            sm={6}
            md={6}
            lg={4}
            className="d-flex justify-content-end align-items-center gap-3"
          >
            <div className="d-flex gap-3">
              {footer5.showPrivacy && (
                <Nav.Link
                  target="_blank"
                  href={"https://" + footer5.privacyPolicyLink}
                >
                  <small
                    className={`${
                      selectedField === "Privacy Policy" ? "focus-field" : ""
                    }`}
                  >
                    Privacy Policy
                  </small>
                </Nav.Link>
              )}
              {footer5.showTos && (
                <Nav.Link
                  target="_blank"
                  href={"https://" + footer5.termsOfServiceLink}
                >
                  <small
                    className={`${
                      selectedField === "Terms of Service" ? "focus-field" : ""
                    }`}
                  >
                    Terms of Service
                  </small>
                </Nav.Link>
              )}
            </div>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default Footer5;
