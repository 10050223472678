import React, { useEffect, useState } from "react";
import { Row, Col, Image } from "react-bootstrap";
import { ChevronRight } from "react-bootstrap-icons";

const CA18 = ({ isPreview, holderState, selectedField }) => {
  const [centerAlign18, setCenterAlign18] = useState({
    text1:
      holderState?.text1Value !== undefined
        ? holderState.text1Value
        : "With the new AirPlay 2, you can control your home audio system and the speakers throughout your house.4 You can play a song in the living room and your kitchen at the same time. Adjust the volume in any room.",
    showText1:
      holderState?.showText1 !== undefined ? holderState.showText1 : true,
    text2:
      holderState?.text2Value !== undefined
        ? holderState.text2Value
        : "All base UI elements are made using Nested Symbols and shared styles that are logically connected with one another.",
    showText2:
      holderState?.showText2 !== undefined ? holderState.showText2 : true,
    link1Title:
      holderState?.link1Title !== undefined ? holderState.link1Title : "Link",
    link1Value:
      holderState?.link1Value !== undefined ? holderState.link1Value : "",
    showLink1:
      holderState?.showLink1 !== undefined ? holderState.showLink1 : true,
    showImg1: holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
    imgLink1: holderState?.imgLink1 !== undefined ? holderState.imgLink1 : "",
    imgSrc1:
      holderState?.imgSrc1 !== undefined
        ? holderState.imgSrc1
        : "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt1: "Placeholder Image Text",
  });

  const imageContainerStyle = {
    display: "flex",
    alignItems: "center",
    height: "100%",
    width: "100%",
    maxHeight: isPreview ? "5rem" : "22rem",
    borderRadius: "1rem",
    overflow: "hidden",
  };
  const imageStyle = {
    objectFit: "cover",
    height: "22rem",
    width: "100%",
    borderRadius: "1rem",
  };

  useEffect(() => {
    setCenterAlign18((prevCA18) => ({
      ...prevCA18,
      text1:
        holderState?.text1Value !== undefined
          ? holderState.text1Value
          : "With the new AirPlay 2, you can control your home audio system and the speakers throughout your house.4 You can play a song in the living room and your kitchen at the same time. Adjust the volume in any room.",
      showText1:
        holderState?.showText1 !== undefined ? holderState.showText1 : true,
      text2:
        holderState?.text2Value !== undefined
          ? holderState.text2Value
          : "All base UI elements are made using Nested Symbols and shared styles that are logically connected with one another.",
      showText2:
        holderState?.showText2 !== undefined ? holderState.showText2 : true,
      link1Title:
        holderState?.link1Title !== undefined ? holderState.link1Title : "Link",
      link1Value:
        holderState?.link1Value !== undefined ? holderState.link1Value : "",
      showLink1:
        holderState?.showLink1 !== undefined ? holderState.showLink1 : true,
      showImg1:
        holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
      imgLink1: holderState?.imgLink1 !== undefined ? holderState.imgLink1 : "",
      imgSrc1:
        holderState?.imgSrc1 !== undefined
          ? holderState.imgSrc1
          : "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgAlt1: "Placeholder Image Text",
    }));
  }, [
    holderState?.link1Title,
    holderState?.link1Value,
    holderState?.showLink1,
    holderState?.showText1,
    holderState?.text1Value,
    holderState?.showText2,
    holderState?.text2Value,
    holderState?.showImg1,
    holderState?.imgLink1,
    holderState?.imgSrc1,
  ]);

  return (
    <Row
      sm={12}
      md={12}
      lg={10}
      xl={10}
      xxl={8}
      style={{ "--bs-gutter-x": "0" }}
      className={`d-flex justify-content-center bg-white ${
        isPreview ? "p-3" : "p-5"
      }`}
    >
      <Col
        xs={12}
        md={12}
        lg={10}
        xl={10}
        className="d-flex flex-column align-items-center justify-content-center"
      >
        <Col
          xs={12}
          sm={10}
          md={10}
          lg={7}
          xl={6}
          xxl={6}
          className="text-center"
        >
          {centerAlign18.showText1 && (
            <p
              className={`mb-0 ${isPreview ? "preview-scaling" : ""} ${
                selectedField === "text1" ? "focus-field" : ""
              }`}
            >
              {centerAlign18.text1}
            </p>
          )}
        </Col>
        <Col
          xxl={7}
          className={`text-center ${
            isPreview ? "preview-scaling py-1" : "py-3"
          }`}
        >
          {centerAlign18.showLink1 && (
            <a
              className={`fw-bold link-offset-2 link-underline link-underline-opacity-0 link-underline-opacity-75-hover ${
                isPreview ? "preview-scaling" : ""
              } ${selectedField === "link1Value" ? "focus-field" : ""}`}
              href={"https://" + centerAlign18.link1Value}
              target="_blank"
              rel="noopener noreferrer"
            >
              {centerAlign18.link1Title} <ChevronRight />
            </a>
          )}
        </Col>
        {centerAlign18.showImg1 && (
          <Col
            className={`d-flex flex-column text-center justify-content-center ${
              selectedField === "imgSrc1" ? "focus-field" : ""
            }`}
            style={imageContainerStyle}
          >
            {centerAlign18.imgLink1 !== "" && (
              <a
                href={"https://" + centerAlign18.imgLink1}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  src={centerAlign18.imgSrc1}
                  alt={centerAlign18.imgAlt1}
                  style={imageStyle}
                  fluid
                />
              </a>
            )}
            {centerAlign18.imgLink1 === "" && (
              <Image
                src={centerAlign18.imgSrc1}
                alt={centerAlign18.imgAlt1}
                style={imageStyle}
                fluid
              />
            )}
          </Col>
        )}
        <div className="d-flex text-center col-xs-12 col-md-10 col-lg-7 col-xl-6">
          {centerAlign18.showText2 && (
            <p className={`${isPreview ? "lh-1" : "pt-3"}`}>
              <small
                className={`${isPreview ? "preview-scaling" : ""} ${
                  selectedField === "text2" ? "focus-field" : ""
                }`}
              >
                {centerAlign18.text2}
              </small>
            </p>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default CA18;
