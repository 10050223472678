import { createSlice } from "@reduxjs/toolkit";

const isLoadingSlice = createSlice({
    name: 'loading',
    initialState: {
        isLoading: false,
    },
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
    },
});

export const {setIsLoading} = isLoadingSlice.actions;
export default isLoadingSlice.reducer;