import { createSlice } from '@reduxjs/toolkit';

const initialState = true


const bodyStateSlice = createSlice({
  name: 'bodyState',
  initialState,
  reducers: {
    setBodyState: (state, action) => {
      return action.payload;
    },
    returnExistingBodyState: (state, action) => {
      return state; // Return the existing state (no changes)
    },
  },
});

export const { setBodyState, returnExistingBodyState } = bodyStateSlice.actions;

export default bodyStateSlice.reducer;
