import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";

const CA7 = ({ isPreview, holderState, selectedField }) => {
  const [centerAlign7, setCenterAlign7] = useState({
    headline:
      holderState?.headline !== undefined ? holderState.headline : "Tagline",
    showHeadline:
      holderState?.showHeadline !== undefined ? holderState.showHeadline : true,
    text1:
      holderState?.text1Value !== undefined
        ? holderState.text1Value
        : "Long headline to turn your visitors into users",
    showText1:
      holderState?.showText1 !== undefined ? holderState.showText1 : true,
    button1:
      holderState?.button1 !== undefined ? holderState.button1 : "Action",
    button1Link:
      holderState?.button1Link !== undefined ? holderState.button1Link : "",
    showButton1:
      holderState?.showButton1 !== undefined ? holderState.showButton1 : true,
  });

  useEffect(() => {
    setCenterAlign7((prevCA7) => ({
      ...prevCA7,
      headline:
        holderState?.headline !== undefined ? holderState.headline : "Tagline",
      showHeadline:
        holderState?.showHeadline !== undefined
          ? holderState.showHeadline
          : true,
      text1:
        holderState?.text1Value !== undefined
          ? holderState.text1Value
          : "Long headline to turn your visitors into users",
      showText1:
        holderState?.showText1 !== undefined ? holderState.showText1 : true,
      button1:
        holderState?.button1 !== undefined ? holderState.button1 : "Action",
      button1Link:
        holderState?.button1Link !== undefined ? holderState.button1Link : "",
      showButton1:
        holderState?.showButton1 !== undefined ? holderState.showButton1 : true,
    }));
  }, [
    holderState?.showText1,
    holderState?.text1Value,
    holderState?.button1,
    holderState?.button1Link,
    holderState?.showButton1,
    holderState?.headline,
    holderState?.showHeadline,
  ]);

  return (
    <Row
      sm={12}
      md={12}
      lg={10}
      xl={10}
      xxl={8}
      style={{ "--bs-gutter-x": "0" }}
      className={`d-flex justify-content-center bg-white ${
        isPreview ? "p-3" : "p-5"
      }`}
    >
      <Col
        xs={12}
        md={12}
        lg={10}
        xl={10}
        className="d-flex flex-column align-items-center justify-content-center"
      >
        <Col
          xs={12}
          sm={10}
          md={10}
          lg={7}
          xl={6}
          xxl={6}
          className="pt-3 text-center"
        >
          {centerAlign7.showHeadline && (
            <p
              className={`fw-semibold pt-3 ${
                isPreview ? "preview-scaling" : ""
              } ${selectedField === "headline" ? "focus-field" : ""}`}
            >
              {centerAlign7.headline}
            </p>
          )}
          {centerAlign7.showText1 && (
            <h2
              className={`fw-bold ${isPreview ? "preview-scaling" : ""} ${
                selectedField === "text1" ? "focus-field" : ""
              }`}
            >
              {centerAlign7.text1}
            </h2>
          )}
          {centerAlign7.showButton1 && (
            <Button
              className={`h-auto ${
                isPreview ? "preview-scaling p-1 mt-2" : "mt-4 "
              } ${selectedField === "button1" ? "focus-field" : ""}`}
              href={"https://" + centerAlign7.button1Link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {centerAlign7.button1}
            </Button>
          )}
        </Col>
      </Col>
    </Row>
  );
};

export default CA7;
