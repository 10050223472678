import React from "react";
import { Form, Card } from "react-bootstrap";

const ImageTextForm = ({
  showImgText,
  setShowImgText,
  imgText,
  setImgText,
  imgNumber,
}) => {
  return (
    <Card className="col-3 px-0 focus-hover">
      <Card.Body>
        <Card.Title>Image Text {imgNumber}</Card.Title>
        <Form.Group>
          <Form.Check
            type="checkbox"
            name={"Image " + imgNumber + " Text"}
            value={"Image " + imgNumber + " Text"}
            label="Display"
            checked={showImgText}
            onChange={(e) => setShowImgText(e.target.checked)}
          />
          <Form.Control
            type="text"
            placeholder="Enter Text"
            value={imgText}
            onChange={(e) => setImgText(e.target.value)}
          />
        </Form.Group>
      </Card.Body>
    </Card>
  );
};

export default ImageTextForm;
