import { createSlice } from '@reduxjs/toolkit';

const initialState = [
  {
      id: 1,
      name: "Navbar1",
      navComponent: {
        componentName: "Navbar1",
        props: {
            companyName:"Company"
        }
    }},
{
      id: 2,
      name: "Navbar2",
      navComponent: {
        componentName: "Navbar2",
        props: {
            companyName:"Company"
        }
    }},
    {
      id: 3,
      name: "Navbar3",
      navComponent: {
        componentName: "Navbar3",
        props: {
            companyName:"Company"
        }
    }},
{
      id: 4,
      name: "Navbar4",
      navComponent: {
        componentName: "Navbar4",
        props: {
            companyName:"Company"
        }
    }},
{
      id: 5,
      name: "Navbar5",
      navComponent: {
        componentName: "Navbar5",
        props: {
            companyName:"Company"
        }
    }},
  ];

const navbarSlice = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    addNavbar: (state, action) => {
      // Add a new webpage navbar to the state
      
      state.push(action.payload);
    },
    removeNavbar: (state, action) => {
      // Remove a webpage navbar from the state by its ID
      return state.filter((navbar) => navbar.id !== action.payload);
    },
    selectNavbar: (state, action) => {
      // Update the selected navbar in the store
      return state.filter((navbar) => navbar.id === action.payload)
    },

    // NOT CURRENTLY IN USE - MAY NOT BE APPLICABLE
updateNavbarHtml: (state, action) => {
      const { id, html, name } = action.payload;
      const navbarToUpdate = state.find((navbar) => navbar.id === id);
      if (navbarToUpdate) {
        navbarToUpdate.html = html;
        navbarToUpdate.name = name;
      }
    },
      clearSelectedNavbar: (state) => {
      state.selectedNavbar = null;
    },
  },
  
});

export const { addNavbar, removeNavbar, selectNavbar, updateNavbarHtml } = navbarSlice.actions;

export default navbarSlice.reducer;
