import React from "react";
import { Form, Card } from "react-bootstrap";
import IconDropdown from "./IconDropdown"; // Adjust the import based on your project structure

const IconFormComponent = ({
  showIcon,
  setShowIcon,
  setIcon,
  iconLink,
  setIconLink,
  iconNumber,
  selectedField,
  setSelectedField,
}) => {
  return (
    <Card
      className={`col-3 px-0 ${
        selectedField === "icon" + iconNumber ? "focus-field" : "focus-hover"
      }`}
      onClick={() => setSelectedField("icon" + iconNumber)}
    >
      <Card.Body>
        <Card.Title>Icon {iconNumber}</Card.Title>
        <Form.Group>
          <Form.Check
            type="checkbox"
            name="icon"
            value={"icon" + iconNumber}
            label="Display"
            checked={showIcon}
            onChange={(e) => setShowIcon(e.target.checked)}
          />
          <IconDropdown setIcon={setIcon} />
          <Form.Control
            type="text"
            className="my-2"
            placeholder="Icon Link"
            value={iconLink}
            onChange={(e) => setIconLink(e.target.value)}
          />
        </Form.Group>
      </Card.Body>
    </Card>
  );
};

export default IconFormComponent;
