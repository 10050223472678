import React from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { ChevronRight } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

const Form3 = () => {
  return (
    <Row sm={12} md={12} lg={10} xl={10} xxl={8} style={{"--bs-gutter-x":'0'}} className='d-flex justify-content-center p-5 bg-white'>
        <Col xs={12} md={12} lg={10} xl={10} className="d-flex flex-column align-items-center justify-content-center">
        
            <Col xs={12} sm={8} md={5} lg={5} xl={4} xxl={3} className='d-flex flex-column text-center gap-3'>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Control id='email' type="email" placeholder="Email" className='mb-2 bg-light' autoComplete="email" />
                        <Form.Control id='password' type='password' placeholder='Password' className='mt-3 bg-light' />
                    </Form.Group>
                    <Button type='submit' className='d-flex w-100 justify-content-center'>Sign in</Button>
                </Form>
                <Link href='#' className='link-offset-2 link-underline link-underline-opacity-0 link-underline-opacity-75-hover'>
                    or Sign In with Facebook <ChevronRight size={12} />
                </Link>
            </Col>

        </Col>
    </Row>
  )
}

export default Form3