
export async function loginAuth(email, password) {
    const response = await fetch(`${window.env.REACT_APP_API_URL}api/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth'
        },
        body: JSON.stringify({
            email: email,
            password: password
        })
    })
    return response;
}

export async function logoutUser(email, accessToken) {
    const response = await fetch(`${window.env.REACT_APP_API_URL}api/logout`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ sessionStorage.getItem('accessToken')
        },
        body: JSON.stringify({
            email: email,
            accessToken: accessToken
        })
    })
    return response;
  }


export async function userByEmail(email) {
    const apiUrl = `${window.env.REACT_APP_API_URL}api/userByEmail?email=${encodeURIComponent(email)}`;
    const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('accessToken')
        }
    });
    return response;
}

export async function forgotPassword(email) {
    const response = await fetch(`${window.env.REACT_APP_API_URL}api/forgot-password`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: email
    })
    return response;
}

export async function resetPassword(email, password, confirmationCode) {
    const response = await fetch(`${window.env.REACT_APP_API_URL}api/update-password`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            email: email,
            password: password,
            confirmationCode: confirmationCode
        })
    })
    return response;
}

export async function adminGetAllUsers() {
    const response = await fetch(`${window.env.REACT_APP_API_URL}api/adminGetAllUsers`);
    return response;
}