import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import RenderIconComponent from "../../../ApplicationComponents/DashboardComponents/ComponentChoices/ChoicesComponents/RenderIconComponent";

const CA9 = ({ isPreview, holderState, selectedField }) => {
  const [centerAlign9, setCenterAlign9] = useState({
    icon1: {
      type:
        holderState?.icon1?.type !== undefined
          ? holderState.icon1.type
          : "Grid1x2",
      size:
        holderState?.icon1?.size !== undefined
          ? holderState.icon1.size
          : isPreview
          ? 11
          : 32,
    },
    icon1Link:
      holderState?.icon1Link !== undefined ? holderState.icon1Link : "",
    showIcon1:
      holderState?.showIcon1 !== undefined ? holderState.showIcon1 : true,
    text1:
      holderState?.text1Value !== undefined
        ? holderState.text1Value
        : "Medium length headline",
    showText1:
      holderState?.showText1 !== undefined ? holderState.showText1 : true,
    text2:
      holderState?.text2Value !== undefined
        ? holderState.text2Value
        : "Change the color to match your brand or vision, add your logo, choose the perfect thumbnail, remove the playbar, add speed controls, and more. Increase engagement with CTAs and custom end screens, or keep your video private and password-protected.",
    showText2:
      holderState?.showText2 !== undefined ? holderState.showText2 : true,
  });

  useEffect(() => {
    setCenterAlign9((prevCA9) => ({
      ...prevCA9,
      icon1: {
        type:
          holderState?.icon1?.type !== undefined
            ? holderState.icon1.type
            : "Grid1x2",
        size:
          holderState?.icon1?.size !== undefined
            ? holderState.icon1.size
            : isPreview
            ? 11
            : 32,
      },
      icon1Link:
        holderState?.icon1Link !== undefined ? holderState.icon1Link : "",
      showIcon1:
        holderState?.showIcon1 !== undefined ? holderState.showIcon1 : true,
      text1:
        holderState?.text1Value !== undefined
          ? holderState.text1Value
          : "Medium length headline",
      showText1:
        holderState?.showText1 !== undefined ? holderState.showText1 : true,
      text2:
        holderState?.text2Value !== undefined
          ? holderState.text2Value
          : "Change the color to match your brand or vision, add your logo, choose the perfect thumbnail, remove the playbar, add speed controls, and more. Increase engagement with CTAs and custom end screens, or keep your video private and password-protected.",
      showText2:
        holderState?.showText2 !== undefined ? holderState.showText2 : true,
    }));
  }, [
    holderState?.showText1,
    holderState?.text1Value,
    holderState?.showText2,
    holderState?.text2Value,
    holderState?.showIcon1,
    holderState?.icon1?.size,
    holderState?.icon1?.type,
    holderState?.icon1Link,
    isPreview,
  ]);

  return (
    <Row
      sm={12}
      md={12}
      lg={10}
      xl={10}
      xxl={8}
      style={{ "--bs-gutter-x": "0" }}
      className={`d-flex justify-content-center bg-white ${
        isPreview ? "p-3" : "p-5"
      }`}
    >
      <Col
        xs={12}
        md={12}
        lg={10}
        xl={10}
        className="d-flex flex-column align-items-center justify-content-center"
      >
        <Col
          xs={12}
          sm={10}
          md={10}
          lg={7}
          xl={6}
          xxl={6}
          className="pt-3 text-center"
        >
          {centerAlign9.showIcon1 && (
            <>
              {centerAlign9.icon1Link !== "" && (
                <a
                  href={"https://" + centerAlign9.icon1Link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`text-black ${
                    selectedField === "icon1" ? "focus-field" : ""
                  }`}
                >
                  {RenderIconComponent({
                    iconType: centerAlign9.icon1.type,
                    iconSize: isPreview === true ? 11 : centerAlign9.icon1.size,
                  })}
                </a>
              )}
              {centerAlign9.icon1Link === "" && (
                <span
                  className={`${
                    selectedField === "icon1" ? "focus-field" : ""
                  }`}
                >
                  {RenderIconComponent({
                    iconType: centerAlign9.icon1.type,
                    iconSize: isPreview === true ? 11 : centerAlign9.icon1.size,
                  })}
                </span>
              )}
            </>
          )}
          {centerAlign9.showText1 && (
            <h2
              className={`fw-bold ${isPreview ? "preview-scaling" : "pt-3"} ${
                selectedField === "text1" ? "focus-field" : ""
              }`}
            >
              {centerAlign9.text1}
            </h2>
          )}
          {centerAlign9.showText2 && (
            <p
              className={`px-3 ${isPreview ? "preview-scaling" : "py-2"} ${
                selectedField === "text2" ? "focus-field" : ""
              }`}
            >
              {centerAlign9.text2}
            </p>
          )}
        </Col>
      </Col>
    </Row>
  );
};

export default CA9;
