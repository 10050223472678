import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import { Modal, Button } from "react-bootstrap";
import "./dashboard.css";
import DashboardHeader from "../ServiceComponents/DashboardHeader";
import RenderFinishedPage from "./ComponentChoices/ChoicesComponents/RenderFinshedPage";
import EditNavbarChoices from "./EditNavbarChoices";
import { initializeNavbarState } from "../../../reducers/navbarStateReducer";

const EditNavbarFlow = () => {
    const navbarState = useSelector((state) => state.navbarState);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedNavbar, setSelectedNavbar] = useState({
    id: 1,
    name: "Navbar1",
    navComponent: {
      componentName: "Navbar1",
      props: {
        companyName: "Company",
      },
    },
  });
  const [setPrevNavbar] = useState()
  //   Eventually use this here to call stored navbar
  //   Pass stage to throw to edit existing navbar
  const [selectedFooter] = useState({
    id: 1,
    name: "Footer1",
    footComponent: {
      componentName: "Footer1",
      props: {
        companyName: `ACME`,
        link1Title: "First Page",
        link1: "testhome",
        link2Title: "Second Page",
        link2: "",
        link3Title: "Third Page",
        link3: "",
        link4Title: "Fourth Page",
        link4: "",
        privacyPolicy: "Privacy Policy",
        privacyPolicyLink: "",
        termsOfService: "Terms of Service",
        termsOfServiceLink: "",
        socialLinkIG: "reducer",
        socialLinkFB: "",
        socialLinkX: "",
        socialLinkPIN: "",
        socialLinkTik: "",
        socialLinkLI: "",
        streetAddress: "Street Address",
        cityStateZip: "City, State, Zip",
        phoneNumber: "800-555-5555",
        email: "info@email.com",
        companyFooter: "2018 Acme. All rights reserved.",
        madeIn: "Made in Texas",
        text1:
          "Change the color to match your band or vision, add your logo, choose the perfect thumbnail, remove the playbar, add speed controls, and more",
      },
    },
  });
  const [showPreview, setShowPreview] = useState(false);
  const [slide] = useState(2);
  const footerState = useSelector((state) => state.footerStates);


  const closePreview = () => {
    setShowPreview(false);
  };

useEffect(() => {
  const fetchData = async () => {
    try {
      // Fetch actual data without a timeout
      await dispatch(initializeNavbarState());
      // Set loading to false after data is fetched
      setPrevNavbar(navbarState)
      setLoading(false);
    } catch (error) {
    }
  };

  fetchData(); // eslint-disable-next-line react-hooks/exhaustive-deps
}, [loading]);

  return (
    <>
      <Container fluid className="d-flex flex-column min-vh-100">
        <Row className="d-flex align-items-center">
          <Col sm={3}>
            <DashboardHeader>Edit Navbar</DashboardHeader>
          </Col>
        </Row>
        <Row className="flex-grow-1 mx-0">
          <Col md={12} className="mb-5 px-0">
            <Accordion defaultActiveKey={"0"} activeKey={"0"}>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Navbar Style</Accordion.Header>
                <Accordion.Body>
                  {(navbarState !== undefined && navbarState !== true) && (
                  <EditNavbarChoices setSelectedNavbar={setSelectedNavbar} slideNumber={slide} prevNavbarChoice={navbarState.name}/>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
      {/* <StickyPreviewComponent
        setShowPreview={setShowPreview}
        selectedFooter={selectedFooter}
        selectedNavbar={selectedNavbar}
      /> */}
      {/* Full Page Preview Modal */}
      <Modal show={showPreview} onHide={closePreview} centered size="xl">
        <Modal.Header className="justify-content-center">
          <Modal.Title>Page Example - Navbar Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RenderFinishedPage
            selectedNavbar={selectedNavbar}
            selectedFooter={selectedFooter}
            footerState={footerState}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closePreview}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditNavbarFlow;
