import React from 'react';
import { Link, useParams } from 'react-router-dom';

const PageFactory = () => {
    const { path } = useParams();
    // PAGE BUILDER LOGIC HERE | USE path TO CALL SINGLE PAGE FROM DYNAMO

    return (
        // Testing Below, Delete if needed:
      <div className='d-flex flex-column justify-content-center align-items-center' style={{minHeight:'100vh'}}>
        <p className='fs-1'>Live Site</p>
        <p className='fs-4'>Path from URL: {path}</p>
        <Link to={"/live/about"}>Go To About</Link>
        <Link to={"/live/home"}>Go To Home</Link>
      </div>
    );
  };

export default PageFactory