import React from 'react';
import { Container, Nav, Navbar, Button } from 'react-bootstrap';

const Navbar2 = ({isPreview}) => {

  return (
    <Navbar
      expand="lg"
      className={`bg-white border ${isPreview ? "preview-scaling" : ""}`}
    >
      <Container fluid>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="col-4">
            <Nav.Link href="#">First</Nav.Link>
            <Nav.Link href="#">Second</Nav.Link>
            <Nav.Link href="#">Third</Nav.Link>
            <Nav.Link href="#">Fourth</Nav.Link>
            <Nav.Link href="#">Fifth</Nav.Link>
          </Nav>
          <Nav className="col-4 justify-content-center">
            <Navbar.Brand
              href="#home"
              className={`${
                isPreview
                  ? "preview-scaling-logo ps-3 text-center"
                  : "text-center"
              }`}
            >
              ACME
            </Navbar.Brand>
          </Nav>
          <Nav className="col-4 justify-content-end">
            <Button
              href="#"
              className={`${isPreview ? "preview-scaling" : ""}`}
            >
              Action
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navbar2;