import React from "react";
import { Form, Card } from "react-bootstrap";

const ImageTitleForm = ({
  showImgTitle,
  setShowImgTitle,
  imgTitle,
  setImgTitle,
  imgNumber
}) => {
  return (
    <Card className="col-3 px-0 focus-hover">
      <Card.Body>
        <Card.Title>Image Title {imgNumber}</Card.Title>
        <Form.Group>
          <Form.Check
            type="checkbox"
            name={"Image " + imgNumber + " Title"}
            value={"Image " + imgNumber + " Title"}
            label="Display"
            checked={showImgTitle}
            onChange={(e) => setShowImgTitle(e.target.checked)}
          />
          <Form.Control
            type="text"
            placeholder="Enter Title"
            value={imgTitle}
            onChange={(e) => setImgTitle(e.target.value)}
          />
        </Form.Group>
      </Card.Body>
    </Card>
  );
};

export default ImageTitleForm;
