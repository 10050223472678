import React, { useEffect, useState } from "react";
import { Row, Col, Image } from "react-bootstrap";
import RenderIconComponent from "../../../ApplicationComponents/DashboardComponents/ComponentChoices/ChoicesComponents/RenderIconComponent";

const LA5 = ({ isPreview, holderState, selectedField }) => {
  const [leftAlign5, setLeftAlign5] = useState({
    headline:
      holderState?.headline !== undefined ? holderState.headline : "Tagline",
    showHeadline:
      holderState?.showHeadline !== undefined ? holderState.showHeadline : true,
    text1:
      holderState?.text1Value !== undefined
        ? holderState.text1Value
        : "Long Headline to turn your visitors into users",
    showText1:
      holderState?.showText1 !== undefined ? holderState.showText1 : true,
    text2:
      holderState?.text2Value !== undefined
        ? holderState.text2Value
        : "Gorgeous, high-quality video sharing on desktop, mobile, tablet",
    showText2:
      holderState?.showText2 !== undefined ? holderState.showText2 : true,
    text3:
      holderState?.text3Value !== undefined
        ? holderState.text3Value
        : "Gorgeous, high-quality video sharing on desktop, mobile, tablet",
    showText3:
      holderState?.showText3 !== undefined ? holderState.showText3 : true,
    icon1: {
      type:
        holderState?.icon1?.type !== undefined
          ? holderState.icon1.type
          : "Disc",
      size:
        holderState?.icon1?.size !== undefined
          ? holderState.icon1.size
          : isPreview
          ? 11
          : 32,
    },
    icon1Link:
      holderState?.icon1Link !== undefined ? holderState.icon1Link : "",
    showIcon1:
      holderState?.showIcon1 !== undefined ? holderState.showIcon1 : true,
    icon2: {
      type:
        holderState?.icon2?.type !== undefined
          ? holderState.icon2.type
          : "Grid1x2",
      size:
        holderState?.icon2?.size !== undefined
          ? holderState.icon2.size
          : isPreview
          ? 11
          : 32,
    },
    icon2Link:
      holderState?.icon2Link !== undefined ? holderState.icon2Link : "",
    showIcon2:
      holderState?.showIcon2 !== undefined ? holderState.showIcon2 : true,
    showImg1: holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
    imgLink1: holderState?.imgLink1 !== undefined ? holderState.imgLink1 : "",
    imgSrc1:
      holderState?.imgSrc1 !== undefined
        ? holderState.imgSrc1
        : "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt1: "Placeholder Image",
  });

  const imageContainerStyle = {
    display: "flex",
    alignItems: "center",
    height: "100%",
    maxHeight: "22rem",
    borderRadius: "1rem",
    overflow: "hidden",
  };
  const imageStyle = {
    objectFit: "cover",
    height: "22rem",
    width: "100%",
    borderRadius: "1rem",
  };

  useEffect(() => {
    setLeftAlign5((prevLA5) => ({
      ...prevLA5,
      headline:
        holderState?.headline !== undefined ? holderState.headline : "Tagline",
      showHeadline:
        holderState?.showHeadline !== undefined
          ? holderState.showHeadline
          : true,
      text1:
        holderState?.text1Value !== undefined
          ? holderState.text1Value
          : "Long Headline to turn your visitors into users",
      showText1:
        holderState?.showText1 !== undefined ? holderState.showText1 : true,
      text2:
        holderState?.text2Value !== undefined
          ? holderState.text2Value
          : "Gorgeous, high-quality video sharing on desktop, mobile, tablet",
      showText2:
        holderState?.showText2 !== undefined ? holderState.showText2 : true,
      text3:
        holderState?.text3Value !== undefined
          ? holderState.text3Value
          : "Gorgeous, high-quality video sharing on desktop, mobile, tablet",
      showText3:
        holderState?.showText3 !== undefined ? holderState.showText3 : true,
      icon1: {
        type:
          holderState?.icon1?.type !== undefined
            ? holderState.icon1.type
            : "Disc",
        size:
          holderState?.icon1?.size !== undefined
            ? holderState.icon1.size
            : isPreview
            ? 11
            : 32,
      },
      icon1Link:
        holderState?.icon1Link !== undefined ? holderState.icon1Link : "",
      showIcon1:
        holderState?.showIcon1 !== undefined ? holderState.showIcon1 : true,
      icon2: {
        type:
          holderState?.icon2?.type !== undefined
            ? holderState.icon2.type
            : "Grid1x2",
        size:
          holderState?.icon2?.size !== undefined
            ? holderState.icon2.size
            : isPreview
            ? 11
            : 32,
      },
      icon2Link:
        holderState?.icon2Link !== undefined ? holderState.icon2Link : "",
      showIcon2:
        holderState?.showIcon2 !== undefined ? holderState.showIcon2 : true,
      showImg1:
        holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
      imgSrc1:
        holderState?.imgSrc1 !== undefined
          ? holderState.imgSrc1
          : "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgLink1: holderState?.imgLink1 !== undefined ? holderState.imgLink1 : "",
      imgSrc:
        "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgAlt: "Placeholder Image",
    }));
  }, [
    holderState?.showText1,
    holderState?.text1Value,
    holderState?.showText2,
    holderState?.text2Value,
    holderState?.showText3,
    holderState?.text3Value,
    holderState?.showHeadline,
    holderState?.headline,
    holderState?.showIcon1,
    holderState?.showIcon2,
    holderState?.icon1?.size,
    holderState?.icon1?.type,
    holderState?.icon2?.size,
    holderState?.icon2?.type,
    holderState?.icon1Link,
    holderState?.icon2Link,
    holderState?.showImg1,
    holderState?.imgLink1,
    holderState?.imgSrc1,
    isPreview,
  ]);

  return (
    <Row
      sm={12}
      md={12}
      lg={10}
      xl={10}
      xxl={8}
      style={{ "--bs-gutter-x": "0" }}
      className={`d-flex justify-content-center bg-white gap-5 ${
        isPreview ? "p-3" : "p-5"
      }`}
    >
      <Col xs={12} sm={10} md={6} lg={5} xl={5} xxl={4}>
        <Col className="d-flex flex-column justify-content-center">
          <Col
            className={`d-flex flex-column justify-content-center mx-2 ${
              isPreview ? "" : "mb-3"
            }`}
          >
            {leftAlign5.showHeadline && (
              <p
                className={`fw-semibold ${
                  isPreview ? "preview-scaling" : "mb-2"
                } ${selectedField === "headline" ? "focus-field" : ""}`}
              >
                {leftAlign5.headline}
              </p>
            )}
            {leftAlign5.showText1 && (
              <h1
                className={`fw-bold ${isPreview ? "preview-scaling" : ""} ${
                  selectedField === "text1" ? "focus-field" : ""
                }`}
              >
                {leftAlign5.text1}
              </h1>
            )}
          </Col>
        </Col>
        <Col md={10} className={`d-flex mx-2 ${isPreview ? "" : "gap-4"}`}>
          <Col className={`${isPreview ? "px-0" : "p-2"}`}>
            {leftAlign5.showIcon1 && (
              <Col className={`${isPreview ? "" : "mb-2"}`}>
                {leftAlign5.icon1Link !== "" && (
                  <a
                    href={"https://" + leftAlign5.icon1Link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-black"
                  >
                    {RenderIconComponent({
                      iconType: leftAlign5.icon1.type,
                      iconSize: isPreview === true ? 11 : leftAlign5.icon1.size,
                    })}
                  </a>
                )}
                {leftAlign5.icon1Link === "" && (
                  <span
                    className={`${
                      selectedField === "icon1" ? "focus-field" : ""
                    }`}
                  >
                    {RenderIconComponent({
                      iconType: leftAlign5.icon1.type,
                      iconSize: isPreview === true ? 11 : leftAlign5.icon1.size,
                    })}
                  </span>
                )}
              </Col>
            )}
            <Col>
              {leftAlign5.showText2 && (
                <p
                  className={`${isPreview ? "preview-scaling" : ""} ${
                    selectedField === "text2" ? "focus-field" : ""
                  }`}
                >
                  {leftAlign5.text2}
                </p>
              )}
            </Col>
          </Col>
          <Col className={`${isPreview ? "px-0" : "p-2"}`}>
            {leftAlign5.showIcon2 && (
              <Col className={`${isPreview ? "" : "mb-2"}`}>
                {leftAlign5.icon2Link !== "" && (
                  <a
                    href={"https://" + leftAlign5.icon2Link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-black"
                  >
                    {RenderIconComponent({
                      iconType: leftAlign5.icon2.type,
                      iconSize: isPreview === true ? 11 : leftAlign5.icon2.size,
                    })}
                  </a>
                )}
                {leftAlign5.icon2Link === "" && (
                  <span
                    className={`${
                      selectedField === "icon2" ? "focus-field" : ""
                    }`}
                  >
                    {RenderIconComponent({
                      iconType: leftAlign5.icon2.type,
                      iconSize: isPreview === true ? 11 : leftAlign5.icon2.size,
                    })}
                  </span>
                )}
              </Col>
            )}
            {leftAlign5.showText3 && (
              <Col>
                <p
                  className={`${isPreview ? "preview-scaling" : ""} ${
                    selectedField === "text3" ? "focus-field" : ""
                  }`}
                >
                  {leftAlign5.text3}
                </p>
              </Col>
            )}
          </Col>
        </Col>
      </Col>
      <Col
        xs={12}
        sm={10}
        md={5}
        lg={5}
        xl={5}
        xxl={4}
        className={`d-flex align-items-center justify-content-center my-auto ${
          isPreview ? "preview-scaling" : ""
        }`}
      >
        {leftAlign5.showImg1 && (
          <Col
            className={`text-center justify-content-center ${
              selectedField === "imgSrc1" ? "focus-field" : ""
            }`}
            style={imageContainerStyle}
          >
            {leftAlign5.imgLink1 !== "" && (
              <a
                href={"https://" + leftAlign5.imgLink1}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  src={leftAlign5.imgSrc1}
                  alt={leftAlign5.imgAlt1}
                  style={imageStyle}
                  fluid
                />
              </a>
            )}
            {leftAlign5.imgLink1 === "" && (
              <Image
                src={leftAlign5.imgSrc1}
                alt={leftAlign5.imgAlt1}
                style={imageStyle}
                fluid
              />
            )}
          </Col>
        )}
      </Col>
    </Row>
  );
};

export default LA5;
