import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setIsLoading } from '../Services/loadingSlice';
import NavBarComponent from '../Components/ApplicationComponents/DashboardComponents/NavBarComponent';
import SideBar from "../Components/ApplicationComponents/DashboardComponents/SideBar";


const Dashboard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsLoading(false));
  },[dispatch])
  const [isCollapsed, setIsCollapsed] = useState(false)

  const toggleCollapse = () => {
    setIsCollapsed(prev => !prev)
  }

  return (
    <Container fluid className="d-flex flex-column min-vh-100 p-0">
      <NavBarComponent toggleCollapse={toggleCollapse}/>
      <Row className="flex-grow-1 mx-0">
        <SideBar isCollapsed={isCollapsed} toggleCollapse={toggleCollapse}/>
        <Col>
            <Outlet />
        </Col>      
      </Row>
    </Container>
  )
}

export default Dashboard