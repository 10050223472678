
export async function registerUser(data) {
    const response = await fetch(`${window.env.REACT_APP_API_URL}api/register`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-Amz-Target': 'AWSCognitoIdentityProviderService.SignUp'
        },
        body: data
    });
    return  response;
}

export async function confirmEmail(verificationCode) {
    const response = await fetch(`${window.env.REACT_APP_API_URL}api/confirm-email`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-Amz-Target': 'AWSCognitoIdentityProviderService.ConfirmSignUp',
        },
        body: JSON.stringify({
            email: sessionStorage.getItem('email'),
            verificationCode: verificationCode
        })
    });
    return response;
}

export async function resendConfirmationCode(email) {
    const response = await fetch(`${window.env.REACT_APP_API_URL}api/resend-verification`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        }, 
        body: email
    });
    return response;
}