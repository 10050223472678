import React from "react";
import { Form, Card } from "react-bootstrap";

const ListFormComponent = ({
  showList,
  setShowList,
  listValue,
  setListValue,
  listNumber,
  selectedField,
  setSelectedField,
}) => {
  return (
    <Card
      className={`col-3 px-0 ${
        selectedField === "list" + listNumber ? "focus-field" : "focus-hover"
      }`}
      onClick={() => setSelectedField("list" + listNumber)}
    >
      <Card.Body>
        <Card.Title>List {listNumber}</Card.Title>
        <Form.Group>
          <Form.Check
            type="checkbox"
            name="list"
            value={"list" + listNumber}
            label="Display"
            checked={showList}
            onChange={(e) => setShowList(e.target.checked)}
          />
          <Form.Control
            type="text"
            className="my-2"
            placeholder="Enter Text"
            value={listValue}
            onChange={(e) => setListValue(e.target.value)}
          />
        </Form.Group>
      </Card.Body>
    </Card>
  );
};

export default ListFormComponent;
