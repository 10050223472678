import React, { useEffect } from 'react';
import { useState } from "react";
import { Row, Col, Image} from 'react-bootstrap';
import { ChevronRight } from 'react-bootstrap-icons';
import RenderIconComponent from '../../../ApplicationComponents/DashboardComponents/ComponentChoices/ChoicesComponents/RenderIconComponent';

const RA16 = ({ isPreview, holderState, selectedField }) => {
  const [rightAlign16, setRightAlign16] = useState({
    icon1: {
      type:
        holderState?.icon1?.type !== undefined
          ? holderState.icon1.type
          : "Grid1x2",
      size:
        holderState?.icon1?.size !== undefined
          ? holderState.icon1.size
          : isPreview
          ? 11
          : 32,
    },
    showIcon1:
      holderState?.showIcon1 !== undefined ? holderState.showIcon1 : true,
    icon1Link:
      holderState?.icon1Link !== undefined ? holderState.icon1Link : "",
    text1:
      holderState?.text1Value !== undefined
        ? holderState.text1Value
        : "Medium length headline",
    showText1:
      holderState?.showText1 !== undefined ? holderState.showText1 : true,
    text2:
      holderState?.text2Value !== undefined
        ? holderState.text2Value
        : "Change the color to match your brand or vision, add your logo, choose the perfect thumbnail, remove the playbar, add speed controls, and more. Increase engagement with CTAs and custom end screens, or keep your video private and password-protected.",
    showText2:
      holderState?.showText2 !== undefined ? holderState.showText2 : true,
    link1Title:
      holderState?.link1Title !== undefined
        ? holderState.link1Title
        : "Action Link",
    link1Value:
      holderState?.link1Value !== undefined ? holderState.link1Value : "",
    showLink1:
      holderState?.showLink1 !== undefined ? holderState.showLink1 : true,
    showImg1: holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
    imgLink1: holderState?.imgLink1 !== undefined ? holderState.imgLink1 : "",
    imgSrc1:
      holderState?.imgSrc1 !== undefined
        ? holderState.imgSrc1
        : "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt1: "Placeholder Image",
  });

  const imageContainerStyle = {
    display: "flex",
    alignItems: "center",
    height: "100%",
    maxHeight: "100%",
    borderRadius: "1rem",
    overflow: "hidden",
  };
  const imageStyle = {
    objectFit: "cover",
    height: "22rem",
    width: "100%",
    borderRadius: "1rem",
  };

  useEffect(() => {
    setRightAlign16((prevRA16) => ({
      ...prevRA16,
      icon1: {
        type:
          holderState?.icon1?.type !== undefined
            ? holderState.icon1.type
            : "Grid1x2",
        size:
          holderState?.icon1?.size !== undefined
            ? holderState.icon1.size
            : isPreview
            ? 11
            : 32,
      },
      showIcon1:
        holderState?.showIcon1 !== undefined ? holderState.showIcon1 : true,
      icon1Link:
        holderState?.icon1Link !== undefined ? holderState.icon1Link : "",
      text1:
        holderState?.text1Value !== undefined
          ? holderState.text1Value
          : "Medium length headline",
      showText1:
        holderState?.showText1 !== undefined ? holderState.showText1 : true,
      text2:
        holderState?.text2Value !== undefined
          ? holderState.text2Value
          : "Change the color to match your brand or vision, add your logo, choose the perfect thumbnail, remove the playbar, add speed controls, and more. Increase engagement with CTAs and custom end screens, or keep your video private and password-protected.",
      showText2:
        holderState?.showText2 !== undefined ? holderState.showText2 : true,
      link1Title:
        holderState?.link1Title !== undefined
          ? holderState.link1Title
          : "Action Link",
      link1Value:
        holderState?.link1Value !== undefined ? holderState.link1Value : "",
      showLink1:
        holderState?.showLink1 !== undefined ? holderState.showLink1 : true,
      showImg1:
        holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
      imgLink1: holderState?.imgLink1 !== undefined ? holderState.imgLink1 : "",
      imgSrc1:
        holderState?.imgSrc1 !== undefined
          ? holderState.imgSrc1
          : "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgAlt1: "Placeholder Image",
    }));
  }, [
    holderState?.showText1,
    holderState?.text1Value,
    holderState?.showText2,
    holderState?.text2Value,
    holderState?.showIcon1,
    holderState?.icon1?.size,
    holderState?.icon1?.type,
    holderState?.icon1Link,
    holderState?.showImg1,
    holderState?.imgLink1,
    holderState?.showLink1,
    holderState?.link1Value,
    holderState?.link1Title,
    holderState?.imgSrc1,
    isPreview,
  ]);

  return (
    <Row
      sm={12}
      md={12}
      lg={10}
      xl={10}
      xxl={8}
      style={{ "--bs-gutter-x": "0" }}
      className={`d-flex justify-content-center bg-white gap-5 ${
        isPreview ? "p-3" : "p-5"
      }`}
    >
      <Col
        xs={12}
        sm={10}
        md={5}
        lg={5}
        xl={5}
        xxl={4}
        className={`d-flex align-items-center justify-content-center my-auto ${
          isPreview ? "preview-scaling" : ""
        }`}
      >
        {rightAlign16.showImg1 && (
          <Col
            className={`text-center justify-content-center ${
              selectedField === "imgSrc1" ? "focus-field" : ""
            }`}
            style={imageContainerStyle}
          >
            {rightAlign16.imgLink1 !== "" && (
              <a
                href={"https://" + rightAlign16.imgLink1}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  src={rightAlign16.imgSrc1}
                  alt={rightAlign16.imgAlt1}
                  style={imageStyle}
                  fluid
                />
              </a>
            )}
            {rightAlign16.imgLink1 === "" && (
              <Image
                src={rightAlign16.imgSrc1}
                alt={rightAlign16.imgAlt1}
                style={imageStyle}
                fluid
              />
            )}
          </Col>
        )}
      </Col>
      <Col
        xs={12}
        sm={10}
        md={6}
        lg={5}
        xl={5}
        xxl={4}
        className="d-flex flex-column align-items-center justify-content-center"
      >
        <Col
          className={`d-flex flex-column justify-content-center mx-2 ${
            isPreview ? "" : "gap-3"
          }`}
        >
          {rightAlign16.showIcon1 && (
            <>
              {rightAlign16.icon1Link !== "" && (
                <a
                  href={"https://" + rightAlign16.icon1Link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`text-black ${
                    selectedField === "icon1" ? "focus-field" : ""
                  }`}
                >
                  {RenderIconComponent({
                    iconType: rightAlign16.icon1.type,
                    iconSize: isPreview === true ? 11 : rightAlign16.icon1.size,
                  })}
                </a>
              )}
              {rightAlign16.icon1Link === "" && (
                <span
                  className={`${
                    selectedField === "icon1" ? "focus-field" : ""
                  }`}
                >
                  {RenderIconComponent({
                    iconType: rightAlign16.icon1.type,
                    iconSize: isPreview === true ? 11 : rightAlign16.icon1.size,
                  })}
                </span>
              )}
            </>
          )}
          {rightAlign16.showText1 && (
            <h5
              className={`fw-bold ${isPreview ? "preview-scaling mt-1" : ""} ${
                selectedField === "text1" ? "focus-field" : ""
              }`}
            >
              {rightAlign16.text1}
            </h5>
          )}
          {rightAlign16.showText2 && (
            <p
              className={`mb-0 ${isPreview ? "preview-scaling" : ""} ${
                selectedField === "text2" ? "focus-field" : ""
              }`}
            >
              {rightAlign16.text2}
            </p>
          )}
          <div>
            {rightAlign16.showLink1 && (
              <a
                className={`fw-bold link-offset-2 link-underline link-underline-opacity-0 link-underline-opacity-75-hover ${
                  isPreview ? "preview-scaling" : ""
                } ${selectedField === "link1Value" ? "focus-field" : ""}`}
                href={"https://" + rightAlign16.link1Value}
                target="_blank"
                rel="noopener noreferrer"
              >
                {rightAlign16.link1Title} <ChevronRight />
              </a>
            )}
          </div>
        </Col>
      </Col>
    </Row>
  );
};

export default RA16;