import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import Progressbar from '../ServiceComponents/ProgressBar';
import "../DashboardComponents/dashboard.css"

const HomePageProgBar = ({progressStage, setProgressStage, selectedNavbar, selectedBodyRows, selectedFooter, footerState, rowIdState}) => {

  return (
    <Container className="pb-3 pe-0">
      <Card className="border">
        <Card.Body className="crud-hover">
          <Row>
            <Col className="">
              <Progressbar
                progressStage={progressStage}
                setProgressStage={setProgressStage}
                selectedNavbar={selectedNavbar}
                selectedBodyRows={selectedBodyRows}
                selectedFooter={selectedFooter}
                footerState={footerState}
                rowIdState={rowIdState}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default HomePageProgBar