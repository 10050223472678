import React from "react";
import { Form, Card } from "react-bootstrap";

const UniversalFooterForm = ({
  title,
  urlLink,
  setShowProp1,
  showProp1,
  prop1,
  setProp1,
  setProp2,
  prop2,
  selectedField,
  setSelectedField,
}) => {
  return (
    <Card
      className={`col-3 px-0 ${
        selectedField === title
          ? "focus-field"
          : "focus-hover"
      }`}
      onClick={() => setSelectedField(title)}
    >
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Form.Group>
          <Form.Check
            type="checkbox"
            name={title}
            value="Display"
            label="Display"
            checked={showProp1}
            onChange={(e) => setShowProp1(e.target.checked)}
          />
          <Form.Control
            type="text"
            className="my-2"
            placeholder={urlLink ? "Enter URL" : "Enter Text"}
            value={prop1 || ""}
            onChange={(e) => setProp1(e.target.value)}
          />
          {title === "Address" && (
            <Form.Control
              type="text"
              placeholder="Enter City, State, Zip"
              value={prop2 || ""}
              onChange={(e) => setProp2(e.target.value)}
            />
          )}
        </Form.Group>
      </Card.Body>
    </Card>
  );
};

export default UniversalFooterForm;
