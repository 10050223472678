import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import { Modal, Button, } from "react-bootstrap";
import "./dashboard.css";
import DashboardHeader from "../ServiceComponents/DashboardHeader";
// import RenderFinishedPage from "./ComponentChoices/ChoicesComponents/RenderFinshedPage";
import { initializeFooterState } from "../../../reducers/footerStateReducer";
import EditFooterChoices from "./ComponentChoices/ChoicesComponents/EditFooterChoices";
import { getPage } from "../../../Services/PageService";

const EditFooterFlow = () => {
const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)

//   Eventually use this here to call stored footer
//   Pass stage to throw to edit existing footer
  const [selectedFooter, setSelectedFooter] = useState();
  const [showPreview, setShowPreview] = useState(false);
  const footerState = useSelector((state) => state.footerStates);


  const closePreview = () => {
    setShowPreview(false);
  };

const fetchData = async () => {
  try {
    // Fetch actual data without a timeout
    await dispatch(initializeFooterState());
    // Set loading to false after data is fetched
    setLoading(false);

    const response = await getPage("FOOTER");
    if (response.ok) {
      const responseData = await response.json();
      console.log(responseData.footer[0].selectedFooter);
      setSelectedFooter(responseData.footer[0].selectedFooter.footComponent.componentName);
    } else {
      console.log("error");
    }
  } catch (error) {
    setSelectedFooter("Footer5")
  }
};

useEffect(() => {
  fetchData(); // eslint-disable-next-line react-hooks/exhaustive-deps
}, [loading, dispatch]);


  return (
    <>
      <Container fluid className="d-flex flex-column min-vh-100">
        <Row className="d-flex align-items-center">
          <Col sm={3}>
            <DashboardHeader>Edit Footer</DashboardHeader>
          </Col>
        </Row>
        <Row className="flex-grow-1 mx-0">
          <Col md={12} className="mb-5 px-0">
            <Accordion defaultActiveKey={"0"} activeKey={"0"}>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Footer Style</Accordion.Header>
                <Accordion.Body>
                  {selectedFooter !== undefined && (
                    <EditFooterChoices
                      setSelectedFooter={setSelectedFooter}
                      slideNumber={2}
                      previousFooterChoice={selectedFooter}
                      previousFooterState={footerState}
                    />
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
      {/* Full Page Preview Modal */}
      <Modal show={showPreview} onHide={closePreview} centered size="xl">
        <Modal.Header className="justify-content-center">
          <Modal.Title>Page Example - Footer Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <RenderFinishedPage
            selectedNavbar={selectedNavbar}
            selectedFooter={footerObj}
            footerState={footerState}
          /> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closePreview}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditFooterFlow;
