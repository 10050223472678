import React, { useState, } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Tooltip,
  Modal,
  OverlayTrigger
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { Eye, Pencil, CheckCircleFill, Trash3 } from "react-bootstrap-icons";
import "../../DashboardComponents/dashboard.css";
import { Link } from "react-router-dom";
import { useMemo } from "react";
import RenderNavbarComponent from "./ChoicesComponents/RenderNavbarComponent";

const NavbarChoices = ({setSelectedNavbar}) => {
  const [disabled, setDisabled] = useState(true);
  const [navbarChoice, setNavbarChoice] = useState(null);
  const [slide, setSlide] = useState(1);
  const navbars = useSelector((state) => state.navbars);

  const navbarTemplates = useMemo(
    () => ({ navbarTemplates: navbars }),
    [navbars]
  );
  const selectedNavbar = useSelector((state) =>
    state.navbars.find((navbar) => navbar.name === navbarChoice)
  );
  const [ , setShowButton] = useState(false);

  const handleChoiceChange = (e) => {
    setNavbarChoice(e.target.value);
    setDisabled(false);
  };
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const resetChoice = () => {
    setShowButton(false);
    setNavbarChoice(null);
    setDisabled(true);
    setSelectedNavbar(null)
    setSlide(1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSelectedNavbar(selectedNavbar);
    setSlide(2);
  };

    const renderTooltipEdit = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        Edit This Pattern
      </Tooltip>
    );
    const renderTooltipView = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        Live Preview
      </Tooltip>
    );
    const renderTooltipReady = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        Ready To Publish
      </Tooltip>
    );
    const renderTooltipDelete = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        Delete This
      </Tooltip>
    );

  return (
    <>
      <Container className="pt-3">
        {slide === 1 && (
          <>
            <p>Choose a style for your Navbar.</p>
            <div className="custom-label-class py-3">
              {navbarTemplates.navbarTemplates.length > 0 &&
                navbarTemplates.navbarTemplates.map((navbarTemplate) => (
                  <Form.Check
                    key={navbarTemplate.name}
                    className="py-2"
                    type="radio"
                    id={navbarTemplate.name}
                    name="navbarChoice"
                    value={navbarTemplate.name}
                    label={
                      <div className="unclickable">
                        {RenderNavbarComponent(
                          navbarTemplate.navComponent.componentName,
                          navbarTemplate.navComponent.props
                        )}
                      </div>
                    }
                    onChange={handleChoiceChange}
                  />
                ))}
            </div>
            <div className="d-flex justify-content-center py-5">
              <Button
                variant="dark"
                type="submit"
                disabled={disabled}
                onClick={handleSubmit}
              >
                CONTINUE
              </Button>
            </div>
          </>
        )}
        {slide === 2 && (
          <Row className="d-flex flex-column">
            <Col className="d-flex justify-content-between">
              <p className="">Navbar is ready to be published.</p>
              <OverlayTrigger
                placement="left"
                delay={{ show: 100, hide: 150 }}
                overlay={renderTooltipReady}
              >
                <CheckCircleFill size={36} style={{ color: "#28A745" }} />
              </OverlayTrigger>
            </Col>

            <Col className="py-3">
              <div className="crud-hover">
                {navbarTemplates.navbarTemplates.length > 0 &&
                  navbarTemplates.navbarTemplates.map((navbarTemplate) => (
                    <div key={navbarTemplate.name}>
                      {navbarChoice === navbarTemplate.name && (
                        <div className="position-relative">
                          <div className="content-wrapper">
                            {RenderNavbarComponent(
                              navbarTemplate.navComponent.componentName,
                              navbarTemplate.navComponent.props
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}

                <div className="crud-hover-overlay d-flex justify-content-center align-items-center">
                  <OverlayTrigger
                    placement="left"
                    delay={{ show: 100, hide: 150 }}
                    overlay={renderTooltipView}
                    className="view-button"
                  >
                    <button className="view-button hover">
                      <Eye onClick={openModal} size={20} />
                    </button>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 100, hide: 150 }}
                    overlay={renderTooltipEdit}
                  >
                    <button className="edit-button">
                      <Pencil onClick={resetChoice} size={20} />
                    </button>
                  </OverlayTrigger>
                </div>
              </div>
            </Col>

            <Col>
              <div className="d-flex justify-content-end">
                <OverlayTrigger
                  placement="left"
                  delay={{ show: 100, hide: 150 }}
                  overlay={renderTooltipDelete}
                >
                  <Link onClick={() => resetChoice()} className="text-end">
                    <Trash3 size={18} style={{ color: "black" }} />
                  </Link>
                </OverlayTrigger>
              </div>
            </Col>
          </Row>
        )}
      </Container>

      <Modal show={showModal} onHide={closeModal} centered size="xl">
        <Modal.Header className="justify-content-center">
          <Modal.Title>Navbar Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {navbarChoice &&
            RenderNavbarComponent(
              selectedNavbar.navComponent.componentName,
              selectedNavbar.navComponent.props
            )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NavbarChoices;