import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Nav,
  Container,
  InputGroup,
} from "react-bootstrap";
import {
  Instagram,
  Facebook,
  Twitter,
  SendFill,
  CCircle,
  Pinterest,
  Linkedin,
  Tiktok,
} from "react-bootstrap-icons";

const Footer4 = ({ isPreview, holderState, selectedField }) => {
  const [footer4, setFooter4] = useState({
    companyName: `ACME`,
    link1Title: "First Page",
    link1: "testhome",
    link1T1: "First Page",
    link1T1L: "testhome",
    link1T2: "Second Page",
    link1T2L: "testhome",
    link1T3: "Third Page",
    link1T3L: "testhome",
    link1T4: "Fourth Page",
    link1T4L: "testhome",
    link2Title: "Second Page",
    link2: "",
    link2T1: "First Page",
    link2T1L: "testhome",
    link2T2: "Second Page",
    link2T2L: "testhome",
    link2T3: "Third Page",
    link2T3L: "testhome",
    link3Title: "Third Page",
    link3: "",
    link3T1: "First Page",
    link3T1L: "testhome",
    link3T2: "Second Page",
    link3T2L: "testhome",
    link3T3: "Third Page",
    link3T3L: "testhome",
    link4Title: "Fourth Page",
    link4: "",
    link5Title: "Fifth Page",
    link5: "",
    link6Title: "Sixth Page",
    link6: "",
    link7Title: "Seventh Page",
    link7: "",
    streetAddress: "Street Address",
    cityStateZip: "City, State, Zip",
    phoneNumber: "800-555-5555",
    sendEmail: "info@email.com",
    language: "English Version",
    privacyPolicy: "Privacy Policy",
    privacyPolicyLink:
      holderState?.privacyLink !== undefined ? holderState.privacyLink : "",
    termsOfService: "Terms of Service",
    termsOfServiceLink:
      holderState?.tosLink !== undefined ? holderState.tosLink : "",
    socialLinkIG: holderState?.igLink !== undefined ? holderState.igLink : "",
    socialLinkFB: holderState?.fbLink !== undefined ? holderState.fbLink : "",
    socialLinkX: holderState?.xLink !== undefined ? holderState.xLink : "",
    socialLinkPIN:
      holderState?.pinLink !== undefined ? holderState.pinLink : "",
    socialLinkTik:
      holderState?.tikLink !== undefined ? holderState.tikLink : "",
    socialLinkLI: holderState?.liLink !== undefined ? holderState.liLink : "",
    text1:
      holderState?.text1Value !== undefined
        ? holderState.text1Value
        : "Join our Newsletter to stay up to date on features and releases",
    companyFooter:
      holderState?.companyFooterValue !== undefined
        ? holderState.companyFooterValue
        : "2018 Acme. All rights reserved.",
    showSocials:
      holderState?.socialIconsChecked !== undefined
        ? holderState.socialIconsChecked
        : true,
    showIG:
      holderState?.showIGChecked !== undefined
        ? holderState.showIGChecked
        : true,
    showFB:
      holderState?.showFbChecked !== undefined
        ? holderState.showFbChecked
        : true,
    showX:
      holderState?.showXChecked !== undefined ? holderState.showXChecked : true,
    showPIN:
      holderState?.showPINChecked !== undefined
        ? holderState.showPINChecked
        : true,
    showTik:
      holderState?.showTikChecked !== undefined
        ? holderState.showTikChecked
        : true,
    showLI:
      holderState?.showTikChecked !== undefined
        ? holderState.showTikChecked
        : true,
    showPrivacy:
      holderState?.showPrivacy !== undefined ? holderState.showPrivacy : true,
    showCompanyFooter:
      holderState?.showCompanyFooter !== undefined
        ? holderState.showCompanyFooter
        : true,
    showTos: holderState?.showTos !== undefined ? holderState.showTos : true,
    showLegal:
      holderState?.showLegal !== undefined ? holderState.showLegal : true,
    showSiteMap:
      holderState?.showSiteMap !== undefined ? holderState.showSiteMap : true,
    showText1:
      holderState?.showText1 !== undefined ? holderState.showText1 : true,
    showSendEmail:
      holderState?.showSendEmail !== undefined
        ? holderState.showSendEmail
        : true,
  });

  useEffect(() => {
    setFooter4((prevFooter4) => ({
      ...prevFooter4,
      showSocials:
        holderState?.socialIconsChecked !== undefined
          ? holderState.socialIconsChecked
          : true,
      showIG:
        holderState?.showIGChecked !== undefined
          ? holderState.showIGChecked
          : true,
      showFB:
        holderState?.showFbChecked !== undefined
          ? holderState.showFbChecked
          : true,
      showX:
        holderState?.showXChecked !== undefined
          ? holderState.showXChecked
          : true,
      showPIN:
        holderState?.showPINChecked !== undefined
          ? holderState.showPINChecked
          : true,
      showTik:
        holderState?.showTikChecked !== undefined
          ? holderState.showTikChecked
          : true,
      showLI:
        holderState?.showLIChecked !== undefined
          ? holderState.showLIChecked
          : true,
      showPrivacy:
        holderState?.showPrivacy !== undefined ? holderState.showPrivacy : true,
      showCompanyFooter:
        holderState?.showCompanyFooter !== undefined
          ? holderState.showCompanyFooter
          : true,
      showTos: holderState?.showTos !== undefined ? holderState.showTos : true,
      showLegal:
        holderState?.showLegal !== undefined ? holderState.showLegal : true,
      showSiteMap:
        holderState?.showSiteMap !== undefined ? holderState.showSiteMap : true,
      showText1:
        holderState?.showText1 !== undefined ? holderState.showText1 : true,
      showSendEmail:
        holderState?.showSendEmail !== undefined
          ? holderState.showSendEmail
          : true,
      showEmail:
        holderState?.showEmail !== undefined ? holderState.showEmail : true,
      privacyPolicyLink:
        holderState?.privacyLink !== undefined ? holderState.privacyLink : "",
      termsOfServiceLink:
        holderState?.tosLink !== undefined ? holderState.tosLink : "",
      socialLinkIG: holderState?.igLink !== undefined ? holderState.igLink : "",
      socialLinkFB: holderState?.fbLink !== undefined ? holderState.fbLink : "",
      socialLinkX: holderState?.xLink !== undefined ? holderState.xLink : "",
      socialLinkPIN:
        holderState?.pinLink !== undefined ? holderState.pinLink : "",
      socialLinkTik:
        holderState?.tikLink !== undefined ? holderState.tikLink : "",
      socialLinkLI: holderState?.liLink !== undefined ? holderState.liLink : "",
      text1:
        holderState?.text1Value !== undefined
          ? holderState.text1Value
          : "Join our Newsletter to stay up to date on features and releases",
      companyFooter:
        holderState?.companyFooterValue !== undefined
          ? holderState.companyFooterValue
          : "2018 Acme. All rights reserved.",
    }));
  }, [
    holderState?.socialIconsChecked,
    holderState?.showIGChecked,
    holderState?.showFbChecked,
    holderState?.showXChecked,
    holderState?.showPINChecked,
    holderState?.showTikChecked,
    holderState?.showLIChecked,
    holderState?.showPrivacy,
    holderState?.showCompanyFooter,
    holderState?.showTos,
    holderState?.showLegal,
    holderState?.showSiteMap,
    holderState?.showText1,
    holderState?.showSendEmail,
    holderState?.showEmail,
    holderState?.companyFooterValue,
    holderState?.privacyLink,
    holderState?.tosLink,
    holderState?.igLink,
    holderState?.fbLink,
    holderState?.xLink,
    holderState?.pinLink,
    holderState?.tikLink,
    holderState?.liLink,
    holderState?.text1Value,
  ]);

  const handleEmailChange = (e) => {
    setFooter4({ ...footer4, sendEmail: e.target.value });
  };


  return (
    <Container fluid className="border bg-white">
      <Row className="py-4 justify-content-center">
        <Col
          xs={12}
          sm={9}
          md={4}
          lg={3}
          xl={3}
          xxl={2}
          className={`flex-column ${isPreview ? "preview-scaling" : ""}`}
        >
          <h3 className={`${isPreview ? "preview-scaling" : ""}`}>
            {footer4.companyName}
          </h3>
          {footer4.showText1 && (
            <p className="m-0">
              <small
                className={`${selectedField === "Text 1" ? "focus-field" : ""}`}
              >
                {footer4.text1}
              </small>
            </p>
          )}
          {footer4.showSendEmail && (
            <div className="mb-4 mt-2">
              <InputGroup
                className={`${
                  selectedField === "Send Email Button" ? "focus-field" : ""
                }`}
              >
                <Form.Control
                  name="Send Email"
                  size="Send Email"
                  type="email"
                  style={{
                    backgroundColor: "#F0F0F1",
                    borderColor: "#F4F4F5",
                  }}
                  placeholder={footer4.sendEmail}
                  value={footer4.sendEmail}
                  onChange={handleEmailChange}
                />
                <Button
                  style={{
                    backgroundColor: "#F0F0F1",
                    borderColor: "#F4F4F5",
                  }}
                  type="submit"
                >
                  <SendFill color="black" />
                </Button>
              </InputGroup>
            </div>
          )}
        </Col>
        <Col
          xs={8}
          sm={6}
          md={4}
          lg={4}
          xl={4}
          className={`d-flex justify-content-center pb-5 ${
            isPreview ? "preview-scaling" : ""
          }`}
        >
          <Col
            xs={6}
            sm={6}
            md={6}
            lg={5}
            xxl={4}
            className="gap-2 px-2 flex-column d-flex"
          >
            <Nav.Link href={footer4.link1}>
              <strong>{footer4.link1Title}</strong>
            </Nav.Link>
            <Nav.Link href={footer4.link2}>
              <strong>{footer4.link2Title}</strong>
            </Nav.Link>
            <Nav.Link href={footer4.link3}>
              <strong>{footer4.link3Title}</strong>
            </Nav.Link>
            <Nav.Link href={footer4.link4}>
              <strong>{footer4.link4Title}</strong>
            </Nav.Link>
          </Col>
          <Col
            xs={6}
            sm={6}
            md={6}
            lg={5}
            xl={4}
            className="gap-2 px-2 flex-column d-flex"
          >
            <Nav.Link href={footer4.link5}>
              <strong>{footer4.link5Title}</strong>
            </Nav.Link>
            <Nav.Link href={footer4.link6}>
              <strong>{footer4.link6Title}</strong>
            </Nav.Link>
            <Nav.Link href={footer4.link7}>
              <strong>{footer4.link7Title}</strong>
            </Nav.Link>
          </Col>
        </Col>
        {footer4.showSocials &&
          (footer4.showIG ||
            footer4.showFB ||
            footer4.showX ||
            footer4.showLI ||
            footer4.showPIN ||
            footer4.showTik) && (
            <Col
              xs={4}
              sm={3}
              md={2}
              lg={2}
              xl={2}
              xxl={2}
              className={`d-flex flex-column gap-3 ${
                isPreview ? "preview-scaling" : ""
              } ${selectedField === "Social Media Icons" ? "focus-field" : ""}`}
            >
              {footer4.showIG && (
                <Nav.Link
                  href={"https://" + footer4.socialLinkIG}
                  target="_blank"
                  className="text-black"
                >
                  <Instagram
                    className={`${
                      selectedField === "Instagram" ? "focus-field" : ""
                    }`}
                  />
                </Nav.Link>
              )}
              {footer4.showFB && (
                <Nav.Link
                  href={"https://" + footer4.socialLinkFB}
                  target="_blank"
                  className="text-black"
                >
                  <Facebook
                    className={`${
                      selectedField === "Facebook" ? "focus-field" : ""
                    }`}
                  />
                </Nav.Link>
              )}
              {footer4.showX && (
                <Nav.Link
                  href={"https://" + footer4.socialLinkX}
                  target="_blank"
                  className="text-black"
                >
                  <Twitter
                    className={`${selectedField === "X" ? "focus-field" : ""}`}
                  />
                </Nav.Link>
              )}
              {footer4.showPIN && (
                <Nav.Link
                  href={"https://" + footer4.socialLinkPIN}
                  target="_blank"
                  className="text-black"
                >
                  <Pinterest
                    className={`${
                      selectedField === "Pinterest" ? "focus-field" : ""
                    }`}
                  />
                </Nav.Link>
              )}
              {footer4.showLI && (
                <Nav.Link
                  href={"https://" + footer4.socialLinkLI}
                  target="_blank"
                  className="text-black"
                >
                  <Linkedin
                    className={`${
                      selectedField === "LinkedIn" ? "focus-field" : ""
                    }`}
                  />
                </Nav.Link>
              )}
              {footer4.showTik && (
                <Nav.Link
                  href={"https://" + footer4.socialLinkTik}
                  target="_blank"
                  className="text-black"
                >
                  <Tiktok
                    className={`${
                      selectedField === "TikTok" ? "focus-field" : ""
                    }`}
                  />
                </Nav.Link>
              )}
            </Col>
          )}
      </Row>
      <Row
        className={`d-flex justify-content-center py-4 ${
          isPreview ? "preview-scaling" : ""
        }`}
      >
        {footer4.showCompanyFooter && (
          <Col xs={6} sm={5} md={5} lg={5} xl={5} xxl={4}>
            <small
              className={`${
                selectedField === "Company Footer" ? "focus-field" : ""
              }`}
            >
              <CCircle size={12} /> {footer4.companyFooter}
            </small>
          </Col>
        )}
        {(footer4.showPrivacy || footer4.showTos) && (
          <Col
            xs={6}
            sm={5}
            md={5}
            lg={4}
            xl={4}
            xxl={4}
            className="d-flex align-items-center justify-content-end"
          >
            <div className="mt-auto d-flex gap-3">
              {footer4.showPrivacy && (
                <Nav.Link
                  target="_blank"
                  href={"https://" + footer4.privacyPolicyLink}
                >
                  <small
                    className={`${
                      selectedField === "Privacy Policy" ? "focus-field" : ""
                    }`}
                  >
                    Privacy Policy
                  </small>
                </Nav.Link>
              )}
              {footer4.showTos && (
                <Nav.Link
                  target="_blank"
                  href={"https://" + footer4.termsOfServiceLink}
                >
                  <small
                    className={`${
                      selectedField === "Terms of Service" ? "focus-field" : ""
                    }`}
                  >
                    Terms of Service
                  </small>
                </Nav.Link>
              )}
            </div>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default Footer4;
