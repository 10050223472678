import React from "react";
import { Form, Card } from "react-bootstrap";

const ButtonFormComponent = ({
  showButton,
  setShowButton,
  buttonText,
  setButtonText,
  buttonLink,
  setButtonLink,
  btnNumber,
  setSelectedField,
  selectedField,
}) => {
  return (
    <Card
      className={`col-3 px-0 ${
        selectedField === "button" + btnNumber ? "focus-field" : "focus-hover"
      }`}
      onClick={() => setSelectedField("button" + btnNumber)}
    >
      <Card.Body>
        <Card.Title>Button {btnNumber}</Card.Title>
        <Form.Group>
          <Form.Check
            type="checkbox"
            name="button"
            value={"button" + btnNumber}
            label="Display"
            checked={showButton}
            onChange={(e) => setShowButton(e.target.checked)}
          />
          <Form.Control
            type="text"
            className="my-2"
            placeholder="Enter Text"
            value={buttonText}
            onChange={(e) => setButtonText(e.target.value)}
          />
          <Form.Control
            type="text"
            placeholder="Enter URL"
            value={buttonLink}
            onChange={(e) => setButtonLink(e.target.value)}
          />
        </Form.Group>
      </Card.Body>
    </Card>
  );
};

export default ButtonFormComponent;
