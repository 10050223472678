import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import RenderIconComponent from "../../../ApplicationComponents/DashboardComponents/ComponentChoices/ChoicesComponents/RenderIconComponent";

const CA11 = ({ isPreview, holderState, selectedField }) => {
  const [centerAlign11, setCenterAlign11] = useState({
    text1:
      holderState?.text1Value !== undefined
        ? holderState.text1Value
        : "Long headline to turn your visitors into users",
    showText1:
      holderState?.showText1 !== undefined ? holderState.showText1 : true,
    text2:
      holderState?.text2Value !== undefined
        ? holderState.text2Value
        : "With the new AirPlay 2, you can control your home audio system and the speakers throughout your house.4 You can play a song in the living room and your kitchen at the same time.",
    showText2:
      holderState?.showText2 !== undefined ? holderState.showText2 : true,
    header1: holderState?.header1 !== undefined ? holderState.header1 : "Pay",
    showHeader1:
      holderState?.showHeader1 !== undefined ? holderState.showHeader1 : true,
    header2: holderState?.header2 !== undefined ? holderState.header2 : "Pay",
    showHeader2:
      holderState?.showHeader2 !== undefined ? holderState.showHeader2 : true,
    header3: holderState?.header3 !== undefined ? holderState.header3 : "Pay",
    showHeader3:
      holderState?.showHeader3 !== undefined ? holderState.showHeader3 : true,
    header4: holderState?.header4 !== undefined ? holderState.header4 : "PAY",
    showHeader4:
      holderState?.showHeader4 !== undefined ? holderState.showHeader4 : true,
    header1Icon: {
      type:
        holderState?.header1Icon?.type !== undefined
          ? holderState.header1Icon.type
          : "Paypal",
      size:
        holderState?.header1Icon?.size !== undefined
          ? holderState.header1Icon.size
          : isPreview
          ? 7
          : 22,
    },
    header2Icon: {
      type:
        holderState?.header2Icon?.type !== undefined
          ? holderState.header2Icon.type
          : "Apple",
      size:
        holderState?.header2Icon?.size !== undefined
          ? holderState.header2Icon.size
          : isPreview
          ? 7
          : 22,
    },
    header3Icon: {
      type:
        holderState?.header3Icon?.type !== undefined
          ? holderState.header3Icon.type
          : "Android2",
      size:
        holderState?.header3Icon?.size !== undefined
          ? holderState.header3Icon.size
          : isPreview
          ? 7
          : 22,
    },
    header4Icon: {
      type:
        holderState?.header4Icon?.type !== undefined
          ? holderState.header4Icon.type
          : "",
      size:
        holderState?.header4Icon?.size !== undefined
          ? holderState.header4Icon.size
          : isPreview
          ? 7
          : 22,
    },
    showHeader1Icon:
      holderState?.showHeader1Icon !== undefined
        ? holderState.showHeader1Icon
        : true,
    showHeader2Icon:
      holderState?.showHeader2Icon !== undefined
        ? holderState.showHeader2Icon
        : true,
    showHeader3Icon:
      holderState?.showHeader3Icon !== undefined
        ? holderState.showHeader3Icon
        : true,
    showHeader4Icon:
      holderState?.showHeader4Icon !== undefined
        ? holderState.showHeader4Icon
        : true,
    header1IconLink:
      holderState?.header1IconLink !== undefined
        ? holderState.header1IconLink
        : "",
    header2IconLink:
      holderState?.header2IconLink !== undefined
        ? holderState.header2IconLink
        : "",
    header3IconLink:
      holderState?.header3IconLink !== undefined
        ? holderState.header3IconLink
        : "",
    header4IconLink:
      holderState?.header4IconLink !== undefined
        ? holderState.header4IconLink
        : "",
  });

  useEffect(() => {
    setCenterAlign11((prevCA11) => ({
      ...prevCA11,
      text1:
        holderState?.text1Value !== undefined
          ? holderState.text1Value
          : "Long headline to turn your visitors into users",
      showText1:
        holderState?.showText1 !== undefined ? holderState.showText1 : true,
      text2:
        holderState?.text2Value !== undefined
          ? holderState.text2Value
          : "With the new AirPlay 2, you can control your home audio system and the speakers throughout your house.4 You can play a song in the living room and your kitchen at the same time.",
      showText2:
        holderState?.showText2 !== undefined ? holderState.showText2 : true,
      header1: holderState?.header1 !== undefined ? holderState.header1 : "Pay",
      showHeader1:
        holderState?.showHeader1 !== undefined ? holderState.showHeader1 : true,
      header2: holderState?.header2 !== undefined ? holderState.header2 : "Pay",
      showHeader2:
        holderState?.showHeader2 !== undefined ? holderState.showHeader2 : true,
      header3: holderState?.header3 !== undefined ? holderState.header3 : "Pay",
      showHeader3:
        holderState?.showHeader3 !== undefined ? holderState.showHeader3 : true,
      header4: holderState?.header4 !== undefined ? holderState.header4 : "PAY",
      showHeader4:
        holderState?.showHeader4 !== undefined ? holderState.showHeader4 : true,
      header1Icon: {
        type:
          holderState?.header1Icon?.type !== undefined
            ? holderState.header1Icon.type
            : "Paypal",
        size:
          holderState?.header1Icon?.size !== undefined
            ? holderState.header1Icon.size
            : isPreview
            ? 7
            : 22,
      },
      header2Icon: {
        type:
          holderState?.header2Icon?.type !== undefined
            ? holderState.header2Icon.type
            : "Apple",
        size:
          holderState?.header2Icon?.size !== undefined
            ? holderState.header2Icon.size
            : isPreview
            ? 7
            : 22,
      },
      header3Icon: {
        type:
          holderState?.header3Icon?.type !== undefined
            ? holderState.header3Icon.type
            : "Android2",
        size:
          holderState?.header3Icon?.size !== undefined
            ? holderState.header3Icon.size
            : isPreview
            ? 7
            : 22,
      },
      header4Icon: {
        type:
          holderState?.header4Icon?.type !== undefined
            ? holderState.header4Icon.type
            : "",
        size:
          holderState?.header4Icon?.size !== undefined
            ? holderState.header4Icon.size
            : isPreview
            ? 7
            : 22,
      },
      showHeader1Icon:
        holderState?.showHeader1Icon !== undefined
          ? holderState.showHeader1Icon
          : true,
      showHeader2Icon:
        holderState?.showHeader2Icon !== undefined
          ? holderState.showHeader2Icon
          : true,
      showHeader3Icon:
        holderState?.showHeader3Icon !== undefined
          ? holderState.showHeader3Icon
          : true,
      showHeader4Icon:
        holderState?.showHeader4Icon !== undefined
          ? holderState.showHeader4Icon
          : true,
      header1IconLink:
        holderState?.header1IconLink !== undefined
          ? holderState.header1IconLink
          : "",
      header2IconLink:
        holderState?.header2IconLink !== undefined
          ? holderState.header2IconLink
          : "",
      header3IconLink:
        holderState?.header3IconLink !== undefined
          ? holderState.header3IconLink
          : "",
      header4IconLink:
        holderState?.header4IconLink !== undefined
          ? holderState.header4IconLink
          : "",
    }));
  }, [
    holderState?.showText1,
    holderState?.text1Value,
    holderState?.showText2,
    holderState?.text2Value,
    holderState?.header1,
    holderState?.showHeader1,
    holderState?.header2,
    holderState?.showHeader2,
    holderState?.header3,
    holderState?.showHeader3,
    holderState?.header4,
    holderState?.showHeader4,
    holderState?.showHeader1Icon,
    holderState?.showHeader2Icon,
    holderState?.showHeader3Icon,
    holderState?.showHeader4Icon,
    holderState?.header1IconLink,
    holderState?.header2IconLink,
    holderState?.header3IconLink,
    holderState?.header4IconLink,
    holderState?.header1Icon,
    holderState?.header2Icon,
    holderState?.header3Icon,
    holderState?.header4Icon,
    holderState?.header1Icon?.size,
    holderState?.header1Icon?.type,
    holderState?.header2Icon?.size,
    holderState?.header2Icon?.type,
    holderState?.header3Icon?.size,
    holderState?.header3Icon?.type,
    holderState?.header4Icon?.size,
    holderState?.header4Icon?.type,
    isPreview,
  ]);

  return (
    <Row
      sm={12}
      md={12}
      lg={10}
      xl={10}
      xxl={8}
      style={{ "--bs-gutter-x": "0" }}
      className={`d-flex justify-content-center bg-white ${
        isPreview ? "p-3" : "p-5"
      }`}
    >
      <Col
        xs={12}
        md={12}
        lg={10}
        xl={10}
        className="d-flex flex-column align-items-center justify-content-center"
      >
        <Col
          xs={12}
          sm={10}
          md={10}
          lg={7}
          xl={6}
          xxl={6}
          className="pt-3 text-center"
        >
          {centerAlign11.showText1 && (
            <h2
              className={`fw-bold ${isPreview ? "preview-scaling" : "pt-3"} ${
                selectedField === "text1" ? "focus-field" : ""
              }`}
            >
              {centerAlign11.text1}
            </h2>
          )}
          {centerAlign11.showText2 && (
            <p
              className={`px-3 ${isPreview ? "preview-scaling" : "py-2"} ${
                selectedField === "text2" ? "focus-field" : ""
              }`}
            >
              {centerAlign11.text2}
            </p>
          )}
        </Col>
        <Col className="d-flex gap-4 py-3">
          <p
            className={`fw-semibold d-flex align-items-center ${
              isPreview ? "preview-scaling" : ""
            }`}
          >
            {centerAlign11.showHeader1Icon && (
              <span
                className={`${
                  selectedField === "header1Icon" ? "focus-field" : ""
                }`}
              >
                {RenderIconComponent({
                  iconType: centerAlign11.header1Icon.type,
                  iconSize:
                    isPreview === true ? 11 : centerAlign11.header1Icon.size,
                })}
              </span>
            )}
            {centerAlign11.showHeader1 && (
              <span
                className={`${
                  selectedField === "header1" ? "focus-field" : ""
                }`}
              >
                {centerAlign11.header1}
              </span>
            )}
          </p>
          <p
            className={`fw-semibold d-flex align-items-center ${
              isPreview ? "preview-scaling" : ""
            }`}
          >
            {centerAlign11.showHeader2Icon && (
              <span
                className={`${
                  selectedField === "header2Icon" ? "focus-field" : ""
                }`}
              >
                {RenderIconComponent({
                  iconType: centerAlign11.header2Icon.type,
                  iconSize:
                    isPreview === true ? 11 : centerAlign11.header2Icon.size,
                })}
              </span>
            )}
            {centerAlign11.showHeader2 && (
              <span
                className={`${
                  selectedField === "header2" ? "focus-field" : ""
                }`}
              >
                {centerAlign11.header2}
              </span>
            )}
          </p>
          <p
            className={`fw-semibold d-flex align-items-center ${
              isPreview ? "preview-scaling" : ""
            }`}
          >
            {centerAlign11.showHeader3Icon && (
              <span
                className={`${
                  selectedField === "header3Icon" ? "focus-field" : ""
                }`}
              >
                {RenderIconComponent({
                  iconType: centerAlign11.header3Icon.type,
                  iconSize:
                    isPreview === true ? 11 : centerAlign11.header3Icon.size,
                })}
              </span>
            )}
            {centerAlign11.showHeader3 && (
              <span
                className={`${
                  selectedField === "header3" ? "focus-field" : ""
                }`}
              >
                {centerAlign11.header3}
              </span>
            )}
          </p>
          <p
            className={`fw-semibold d-flex align-items-center ${
              isPreview ? "preview-scaling" : ""
            }`}
          >
            {centerAlign11.showHeader4Icon && (
              <span
                className={`${
                  selectedField === "header4Icon" ? "focus-field" : ""
                }`}
              >
                {RenderIconComponent({
                  iconType: centerAlign11.header4Icon.type,
                  iconSize:
                    isPreview === true ? 11 : centerAlign11.header4Icon.size,
                })}
              </span>
            )}
            {centerAlign11.showHeader4 && (
              <span
                className={`${
                  selectedField === "header4" ? "focus-field" : ""
                }`}
              >
                {centerAlign11.header4}
              </span>
            )}
          </p>
        </Col>
      </Col>
    </Row>
  );
};

export default CA11;
