import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { PlayCircle } from "react-bootstrap-icons";
import RenderIconComponent from "../../../ApplicationComponents/DashboardComponents/ComponentChoices/ChoicesComponents/RenderIconComponent";

const CA15 = ({ isPreview, holderState, selectedField }) => {
  const [centerAlign15, setCenterAlign15] = useState({
    icon1: {
      type:
        holderState?.icon1?.type !== undefined
          ? holderState.icon1.type
          : "Grid1x2",
      size:
        holderState?.icon1?.size !== undefined
          ? holderState.icon1.size
          : isPreview
          ? 12
          : 36,
    },
    icon1Link:
      holderState?.icon1Link !== undefined ? holderState.icon1Link : "",
    showIcon1:
      holderState?.showIcon1 !== undefined ? holderState.showIcon1 : true,
    text1:
      holderState?.text1Value !== undefined
        ? holderState.text1Value
        : "Medium Length Title",
    showText1:
      holderState?.showText1 !== undefined ? holderState.showText1 : true,
    text2:
      holderState?.text2Value !== undefined
        ? holderState.text2Value
        : "Change the color to match your brand or vision, add your logo, choose the perfect thumbnail, remove the playbar, add speed controls, and more. Increase engagement with CTAs and custom end screens, or keep your video private and password-protected.",
    showText2:
      holderState?.showText2 !== undefined ? holderState.showText2 : true,
    link1Title:
      holderState?.link1Title !== undefined
        ? holderState.link1Title
        : "Long text on Link",
    link1Value:
      holderState?.link1Value !== undefined ? holderState.link1Value : "",
    showLink1:
      holderState?.showLink1 !== undefined ? holderState.showLink1 : true,
  });

  useEffect(() => {
    setCenterAlign15((prevCA15) => ({
      ...prevCA15,
      icon1: {
        type:
          holderState?.icon1?.type !== undefined
            ? holderState.icon1.type
            : "Grid1x2",
        size:
          holderState?.icon1?.size !== undefined
            ? holderState.icon1.size
            : isPreview
            ? 12
            : 36,
      },
      icon1Link:
        holderState?.icon1Link !== undefined ? holderState.icon1Link : "",
      showIcon1:
        holderState?.showIcon1 !== undefined ? holderState.showIcon1 : true,
      text1:
        holderState?.text1Value !== undefined
          ? holderState.text1Value
          : "Medium Length Title",
      showText1:
        holderState?.showText1 !== undefined ? holderState.showText1 : true,
      text2:
        holderState?.text2Value !== undefined
          ? holderState.text2Value
          : "Change the color to match your brand or vision, add your logo, choose the perfect thumbnail, remove the playbar, add speed controls, and more. Increase engagement with CTAs and custom end screens, or keep your video private and password-protected.",
      showText2:
        holderState?.showText2 !== undefined ? holderState.showText2 : true,
      link1Title:
        holderState?.link1Title !== undefined
          ? holderState.link1Title
          : "Long text on Link",
      link1Value:
        holderState?.link1Value !== undefined ? holderState.link1Value : "",
      showLink1:
        holderState?.showLink1 !== undefined ? holderState.showLink1 : true,
    }));
  }, [
    holderState?.text1Value,
    holderState?.showText1,
    holderState?.text2Value,
    holderState?.showText2,
    holderState?.link1Title,
    holderState?.link1Value,
    holderState?.showLink1,
    holderState?.icon1?.type,
    holderState?.icon1?.size,
    holderState?.icon1Link,
    holderState?.showIcon1,
    isPreview,
  ]);

  return (
    <Row
      sm={12}
      md={12}
      lg={10}
      xl={10}
      xxl={8}
      style={{ "--bs-gutter-x": "0" }}
      className={`d-flex justify-content-center bg-white ${
        isPreview ? "p-3" : "p-5"
      }`}
    >
      <Col
        xs={12}
        md={12}
        lg={10}
        xl={10}
        className="d-flex flex-column align-items-center justify-content-center"
      >
        <Col
          xs={12}
          sm={10}
          md={10}
          lg={7}
          xl={6}
          xxl={6}
          className="pt-3 text-center"
        >
          {centerAlign15.showIcon1 && (
            <>
              {centerAlign15.icon1Link !== "" && (
                <a
                  href={"https://" + centerAlign15.icon1Link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`text-black ${
                    selectedField === "icon1" ? "focus-field" : ""
                  }`}
                >
                  {RenderIconComponent({
                    iconType: centerAlign15.icon1.type,
                    iconSize: isPreview === true ? 12 : centerAlign15.icon1.size,
                  })}
                </a>
              )}
              {centerAlign15.icon1Link === "" && (
                <span
                  className={`${
                    selectedField === "icon1" ? "focus-field" : ""
                  }`}
                >
                  {RenderIconComponent({
                    iconType: centerAlign15.icon1.type,
                    iconSize: isPreview === true ? 12 : centerAlign15.icon1.size,
                  })}
                </span>
              )}
            </>
          )}
          {centerAlign15.showText1 && (
            <h4
              className={`fw-semibold pt-3 ${
                isPreview ? "preview-scaling" : ""
              } ${selectedField === "text1" ? "focus-field" : ""}`}
            >
              {centerAlign15.text1}
            </h4>
          )}
          {centerAlign15.showText2 && (
            <p
              className={`${isPreview ? "preview-scaling" : "pt-2"} ${
                selectedField === "text2" ? "focus-field" : ""
              }`}
            >
              {centerAlign15.text2}
            </p>
          )}
        </Col>
        <Col xxl={7} className={`${isPreview ? "" : "py-3"} `}>
          {centerAlign15.showLink1 && (
            <a
              className={`fw-bold link-offset-2 link-underline link-underline-opacity-0 link-underline-opacity-75-hover d-flex justify-content-center align-items-center gap-2 ${
                isPreview ? "preview-scaling" : ""
              } ${selectedField === "link1Value" ? "focus-field" : ""}`}
              href={"https://" + centerAlign15.link1Value}
              target="_blank"
              rel="noopener noreferrer"
            >
              <PlayCircle /> {centerAlign15.link1Title}
            </a>
          )}
        </Col>
      </Col>
    </Row>
  );
};

export default CA15;
