import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { ChevronRight } from "react-bootstrap-icons";

const CA3 = ({ isPreview, holderState, selectedField }) => {
  const [centerAlign3, setCenterAlign3] = useState({
    text1:
      holderState?.text1Value !== undefined
        ? holderState.text1Value
        : "Long headline to turn your visitors into users",
    showText1:
      holderState?.showText1 !== undefined ? holderState.showText1 : true,
    text2:
      holderState?.text2Value !== undefined
        ? holderState.text2Value
        : "Separated they live in Bookmarksgrove right at the coast of the famous Semantics, large language ocean and many more stuff and more more more",
    showText2:
      holderState?.showText2 !== undefined ? holderState.showText2 : true,
    text3:
      holderState?.text3Value !== undefined
        ? holderState.text3Value
        : "Seperated they live in Bookmarkgrove right.",
    showText3:
      holderState?.showText3 !== undefined ? holderState.showText3 : true,
    button1:
      holderState?.button1 !== undefined ? holderState.button1 : "Action",
    button1Link:
      holderState?.button1Link !== undefined ? holderState.button1Link : "",
    showButton1:
      holderState?.showButton1 !== undefined ? holderState.showButton1 : true,
    link1Title:
      holderState?.link1Title !== undefined ? holderState.link1Title : "Link",
    link1Value:
      holderState?.link1Value !== undefined ? holderState.link1Value : "",
    showLink1:
      holderState?.showLink1 !== undefined ? holderState.showLink1 : true,
  });

  useEffect(() => {
    setCenterAlign3((prevCA3) => ({
      ...prevCA3,
      text1:
        holderState?.text1Value !== undefined
          ? holderState.text1Value
          : "Long headline to turn your visitors into users",
      showText1:
        holderState?.showText1 !== undefined ? holderState.showText1 : true,
      text2:
        holderState?.text2Value !== undefined
          ? holderState.text2Value
          : "Separated they live in Bookmarksgrove right at the coast of the famous Semantics, large language ocean and many more stuff and more more more",
      showText2:
        holderState?.showText2 !== undefined ? holderState.showText2 : true,
      text3:
        holderState?.text3Value !== undefined
          ? holderState.text3Value
          : "Seperated they live in Bookmarkgrove right.",
      showText3:
        holderState?.showText3 !== undefined ? holderState.showText3 : true,
      button1:
        holderState?.button1 !== undefined ? holderState.button1 : "Action",
      button1Link:
        holderState?.button1Link !== undefined ? holderState.button1Link : "",
      showButton1:
        holderState?.showButton1 !== undefined ? holderState.showButton1 : true,
      link1Title:
        holderState?.link1Title !== undefined ? holderState.link1Title : "Link",
      link1Value:
        holderState?.link1Value !== undefined ? holderState.link1Value : "",
      showLink1:
        holderState?.showLink1 !== undefined ? holderState.showLink1 : true,
    }));
  }, [
    holderState?.showText1,
    holderState?.text1Value,
    holderState?.showText2,
    holderState?.text2Value,
    holderState?.showText3,
    holderState?.text3Value,
    holderState?.link1Title,
    holderState?.link1Value,
    holderState?.showLink1,
    holderState?.button1,
    holderState?.button1Link,
    holderState?.showButton1,
  ]);

  return (
    <Row
      sm={12}
      md={12}
      lg={10}
      xl={10}
      xxl={8}
      style={{ "--bs-gutter-x": "0" }}
      className={`d-flex justify-content-center bg-white ${
        isPreview ? "p-3" : "p-5"
      }`}
    >
      <Col
        xs={12}
        md={12}
        lg={10}
        xl={10}
        className="d-flex flex-column align-items-center justify-content-center"
      >
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={10}
          xl={8}
          xxl={8}
          className="pt-3 text-center"
        >
          {centerAlign3.showText1 && (
            <h1
              className={`fw-bold ${isPreview ? "preview-scaling" : "py-3"} ${
                selectedField === "text1" ? "focus-field" : ""
              }`}
            >
              {centerAlign3.text1}
            </h1>
          )}
        </Col>
        <Col
          xs={12}
          sm={12}
          md={11}
          lg={9}
          xl={6}
          xxl={6}
          className={`text-center ${isPreview ? "" : "py-3"}`}
        >
          {centerAlign3.showText2 && (
            <p
              className={`${isPreview ? "preview-scaling" : "pb-3"} ${
                selectedField === "text2" ? "focus-field" : ""
              }`}
            >
              {centerAlign3.text2}
            </p>
          )}
          {centerAlign3.showButton1 && (
            <InputGroup className="my-3 gap-2">
              <Form.Control
                varient="dark"
                className={`bg-light ${isPreview ? "preview-scaling p-1" : ""}`}
                size="md"
                placeholder="Placeholder"
              />
              <Form.Control
                varient="dark"
                className={`bg-light ${isPreview ? "preview-scaling p-1" : ""}`}
                size="md"
                placeholder="Placeholder"
              />
              <Button
                className={`rounded ${isPreview ? "preview-scaling p-1" : ""} ${
                  selectedField === "button1" ? "focus-field" : ""
                }`}
                href={"https://" + centerAlign3.button1Link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {centerAlign3.button1}
              </Button>
            </InputGroup>
          )}
          <p className="mb-0 pt-2">
            <small className={`${isPreview ? "preview-scaling" : ""}`}>
              {centerAlign3.showText3 && <span className={`${
                selectedField === "text3" ? "focus-field" : ""
              }`}>{centerAlign3.text3}</span>}
              {centerAlign3.showLink1 && (
                <a
                  href={"https://" + centerAlign3.link1Value}
                  className={`fw-semibold link-offset-2 link-underline link-underline-opacity-0 link-underline-opacity-75-hover px-2 ${
                    selectedField === "link1Value" ? "focus-field" : ""
                  }`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {centerAlign3.link1Title}
                  <ChevronRight size={`${isPreview ? 4 : 12}`} />
                </a>
              )}
            </small>
          </p>
        </Col>
      </Col>
    </Row>
  );
};

export default CA3;
