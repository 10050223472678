import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import RenderIconComponent from "../../../ApplicationComponents/DashboardComponents/ComponentChoices/ChoicesComponents/RenderIconComponent";

const CA10 = ({ isPreview, holderState, selectedField }) => {
  const [centerAlign10, setCenterAlign10] = useState({
    text1:
      holderState?.text1Value !== undefined
        ? holderState.text1Value
        : "Long headline to turn your visitors into users",
    showText1:
      holderState?.showText1 !== undefined ? holderState.showText1 : true,
    text2:
      holderState?.text2Value !== undefined ? holderState.text2Value : "Share:",
    showText2:
      holderState?.showText2 !== undefined ? holderState.showText2 : true,
    icon1: {
      type:
        holderState?.icon1?.type !== undefined
          ? holderState.icon1.type
          : "Facebook",
      size:
        holderState?.icon1?.size !== undefined
          ? holderState.icon1.size
          : isPreview
          ? 11
          : 24,
    },
    showIcon1:
      holderState?.showIcon1 !== undefined ? holderState.showIcon1 : true,
    icon1Link:
      holderState?.icon1Link !== undefined ? holderState.icon1Link : "",
    icon2: {
      type:
        holderState?.icon2?.type !== undefined
          ? holderState.icon2.type
          : "Twitter",
      size:
        holderState?.icon2?.size !== undefined
          ? holderState.icon2.size
          : isPreview
          ? 11
          : 24,
    },
    showIcon2:
      holderState?.showIcon2 !== undefined ? holderState.showIcon2 : true,
    icon2Link:
      holderState?.icon2Link !== undefined ? holderState.icon2Link : "",
    icon3: {
      type:
        holderState?.icon3?.type !== undefined
          ? holderState.icon3.type
          : "Link45deg",
      size:
        holderState?.icon3?.size !== undefined
          ? holderState.icon3.size
          : isPreview
          ? 11
          : 24,
    },
    showIcon3:
      holderState?.showIcon3 !== undefined ? holderState.showIcon3 : true,
    icon3Link:
      holderState?.icon3Link !== undefined ? holderState.icon3Link : "",
  });

  useEffect(() => {
    setCenterAlign10((prevCA10) => ({
      ...prevCA10,
      text1:
        holderState?.text1Value !== undefined
          ? holderState.text1Value
          : "Long headline to turn your visitors into users",
      showText1:
        holderState?.showText1 !== undefined ? holderState.showText1 : true,
      text2:
        holderState?.text2Value !== undefined
          ? holderState.text2Value
          : "Share:",
      showText2:
        holderState?.showText2 !== undefined ? holderState.showText2 : true,
      icon1: {
        type:
          holderState?.icon1?.type !== undefined
            ? holderState.icon1.type
            : "Facebook",
        size:
          holderState?.icon1?.size !== undefined
            ? holderState.icon1.size
            : isPreview
            ? 11
            : 24,
      },
      showIcon1:
        holderState?.showIcon1 !== undefined ? holderState.showIcon1 : true,
      icon1Link:
        holderState?.icon1Link !== undefined ? holderState.icon1Link : "",
      icon2: {
        type:
          holderState?.icon2?.type !== undefined
            ? holderState.icon2.type
            : "Twitter",
        size:
          holderState?.icon2?.size !== undefined
            ? holderState.icon2.size
            : isPreview
            ? 11
            : 24,
      },
      showIcon2:
        holderState?.showIcon2 !== undefined ? holderState.showIcon2 : true,
      icon2Link:
        holderState?.icon2Link !== undefined ? holderState.icon2Link : "",
      icon3: {
        type:
          holderState?.icon3?.type !== undefined
            ? holderState.icon3.type
            : "Link45deg",
        size:
          holderState?.icon3?.size !== undefined
            ? holderState.icon3.size
            : isPreview
            ? 11
            : 24,
      },
      showIcon3:
        holderState?.showIcon3 !== undefined ? holderState.showIcon3 : true,
      icon3Link:
        holderState?.icon3Link !== undefined ? holderState.icon3Link : "",
    }));
  }, [
    holderState?.showText1,
    holderState?.text1Value,
    holderState?.showText2,
    holderState?.text2Value,
    holderState?.showIcon1,
    holderState?.icon1?.type,
    holderState?.icon1?.size,
    holderState?.icon2?.type,
    holderState?.icon2?.size,
    holderState?.icon3?.type,
    holderState?.icon3?.size,
    holderState?.icon1Link,
    holderState?.showIcon2,
    holderState?.icon2Link,
    holderState?.showIcon3,
    holderState?.icon3Link,
    isPreview,
  ]);

  return (
    <Row
      sm={12}
      md={12}
      lg={10}
      xl={10}
      xxl={8}
      style={{ "--bs-gutter-x": "0" }}
      className={`d-flex justify-content-center bg-white ${
        isPreview ? "p-3" : "p-5"
      }`}
    >
      <Col
        xs={12}
        md={12}
        lg={10}
        xl={10}
        className="d-flex flex-column align-items-center justify-content-center"
      >
        <Col
          xs={12}
          sm={10}
          md={10}
          lg={7}
          xl={6}
          xxl={6}
          className="pt-3 text-center"
        >
          {centerAlign10.showText1 && (
            <h2
              className={`fw-bold ${
                isPreview ? "preview-scaling mb-0" : "pt-3"
              } ${selectedField === "text1" ? "focus-field" : ""}`}
            >
              {centerAlign10.text1}
            </h2>
          )}
        </Col>
        <Col className={`d-flex gap-2 ${isPreview ? "" : "py-3"}`}>
          {centerAlign10.showText2 && (
            <p
              className={`mb-0 d-flex align-items-center ${
                isPreview ? "preview-scaling" : ""
              } ${selectedField === "text2" ? "focus-field" : ""}`}
            >
              {centerAlign10.text2}{" "}
            </p>
          )}
          {centerAlign10.showIcon1 && (
            <Button
              href={"https://" + centerAlign10.icon1Link}
              variant="link"
              className={`px-1 text-dark ${
                selectedField === "icon1" ? "focus-field" : ""
              }`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {RenderIconComponent({
                iconType: centerAlign10.icon1.type,
                iconSize: isPreview === true ? 11 : centerAlign10.icon1.size,
              })}
            </Button>
          )}
          {centerAlign10.showIcon2 && (
            <Button
              href={"https://" + centerAlign10.icon2Link}
              variant="link"
              className={`px-1 text-dark ${
                selectedField === "icon2" ? "focus-field" : ""
              }`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {RenderIconComponent({
                iconType: centerAlign10.icon2.type,
                iconSize: isPreview === true ? 11 : centerAlign10.icon2.size,
              })}
            </Button>
          )}
          {centerAlign10.showIcon3 && (
            <Button
              href={"https://" + centerAlign10.icon3Link}
              variant="link"
              className={`px-1 text-dark ${
                selectedField === "icon3" ? "focus-field" : ""
              }`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {RenderIconComponent({
                iconType: centerAlign10.icon3.type,
                iconSize: isPreview === true ? 11 : centerAlign10.icon3.size,
              })}
            </Button>
          )}
        </Col>
      </Col>
    </Row>
  );
};

export default CA10;
