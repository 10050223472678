import React, { useEffect, useState } from "react";
import { Row, Col, Image, Button } from "react-bootstrap";

const CA17 = ({ isPreview, holderState, selectedField }) => {
  const [centerAlign17, setCenterAlign17] = useState({
    text1:
      holderState?.text1Value !== undefined
        ? holderState.text1Value
        : "Long headline for two lines for attention and evolving",
    showText1:
      holderState?.showText1 !== undefined ? holderState.showText1 : true,
    text2:
      holderState?.text2Value !== undefined
        ? holderState.text2Value
        : "With the new AirPlay 2, you can control your home audio system and the speakers throughout your house. You can play a song in the living room and your kitchen at the same time.",
    showText2:
      holderState?.showText2 !== undefined ? holderState.showText2 : true,
    button1:
      holderState?.button1 !== undefined ? holderState.button1 : "Action",
    button1Link:
      holderState?.button1Link !== undefined ? holderState.button1Link : "",
    showButton1:
      holderState?.showButton1 !== undefined ? holderState.showButton1 : true,
    showImg1: holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
    imgLink1: holderState?.imgLink1 !== undefined ? holderState.imgLink1 : "",
    imgSrc1:
      holderState?.imgSrc1 !== undefined
        ? holderState.imgSrc1
        : "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt1: "Placeholder Image Text",
  });

  const imageContainerStyle = {
    display: "flex",
    alignItems: "center",
    height: "100%",
    width: "100%",
    maxHeight: isPreview ? "5rem" : "22rem",
    borderRadius: "1rem",
    overflow: "hidden",
  };
  const imageStyle = {
    objectFit: "cover",
    height: "22rem",
    width: "100%",
    borderRadius: "1rem",
  };

  useEffect(() => {
    setCenterAlign17((prevCA17) => ({
      ...prevCA17,
      text1:
        holderState?.text1Value !== undefined
          ? holderState.text1Value
          : "Long headline for two lines for attention and evolving",
      showText1:
        holderState?.showText1 !== undefined ? holderState.showText1 : true,
      text2:
        holderState?.text2Value !== undefined
          ? holderState.text2Value
          : "With the new AirPlay 2, you can control your home audio system and the speakers throughout your house. You can play a song in the living room and your kitchen at the same time.",
      showText2:
        holderState?.showText2 !== undefined ? holderState.showText2 : true,
      button1:
        holderState?.button1 !== undefined ? holderState.button1 : "Action",
      button1Link:
        holderState?.button1Link !== undefined ? holderState.button1Link : "",
      showButton1:
        holderState?.showButton1 !== undefined ? holderState.showButton1 : true,
      showImg1:
        holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
      imgLink1: holderState?.imgLink1 !== undefined ? holderState.imgLink1 : "",
      imgSrc1:
        holderState?.imgSrc1 !== undefined
          ? holderState.imgSrc1
          : "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgAlt1: "Placeholder Image Text",
    }));
  }, [
    holderState?.showText1,
    holderState?.text1Value,
    holderState?.showText2,
    holderState?.text2Value,
    holderState?.button1,
    holderState?.button1Link,
    holderState?.showButton1,
    holderState?.showImg1,
    holderState?.imgLink1,
    holderState?.imgSrc1,
  ]);

  return (
    <Row
      sm={12}
      md={12}
      lg={10}
      xl={10}
      xxl={8}
      style={{ "--bs-gutter-x": "0" }}
      className={`d-flex justify-content-center bg-white ${
        isPreview ? "p-3" : "p-5"
      }`}
    >
      <Col
        xs={12}
        md={12}
        lg={10}
        xl={10}
        className="d-flex flex-column align-items-center justify-content-center gap-3"
      >
        <Col
          xs={12}
          sm={10}
          md={10}
          lg={7}
          xl={6}
          xxl={6}
          className="text-center"
        >
          {centerAlign17.showText1 && (
            <h3
              className={`fw-bold ${isPreview ? "preview-scaling" : "py-3"} ${
                selectedField === "text1" ? "focus-field" : ""
              }`}
            >
              {centerAlign17.text1}
            </h3>
          )}
          {centerAlign17.showText2 && (
            <p
              className={`mb-0 ${isPreview ? "preview-scaling" : ""} ${
                selectedField === "text2" ? "focus-field" : ""
              }`}
            >
              {centerAlign17.text2}
            </p>
          )}
        </Col>
        <Col xxl={7} className={`text-center ${isPreview ? "" : "py-3"}`}>
          {centerAlign17.showButton1 && (
            <Button
              className={`h-auto ms-2 ${isPreview ? "preview-scaling" : ""} ${
                selectedField === "button1" ? "focus-field" : ""
              }`}
              href={"https://" + centerAlign17.button1Link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {centerAlign17.button1}
            </Button>
          )}
        </Col>
        {centerAlign17.showImg1 && (
          <Col
            className={`text-center justify-content-center ${
              selectedField === "imgSrc1" ? "focus-field" : ""
            }`}
            style={imageContainerStyle}
          >
            {centerAlign17.imgLink1 !== "" && (
              <a
                href={"https://" + centerAlign17.imgLink1}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  src={centerAlign17.imgSrc1}
                  alt={centerAlign17.imgAlt1}
                  style={imageStyle}
                  fluid
                />
              </a>
            )}
            {centerAlign17.imgLink1 === "" && (
              <Image
                src={centerAlign17.imgSrc1}
                alt={centerAlign17.imgAlt1}
                style={imageStyle}
                fluid
              />
            )}
          </Col>
        )}
      </Col>
    </Row>
  );
};

export default CA17;
