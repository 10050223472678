import React, { useEffect, useState } from "react";
import { Row, Col, Image } from "react-bootstrap";
import { ChevronRight } from "react-bootstrap-icons";

const LA4 = ({ isPreview, holderState, selectedField }) => {
  const [leftAlign4, setLeftAlign4] = useState({
    headline:
      holderState?.headline !== undefined ? holderState.headline : "Tagline",
    showHeadline:
      holderState?.showHeadline !== undefined ? holderState.showHeadline : true,
    showText1:
      holderState?.showText1 !== undefined ? holderState.showText1 : true,
    text1:
      holderState?.text1Value !== undefined
        ? holderState.text1Value
        : "Medium Length display headline",
    link1Title:
      holderState?.link1Title !== undefined
        ? holderState.link1Title
        : "Action Link",
    link1Value:
      holderState?.link1Value !== undefined ? holderState.link1Value : "",
    showLink1:
      holderState?.showLink1 !== undefined ? holderState.showLink1 : true,
    showImg1: holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
    imgLink1: holderState?.imgLink1 !== undefined ? holderState.imgLink1 : "",
    imgSrc1:
      holderState?.imgSrc1 !== undefined
        ? holderState.imgSrc1
        : "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt: "Placeholder Image",
  });

  const imageContainerStyle = {
    display: "flex",
    alignItems: "center",
    height: "100%",
    maxHeight: "22rem",
    borderRadius: "1rem",
    overflow: "hidden",
  };
  const imageStyle = {
    objectFit: "cover",
    height: "22rem",
    width: "100%",
    borderRadius: "1rem",
  };

  useEffect(() => {
    setLeftAlign4((prevLA4) => ({
      ...prevLA4,
      headline:
        holderState?.headline !== undefined ? holderState.headline : "Tagline",
      showHeadline:
        holderState?.showHeadline !== undefined
          ? holderState.showHeadline
          : true,
      showText1:
        holderState?.showText1 !== undefined ? holderState.showText1 : true,
      text1:
        holderState?.text1Value !== undefined
          ? holderState.text1Value
          : "Medium Length display headline",
      link1Title:
        holderState?.link1Title !== undefined
          ? holderState.link1Title
          : "Action Link",
      link1Value:
        holderState?.link1Value !== undefined ? holderState.link1Value : "",
      showLink1:
        holderState?.showLink1 !== undefined ? holderState.showLink1 : true,
      showImg1:
        holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
      imgSrc1:
        holderState?.imgSrc1 !== undefined
          ? holderState.imgSrc1
          : "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgLink1: holderState?.imgLink1 !== undefined ? holderState.imgLink1 : "",
    }));
  }, [
    holderState?.showText1,
    holderState?.text1Value,
    holderState?.showHeadline,
    holderState?.headline,
    holderState?.link1Title,
    holderState?.link1Value,
    holderState?.showLink1,
    holderState?.showImg1,
    holderState?.imgLink1,
    holderState?.imgSrc1,
  ]);  

  return (
    <Row
      sm={12}
      md={12}
      lg={10}
      xl={10}
      xxl={8}
      style={{ "--bs-gutter-x": "0" }}
      className={`d-flex justify-content-center bg-white gap-5 ${
        isPreview ? "p-3" : "p-5"
      }`}
    >
      <Col
        xs={12}
        sm={10}
        md={6}
        lg={5}
        xl={5}
        xxl={4}
        className="d-flex flex-column align-items-center justify-content-center"
      >
        <Col
          className={`d-flex flex-column justify-content-center mx-2 ${
            isPreview ? "" : "gap-4"
          }`}
        >
          {leftAlign4.showHeadline && (
            <p
              className={`fw-semibold ${
                isPreview ? "preview-scaling mb-0" : ""
              } ${selectedField === "headline" ? "focus-field" : ""}`}
            >
              {leftAlign4.headline}
            </p>
          )}
          {leftAlign4.showText1 && (
            <h1
              className={`fw-bold ${isPreview ? "preview-scaling mb-0" : ""} ${
                selectedField === "text1" ? "focus-field" : ""
              }`}
            >
              {leftAlign4.text1}
            </h1>
          )}
          {leftAlign4.showLink1 && (
            <a
              className={`fw-bold link-offset-2 link-underline link-underline-opacity-0 link-underline-opacity-75-hover ${
                isPreview ? "preview-scaling" : ""
              } ${selectedField === "link1Value" ? "focus-field" : ""}`}
              href={"https://" + leftAlign4.link1Value}
              target="_blank"
              rel="noopener noreferrer"
            >
              {leftAlign4.link1Title} <ChevronRight />
            </a>
          )}
        </Col>
      </Col>
      <Col
        xs={12}
        sm={10}
        md={5}
        lg={5}
        xl={5}
        xxl={4}
        className={`d-flex align-items-center justify-content-center my-auto ${
          isPreview ? "preview-scaling" : ""
        }`}
      >
        {leftAlign4.showImg1 && (
          <Col
            className={`text-center justify-content-center ${
              selectedField === "imgSrc1" ? "focus-field" : ""
            }`}
            style={imageContainerStyle}
          >
            {leftAlign4.imgLink1 !== "" && (
              <a
                href={"https://" + leftAlign4.imgLink1}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  src={leftAlign4.imgSrc1}
                  alt={leftAlign4.imgAlt1}
                  style={imageStyle}
                  fluid
                />
              </a>
            )}
            {leftAlign4.imgLink1 === "" && (
              <Image
                src={leftAlign4.imgSrc1}
                alt={leftAlign4.imgAlt1}
                style={imageStyle}
                fluid
              />
            )}
          </Col>
        )}
      </Col>
    </Row>
  );
};

export default LA4;
