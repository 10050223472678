import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  OverlayTrigger,
  Tooltip,
  Modal,
  Card,
} from "react-bootstrap";
import {
  ChevronDoubleLeft,
  Trash3,
  CheckCircleFill,
  Eye,
  Pencil,
} from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { setFooterState } from "../../../../../reducers/footerStateReducer";
import { useDispatch } from "react-redux";
import "../../dashboard.css";
import UniversalFooterForm from ".././ChoicesComponents/UniversalFooterForm";
import RenderFooterComponent from ".././ChoicesComponents/RenderFooterComponent";
import { publishPage } from "../../../../../Services/PageService";

const EditFooterChoices = ({
  setSelectedFooter,
  slideNumber,
  previousFooterChoice,
  previousFooterState,
}) => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(previousFooterChoice ? false : true);
  const [footerStateChanged, setFooterStateChanged] = useState(false);
  const [slide, setSlide] = useState(slideNumber || 1);
  const [socialIconsChecked, setSocialIconsChecked] = useState(
    previousFooterState.socialIconsChecked || true
  );
  const [showIGChecked, setShowIGChecked] = useState(true);
  const [showFbChecked, setShowFbChecked] = useState(true);
  const [showXChecked, setShowXChecked] = useState(true);
  const [showPINChecked, setShowPINChecked] = useState(true);
  const [showTikChecked, setShowTikChecked] = useState(true);
  const [showLIChecked, setShowLIChecked] = useState(true);
  const [showCompanyFooter, setShowCompanyFooter] = useState(true);
  const [showPrivacy, setShowPrivacy] = useState(true);
  const [showTos, setShowTos] = useState(true);
  const [showMadeIn, setShowMadeIn] = useState(true);
  const [showLegal, setShowLegal] = useState(true);
  const [showLanguage, setShowLanguage] = useState(
    previousFooterState.showLanguage || true
  );
  const [showSiteMap, setShowSiteMap] = useState(
    previousFooterState.showSiteMap || true
  );
  const [showSendEmail, setShowSendEmail] = useState(previousFooterState.showSendEmail ||true);
  const [showEmail, setShowEmail] = useState(
    previousFooterState.showEmail || true
  );
  const [showText1, setShowText1] = useState(
    previousFooterState.showText1 || true
  );
  const [showAddress, setShowAddress] = useState(true);
  const [showPhone, setShowPhone] = useState(true);
  const [legLink, setLegLink] = useState(previousFooterState.legLink || "");
  const [siteLink, setSiteLink] = useState(
    previousFooterState.siteLink || ""
  );
  const [igLink, setIGLink] = useState(previousFooterState.igLink || "");
  const [fbLink, setFBLink] = useState(previousFooterState.fbLink || "");
  const [xLink, setXLink] = useState(previousFooterState.xLink || "");
  const [pinLink, setPINLink] = useState(previousFooterState.pinLink || "");
  const [tikLink, setTikLink] = useState(previousFooterState.tikLink || "");
  const [liLink, setLILink] = useState(previousFooterState.liLink || "");
  const [privacyLink, setPrivacyLink] = useState(
    previousFooterState.privacyLink || ""
  );
  const [tosLink, setTosLink] = useState(previousFooterState.tosLink || "");
  const [text1Value, setText1Value] = useState(
    previousFooterState.text1Value || ""
  );
  const [streetValue, setStreetValue] = useState(previousFooterState.streetValue || null);
  const [cityValue, setCityValue] = useState(
    previousFooterState.cityValue || null
  );
  const [phoneValue, setPhoneValue] = useState(
    previousFooterState.phoneValue || null
  );
  const [emailValue, setEmailValue] = useState(
    previousFooterState.emailValue || null
  );
  const [madeInValue, setMadeInValue] = useState(
    previousFooterState.madeInValue || null
  );
  const [companyFooterValue, setCompanyFooterValue] = useState(
    previousFooterState.companyFooterValue || null
  );
  const [footerChoice, setFooterChoice] = useState(
    previousFooterChoice || null
  );
  const [selectedField, setSelectedField] = useState(null);
  const footers = useSelector((state) => state.footers);

  const footerTemplates = useMemo(
    () => ({ footerTemplates: footers }),
    [footers]
  );
  const selectedFooter = useSelector((state) =>
    state.footers.find((footer) => footer.name === footerChoice)
  );
  const [showModal, setShowModal] = useState(false);
  const holderState = useSelector((state) => state.footerStates);

  const resetChoice = () => {
    setSlide(1);
    setSelectedFooter(null);
    setFooterChoice(null);
    setDisabled(true);
  };

  const goBack = () => {
    setSlide(2);
    setSelectedFooter(null);
  };

  const changeShowIcons = (x) => {
    setSocialIconsChecked(x);
    const footerState = {
      socialIconsChecked: socialIconsChecked,
      showIGChecked: showIGChecked,
      showFbChecked: showFbChecked,
      showXChecked: showXChecked,
      showPINChecked: showPINChecked,
      showTikChecked: showTikChecked,
      showLIChecked: showLIChecked,
      showCompanyFooter: showCompanyFooter,
      showPrivacy: showPrivacy,
      showTos: showTos,
      showMadeIn: showMadeIn,
      showLegal: showLegal,
      showSiteMap: showSiteMap,
      showText1: showText1,
      showEmail: showEmail,
      showSendEmail: showSendEmail,
      showAddress: showAddress,
      showPhone: showPhone,
      showLanguage: showLanguage,
      igLink: igLink,
      fbLink: fbLink,
      xLink: xLink,
      pinLink: pinLink,
      tikLink: tikLink,
      liLink: liLink,
      text1Value: text1Value,
      streetValue: streetValue,
      cityValue: cityValue,
      phoneValue: phoneValue,
      emailValue: emailValue,
      madeInValue: madeInValue,
      companyFooterValue: companyFooterValue,
      privacyLink: privacyLink,
      tosLink: tosLink,
      legLink: legLink,
      siteLink: siteLink,
    };
    dispatch(setFooterState(footerState));
  };

    const saveFooterChanges = async () => {
      const footerState = holderState

      const pageObject = {
        pageName: "FOOTER",
        body: [
          {
            rowIdState: [
              {
                bodyState: {
                  button1: undefined,
                  button1Link: undefined,
                  header1: undefined,
                  header1Icon: undefined,
                  header1IconLink: undefined,
                  header2: undefined,
                  header2Icon: undefined,
                  header2IconLink: undefined,
                  header3: undefined,
                  header3Icon: undefined,
                  header3IconLink: undefined,
                  header4: undefined,
                  header4Icon: undefined,
                  header4IconLink: undefined,
                  headline: "First Reducer",
                  icon1: undefined,
                  icon1Link: undefined,
                  icon2: undefined,
                  icon2Link: undefined,
                  imgLink1: undefined,
                  imgLink2: undefined,
                  imgLink3: undefined,
                  imgSrc1: undefined,
                  imgSrc2: undefined,
                  imgSrc3: undefined,
                  link1Title: undefined,
                  link1Value: undefined,
                  list1: undefined,
                  list2: undefined,
                  list3: undefined,
                  list4: undefined,
                  showButton1: true,
                  showHeader1: true,
                  showHeader1Icon: true,
                  showHeader2: true,
                  showHeader2Icon: true,
                  showHeader3: true,
                  showHeader3Icon: true,
                  showHeader4: true,
                  showHeader4Icon: true,
                  showHeadline: true,
                  showIcon1: true,
                  showIcon2: true,
                  showImg1: true,
                  showImg2: true,
                  showImg3: true,
                  showLink1: true,
                  showList1: true,
                  showList2: true,
                  showList3: true,
                  showList4: true,
                  showText1: false,
                  showText2: true,
                  showText3: true,
                  showText4: true,
                  text1Value: undefined,
                  text2Value: undefined,
                  text3Value: undefined,
                  text4Value: undefined,
                },
                rowId: 1,
              },
            ],
            selectedBodyRows: [
              {
                bodyRowComponent: {
                  componentName: "LA1",
                  props: {
                    headline: "Medium Length display headline",
                    imgAlt1: "Placeholder Img",
                    imgLink1: "",
                    imgSrc1:
                      "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
                    link1Title: "Action Link",
                    link1Value: "",
                    text1:
                      "Separated they live in Bookmarks right at the coast of the famous Semantics, large language ocean Seperated they live in the Bookmarks right",
                  },
                },
                rowId: 1,
                name: "LA1",
              },
            ],
          },
        ],
        footer: [
          {
            selectedFooter,
            footerState,
          },
        ],
        navbar: [
          {
            id: 1,
            name: "Navbar1",
            navComponent: {
              componentName: "Navbar1",
              props: {
                companyName: "Company",
              },
            },
          },
        ],
        details: "FOOTER - Holds Footer state and selectedFooter obj",
      };
      try {
        const response = await publishPage(pageObject);
              console.log(holderState);
        console.log("API Response:", response);
      } catch (error) {
              console.log(holderState);
        console.error("Error creating page:", error);
      }
    };

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const handleFooterChange = (e) => {
    setFooterChoice(e.target.value);
    setDisabled(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleSave = (e) => {
    e.preventDefault();
    setFooterChoice(footerChoice);
    setSlide(3);
  };

  useEffect(() => {
    if (!footerStateChanged) {
      const footerState = previousFooterState;
      setSocialIconsChecked(previousFooterState.socialIconsChecked)
      setShowEmail(previousFooterState.showEmail)
      setShowIGChecked(previousFooterState.showIGChecked)
      setShowFbChecked(previousFooterState.showFbChecked)
      setShowXChecked(previousFooterState.showXChecked)
      setShowPINChecked(previousFooterState.showPINChecked)
      setShowTikChecked(previousFooterState.showTikChecked)
      setShowLIChecked(previousFooterState.showLIChecked)
      setShowCompanyFooter(previousFooterState.showCompanyFooter)
      setShowPrivacy(previousFooterState.showPrivacy)
      setShowTos(previousFooterState.showTos)
      setShowMadeIn(previousFooterState.showMadeIn)
      setShowLegal(previousFooterState.showLegal)
      setShowSiteMap(previousFooterState.showSiteMap)
      setShowText1(previousFooterState.showText1)
      setShowSendEmail(previousFooterState.showSendEmail)
      setShowAddress(previousFooterState.showAddress)
      setShowPhone(previousFooterState.showPhone)
      setShowLanguage(previousFooterState.showLanguage)
      dispatch(setFooterState(footerState));
    } 
    else {
      const footerState = {
        socialIconsChecked: socialIconsChecked,
        showIGChecked: showIGChecked,
        showFbChecked: showFbChecked,
        showXChecked: showXChecked,
        showPINChecked: showPINChecked,
        showTikChecked: showTikChecked,
        showLIChecked: showLIChecked,
        showCompanyFooter: showCompanyFooter,
        showPrivacy: showPrivacy,
        showTos: showTos,
        showMadeIn: showMadeIn,
        showLegal: showLegal,
        showSiteMap: showSiteMap,
        showText1: showText1,
        showEmail: showEmail,
        showSendEmail: showSendEmail,
        showAddress: showAddress,
        showPhone: showPhone,
        showLanguage: showLanguage,
        igLink: igLink,
        fbLink: fbLink,
        xLink: xLink,
        pinLink: pinLink,
        tikLink: tikLink,
        liLink: liLink,
        text1Value: text1Value,
        streetValue: streetValue,
        cityValue: cityValue,
        phoneValue: phoneValue,
        emailValue: emailValue,
        madeInValue: madeInValue,
        companyFooterValue: companyFooterValue,
        privacyLink: privacyLink,
        tosLink: tosLink,
        legLink: legLink,
        siteLink: siteLink,
      };
      dispatch(setFooterState(footerState));
    }
    setFooterStateChanged(true);
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [
    dispatch,
    socialIconsChecked,
    showIGChecked,
    showFbChecked,
    showXChecked,
    showPINChecked,
    showTikChecked,
    showLIChecked,
    showCompanyFooter,
    showPrivacy,
    showTos,
    showMadeIn,
    showLegal,
    showSiteMap,
    showText1,
    showEmail,
    showSendEmail,
    showAddress,
    showPhone,
    showLanguage,
    igLink,
    fbLink,
    xLink,
    pinLink,
    tikLink,
    liLink,
    text1Value,
    streetValue,
    cityValue,
    phoneValue,
    emailValue,
    madeInValue,
    companyFooterValue,
    privacyLink,
    tosLink,
    legLink,
    siteLink,
  ]);

  const renderTooltipEdit = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Edit This Pattern
    </Tooltip>
  );
  const renderTooltipView = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Live Preview
    </Tooltip>
  );
  const renderTooltipReady = (props) => (
    <Tooltip id="button-tooltip" {...props} className="custom-tooltip">
      Ready To Publish
    </Tooltip>
  );
  const renderTooltipDelete = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Delete This
    </Tooltip>
  );

  return (
    <>
      <Container className="pt-3">
        {slide === 1 && (
          <>
            <p>Choose a style for your Footer.</p>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="custom-label-class py-3">
                {footerTemplates.footerTemplates.length > 0 &&
                  footerTemplates.footerTemplates.map((footerTemplate) => (
                    <Form.Check
                      key={footerTemplate.name}
                      className="py-2"
                      type="radio"
                      id={footerTemplate.name}
                      name="navbarChoice"
                      value={footerTemplate.name}
                      label={
                        <div className="unclickable">
                          {RenderFooterComponent(
                            footerTemplate.footComponent.componentName,
                            footerTemplate.footComponent.props
                          )}
                        </div>
                      }
                      onChange={handleFooterChange}
                    />
                  ))}
              </Form.Group>

              <div className="d-flex justify-content-center py-5">
                <Button
                  variant="dark"
                  disabled={disabled}
                  onClick={() => setSlide(2)}
                >
                  CONTINUE
                </Button>
              </div>
            </Form>
          </>
        )}

        {slide === 2 && (
          <Row className="d-flex flex-column">
            <p>Set the Footer options</p>
            <Col className="d-flex justify-content-end">
              <Button variant="dark" onClick={() => resetChoice()}>
                <ChevronDoubleLeft size={18} /> GO BACK
              </Button>
            </Col>
            <Col className="pt-5">
              {footerTemplates.footerTemplates.length > 0 &&
                footerTemplates.footerTemplates.map((footerTemplate) => (
                  <div key={footerTemplate.name}>
                    {footerChoice === footerTemplate.name && (
                      <div className="position-relative">
                        <div className="content-wrapper unclickable">
                          {RenderFooterComponent(
                            footerChoice,
                            { holderState: holderState },
                            false,
                            selectedField
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
            </Col>
            <Row className="gap-4 my-3 d-flex justify-content-around">
              <div>
                {(selectedFooter.footComponent.props.socialLinkIG !==
                  undefined ||
                  selectedFooter.footComponent.props.socialLinkFB !==
                    undefined ||
                  selectedFooter.footComponent.props.socialLinkX !==
                    undefined ||
                  selectedFooter.footComponent.props.socialLinkPIN !==
                    undefined ||
                  selectedFooter.footComponent.props.socialLinkTik !==
                    undefined) && (
                  <Col className="d-flex justify-content-center my-5">
                    <Card
                      className={`col-3 px-0 ${
                        selectedField === "Social Media Icons"
                          ? "focus-field"
                          : "focus-hover"
                      }`}
                      onClick={() => setSelectedField("Social Media Icons")}
                    >
                      <Card.Body>
                        <Card.Title>Social Media Icons</Card.Title>
                        <Form.Check
                          className="py-2"
                          type="checkbox"
                          id="socials"
                          name="socialsChoice"
                          value="Display"
                          label="Display"
                          checked={socialIconsChecked}
                          onChange={(e) => changeShowIcons(e.target.checked)}
                        />
                      </Card.Body>
                    </Card>
                  </Col>
                )}
                {socialIconsChecked === true && (
                  <Col className="d-flex flex-wrap justify-content-center gap-5 mb-5">
                    {selectedFooter.footComponent.props.socialLinkIG !==
                      undefined && (
                      <UniversalFooterForm
                        title={"Instagram"}
                        urlLink={true}
                        setShowProp1={setShowIGChecked}
                        showProp1={showIGChecked}
                        prop1={igLink}
                        setProp1={setIGLink}
                        setSelectedField={setSelectedField}
                        selectedField={selectedField}
                      />
                    )}
                    {selectedFooter.footComponent.props.socialLinkX !==
                      undefined && (
                      <UniversalFooterForm
                        title={"X"}
                        urlLink={true}
                        setShowProp1={setShowXChecked}
                        showProp1={showXChecked}
                        prop1={xLink}
                        setProp1={setXLink}
                        setSelectedField={setSelectedField}
                        selectedField={selectedField}
                      />
                    )}
                    {selectedFooter.footComponent.props.socialLinkLI !==
                      undefined && (
                      <UniversalFooterForm
                        title={"LinkedIn"}
                        urlLink={true}
                        setShowProp1={setShowLIChecked}
                        showProp1={showLIChecked}
                        prop1={liLink}
                        setProp1={setLILink}
                        setSelectedField={setSelectedField}
                        selectedField={selectedField}
                      />
                    )}
                    {selectedFooter.footComponent.props.socialLinkFB !==
                      undefined && (
                      <UniversalFooterForm
                        title={"Facebook"}
                        urlLink={true}
                        setShowProp1={setShowFbChecked}
                        showProp1={showFbChecked}
                        prop1={fbLink}
                        setProp1={setFBLink}
                        setSelectedField={setSelectedField}
                        selectedField={selectedField}
                      />
                    )}
                    {selectedFooter.footComponent.props.socialLinkPIN !==
                      undefined && (
                      <UniversalFooterForm
                        title={"Pinterest"}
                        urlLink={true}
                        setShowProp1={setShowPINChecked}
                        showProp1={showPINChecked}
                        prop1={pinLink}
                        setProp1={setPINLink}
                        setSelectedField={setSelectedField}
                        selectedField={selectedField}
                      />
                    )}
                    {selectedFooter.footComponent.props.socialLinkTik !==
                      undefined && (
                      <UniversalFooterForm
                        title={"TikTok"}
                        urlLink={true}
                        setShowProp1={setShowTikChecked}
                        showProp1={showTikChecked}
                        prop1={tikLink}
                        setProp1={setTikLink}
                        setSelectedField={setSelectedField}
                        selectedField={selectedField}
                      />
                    )}
                  </Col>
                )}
                <Col className="d-flex flex-wrap justify-content-center gap-5">
                  {selectedFooter.footComponent.props.companyFooter !==
                    undefined && (
                    <UniversalFooterForm
                      title={"Company Footer"}
                      setShowProp1={setShowCompanyFooter}
                      showProp1={showCompanyFooter}
                      prop1={companyFooterValue}
                      setProp1={setCompanyFooterValue}
                      setSelectedField={setSelectedField}
                      selectedField={selectedField}
                    />
                  )}
                  {selectedFooter.footComponent.props.privacyPolicy !==
                    undefined && (
                    <UniversalFooterForm
                      title={"Privacy Policy"}
                      setShowProp1={setShowPrivacy}
                      showProp1={showPrivacy}
                      prop1={privacyLink}
                      setProp1={setPrivacyLink}
                      setSelectedField={setSelectedField}
                      selectedField={selectedField}
                    />
                  )}
                  {selectedFooter.footComponent.props.termsOfService !==
                    undefined && (
                    <UniversalFooterForm
                      title={"Terms of Service"}
                      setShowProp1={setShowTos}
                      showProp1={showTos}
                      prop1={tosLink}
                      setProp1={setTosLink}
                      setSelectedField={setSelectedField}
                      selectedField={selectedField}
                    />
                  )}
                  {selectedFooter.footComponent.props.madeIn !== undefined && (
                    <UniversalFooterForm
                      title={"Made In"}
                      setShowProp1={setShowMadeIn}
                      showProp1={showMadeIn}
                      prop1={madeInValue}
                      setProp1={setMadeInValue}
                      setSelectedField={setSelectedField}
                      selectedField={selectedField}
                    />
                  )}
                  {selectedFooter.footComponent.props.legal !== undefined && (
                    <UniversalFooterForm
                      title={"Legal"}
                      setShowProp1={setShowLegal}
                      showProp1={showLegal}
                      prop1={legLink}
                      setProp1={setLegLink}
                      setSelectedField={setSelectedField}
                      selectedField={selectedField}
                    />
                  )}
                  {selectedFooter.footComponent.props.siteMap !== undefined && (
                    <UniversalFooterForm
                      title={"Site Map"}
                      setShowProp1={setShowSiteMap}
                      showProp1={showSiteMap}
                      prop1={siteLink}
                      setProp1={setSiteLink}
                      setSelectedField={setSelectedField}
                      selectedField={selectedField}
                    />
                  )}
                  {selectedFooter.footComponent.props.text1 !== undefined && (
                    <UniversalFooterForm
                      title={"Text 1"}
                      setShowProp1={setShowText1}
                      showProp1={showText1}
                      setProp1={setText1Value}
                      prop1={text1Value}
                      setSelectedField={setSelectedField}
                      selectedField={selectedField}
                    />
                  )}
                  {selectedFooter.footComponent.props.sendEmail !==
                    undefined && (
                    <UniversalFooterForm
                      title={"Send Email Button"}
                      setShowProp1={setShowSendEmail}
                      showProp1={showSendEmail}
                      setSelectedField={setSelectedField}
                      selectedField={selectedField}
                    />
                  )}
                  {selectedFooter.footComponent.props.email !== undefined && (
                    <UniversalFooterForm
                      title={"Email"}
                      setShowProp1={setShowEmail}
                      showProp1={showEmail}
                      prop1={emailValue}
                      setProp1={setEmailValue}
                      setSelectedField={setSelectedField}
                      selectedField={selectedField}
                    />
                  )}
                  {selectedFooter.footComponent.props.streetAddress !==
                    undefined && (
                    <UniversalFooterForm
                      title={"Address"}
                      setShowProp1={setShowAddress}
                      showProp1={showAddress}
                      prop1={streetValue}
                      setProp1={setStreetValue}
                      prop2={cityValue}
                      setProp2={setCityValue}
                      setSelectedField={setSelectedField}
                      selectedField={selectedField}
                    />
                  )}
                  {selectedFooter.footComponent.props.phoneNumber !==
                    undefined && (
                    <UniversalFooterForm
                      title={"Phone Number"}
                      setShowProp1={setShowPhone}
                      showProp1={showPhone}
                      prop1={phoneValue}
                      setProp1={setPhoneValue}
                      setSelectedField={setSelectedField}
                      selectedField={selectedField}
                    />
                  )}
                  {selectedFooter.footComponent.props.language !==
                    undefined && (
                    <UniversalFooterForm
                      title={"Language"}
                      setShowProp1={setShowLanguage}
                      showProp1={showLanguage}
                      setSelectedField={setSelectedField}
                      selectedField={selectedField}
                    />
                  )}
                </Col>
                <div className="d-flex justify-content-center py-5">
                  <Button
                    variant="dark"
                    disabled={disabled}
                    onClick={handleSave}
                  >
                    ADD THIS FOOTER
                  </Button>
                </div>
              </div>
            </Row>
          </Row>
        )}

        {slide === 3 && (
          <Row className="d-flex flex-column">
            <Col className="d-flex justify-content-between">
              <p className="">Footer is ready to be published.</p>
              <OverlayTrigger
                placement="left"
                delay={{ show: 100, hide: 150 }}
                overlay={renderTooltipReady}
              >
                <CheckCircleFill size={36} style={{ color: "#28A745" }} />
              </OverlayTrigger>
            </Col>

            <Col className="py-3">
              <div className="crud-hover">
                <div>
                  {footerTemplates.footerTemplates.length > 0 &&
                    footerTemplates.footerTemplates.map((footerTemplate) => (
                      <div key={footerTemplate.name}>
                        {footerChoice === footerTemplate.name && (
                          <div className="position-relative">
                            <div className="content-wrapper">
                              {RenderFooterComponent(
                                footerChoice,
                                { holderState: holderState },
                                false
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                </div>

                <div className="crud-hover-overlay d-flex justify-content-center align-items-center">
                  <OverlayTrigger
                    placement="left"
                    delay={{ show: 100, hide: 150 }}
                    overlay={renderTooltipView}
                  >
                    <button className="view-button hover">
                      <Eye onClick={openModal} size={20} />
                    </button>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 100, hide: 150 }}
                    overlay={renderTooltipEdit}
                  >
                    <button className="edit-button">
                      <Pencil onClick={goBack} size={20} />
                    </button>
                  </OverlayTrigger>
                </div>
              </div>
            </Col>

            <Col>
              <div className="d-flex justify-content-end">
                <OverlayTrigger
                  placement="left"
                  delay={{ show: 100, hide: 150 }}
                  overlay={renderTooltipDelete}
                >
                  <Link onClick={() => goBack()} className="text-end">
                    <Trash3 size={18} style={{ color: "black" }} />
                  </Link>
                </OverlayTrigger>
              </div>
            </Col>
            <Col className="d-flex justify-content-center">
              <Button variant="dark" onClick={() => saveFooterChanges()}>
                Update Footer
              </Button>
            </Col>
          </Row>
        )}
      </Container>

      <Modal show={showModal} onHide={closeModal} centered size="xl">
        <Modal.Header className="justify-content-center">
          <Modal.Title>Footer Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {footerChoice &&
            RenderFooterComponent(
              selectedFooter.footComponent.componentName,
              { holderState: holderState },
              false
            )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditFooterChoices;
