import React, { useState, useMemo } from "react";
import { Container, Row, Col, Tooltip, OverlayTrigger } from "react-bootstrap";
import { CheckCircleFill } from "react-bootstrap-icons";
import GalleryChoices from "./GalleryChoices";
import { useSelector } from "react-redux";
import LeftAlignedChoices from "./LeftAlignedChoices";
import RightAlignedChoices from "./RightAlignedChoices";
import CenterAlignedChoices from "./CenterAlignedChoices";
import RenderRowComponent from "./ChoicesComponents/RenderRowComponent";

const PagePatternChoices = ({ setSelectedBodyRow, selectedBodyRow, selectedBodyRows, rowId, setRowId, itemId, handleRemoveAccordionItem, existingRowId, slideName, prevSelection, setStage, stage, setPrevSelection, prevRowIdState }) => {
  const [slide, setSlide] = useState(slideName || "home");
  const [home, setHome] = useState(true); // eslint-disable-line no-unused-vars
  const initialRowId = useMemo(() => (existingRowId ? existingRowId : rowId),[]); // eslint-disable-line react-hooks/exhaustive-deps
  const initialItemId = useMemo(() => itemId, []); // eslint-disable-line react-hooks/exhaustive-deps
  const brOptions = useSelector((state) => state.bodyRowOptions);
  const handleBack = () => {
    setPrevSelection(null);
    setSlide("home");
    setHome(true);
    setSelectedBodyRow(null);
    setStage(1);
  };
  const renderTooltipReady = (props) => (
    <Tooltip id="button-tooltip" {...props} className="custom-tooltip">
      Ready To Publish
    </Tooltip>
  );
  const changeHome = (x) => {
    setHome(x);
  };
  const bodyRowOptions = useMemo(
    () => ({ bodyRowOptions: brOptions }),
    [brOptions]
  );

  return (
    <Container className="py-2">
      {slide === "home" && (
        <>
          <p>Choose a Page Pattern.</p>
          {bodyRowOptions.bodyRowOptions.length > 0 &&
            bodyRowOptions.bodyRowOptions.map((bodyRowOption) => (
              <div key={bodyRowOption.id} className="my-4">
                <div
                  onClick={() => setSlide(bodyRowOption.id)}
                  className="text-decoration-none text-black"
                >
                  <div className="mt-4">
                    <h5>{bodyRowOption.name}</h5>
                  </div>
                  <Col className="p-3 border unclickable">
                    {RenderRowComponent(
                      bodyRowOption.bodyRowComponent.componentName,
                      bodyRowOption.bodyRowComponent.props,
                      false,
                      false,
                      bodyRowOption.id
                    )}
                  </Col>
                </div>
              </div>
            ))}
        </>
      )}
      {slide === "image-patterns" && (
        <Row className="justify-content-center">
          <div className="d-flex justify-content-between py-3">
            <p>Select a Image Pattern then upload your images.</p>
            {selectedBodyRow && (
              <OverlayTrigger
                placement="left"
                delay={{ show: 100, hide: 150 }}
                overlay={renderTooltipReady}
              >
                <div>
                  <CheckCircleFill size={36} style={{ color: "#28A745" }} />
                </div>
              </OverlayTrigger>
            )}
          </div>
          <GalleryChoices
            setHome={changeHome}
            setSelectedBodyRow={setSelectedBodyRow}
            selectedBodyRows={selectedBodyRows}
            rowId={rowId}
            setRowId={setRowId}
            initialRowId={initialRowId}
            itemId={initialItemId}
            handleRemoveAccordionItem={handleRemoveAccordionItem}
            prevSelection={prevSelection}
            setPrevSelection={setPrevSelection}
            stage={stage}
            setStage={setStage}
            handleBack={handleBack}
            prevRowIdState={prevRowIdState}
          />
        </Row>
      )}

      {slide === "left-aligned" && (
        <Row className="justify-content-center">
          <div className="d-flex justify-content-between py-3">
            <p>Select a Left-Aligned pattern.</p>
            {selectedBodyRow && (
              <OverlayTrigger
                placement="left"
                delay={{ show: 100, hide: 150 }}
                overlay={renderTooltipReady}
              >
                <div>
                  <CheckCircleFill size={36} style={{ color: "#28A745" }} />
                </div>
              </OverlayTrigger>
            )}
          </div>
          <LeftAlignedChoices
            setHome={changeHome}
            setSelectedBodyRow={setSelectedBodyRow}
            selectedBodyRows={selectedBodyRows}
            rowId={rowId}
            setRowId={setRowId}
            initialRowId={initialRowId}
            itemId={initialItemId}
            handleRemoveAccordionItem={handleRemoveAccordionItem}
            prevSelection={prevSelection}
            setPrevSelection={setPrevSelection}
            stage={stage}
            setStage={setStage}
            handleBack={handleBack}
            prevRowIdState={prevRowIdState}
          />
        </Row>
      )}
      {slide === "right-aligned" && (
        <Row className="justify-content-center">
          <div className="d-flex justify-content-between py-3">
            <p>Select a Right-Aligned pattern.</p>
            {selectedBodyRow && (
              <OverlayTrigger
                placement="left"
                delay={{ show: 100, hide: 150 }}
                overlay={renderTooltipReady}
              >
                <div>
                  <CheckCircleFill size={36} style={{ color: "#28A745" }} />
                </div>
              </OverlayTrigger>
            )}
          </div>
          <RightAlignedChoices
            setHome={changeHome}
            setSelectedBodyRow={setSelectedBodyRow}
            selectedBodyRows={selectedBodyRows}
            rowId={rowId}
            setRowId={setRowId}
            initialRowId={initialRowId}
            itemId={initialItemId}
            handleRemoveAccordionItem={handleRemoveAccordionItem}
            prevSelection={prevSelection}
            setPrevSelection={setPrevSelection}
            stage={stage}
            setStage={setStage}
            handleBack={handleBack}
            prevRowIdState={prevRowIdState}
          />
        </Row>
      )}
      {slide === "center-aligned" && (
        <Row className="justify-content-center">
          <div className="d-flex justify-content-between py-3">
            <p>Select a Right-Aligned pattern.</p>
            {selectedBodyRow && (
              <OverlayTrigger
                placement="left"
                delay={{ show: 100, hide: 150 }}
                overlay={renderTooltipReady}
              >
                <div>
                  <CheckCircleFill size={36} style={{ color: "#28A745" }} />
                </div>
              </OverlayTrigger>
            )}
          </div>
          <CenterAlignedChoices
            setHome={changeHome}
            setSelectedBodyRow={setSelectedBodyRow}
            selectedBodyRows={selectedBodyRows}
            rowId={rowId}
            setRowId={setRowId}
            initialRowId={initialRowId}
            itemId={initialItemId}
            handleRemoveAccordionItem={handleRemoveAccordionItem}
            prevSelection={prevSelection}
            setPrevSelection={setPrevSelection}
            stage={stage}
            setStage={setStage}
            handleBack={handleBack}
            prevRowIdState={prevRowIdState}
          />
        </Row>
      )}
    </Container>
  );
};

export default PagePatternChoices;
