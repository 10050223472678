import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ChevronDoubleLeft } from "react-bootstrap-icons";
import {
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { addBodyRow, removeBodyRow } from '../../../../reducers/bodyRowsReducer'
import { incrementRowId } from "../../../../reducers/rowIdReducer";
import "../../DashboardComponents/dashboard.css";
import { setBodyState } from "../../../../reducers/bodyStateReducer";
import { pushToRowIdState } from "../../../../reducers/rowIdStateReducer";
import { addImage, deleteImage, } from "../../../../reducers/imgCollectionReducer";
import HeadlineFormComponent from "./ChoicesComponents/HeadlineFormComponent";
import TextFormComponent from "./ChoicesComponents/TextFormComponent";
import LinkFormComponent from "./ChoicesComponents/LinkFormComponent";
import ButtonFormComponent from "./ChoicesComponents/ButtonFormComponent";
import IconFormComponent from "./ChoicesComponents/IconFormComponent";
import HeaderFormComponent from "./ChoicesComponents/HeaderFormComponent";
import HeaderIconFormComponent from "./ChoicesComponents/HeaderIconFormComponent";
import ListFormComponent from "./ChoicesComponents/ListFormComponents";
import ImageSourceForm from "./ChoicesComponents/ImageSourceForm";
import RenderRowComponent from "./ChoicesComponents/RenderRowComponent";
// import RichTextFormComponent from "./ChoicesComponents/RichTextFormComponent";

const LeftAlignedChoices = ({
  setHome,
  setSelectedBodyRow,
  selectedBodyRows,
  rowId,
  initialRowId,
  itemId,
  handleRemoveAccordionItem,
  prevSelection,
  stage,
  setStage,
  handleBack,
  setPrevSelection,
  prevRowIdState,
}) => {
  const [slide, setSlide] = useState(stage || 1);
  const [leftAlignedChoice, setLeftAlignedChoice] = useState(
    prevSelection ?? null
  );
  const [updatedLA, setUpdatedLA] = useState();
  const [showText1, setShowText1] = useState(
    prevRowIdState ? prevRowIdState.showText1 : true
  );
  const [text1Value, setText1Value] = useState(prevRowIdState ? prevRowIdState.text1Value : undefined);
  const [showText2, setShowText2] = useState(
    prevRowIdState ? prevRowIdState.showText2 : true
  );
  const [text2Value, setText2Value] = useState(
    prevRowIdState ? prevRowIdState.text2Value : undefined
  );
  const [showText3, setShowText3] = useState(
    prevRowIdState ? prevRowIdState.showText3 : true
  );
  const [text3Value, setText3Value] = useState(
    prevRowIdState ? prevRowIdState.text3Value : undefined
  );
  const [showText4, setShowText4] = useState(
    prevRowIdState ? prevRowIdState.showText4 : true
  );
  const [text4Value, setText4Value] = useState(
    prevRowIdState ? prevRowIdState.text4Value : undefined
  );
  const [list1, setList1] = useState(prevRowIdState ? prevRowIdState.list1 : undefined);
  const [showList1, setShowList1] = useState(
    prevRowIdState ? prevRowIdState.showList1 : true
  );
  const [list2, setList2] = useState(prevRowIdState ? prevRowIdState.list2 : undefined);
  const [showList2, setShowList2] = useState(
    prevRowIdState ? prevRowIdState.showList2 : true
  );
  const [list3, setList3] = useState(prevRowIdState ? prevRowIdState.list3 : undefined);
  const [showList3, setShowList3] = useState(
    prevRowIdState ? prevRowIdState.showList3 : true
  );
  const [list4, setList4] = useState(prevRowIdState ? prevRowIdState.list4 : undefined);
  const [showList4, setShowList4] = useState(
    prevRowIdState ? prevRowIdState.showList4 : true
  );
  const [showIcon1, setShowIcon1] = useState(
    prevRowIdState ? prevRowIdState.showIcon1 : true
  );
  const [icon1, setIcon1] = useState(
    prevRowIdState ? prevRowIdState.icon1 : undefined
  );
  const [icon1Link, setIcon1Link] = useState(
    prevRowIdState ? prevRowIdState.icon1Link : undefined
  );
  const [showIcon2, setShowIcon2] = useState(
    prevRowIdState ? prevRowIdState.showIcon2 : true
  );
  const [icon2, setIcon2] = useState(
    prevRowIdState ? prevRowIdState.icon2 : undefined
  );
  const [icon2Link, setIcon2Link] = useState(
    prevRowIdState ? prevRowIdState.icon2Link : undefined
  );
  const [showHeader1, setShowHeader1] = useState(
    prevRowIdState ? prevRowIdState.showHeader1 : true
  );
  const [header1Icon, setHeader1Icon] = useState(
    prevRowIdState ? prevRowIdState.header1Icon : undefined
  );
  const [showHeader1Icon, setShowHeader1Icon] = useState(
    prevRowIdState ? prevRowIdState.showHeader1Icon : true
  );
  const [header1IconLink, setHeader1IconLink] = useState(
    prevRowIdState ? prevRowIdState.header1IconLink : undefined
  );
  const [header1, setHeader1] = useState(
    prevRowIdState ? prevRowIdState.header1 : undefined
  );  
  const [showHeader2, setShowHeader2] = useState(
    prevRowIdState ? prevRowIdState.showHeader2 : true
  );
  const [header2Icon, setHeader2Icon] = useState(
    prevRowIdState ? prevRowIdState.header2Icon : undefined
  );  
  const [showHeader2Icon, setShowHeader2Icon] = useState(
    prevRowIdState ? prevRowIdState.showHeader2Icon : true
  );
  const [header2IconLink, setHeader2IconLink] = useState(
    prevRowIdState ? prevRowIdState.header2IconLink : undefined
  );  
  const [header2, setHeader2] = useState(
    prevRowIdState ? prevRowIdState.header2 : undefined
  );  
  const [showHeader3, setShowHeader3] = useState(
    prevRowIdState ? prevRowIdState.showHeader3 : true
  );
  const [header3Icon, setHeader3Icon] = useState(
    prevRowIdState ? prevRowIdState.header3Icon : undefined
  ); 
  const [showHeader3Icon, setShowHeader3Icon] = useState(
    prevRowIdState ? prevRowIdState.showHeader3Icon : true
  );
  const [header3IconLink, setHeader3IconLink] = useState(
    prevRowIdState ? prevRowIdState.header3IconLink : undefined
  );
  const [header3, setHeader3] = useState(
    prevRowIdState ? prevRowIdState.header3 : undefined
  );  
  const [showHeader4, setShowHeader4] = useState(
    prevRowIdState ? prevRowIdState.showHeader4 : true
  );
  const [header4Icon, setHeader4Icon] = useState(
    prevRowIdState ? prevRowIdState.header4Icon : undefined
  ); 
  const [showHeader4Icon, setShowHeader4Icon] = useState(
    prevRowIdState ? prevRowIdState.showHeader4Icon : true
  );
  const [header4IconLink, setHeader4IconLink] = useState(
    prevRowIdState ? prevRowIdState.header4IconLink : undefined
  );
  const [header4, setHeader4] = useState(
    prevRowIdState ? prevRowIdState.header4 : undefined
  );  
  const [showHeadline, setShowHeadline] = useState(
    prevRowIdState ? prevRowIdState.showHeadline : true
  );
  const [headline, setHeadline] = useState(
    prevRowIdState ? prevRowIdState.headline : undefined
  );
  const [showLink1, setShowLink1] = useState(
    prevRowIdState ? prevRowIdState.showLink1 : true
  );
  const [link1Value, setLink1Value] = useState(
    prevRowIdState ? prevRowIdState.link1Value : undefined
  );
  const [link1Title, setLink1Title] = useState(
    prevRowIdState ? prevRowIdState.link1Title : undefined
  );
  const [showButton1, setShowButton1] = useState(
    prevRowIdState ? prevRowIdState.showButton1 : true
  );
  const [button1, setButton1] = useState(
    prevRowIdState ? prevRowIdState.button1 : undefined
  );
  const [button1Link, setButton1Link] = useState(
    prevRowIdState ? prevRowIdState.button1Link : undefined
  );
  const [showImg1, setShowImg1] = useState(
    prevRowIdState ? prevRowIdState.showImg1 : true
  );
  const [showImg2, setShowImg2] = useState(
    prevRowIdState ? prevRowIdState.showImg2 : true
  );
  const [showImg3, setShowImg3] = useState(
    prevRowIdState ? prevRowIdState.showImg3 : true
  );
  const [imgLink1, setImgLink1] = useState(
    prevRowIdState ? prevRowIdState.imgLink1 : undefined
  );
  const [imgLink2, setImgLink2] = useState(
    prevRowIdState ? prevRowIdState.imgLink2 : undefined
  );
  const [imgLink3, setImgLink3] = useState(
    prevRowIdState ? prevRowIdState.imgLink3 : undefined
  );
  const [imgSrc1, setImgSrc1] = useState(
    prevRowIdState ? prevRowIdState.imgSrc1 : undefined
  );
  const [imgSrc2, setImgSrc2] = useState(
    prevRowIdState ? prevRowIdState.imgSrc2 : undefined
  );
  const [imgSrc3, setImgSrc3] = useState(
    prevRowIdState ? prevRowIdState.imgSrc3 : undefined
  );
  const [selectedImage, setSelectedImage] = useState("imgSrc1");
  const [selectedField, setSelectedField] = useState(null);

  const holderStateSelector = useSelector((state) => state.bodyStates);
  const imgCollection = useSelector((state) => state.imgCollection);
  const holderState = prevRowIdState ? prevRowIdState : holderStateSelector;
  const leftAligneds = useSelector((state) => state.leftAligneds);

  const leftAlignedTemplates = useMemo(
    () => ({ leftAlignedTemplates: leftAligneds }),
    [leftAligneds]
  );
  const selectedLeftAligned = useSelector((state) =>
    state.leftAligneds.find(
      (leftAligned) => leftAligned.name === leftAlignedChoice
    )
  );
  const dispatch = useDispatch();

const handleFileChange = (event) => {
  console.log("File selected");
  const file = event.target.files[0];

  if (file) {
    console.log("Selected File:", file);

    // Assuming you are using Redux for state management
    dispatch(addImage(file));

    const reader = new FileReader();

    reader.onload = (e) => {
      const fileData = e.target.result;
      selectedImage === "imgSrc1" && setImgSrc1(fileData);
      selectedImage === "imgSrc2" && setImgSrc2(fileData);
      selectedImage === "imgSrc3" && setImgSrc3(fileData);
    };

    reader.readAsDataURL(file);

    // Here, you can perform additional actions like uploading the file to a server
  } else {
    console.log("No file selected");
  }
};
  
  const submitPattern = () => {
    setStage(2);
    setPrevSelection(null);
    const updatedSelectedLeftAligned = {
      ...selectedLeftAligned,
      rowId: initialRowId,
    };
    setSelectedBodyRow(updatedSelectedLeftAligned);
    dispatch(addBodyRow(updatedSelectedLeftAligned));
    setSlide(3);
    setUpdatedLA(updatedSelectedLeftAligned);
    dispatch(incrementRowId());
    dispatch(
      pushToRowIdState({
        rowId: initialRowId,
        data: {
          bodyState: holderState,
        },
      })
    );
    if (rowId !== 1) {
      handleRemoveAccordionItem(itemId);
    }
  };

  const handleImageClick = (x) => {
    selectedImage === "imgSrc1" && setImgSrc1(x);
    selectedImage === "imgSrc2" && setImgSrc2(x);
    selectedImage === "imgSrc3" && setImgSrc3(x);
  };

  const handleDeleteClick = (id) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this image?"
    );
    if (isConfirmed) {
      dispatch(deleteImage(id));
    }
  };

  const handleUpload = (x) => {
    if (x) {
      console.log("Selected File:", x);
      dispatch(addImage(x));
      // Here, you can perform additional actions like uploading the file to a server
    } else {
      console.log("No file selected");
    }
  };

  const handleSelect = (leftAlignedName) => {
    setLeftAlignedChoice(leftAlignedName);
    setSelectedBodyRow(null);
    setHome(false);
    setSlide(2);
  };

  const goBack = (e) => {
    setSelectedBodyRow(null);
    if (updatedLA) {
      dispatch(removeBodyRow(updatedLA.rowId));
    }
    setHome(true);
    setSlide(1);
  };

  useEffect(() => {
    const bodyState = {
      showText1: showText1,
      text1Value: text1Value,
      showText2: showText2,
      text2Value: text2Value,
      showText3: showText3,
      text3Value: text3Value,
      showText4: showText4,
      text4Value: text4Value,
      showHeadline: showHeadline,
      headline: headline,
      showLink1: showLink1,
      link1Title: link1Title,
      link1Value: link1Value,
      showImg1: showImg1,
      showImg2: showImg2,
      showImg3: showImg3,
      imgLink1: imgLink1,
      imgLink2: imgLink2,
      imgLink3: imgLink3,
      button1: button1,
      button1Link: button1Link,
      showButton1: showButton1,
      icon1: icon1,
      icon1Link: icon1Link,
      showIcon1: showIcon1,
      icon2: icon2,
      icon2Link: icon2Link,
      showIcon2: showIcon2,
      header1: header1,
      showHeader1: showHeader1,
      header2: header2,
      showHeader2: showHeader2,
      header3: header3,
      showHeader3: showHeader3,
      header4: header4,
      showHeader4: showHeader4,
      list1: list1,
      showList1: showList1,
      list2: list2,
      showList2: showList2,
      list3: list3,
      showList3: showList3,
      list4: list4,
      showList4: showList4,
      showHeader1Icon: showHeader1Icon,
      showHeader2Icon: showHeader2Icon,
      showHeader3Icon: showHeader3Icon,
      showHeader4Icon: showHeader4Icon,
      header1IconLink: header1IconLink,
      header2IconLink: header2IconLink,
      header3IconLink: header3IconLink,
      header4IconLink: header4IconLink,
      header1Icon: header1Icon,
      header2Icon: header2Icon,
      header3Icon: header3Icon,
      header4Icon: header4Icon,
      imgSrc1: imgSrc1,
      imgSrc2: imgSrc2,
      imgSrc3: imgSrc3,
    };
    dispatch(setBodyState(bodyState));
    dispatch(
      pushToRowIdState({
        rowId: initialRowId,
        data: {
          bodyState: bodyState,
        },
      })
    );
  }, [
    dispatch,
    showText1,
    text1Value,
    showText2,
    text2Value,
    showText3,
    text3Value,
    showText4,
    text4Value,
    showHeadline,
    headline,
    showLink1,
    link1Title,
    link1Value,
    showImg1,
    showImg2,
    showImg3,
    imgLink1,
    imgLink2,
    imgLink3,
    button1,
    button1Link,
    showButton1,
    icon1,
    icon1Link,
    showIcon1,
    icon2,
    icon2Link,
    showIcon2,
    header1,
    showHeader1,
    header2,
    showHeader2,
    header3,
    showHeader3,
    header4,
    showHeader4,
    initialRowId,
    list1,
    list2,
    list3,
    list4,
    showList1,
    showList2,
    showList3,
    showList4,
    showHeader1Icon,
    showHeader2Icon,
    showHeader3Icon,
    showHeader4Icon,
    header1Icon,
    header2Icon,
    header3Icon,
    header4Icon,
    header1IconLink,
    header2IconLink,
    header3IconLink,
    header4IconLink,
    imgSrc1,
    imgSrc2,
    imgSrc3,
  ]);
  
  return (
    <>
      {slide === 1 && (
        <Col className="d-flex justify-content-end">
          <Button variant="dark" className="mb-5" onClick={handleBack}>
            <ChevronDoubleLeft size={18} /> GO BACK
          </Button>
        </Col>
      )}
      {leftAlignedTemplates.leftAlignedTemplates.length > 0 &&
        slide === 1 &&
        leftAlignedTemplates.leftAlignedTemplates.map((leftAlignedTemplate) => (
          <div key={leftAlignedTemplate.id} className="my-4">
            <div
              className="text-decoration-none text-black"
              onClick={() => handleSelect(leftAlignedTemplate.name)}
            >
              <div className="unclickable">
                {RenderRowComponent(
                  leftAlignedTemplate.bodyRowComponent.componentName,
                  leftAlignedTemplate.bodyRowComponent.props
                )}
              </div>
            </div>
          </div>
        ))}
      {slide === 2 && (
        <>
          <Col className="d-flex justify-content-end">
            <Button variant="dark" className="mb-5" onClick={goBack}>
              <ChevronDoubleLeft size={18} /> GO BACK
            </Button>
          </Col>
          <div className="crud-hover">
            {leftAlignedChoice.length > 0 && (
              <div className="unclickable">
                {RenderRowComponent(
                  selectedLeftAligned.bodyRowComponent.componentName,
                  { holderState: holderState },
                  false,
                  selectedField
                )}
              </div>
            )}
          </div>
          <Row className="d-flex flex-column align-items-center">
            <Row className="gap-4 my-3 d-flex justify-content-around">
              {selectedLeftAligned.bodyRowComponent.props.headline && (
                <HeadlineFormComponent
                  className={`${
                    selectedField === "headline" ? "focus-field" : ""
                  }`}
                  showHeadline={showHeadline}
                  setShowHeadline={setShowHeadline}
                  headline={headline}
                  setHeadline={setHeadline}
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedLeftAligned.bodyRowComponent.props.text1 && (
                <TextFormComponent
                  showText={showText1}
                  setShowText={setShowText1}
                  textValue={text1Value}
                  setTextValue={setText1Value}
                  txtNumber="1"
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
                // <RichTextFormComponent
                //   showText={showText1}
                //   setShowText={setShowText1}
                //   textValue={text1Value}
                //   setTextValue={setText1Value}
                //   txtNumber="1" />
              )}
              {selectedLeftAligned.bodyRowComponent.props.text2 && (
                <TextFormComponent
                  showText={showText2}
                  setShowText={setShowText2}
                  textValue={text2Value}
                  setTextValue={setText2Value}
                  txtNumber="2"
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedLeftAligned.bodyRowComponent.props.text3 && (
                <TextFormComponent
                  showText={showText3}
                  setShowText={setShowText3}
                  textValue={text3Value}
                  setTextValue={setText3Value}
                  txtNumber="3"
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedLeftAligned.bodyRowComponent.props.text4 && (
                <TextFormComponent
                  showText={showText4}
                  setShowText={setShowText4}
                  textValue={text4Value}
                  setTextValue={setText4Value}
                  txtNumber="4"
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedLeftAligned.bodyRowComponent.props.link1Title && (
                <LinkFormComponent
                  showLink={showLink1}
                  setShowLink={setShowLink1}
                  linkTitle={link1Title}
                  setLinkTitle={setLink1Title}
                  linkValue={link1Value}
                  setLinkValue={setLink1Value}
                  linkNumber={"1"}
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedLeftAligned.bodyRowComponent.props.button1 && (
                <ButtonFormComponent
                  showButton={showButton1}
                  setShowButton={setShowButton1}
                  buttonText={button1}
                  setButtonText={setButton1}
                  buttonLink={button1Link}
                  setButtonLink={setButton1Link}
                  btnNumber="1"
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedLeftAligned.bodyRowComponent.props.icon1 && (
                <IconFormComponent
                  showIcon={showIcon1}
                  setShowIcon={setShowIcon1}
                  setIcon={setIcon1}
                  iconLink={icon1Link}
                  setIconLink={setIcon1Link}
                  iconNumber="1"
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedLeftAligned.bodyRowComponent.props.icon2 && (
                <IconFormComponent
                  showIcon={showIcon2}
                  setShowIcon={setShowIcon2}
                  setIcon={setIcon2}
                  iconLink={icon2Link}
                  setIconLink={setIcon2Link}
                  iconNumber="2"
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedLeftAligned.bodyRowComponent.props.header1 && (
                <HeaderFormComponent
                  showHeader={showHeader1}
                  setShowHeader={setShowHeader1}
                  headerValue={header1}
                  setHeaderValue={setHeader1}
                  headerNumber="1"
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedLeftAligned.bodyRowComponent.props.header1Icon && (
                <HeaderIconFormComponent
                  showHeaderIcon={showHeader1Icon}
                  setShowHeaderIcon={setShowHeader1Icon}
                  setHeaderIcon={setHeader1Icon}
                  headerIconLink={header1IconLink}
                  setHeaderIconLink={setHeader1IconLink}
                  headerIconNumber="1"
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedLeftAligned.bodyRowComponent.props.header2 && (
                <HeaderFormComponent
                  showHeader={showHeader2}
                  setShowHeader={setShowHeader2}
                  headerValue={header2}
                  setHeaderValue={setHeader2}
                  headerNumber="2"
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedLeftAligned.bodyRowComponent.props.header2Icon && (
                <HeaderIconFormComponent
                  showHeaderIcon={showHeader2Icon}
                  setShowHeaderIcon={setShowHeader2Icon}
                  setHeaderIcon={setHeader2Icon}
                  headerIconLink={header2IconLink}
                  setHeaderIconLink={setHeader2IconLink}
                  headerIconNumber="2"
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedLeftAligned.bodyRowComponent.props.list1 && (
                <ListFormComponent
                  showList={showList1}
                  setShowList={setShowList1}
                  listValue={list1}
                  setListValue={setList1}
                  listNumber="1"
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedLeftAligned.bodyRowComponent.props.list2 && (
                <ListFormComponent
                  showList={showList2}
                  setShowList={setShowList2}
                  listValue={list2}
                  setListValue={setList2}
                  listNumber="2"
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedLeftAligned.bodyRowComponent.props.list3 && (
                <ListFormComponent
                  showList={showList3}
                  setShowList={setShowList3}
                  listValue={list3}
                  setListValue={setList3}
                  listNumber="3"
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedLeftAligned.bodyRowComponent.props.list4 && (
                <ListFormComponent
                  showList={showList4}
                  setShowList={setShowList4}
                  listValue={list4}
                  setListValue={setList4}
                  listNumber="4"
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedLeftAligned.bodyRowComponent.props.header3 && (
                <HeaderFormComponent
                  showHeader={showHeader3}
                  setShowHeader={setShowHeader3}
                  headerValue={header3}
                  setHeaderValue={setHeader3}
                  headerNumber="3"
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedLeftAligned.bodyRowComponent.props.header3Icon && (
                <HeaderIconFormComponent
                  showHeaderIcon={showHeader3Icon}
                  setShowHeaderIcon={setShowHeader3Icon}
                  setHeaderIcon={setHeader3Icon}
                  headerIconLink={header3IconLink}
                  setHeaderIconLink={setHeader3IconLink}
                  headerIconNumber="3"
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedLeftAligned.bodyRowComponent.props.header4 && (
                <HeaderFormComponent
                  showHeader={showHeader4}
                  setShowHeader={setShowHeader4}
                  headerValue={header4}
                  setHeaderValue={setHeader4}
                  headerNumber="4"
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedLeftAligned.bodyRowComponent.props.header4Icon && (
                <HeaderIconFormComponent
                  showHeaderIcon={showHeader4Icon}
                  setShowHeaderIcon={setShowHeader4Icon}
                  setHeaderIcon={setHeader4Icon}
                  headerIconLink={header4IconLink}
                  setHeaderIconLink={setHeader4IconLink}
                  headerIconNumber="4"
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedLeftAligned.bodyRowComponent.props.imgSrc1 && (
                <ImageSourceForm
                  showImg={showImg1}
                  setShowImg={setShowImg1}
                  imgSrc={imgSrc1}
                  imgSrcString={"imgSrc1"}
                  imgNumber="1"
                  handleImageClick={handleImageClick}
                  imgLink={imgLink1}
                  setImgLink={setImgLink1}
                  setSelectedImage={setSelectedImage}
                  handleFileChange={handleFileChange}
                  handleUpload={handleUpload}
                  handleDeleteClick={handleDeleteClick}
                  imgCollection={imgCollection}
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedLeftAligned.bodyRowComponent.props.imgSrc2 && (
                <ImageSourceForm
                  showImg={showImg2}
                  setShowImg={setShowImg2}
                  imgSrc={imgSrc2}
                  imgSrcString={"imgSrc2"}
                  imgNumber="2"
                  handleImageClick={handleImageClick}
                  imgLink={imgLink2}
                  setImgLink={setImgLink2}
                  setSelectedImage={setSelectedImage}
                  handleFileChange={handleFileChange}
                  handleUpload={handleUpload}
                  handleDeleteClick={handleDeleteClick}
                  imgCollection={imgCollection}
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedLeftAligned.bodyRowComponent.props.imgSrc3 && (
                <ImageSourceForm
                  showImg={showImg3}
                  setShowImg={setShowImg3}
                  imgSrc={imgSrc3}
                  imgSrcString={"imgSrc3"}
                  imgNumber="3"
                  handleImageClick={handleImageClick}
                  imgLink={imgLink3}
                  setImgLink={setImgLink3}
                  setSelectedImage={setSelectedImage}
                  handleFileChange={handleFileChange}
                  handleUpload={handleUpload}
                  handleDeleteClick={handleDeleteClick}
                  imgCollection={imgCollection}
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
            </Row>
          </Row>
          <div className="text-center">
            <Button
              onClick={submitPattern}
              variant="dark"
              className="mt-2 mb-4"
            >
              ADD THIS PATTERN
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default LeftAlignedChoices;
