import { createSlice } from '@reduxjs/toolkit';
import { getPage } from '../Services/PageService';

export const initializeFooterState = () => async (dispatch) => {
  try {
    const response = await getPage('FOOTER');
    const responseBody = await response.text();
    const data = JSON.parse(responseBody);
    // Dispatch the addExistingPage action to update the state
    dispatch(setFooterState(data.footer[0].footerState));
  } catch (error) {
    // LOCAL TESTING
    dispatch(
      setFooterState(
        {
                cityValue: undefined,
                companyFooterValue: "THE Company",
                emailValue: "luke@gmail.com",
                fbLink: "facebook.com",
                igLink: "instagram.com",
                legLink: "leagueoflegends.com",
                liLink: "linkedin.com",
                madeInValue: "Texas",
                phoneValue: "210-543-1234",
                pinLink: "pinterest.com",
                privacyLink: "privacylink",
                showAddress: false,
                showCompanyFooter: false,
                showEmail: false,
                showFbChecked: false,
                showIGChecked: false,
                showLIChecked: true,
                showLanguage: true,
                showLegal: true,
                showMadeIn: true,
                showPINChecked: true,
                showPhone: true,
                showPrivacy: true,
                showSendEmail: true,
                showSiteMap: true,
                showText1: true,
                showTikChecked: false,
                showTos: true,
                showXChecked: true,
                siteLink: "",
                socialIconsChecked: true,
                streetValue: "1234 Street",
                text1Value: "First",
                tikLink: "tiktok.com",
                tosLink: "terms of service",
                xLink: "twitter.com",
        })
    );
  }
};

const initialState = true


const footerStateSlice = createSlice({
  name: 'footerState',
  initialState,
  reducers: {
    setFooterState: (state, action) => {
      return action.payload;
    },
    returnExistingFooterState: (state, action) => {
      return state;
    },
  },
});

export const { setFooterState, returnExistingState } = footerStateSlice.actions;

export default footerStateSlice.reducer;
