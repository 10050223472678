import React, { useEffect } from "react";
import { InputGroup, Container, Row, Col, Card, Button, Form, Spinner } from "react-bootstrap";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DashboardHeader from "../ServiceComponents/DashboardHeader";
import './dashboard.css';

const FirstLogin = () => {
  const [cmsManaged, setCmsManaged] = useState(false);
  const [selfManaged, setSelfManaged] = useState(true);
  const [siteName, setSiteName] = useState("");
  const [siteNameError, setSiteNameError] = useState(true);
  const [siteNameText, setSiteNameText] = useState('#B0B6BA');
  const [adminEmail, setAdminEmail] = useState("");
  const [adminEmailError, setAdminEmailError] = useState(true);
  const [adminEmailText, setAdminEmailText] = useState('#B0B6BA');
  const [domain, setDomain] = useState("");
  const [domainError, setDomainError] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [selectedImageFile, setSelectedImageFile] = useState();
  const [slide, setSlide] = useState(1);
  const [slideOneValid, setSlideOneValid] = useState(false);
  const [slideTwoValid, setSlideTwoValid] = useState(false);
  const [slideThreeValid, setSlideThreeValid] = useState(false);
  const [imgUploaded, setImgUploaded] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingButtonText, setLoadingButtonText] = useState('Check Availability');
  const styleComplete = {fontColor:"#FFF", bodyColor:"#343A40"};
  const styleUnComplete = {fontColor:"#000", bodyColor:"#E2E6EA"};
  const [arrowOneStyle, setArrowOneStyle] = useState(styleUnComplete);
  const [arrowTwoStyle, setArrowTwoStyle] = useState(styleUnComplete);
  const [arrowThreeStyle, setArrowThreeStyle] = useState(styleUnComplete);
  const navigate = useNavigate();

  const firstLoginData = {
    siteName: siteName,
    adminEmail: adminEmail,
    domain: domain,
    cmsManaged: cmsManaged,
    selfManaged: selfManaged,
    selectedImageFile: selectedImageFile,
  };

  const slideChange = () => {
    if (slide === 1) {
      setArrowOneStyle(styleComplete);
      setArrowTwoStyle(styleUnComplete);
    } else if (slide === 2) {
      setArrowTwoStyle(styleComplete);
      setArrowThreeStyle(styleUnComplete);
    }  else if (slide === 3) {
      setArrowThreeStyle(styleComplete);
    } 
  }

  useEffect(() => {
    slideChange();
    // eslint-disable-next-line
  }, [slide]);

  useEffect(() => {
    if (slide === 1 && siteNameError === false && adminEmailError === false) {
     setSlideOneValid(true);
     setDisabled(false)
    } else if (slide === 1 && (siteNameError === true || adminEmailError === true)){
      setSlideOneValid(false);
      setDisabled(true);
    }
    if (slide === 2 && cmsManaged === true && domainError === false && selfManaged === false) {
      setSlideTwoValid(true)
      setDisabled(false)
    } else if (slide === 2 && cmsManaged === false && selfManaged === true){
      setSlideTwoValid(true)
      setDisabled(false)
    } else if (slide === 3) {
      setSlideThreeValid(true);
    }
  }, [slide, siteName, adminEmail, domain, cmsManaged, domainError, selfManaged, siteNameError, adminEmailError]);

  const validateSiteName = (value) => {
    const sqlInjectionPattern = /(['";])/;
    if (value.length > 0 && value.length < 50 && !sqlInjectionPattern.test(value)) {
      setSiteNameError(false);
      setSiteNameText('#B0B6BA');
    } else {
      setSiteNameError(true);
      setSiteNameText('red');
    }
  };
  const validateAdminEmail = (value) => {
    const sqlInjectionPattern = /(['";])/;
    if (value.includes('@') && (value.endsWith('.com') || value.endsWith('.gov') || value.endsWith('.net') || value.endsWith('.org')) && !sqlInjectionPattern.test(value)){
        setAdminEmailError(false);
        setAdminEmailText('#B0B6BA')
    } else {
      setAdminEmailError(true);
      setAdminEmailText('red');
    }
  }
  const validDomain = (value) => {
    const sqlInjectionPattern = /(['";])/;
    const trimmedValue = value.trim();
    if (trimmedValue === '') {
      setDomainError(true);
      setSlideTwoValid(false);
    } else if (!sqlInjectionPattern.test(trimmedValue)) {
      setDomainError(false);
      setSlideTwoValid(true);
    }
  };
  

  const changeImg = (e) => {
    const file = e.target.files[0];
    setSelectedImageFile(file);
  }
  const uploadImg = (e) => {
    setDisabled(false);
    setImgUploaded(true);
  }

  return (
    <Container fluid className="d-flex flex-column min-vh-100">

      <DashboardHeader children={"dashboard"}/>

      <Row className="d-flex justify-content-center">
        <Col md={12} lg={10}>
          <div className="d-flex flex-row justify-content-center">
            <div className={`triangle-left ${slideOneValid ? 'first-log-title' : ''}`}></div>
            <div className={`triangle-center fs-5 ${slideOneValid ? 'first-log-title' : ''}`} style={{backgroundColor:arrowOneStyle.bodyColor, color:arrowOneStyle.fontColor}}>Site Name</div>
            <div className={`triangle-right ${slideOneValid ? 'first-log-title' : ''}`} style={{color:arrowOneStyle.bodyColor}}></div>

            <div className={`triangle-left ${slideTwoValid ? 'first-log-title' : ''}`}></div>
            <div className={`triangle-center fs-5 ${slideTwoValid ? 'first-log-title' : ''}`} style={{backgroundColor:arrowTwoStyle.bodyColor, color:arrowTwoStyle.fontColor}}>Domain Name</div>
            <div className={`triangle-right ${slideTwoValid ? 'first-log-title' : ''}`} style={{color:arrowTwoStyle.bodyColor}}></div>

            <div className={`triangle-left ${slideThreeValid ? 'first-log-title' : ''}`}></div>
            <div className={`triangle-center fs-5 ${slideThreeValid ? 'first-log-title' : ''}`} style={{backgroundColor:arrowThreeStyle.bodyColor, color:arrowThreeStyle.fontColor}}>Site Logo/Brand</div>
            <div className={`triangle-right ${slideThreeValid ? 'first-log-title' : ''}`} style={{color:arrowThreeStyle.bodyColor}}></div>
          </div>
        </Col>
      </Row>     

      <Row>
      <Col className="col-8 offset-2 mt-4">
        <Card className="px-5 pt-3 pb-4">
          <Card.Body>
            {slide === 1 && (<>
              <Row>
                <Col className="text-secondary pb-4">
                  <h5>This is your first time here. Let's set up your website information.</h5>
                </Col>
              </Row>
                
              <Form onSubmit={(e) => e.preventDefault()}>
                <Form.Group>
                  <Form.Label className="text-start mt-2 fw-semibold">Site Name</Form.Label>
                  <Form.Control
                    id="site-name"
                    required
                    type="text"
                    placeholder="Enter Site Name Here"
                    value={siteName}
                    onChange={(e) => {
                      setSiteName(e.target.value);
                      validateSiteName(e.target.value);
                    }}
                  />
                  <Form.Text style={{color:siteNameText}}>Must enter Site Name Less Than 50 Characters</Form.Text>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="text-start mt-4 fw-semibold">Administrator Email</Form.Label>
                  <Form.Control id="admin-email"
                    required
                    type="text"
                    placeholder="Enter Administrator Email Here"
                    value={adminEmail}
                    onChange={(e) => {
                      setAdminEmail(e.target.value);
                      validateAdminEmail(e.target.value)
                    }}
                  />
                  <Form.Text style={{color:adminEmailText}}>Administrator email must include "@" and end in ".com" | ".org" | ".gov" | ".net"</Form.Text>
                </Form.Group>
                <div className="text-center my-5">
                  <Button disabled={!slideOneValid} variant="dark" onClick={(e) => {setSlide(2); setDisabled(true)}}>
                    CONTINUE
                  </Button>
                </div>
              </Form>
              </> )}

            {slide ===2 && (<>
              <Row className="d-flex flex-column gap-3 pb-5">
                <Col className="text-secondary">
                    <h5>Your domain name is the foundation of your online identity, and we're committed to ensuring you have the best experience possible.</h5>
                  </Col>
                  <Col className="text-secondary">
                    <h5>Select your preferred domain management option below and let's begin building your online presence together!</h5>
                </Col>
              </Row>

              <Form onSubmit={(e) => e.preventDefault()}>
                <div className="mb-3">
                  <Form.Check
                    type="radio"
                    id="default-radio-1"
                    label={
                      <Col className="d-flex">
                        <h5 className="text-secondary"><span className='fw-bold'>CMS Managed -</span> I want CMS to manage my domain name. I will provide my domain name and CMS will connect it.</h5>
                      </Col>
                    }
                    name="domainGroup"
                    value={'cmsManaged'}
                    checked={cmsManaged}
                    onChange={() => {setCmsManaged(true); setSelfManaged(false); setDisabled(domainError)}}
                  />

                {cmsManaged && (
                  <Form.Group controlId="validationCustom01">
                    <Form.Label className="text-start mt-2 fw-semibold text-secondary">
                      Domain Name
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        required
                        aria-describedby="cms-managed"
                        type="text"
                        placeholder="www.yourdomainname.com"
                        onChange={(e) => {setDomain(e.target.value); validDomain(e.target.value)}}
                        value={domain}
                        isInvalid={domainError}
                      />
                      <Button variant="dark" id="cms-managed" onClick={() => setLoadingButton(true)} onDoubleClick={() => {setLoadingButton(false); setLoadingButtonText('Available')}}>
                        {!loadingButton ? loadingButtonText : <Spinner animation="border" variant="light" size="sm"/>}
                      </Button>
                    </InputGroup>                  
                    <Form.Text style={{color:'#B0B6BA'}}>
                      Domain name must start "www." and end in ".com" | ".org" | ".gov" | ".net"
                    </Form.Text>
                  </Form.Group>
                  )}
                </div>

                <div className="mb-3">
                  <Form.Check
                    type="radio"
                    id="default-radio-2"
                    label={
                      <Col className="d-flex">
                        <h5 className="text-secondary"><span className='fw-bold'>Self Managed -</span> I want to manage my domain myself and will point the DNS records to the ones given to me by CMS.</h5>
                      </Col>
                    }
                    name="domainGroup"
                    value={'selfManaged'}
                    checked={selfManaged}
                    onChange={() => {setCmsManaged(false); setSelfManaged(true)}}
                  />

                  {selfManaged && (
                    <div className="dns-server-container d-flex flex-column px-5 py-2">
                      <div className="d-flex flex-row gap-3">
                        <p className="fs-5 mt-2 fw-semibold">Primary DNS Server:</p>
                        <p className="fs-5 mt-2 fw-semibold">123.456.789</p>
                      </div>
                      <div className="d-flex flex-row gap-3">
                        <p className="fs-5 mt-2 fw-semibold">Secondary DNS Server:</p>
                        <p className="fs-5 mt-2 fw-semibold">456.123.789</p>
                      </div>
                    </div>
                  )}
                </div>
                <div className="text-center my-4 pt-5">
                  <Button onClick={(e) => {setSlide(1); setDisabled(false)}} variant="dark me-3">
                    BACK
                  </Button>
                  <Button disabled={disabled} onClick={(e) => {setSlide(3); setDisabled(true)}} variant="dark">
                    CONTINUE
                  </Button>
                </div>
              </Form>
              </>)}

            {slide === 3 && (
              <div>
                <Row>
                  <Col className="text-secondary pb-4">
                    <h5>Add a site-wide logo or brand badge. This will be used in the Navbar and Footer sections.</h5>
                  </Col>
                </Row>
            
                <InputGroup className="my-3">
                  <Form.Control aria-describedby="basic-addon2" type="file" content="Tough" onChange={changeImg} />
                    <Button id="button-addon2" className="border text-dark px-4 fw-semibold" onClick={uploadImg} variant={selectedImageFile ? 'warning' : 'light'}>
                      Upload
                    </Button>
                </InputGroup>
              
                <div className="text-center">
                  <div className="p-4">
                    <Button onClick={(e) => {navigate('/dashboard/nopages')}} variant="dark mx-2 mt-4">
                      SKIP
                    </Button>
                    <Button onClick={(e) => {setSlide(2); setDisabled(false)}} variant="dark mx-2 mt-4">
                      BACK
                    </Button>
                    <Button variant="dark" className="mt-4 mx-2" disabled={!imgUploaded} onClick={(e) => console.log(firstLoginData)}>
                    {/* <Button variant="dark" className="mt-4 mx-4" disabled={!imgUploaded} onClick={(e) => navigate('/dashboard/nopages')}> */}
                      CONTINUE
                    </Button>
                  </div>
                </div>

                {selectedImageFile && (
                  <Row>
                      <p>Preview of the selected image:</p>
                    <Col className="mt-3">
                      <img
                        style={{maxWidth:"50%"}}
                        src={URL.createObjectURL(selectedImageFile)}
                        alt="Uploaded"
                      />
                    </Col>
                  </Row>
                )}
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
      </Row>
    </Container>
  );
};
export default FirstLogin;