import React, { useEffect, useState } from 'react';
import { Row, Col, Image } from 'react-bootstrap';

const LA10 = ({isPreview, holderState, selectedField}) => {
  const [leftAlign10, setLeftAlign10] = useState({
    text1:
      holderState?.text1Value !== undefined
        ? holderState.text1Value
        : "Long headline on two lines to turn your visitors into users and achieve more",
    showText1:
      holderState?.showText1 !== undefined ? holderState.showText1 : true,
    text2:
      holderState?.text2Value !== undefined
        ? holderState.text2Value
        : "Separated they live in Bookmarks right at the coast of the famous Semantics, large language ocean",
    showText2:
      holderState?.showText2 !== undefined ? holderState.showText2 : true,
    list1:
      holderState?.list1 !== undefined
        ? holderState.list1
        : "Showcase and embed your work with",
    showList1:
      holderState?.showList1 !== undefined ? holderState.showList1 : true,
    list2:
      holderState?.list2 !== undefined
        ? holderState.list2
        : "Publish across social channels in a click",
    showList2:
      holderState?.showList2 !== undefined ? holderState.showList2 : true,
    list3:
      holderState?.list3 !== undefined
        ? holderState.list3
        : "Sell your videos worldwide",
    showList3:
      holderState?.showList3 !== undefined ? holderState.showList3 : true,
    list4:
      holderState?.list4 !== undefined
        ? holderState.list4
        : "Embed your work with",
    showList4:
      holderState?.showList4 !== undefined ? holderState.showList4 : true,
    showImg1: holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
    imgLink1: holderState?.imgLink1 !== undefined ? holderState.imgLink1 : "",
    imgSrc1:
      holderState?.imgSrc1 !== undefined
        ? holderState.imgSrc1
        : "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt1: "Placeholder Image",
  });  

  const imageContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    maxHeight: '100%',
    borderRadius: '1rem',
    overflow: 'hidden'
  };
  const imageStyle = {
    objectFit: 'cover',
    height: '22rem',
    width: '100%',
    borderRadius: '1rem',
  }

  useEffect(() => {
    setLeftAlign10((prevLA10) => ({
      ...prevLA10,
      text1:
        holderState?.text1Value !== undefined
          ? holderState.text1Value
          : "Long headline on two lines to turn your visitors into users and achieve more",
      showText1:
        holderState?.showText1 !== undefined ? holderState.showText1 : true,
      text2:
        holderState?.text2Value !== undefined
          ? holderState.text2Value
          : "Separated they live in Bookmarks right at the coast of the famous Semantics, large language ocean",
      showText2:
        holderState?.showText2 !== undefined ? holderState.showText2 : true,
      list1:
        holderState?.list1 !== undefined
          ? holderState.list1
          : "Showcase and embed your work with",
      showList1:
        holderState?.showList1 !== undefined ? holderState.showList1 : true,
      list2:
        holderState?.list2 !== undefined
          ? holderState.list2
          : "Publish across social channels in a click",
      showList2:
        holderState?.showList2 !== undefined ? holderState.showList2 : true,
      list3:
        holderState?.list3 !== undefined
          ? holderState.list3
          : "Sell your videos worldwide",
      showList3:
        holderState?.showList3 !== undefined ? holderState.showList3 : true,
      list4:
        holderState?.list4 !== undefined
          ? holderState.list4
          : "Embed your work with",
      showList4:
        holderState?.showList4 !== undefined ? holderState.showList4 : true,
      imgSrc1:
        holderState?.imgSrc1 !== undefined
          ? holderState.imgSrc1
          : "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgAlt1: "Placeholder Image",
      showImg1:
        holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
      imgLink1: holderState?.imgLink1 !== undefined ? holderState.imgLink1 : "",
    }));
  }, [
    holderState?.showText1,
    holderState?.text1Value,
    holderState?.showText2,
    holderState?.text2Value,
    holderState?.showList1,
    holderState?.list1,
    holderState?.showList2,
    holderState?.list2,
    holderState?.showList3,
    holderState?.list3,
    holderState?.showList4,
    holderState?.list4,
    holderState?.showImg1,
    holderState?.imgLink1,
    holderState?.imgSrc1,
  ]);  

  return (
    <Row
      sm={12}
      md={12}
      lg={10}
      xl={10}
      xxl={8}
      style={{ "--bs-gutter-x": "0" }}
      className={`d-flex justify-content-center bg-white align-items-center gap-5 ${
        isPreview ? "p-3" : "p-5"
      }`}
    >
      <Col
        xs={12}
        sm={10}
        md={6}
        lg={5}
        xl={5}
        xxl={4}
        className="d-flex flex-column align-items-center justify-content-center"
      >
        <Col className="d-flex flex-column justify-content-center mx-2 gap-2">
          {leftAlign10.showText1 && (
            <h3
              className={`fw-bold ${isPreview ? "preview-scaling" : ""} ${
                selectedField === "text1" ? "focus-field" : ""
              }`}
            >
              {leftAlign10.text1}
            </h3>
          )}
          {leftAlign10.showText2 && (
            <p
              className={`${isPreview ? "preview-scaling" : ""} ${
                selectedField === "text2" ? "focus-field" : ""
              }`}
            >
              {leftAlign10.text2}
            </p>
          )}
          <ul className={`${isPreview ? "preview-scaling ps-2" : ""}`}>
            {leftAlign10.showList1 && (
              <li
                className={`mb-1 ${
                  selectedField === "list1" ? "focus-field" : ""
                }`}
              >
                {leftAlign10.list1}
              </li>
            )}
            {leftAlign10.showList2 && (
              <li
                className={`mb-1 ${
                  selectedField === "list2" ? "focus-field" : ""
                }`}
              >
                {leftAlign10.list2}
              </li>
            )}
            {leftAlign10.showList3 && (
              <li
                className={`mb-1 ${
                  selectedField === "list3" ? "focus-field" : ""
                }`}
              >
                {leftAlign10.list3}
              </li>
            )}
            {leftAlign10.showList4 && (
              <li
                className={`mb-1 ${
                  selectedField === "list4" ? "focus-field" : ""
                }`}
              >
                {leftAlign10.list4}
              </li>
            )}
          </ul>
        </Col>
      </Col>
      <Col
        xs={12}
        sm={10}
        md={5}
        lg={5}
        xl={5}
        xxl={4}
        className={`d-flex align-items-center justify-content-center my-auto ${
          isPreview ? "preview-scaling" : ""
        }`}
      >
        {leftAlign10.showImg1 && (
          <Col
            className={`text-center justify-content-center ${
              selectedField === "imgSrc1" ? "focus-field" : ""
            }`}
            style={imageContainerStyle}
          >
            {leftAlign10.imgLink1 !== "" && (
              <a
                href={"https://" + leftAlign10.imgLink1}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  src={leftAlign10.imgSrc1}
                  alt={leftAlign10.imgAlt1}
                  style={imageStyle}
                  fluid
                />
              </a>
            )}
            {leftAlign10.imgLink1 === "" && (
              <Image
                src={leftAlign10.imgSrc1}
                alt={leftAlign10.imgAlt1}
                style={imageStyle}
                fluid
              />
            )}
          </Col>
        )}
      </Col>
    </Row>
  );
}

export default LA10;
