import React, { useEffect } from "react";
import { Row } from "react-bootstrap";
import { Carousel, Image } from "react-bootstrap";
import { useState } from "react";

const Gallery11 = ({ isPreview, holderState, selectedField }) => {
  const [gal11, setGal11] = useState({
    showImg1: holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
    showImg2: holderState?.showImg2 !== undefined ? holderState.showImg2 : true,
    showImg3: holderState?.showImg3 !== undefined ? holderState.showImg3 : true,
    showImg4: holderState?.showImg4 !== undefined ? holderState.showImg4 : true,
    showImg5: holderState?.showImg5 !== undefined ? holderState.showImg5 : true,
    showImg6: holderState?.showImg6 !== undefined ? holderState.showImg6 : true,
    showImg7: holderState?.showImg7 !== undefined ? holderState.showImg7 : true,
    showImg8: holderState?.showImg8 !== undefined ? holderState.showImg8 : true,
    imgLink1: holderState?.imgLink1 !== undefined ? holderState.imgLink1 : "",
    imgLink2: holderState?.imgLink2 !== undefined ? holderState.imgLink2 : "",
    imgLink3: holderState?.imgLink3 !== undefined ? holderState.imgLink3 : "",
    imgLink4: holderState?.imgLink4 !== undefined ? holderState.imgLink4 : "",
    imgLink5: holderState?.imgLink5 !== undefined ? holderState.imgLink5 : "",
    imgLink6: holderState?.imgLink6 !== undefined ? holderState.imgLink6 : "",
    imgLink7: holderState?.imgLink7 !== undefined ? holderState.imgLink7 : "",
    imgLink8: holderState?.imgLink8 !== undefined ? holderState.imgLink8 : "",
    showImgTitle1:
      holderState?.showImgTitle1 !== undefined
        ? holderState.showImgTitle1
        : true,
    showImgTitle2:
      holderState?.showImgTitle2 !== undefined
        ? holderState.showImgTitle2
        : true,
    showImgTitle3:
      holderState?.showImgTitle3 !== undefined
        ? holderState.showImgTitle3
        : true,
    showImgTitle4:
      holderState?.showImgTitle4 !== undefined
        ? holderState.showImgTitle4
        : true,
    showImgTitle5:
      holderState?.showImgTitle5 !== undefined
        ? holderState.showImgTitle5
        : true,
    showImgTitle6:
      holderState?.showImgTitle6 !== undefined
        ? holderState.showImgTitle6
        : true,
    showImgTitle7:
      holderState?.showImgTitle7 !== undefined
        ? holderState.showImgTitle7
        : true,
    showImgTitle8:
      holderState?.showImgTitle8 !== undefined
        ? holderState.showImgTitle8
        : true,
    showImgText1:
      holderState?.showImgText1 !== undefined ? holderState.showImgText1 : true,
    showImgText2:
      holderState?.showImgText2 !== undefined ? holderState.showImgText2 : true,
    showImgText3:
      holderState?.showImgText3 !== undefined ? holderState.showImgText3 : true,
    showImgText4:
      holderState?.showImgText4 !== undefined ? holderState.showImgText4 : true,
    showImgText5:
      holderState?.showImgText5 !== undefined ? holderState.showImgText5 : true,
    showImgText6:
      holderState?.showImgText6 !== undefined ? holderState.showImgText6 : true,
    showImgText7:
      holderState?.showImgText7 !== undefined ? holderState.showImgText7 : true,
    showImgText8:
      holderState?.showImgText8 !== undefined ? holderState.showImgText8 : true,
    imgTitle1:
      holderState?.imgTitle1 !== undefined
        ? holderState.imgTitle1
        : "First Slide Label",
    imgTitle2:
      holderState?.imgTitle2 !== undefined
        ? holderState.imgTitle2
        : "Second Slide Label",
    imgTitle3:
      holderState?.imgTitle3 !== undefined
        ? holderState.imgTitle3
        : "Third Slide Label",
    imgTitle4:
      holderState?.imgTitle4 !== undefined
        ? holderState.imgTitle4
        : "Fourth Slide Label",
    imgTitle5:
      holderState?.imgTitle5 !== undefined
        ? holderState.imgTitle5
        : "Fifth Slide Label",
    imgTitle6:
      holderState?.imgTitle6 !== undefined
        ? holderState.imgTitle6
        : "Sixth Slide Label",
    imgTitle7:
      holderState?.imgTitle7 !== undefined
        ? holderState.imgTitle7
        : "Seventh Slide Label",
    imgTitle8:
      holderState?.imgTitle8 !== undefined
        ? holderState.imgTitle8
        : "Eigth Slide Label",
    imgText1:
      holderState?.imgText1 !== undefined
        ? holderState.imgText1
        : "Nulla vitae elit libero, a pharetra augue mollis interdum.",
    imgText2:
      holderState?.imgText2 !== undefined
        ? holderState.imgText2
        : "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    imgText3:
      holderState?.imgText3 !== undefined
        ? holderState.imgText3
        : "Praesent commodo cursus magna, vel scelerisque nisl consectetur.",
    imgText4:
      holderState?.imgText4 !== undefined
        ? holderState.imgText4
        : "Nulla vitae elit libero, a pharetra augue mollis interdum.",
    imgText5:
      holderState?.imgText5 !== undefined
        ? holderState.imgText5
        : "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    imgText6:
      holderState?.imgText6 !== undefined
        ? holderState.imgText6
        : "Praesent commodo cursus magna, vel scelerisque nisl consectetur.",
    imgText7:
      holderState?.imgText7 !== undefined
        ? holderState.imgText7
        : "Nulla vitae elit libero, a pharetra augue mollis interdum.",
    imgText8:
      holderState?.imgText8 !== undefined
        ? holderState.imgText8
        : "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    imgSrc1:
      holderState?.imgSrc1 !== undefined
        ? holderState.imgSrc1
        : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt1: "Placeholder Img",
    imgSrc2:
      holderState?.imgSrc2 !== undefined
        ? holderState.imgSrc2
        : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt2: "Placeholder Img",
    imgSrc3:
      holderState?.imgSrc3 !== undefined
        ? holderState.imgSrc3
        : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt3: "Placeholder Img",
    imgSrc4:
      holderState?.imgSrc4 !== undefined
        ? holderState.imgSrc4
        : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt4: "Placeholder Img",
    imgSrc5:
      holderState?.imgSrc5 !== undefined
        ? holderState.imgSrc5
        : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt5: "Placeholder Img",
    imgSrc6:
      holderState?.imgSrc6 !== undefined
        ? holderState.imgSrc6
        : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt6: "Placeholder Img",
    imgSrc7:
      holderState?.imgSrc7 !== undefined
        ? holderState.imgSrc7
        : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt7: "Placeholder Img",
    imgSrc8:
      holderState?.imgSrc8 !== undefined
        ? holderState.imgSrc8
        : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt8: "Placeholder Img",
  });

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    setGal11((prevGal11) => ({
      ...prevGal11,
      showImg1:
        holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
      showImg2:
        holderState?.showImg2 !== undefined ? holderState.showImg2 : true,
      showImg3:
        holderState?.showImg3 !== undefined ? holderState.showImg3 : true,
      showImg4:
        holderState?.showImg4 !== undefined ? holderState.showImg4 : true,
      showImg5:
        holderState?.showImg5 !== undefined ? holderState.showImg5 : true,
      showImg6:
        holderState?.showImg6 !== undefined ? holderState.showImg6 : true,
      showImg7:
        holderState?.showImg7 !== undefined ? holderState.showImg7 : true,
      showImg8:
        holderState?.showImg8 !== undefined ? holderState.showImg8 : true,
      imgLink1: holderState?.imgLink1 !== undefined ? holderState.imgLink1 : "",
      imgLink2: holderState?.imgLink2 !== undefined ? holderState.imgLink2 : "",
      imgLink3: holderState?.imgLink3 !== undefined ? holderState.imgLink3 : "",
      imgLink4: holderState?.imgLink4 !== undefined ? holderState.imgLink4 : "",
      imgLink5: holderState?.imgLink5 !== undefined ? holderState.imgLink5 : "",
      imgLink6: holderState?.imgLink6 !== undefined ? holderState.imgLink6 : "",
      imgLink7: holderState?.imgLink7 !== undefined ? holderState.imgLink7 : "",
      imgLink8: holderState?.imgLink8 !== undefined ? holderState.imgLink8 : "",
      showImgTitle1:
        holderState?.showImgTitle1 !== undefined
          ? holderState.showImgTitle1
          : true,
      showImgTitle2:
        holderState?.showImgTitle2 !== undefined
          ? holderState.showImgTitle2
          : true,
      showImgTitle3:
        holderState?.showImgTitle3 !== undefined
          ? holderState.showImgTitle3
          : true,
      showImgTitle4:
        holderState?.showImgTitle4 !== undefined
          ? holderState.showImgTitle4
          : true,
      showImgTitle5:
        holderState?.showImgTitle5 !== undefined
          ? holderState.showImgTitle5
          : true,
      showImgTitle6:
        holderState?.showImgTitle6 !== undefined
          ? holderState.showImgTitle6
          : true,
      showImgTitle7:
        holderState?.showImgTitle7 !== undefined
          ? holderState.showImgTitle7
          : true,
      showImgTitle8:
        holderState?.showImgTitle8 !== undefined
          ? holderState.showImgTitle8
          : true,
      showImgText1:
        holderState?.showImgText1 !== undefined
          ? holderState.showImgText1
          : true,
      showImgText2:
        holderState?.showImgText2 !== undefined
          ? holderState.showImgText2
          : true,
      showImgText3:
        holderState?.showImgText3 !== undefined
          ? holderState.showImgText3
          : true,
      showImgText4:
        holderState?.showImgText4 !== undefined
          ? holderState.showImgText4
          : true,
      showImgText5:
        holderState?.showImgText5 !== undefined
          ? holderState.showImgText5
          : true,
      showImgText6:
        holderState?.showImgText6 !== undefined
          ? holderState.showImgText6
          : true,
      showImgText7:
        holderState?.showImgText7 !== undefined
          ? holderState.showImgText7
          : true,
      showImgText8:
        holderState?.showImgText8 !== undefined
          ? holderState.showImgText8
          : true,
      imgTitle1:
        holderState?.imgTitle1 !== undefined
          ? holderState.imgTitle1
          : "First Slide Label",
      imgTitle2:
        holderState?.imgTitle2 !== undefined
          ? holderState.imgTitle2
          : "Second Slide Label",
      imgTitle3:
        holderState?.imgTitle3 !== undefined
          ? holderState.imgTitle3
          : "Third Slide Label",
      imgTitle4:
        holderState?.imgTitle4 !== undefined
          ? holderState.imgTitle4
          : "Fourth Slide Label",
      imgTitle5:
        holderState?.imgTitle5 !== undefined
          ? holderState.imgTitle5
          : "Fifth Slide Label",
      imgTitle6:
        holderState?.imgTitle6 !== undefined
          ? holderState.imgTitle6
          : "Sixth Slide Label",
      imgTitle7:
        holderState?.imgTitle7 !== undefined
          ? holderState.imgTitle7
          : "Seventh Slide Label",
      imgTitle8:
        holderState?.imgTitle8 !== undefined
          ? holderState.imgTitle8
          : "Eigth Slide Label",
      imgText1:
        holderState?.imgText1 !== undefined
          ? holderState.imgText1
          : "Nulla vitae elit libero, a pharetra augue mollis interdum.",
      imgText2:
        holderState?.imgText2 !== undefined
          ? holderState.imgText2
          : "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      imgText3:
        holderState?.imgText3 !== undefined
          ? holderState.imgText3
          : "Praesent commodo cursus magna, vel scelerisque nisl consectetur.",
      imgText4:
        holderState?.imgText4 !== undefined
          ? holderState.imgText4
          : "Nulla vitae elit libero, a pharetra augue mollis interdum.",
      imgText5:
        holderState?.imgText5 !== undefined
          ? holderState.imgText5
          : "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      imgText6:
        holderState?.imgText6 !== undefined
          ? holderState.imgText6
          : "Praesent commodo cursus magna, vel scelerisque nisl consectetur.",
      imgText7:
        holderState?.imgText7 !== undefined
          ? holderState.imgText7
          : "Nulla vitae elit libero, a pharetra augue mollis interdum.",
      imgText8:
        holderState?.imgText8 !== undefined
          ? holderState.imgText8
          : "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      imgSrc1:
        holderState?.imgSrc1 !== undefined
          ? holderState.imgSrc1
          : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgAlt1: "Placeholder Img",
      imgSrc2:
        holderState?.imgSrc2 !== undefined
          ? holderState.imgSrc2
          : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgAlt2: "Placeholder Img",
      imgSrc3:
        holderState?.imgSrc3 !== undefined
          ? holderState.imgSrc3
          : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgAlt3: "Placeholder Img",
      imgSrc4:
        holderState?.imgSrc4 !== undefined
          ? holderState.imgSrc4
          : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgAlt4: "Placeholder Img",
      imgSrc5:
        holderState?.imgSrc5 !== undefined
          ? holderState.imgSrc5
          : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgAlt5: "Placeholder Img",
      imgSrc6:
        holderState?.imgSrc6 !== undefined
          ? holderState.imgSrc6
          : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgAlt6: "Placeholder Img",
      imgSrc7:
        holderState?.imgSrc7 !== undefined
          ? holderState.imgSrc7
          : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgAlt7: "Placeholder Img",
      imgSrc8:
        holderState?.imgSrc8 !== undefined
          ? holderState.imgSrc8
          : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgAlt8: "Placeholder Img",
    }));
  }, [
    holderState?.showImg1,
    holderState?.showImg2,
    holderState?.showImg3,
    holderState?.showImg4,
    holderState?.showImg5,
    holderState?.showImg6,
    holderState?.showImg7,
    holderState?.showImg8,
    holderState?.imgLink1,
    holderState?.imgLink2,
    holderState?.imgLink3,
    holderState?.imgLink4,
    holderState?.imgLink5,
    holderState?.imgLink6,
    holderState?.imgLink7,
    holderState?.imgLink8,
    holderState?.showImgText1,
    holderState?.showImgText2,
    holderState?.showImgText3,
    holderState?.showImgText4,
    holderState?.showImgText5,
    holderState?.showImgText6,
    holderState?.showImgText7,
    holderState?.showImgText8,
    holderState?.imgText1,
    holderState?.imgText2,
    holderState?.imgText3,
    holderState?.imgText4,
    holderState?.imgText5,
    holderState?.imgText6,
    holderState?.imgText7,
    holderState?.imgText8,
    holderState?.showImgTitle1,
    holderState?.showImgTitle2,
    holderState?.showImgTitle3,
    holderState?.showImgTitle4,
    holderState?.showImgTitle5,
    holderState?.showImgTitle6,
    holderState?.showImgTitle7,
    holderState?.showImgTitle8,
    holderState?.imgTitle1,
    holderState?.imgTitle2,
    holderState?.imgTitle3,
    holderState?.imgTitle4,
    holderState?.imgTitle5,
    holderState?.imgTitle6,
    holderState?.imgTitle7,
    holderState?.imgTitle8,
    holderState?.imgSrc1,
    holderState?.imgSrc2,
    holderState?.imgSrc3,
    holderState?.imgSrc4,
    holderState?.imgSrc5,
    holderState?.imgSrc6,
    holderState?.imgSrc7,
    holderState?.imgSrc8,
  ]);

  return (
    <Row sm={12} md={10} className="d-flex justify-content-center p-5 bg-white">
      <Carousel activeIndex={index} onSelect={handleSelect}>
        {gal11.showImg1 && (
          <Carousel.Item>
            <div className="text-center">
              {gal11.imgLink1 !== "" && (
                <a
                  href={"https://" + gal11.imgLink1}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src={gal11.imgSrc1}
                    alt={gal11.imgAlt1}
                    className="mb-3 mb-sm-0"
                    fluid
                  />
                </a>
              )}
              {gal11.imgLink1 === "" && (
                <Image
                  src={gal11.imgSrc1}
                  alt={gal11.imgAlt1}
                  className="mb-3 mb-sm-0"
                  fluid
                />
              )}
            </div>
            <Carousel.Caption>
              <div className="text-black">
                {gal11.showImgTitle1 && <h3>{gal11.imgTitle1}</h3>}
                {gal11.showImgText1 && <p>{gal11.imgText1}</p>}
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        )}
        {gal11.showImg2 && (
          <Carousel.Item>
            <div className="text-center">
              {gal11.imgLink2 !== "" && (
                <a
                  href={"https://" + gal11.imgLink2}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src={gal11.imgSrc2}
                    alt={gal11.imgAlt2}
                    className="mb-3 mb-sm-0"
                    fluid
                  />
                </a>
              )}
              {gal11.imgLink2 === "" && (
                <Image
                  src={gal11.imgSrc2}
                  alt={gal11.imgAlt2}
                  className="mb-3 mb-sm-0"
                  fluid
                />
              )}
            </div>
            <Carousel.Caption>
              <div className="text-black">
                {gal11.showImgTitle2 && <h3>{gal11.imgTitle2}</h3>}
                {gal11.showImgText2 && <p>{gal11.imgText2}</p>}
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        )}
        {gal11.showImg3 && (
          <Carousel.Item>
            <div className="text-center">
              {gal11.imgLink3 !== "" && (
                <a
                  href={"https://" + gal11.imgLink3}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src={gal11.imgSrc3}
                    alt={gal11.imgAlt3}
                    className="mb-3 mb-sm-0"
                    fluid
                  />
                </a>
              )}
              {gal11.imgLink3 === "" && (
                <Image
                  src={gal11.imgSrc3}
                  alt={gal11.imgAlt3}
                  className="mb-3 mb-sm-0"
                  fluid
                />
              )}
            </div>
            <Carousel.Caption>
              <div className="text-black">
                {gal11.showImgTitle3 && <h3>{gal11.imgTitle3}</h3>}
                {gal11.showImgText3 && <p>{gal11.imgText3}</p>}
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        )}
        {gal11.showImg4 && (
          <Carousel.Item>
            <div className="text-center">
              {gal11.imgLink4 !== "" && (
                <a
                  href={"https://" + gal11.imgLink4}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src={gal11.imgSrc4}
                    alt={gal11.imgAlt4}
                    className="mb-3 mb-sm-0"
                    fluid
                  />
                </a>
              )}
              {gal11.imgLink4 === "" && (
                <Image
                  src={gal11.imgSrc4}
                  alt={gal11.imgAlt4}
                  className="mb-3 mb-sm-0"
                  fluid
                />
              )}
            </div>
            <Carousel.Caption>
              <div className="text-black">
                {gal11.showImgTitle4 && <h3>{gal11.imgTitle4}</h3>}
                {gal11.showImgText4 && <p>{gal11.imgText4}</p>}
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        )}
        {gal11.showImg5 && (
          <Carousel.Item>
            <div className="text-center">
              {gal11.imgLink5 !== "" && (
                <a
                  href={"https://" + gal11.imgLink5}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src={gal11.imgSrc5}
                    alt={gal11.imgAlt5}
                    className="mb-3 mb-sm-0"
                    fluid
                  />
                </a>
              )}
              {gal11.imgLink5 === "" && (
                <Image
                  src={gal11.imgSrc5}
                  alt={gal11.imgAlt5}
                  className="mb-3 mb-sm-0"
                  fluid
                />
              )}
            </div>
            <Carousel.Caption>
              <div className="text-black">
                {gal11.showImgTitle5 && <h3>{gal11.imgTitle5}</h3>}
                {gal11.showImgText5 && <p>{gal11.imgText5}</p>}
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        )}
        {gal11.showImg6 && (
          <Carousel.Item>
            <div className="text-center">
              {gal11.imgLink6 !== "" && (
                <a
                  href={"https://" + gal11.imgLink6}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src={gal11.imgSrc6}
                    alt={gal11.imgAlt6}
                    className="mb-3 mb-sm-0"
                    fluid
                  />
                </a>
              )}
              {gal11.imgLink6 === "" && (
                <Image
                  src={gal11.imgSrc6}
                  alt={gal11.imgAlt6}
                  className="mb-3 mb-sm-0"
                  fluid
                />
              )}
            </div>
            <Carousel.Caption>
              <div className="text-black">
                {gal11.showImgTitle6 && <h3>{gal11.imgTitle6}</h3>}
                {gal11.showImgText6 && <p>{gal11.imgText6}</p>}
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        )}
        {gal11.showImg7 && (
          <Carousel.Item>
            <div className="text-center">
              {gal11.imgLink7 !== "" && (
                <a
                  href={"https://" + gal11.imgLink7}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src={gal11.imgSrc7}
                    alt={gal11.imgAlt7}
                    className="mb-3 mb-sm-0"
                    fluid
                  />
                </a>
              )}
              {gal11.imgLink7 === "" && (
                <Image
                  src={gal11.imgSrc7}
                  alt={gal11.imgAlt7}
                  className="mb-3 mb-sm-0"
                  fluid
                />
              )}
            </div>
            <Carousel.Caption>
              <div className="text-black">
                {gal11.showImgTitle7 && <h3>{gal11.imgTitle7}</h3>}
                {gal11.showImgText7 && <p>{gal11.imgText7}</p>}
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        )}
        {gal11.showImg8 && (
          <Carousel.Item>
            <div className="text-center">
              {gal11.imgLink8 !== "" && (
                <a
                  href={"https://" + gal11.imgLink8}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src={gal11.imgSrc8}
                    alt={gal11.imgAlt8}
                    className="mb-3 mb-sm-0"
                    fluid
                  />
                </a>
              )}
              {gal11.imgLink8 === "" && (
                <Image
                  src={gal11.imgSrc8}
                  alt={gal11.imgAlt8}
                  className="mb-3 mb-sm-0"
                  fluid
                />
              )}
            </div>
            <Carousel.Caption>
              <div className="text-black">
                {gal11.showImgTitle8 && <h3>{gal11.imgTitle8}</h3>}
                {gal11.showImgText8 && <p>{gal11.imgText8}</p>}
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        )}
      </Carousel>
    </Row>
  );
};

export default Gallery11;
