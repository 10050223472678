import React, { useState } from 'react'
import '../serviceComponents.css';
import { Card, Row, Col, Form } from 'react-bootstrap'

const DevRoleCard = () => {
    const [role, setRole] = useState('super_admin');

  return (
    <Card className="mt-3">
      <Card.Body>
        <Card.Title className="mb-3">Dev Roles</Card.Title>
        <Row>
          <Col className="col-12 mt-2 mb-2">Role: {role}</Col>
          <Col className='d-flex'>
            <Form.Select size='sm' onChange={(e) => setRole(e.target.value)}>
              <option value='super-admin' id='superAdmin' aria-labelledby="super-admin">Super-Admin</option>
              <option value='admin' id='admin' aria-labelledby="admin">Admin</option>
              <option value='editor' id='editor' aria-labelledby="editor">Editor</option>
            </Form.Select>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default DevRoleCard