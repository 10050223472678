import { createSlice } from '@reduxjs/toolkit';


const initialState = [
  {
      id: 1,
      name: "LA1",
      rowId: 0,
      bodyRowComponent: {
        componentName: "LA1",
        props: {
        imgSrc1: 'https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D',
        imgAlt1: 'Placeholder Img',
        imgLink1: "",
        headline: 'Medium Length display headline',
        text1: 'Separated they live in Bookmarks right at the coast of the famous Semantics, large language ocean Seperated they live in the Bookmarks right',
        link1Title: 'Action Link',
        link1Value: '',
        }
    }},
  {
      id: 2,
      name: "LA2",
      rowId: 0,
      bodyRowComponent: {
        componentName: "LA2",
        props: {
        imgSrc1: 'https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D',
        imgAlt1: 'Placeholder Img',
        imgLink1: "",
        headline: 'Medium Length display headline',
        text1: 'Separated they live in Bookmarks right at the coast of the famous Semantics, large language ocean Seperated they live in the Bookmarks right',
        text2: "Separated they live in Books, but sometimes",
        link1Title: 'Action Link',
        link1Value: '',
        button1: "Action",
        button1Link: "",
        }
    }},
  {
      id: 3,
      name: "LA3",
      rowId: 0,
      bodyRowComponent: {
        componentName: "LA3",
        props: {
        imgSrc1: 'https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D',
        imgAlt1: 'Placeholder Img',
        imgLink1: "",
        headline: 'Tagline',
        text1: 'Medium Length Display headline',
        text2: "Separated they live in Books, but sometimes",
        link1Title: 'Terms and Conditions',
        link1Value: '',
        button1: "Action",
        button1Link: "",
        }
    }},
  {
      id: 4,
      name: "LA4",
      rowId: 0,
      bodyRowComponent: {
        componentName: "LA4",
        props: {
        imgSrc1: 'https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D',
        imgAlt1: 'Placeholder Img',
        imgLink1: "",
        headline: 'Tagline',
        text1: 'Long headline to turn your visitors into users',
        link1Title: 'Action Link',
        link1Value: '',
        }
    }},
  {
      id: 5,
      name: "LA5",
      rowId: 0,
      bodyRowComponent: {
        componentName: "LA5",
        props: {
        imgSrc1: 'https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D',
        imgAlt1: 'Placeholder Img',
        imgLink1: "",
        headline: 'Tagline',
        text1: "Long Headline to turn your visitors into users",
        text2: "Gorgeous, high-quality video sharing on desktop, mobile, tablet",
        text3: "Gorgeous, high-quality video sharing on desktop, mobile, tablet",
        icon1: {
          type: "Disc",
          size: 32,
        },
        icon2: {
          type: "Grid1x2",
          size: 32,
        },
        }
    }},
  {
      id: 6,
      name: "LA6",
      rowId: 0,
      bodyRowComponent: {
        componentName: "LA6",
        props: {
        headline: "Tagline",
        text1: "Long headline to turn your visitors into users",
        text2: "Seperated they live in Bookmarks right at the coast of the famous Semantics, large language ocean Seperated they live in Bookmarks right at the coast",
        button1: "Action",
        button1Link: "",
        link1Title: "Action Link",
        link1Value: "",
        imgSrc1: 'https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D',
        imgAlt1: 'Placeholder Img',
        imgLink1: "",
        }
    }},
  {
      id: 7,
      name: "LA7",
      rowId: 0,
      bodyRowComponent: {
        componentName: "LA7",
        props: {
        text1: "Long Headline to turn your visitors into users",
        text2:
          "Separated they live in Bookmarks right at the coas of the famousSemantics, large language ocean Separated they live in Bookmarks right at the coast.",
        text3: "Gorgeous, high-quality video sharing on desktop, mobile, tablet",
        text4: "Gorgeous, high-quality video sharing on desktop, mobile, tablet",
        header1: "Feature One",
        header2: "Feature Two",
        imgSrc1: 'https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D',
        imgAlt1: 'Placeholder Img',
        imgSrc2:
          "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt2: "Placeholder Image",
        imgSrc3:
          "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt3: "Placeholder Image",
        imgLink1: "",
        imgLink2: "",
        imgLink3: "",
        }
    }},
  {
      id: 8,
      name: "LA8",
      rowId: 0,
      bodyRowComponent: {
        componentName: "LA8",
        props: {
        headline: "Tagline",
        text1: "Long headline to turn your visitors into users",
        text2: "Gorgeous, high-quality video sharing on desktop, mobile, tablet",
        text3: "Change the color to match your brand or vision",
        icon1: {
          type: "CircleHalf",
          size: 32,
        },
        icon2: {
          type: "UiRadiosGrid",
          size: 32,
        },
        imgSrc1: 'https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D',
        imgAlt1: 'Placeholder Img',
        imgLink1: "",
        }
    }},
  {
      id: 9,
      name: "LA9",
      rowId: 0,
      bodyRowComponent: {
        componentName: "LA9",
        props: {
        text1: "Long headline on two lines to turn your visitors into users and achieve more",
        text2: "Separated they live in Bookmarks right at the coast of the famous Semantics, large language ocean Seperated they live in the Bookmarks right at the coast",
        button1: "Action",
        button1Link: "",
        link1Title: "Action Link",
        link1Value: "",
        imgSrc1: 'https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D',
        imgAlt1: 'Placeholder Img',
        imgLink1: "",
        }
    }},
  {
      id: 10,
      name: "LA10",
      rowId: 0,
      bodyRowComponent: {
        componentName: "LA10",
        props: {
        text1: "Long headline on two lines to turn your visitors into users and achieve more",
        text2: "Separated they live in Bookmarks right at the coast of the famous Semantics, large language ocean",
        list1: "Showcase and embed your work with",
        list2: "Publish across social channels in a click",
        list3: "Sell your videos worldwide",
        list4: "Embed your work with",
        imgSrc1: "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt1: "Placeholder Image",
        imgLink1: "",
        }
    }},
  {
      id: 11,
      name: "LA11",
      rowId: 0,
      bodyRowComponent: {
        componentName: "LA11",
        props: {
        text1:
          "Long headline on two lines to turn your visitors into users and achieve more",
        text2:
          "Separated they live in Bookmarks right at the coast of the famous Semantics, large language ocean Separated they live in Bookmarks right at the coast",
        list1: "Showcase and embed your work with",
        list1Icon: "CheckCircle",
        list2: "Publish across social channels in a click",
        list2Icon: "CheckCircle",
        list3: "Sell your videos worldwide",
        list3Icon: "CheckCircle",
        imgSrc1:
          "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt1: "Placeholder Image",
        imgLink1: "",  
      }
    }},
  {
      id: 12,
      name: "LA12",
      rowId: 0,
      bodyRowComponent: {
        componentName: "LA12",
        props: {
      text1:
        "Long headline on two lines to turn your visitors into users and achieve more",
      text2:
        "Separated they live in Bookmarks right at the coast of the famous Semantics, large language ocean Separated they live in Bookmarks right at the coast. Separated they live in Bookmarks right at the coast of the famous Semantics.",
      text3: "Gorgeous, high-quality video sharing on desktop, mobile, tablet",
      text4: "Gorgeous, high-quality video sharing on desktop, mobile, tablet",
      readMore: "",
      header1: "Feature One",
      header1Icon: {
          type: "Disc",
          size: 32,
        },
      header2: "Feature Two",
      header2Icon: {
          type: "Grid1x2",
          size: 32,
        },
      imgSrc1:
        "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgAlt1: "Placeholder Image",
      imgLink1: "",
        }
    }},
  {
      id: 13,
      name: "LA13",
      rowId: 0,
      bodyRowComponent: {
        componentName: "LA13",
        props: {
        text1:
          "Long headline on two lines to turn your visitors into users and achieve more",
        text2:
          "Separated they live in Bookmarks right at the coast of the famous Semantics, large language ocean Separated they live in Bookmarks right at the coast",
        header1: "Paypal",
        header1Icon: {
          type: "Paypal",
          size: 22,
        },
        header2: "Pay",
        header2Icon: {
          type: "Apple",
          size: 22,
        },
        header3: "Pay",
        header3Icon: {
          type: "Android2",
          size: 22,
        },
        header4: "SAMSUNG PAY",
        header4Icon: {
          type: "",
          size: 22,
        },
        imgSrc1:
          "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt1: "Placeholder Image",
        imgLink1: "",
        }
    }},
  {
      id: 14,
      name: "LA14",
      rowId: 0,
      bodyRowComponent: {
        componentName: "LA14",
        props: {
        text1:
          "Medium length headline",
        text2:
          "Change the color to match your brand or vision, add your logo, choose the perfect thumbnail, remove the playbar, add speed controls, and more. Increase engagement with CTAs and custom end screens. So what are the benefits?",
        list1: "Showcase and embed your work with",
        list2: "Publish across social channels in a click",
        list3: "Sell your videos worldwide",
        list4: "Make more profit",
        imgSrc1:
          "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt1: "Placeholder Image",
        imgLink1: "",
        }
    }},
  {
      id: 15,
      name: "LA15",
      bodyRowComponent: {
        componentName: "LA15",
        props: {
        text1: "Medium length headline",
        text2:
          "Change the color to match your brand or vision, add your logo, choose the perfect thumbnail, remove the playbar, add speed controls, and more. Increase engagement with CTAs and custom end screens, or keep your video private and password-protected. Increase engagement with CTAs and custom end screens, or keep your video private and password-protected.",
        text3: "Gorgeous, high-quality video sharing on all devices",
        text4: "Gorgeous, high-quality video sharing on all devices",
        header1: "32",
        header2: "GB",
        header3: "2.6",
        header4: "Million",
        imgSrc1:
          "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt1: "Placeholder Image",
        imgSrc2:
          "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt2: "Placeholder Image",
        imgSrc3:
          "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt3: "Placeholder Image",
        imgLink1: "",
        }
    }},
  {
      id: 16,
      name: "LA16",
      bodyRowComponent: {
        componentName: "LA16",
        props: {
        icon1: {
          type: "Grid1x2",
          size: 32,
        },
        text1: "Medium length headline",
        text2:
          "Change the color to match your brand or vision, add your logo, choose the perfect thumbnail, remove the playbar, add speed controls, and more. Increase engagement with CTAs and custom end screens, or keep your video private and password-protected.",
        link1Title: "Action Link",
        link1Value: "",
        imgSrc1:
          "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt1: "Placeholder Image",
        imgLink1: "",
        }
    }},
  {
      id: 17,
      name: "LA17",
      bodyRowComponent: {
        componentName: "LA17",
        props: {
        text1: "Medium length headline",
        text2:
          "Change the color to match your brand or vision, add your logo, choose the perfect thumbnail, remove the playbar, add speed controls, and more. Increase engagement with CTAs and custom end screens, or keep your video private and password-protected. Increase engagement with CTAs and custom end screens, or keep your video private and password-protected.",
        text3: "Gorgeous, high-quality video sharing on desktop, mobile, tablet",
        text4: "Change the color to match your brand or vision",
        header1Icon: {
            type: "CircleHalf",
            size: 32,
          },
        header2Icon: {
            type: "UiRadiosGrid",
            size: 32,
          },
        imgSrc1:
          "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt1: "Placeholder Image",
        imgLink1: "",
        }
    }},
  {
      id: 18,
      name: "LA18",
      bodyRowComponent: {
        componentName: "LA18",
        props: {
        text1: "Gorgeous, high-quality video sharing on desktop, mobile, tablet",
        text2: "Gorgeous, high-quality video sharing on desktop, mobile, tablet",
        text3: "Gorgeous, high-quality video sharing on desktop, mobile, tablet",
        text4: "Gorgeous, high-quality video sharing on desktop, mobile, tablet",
        header1Icon: {
            type: "Grid1x2",
            size: 32,
          },
        header2Icon: {
            type: "CircleHalf",
            size: 32,
          },
        header3Icon: {
            type: "SquareHalf",
            size: 32,
          },
        header4Icon: {
            type: "UiRadiosGrid",
            size: 32,
          },
        header1: "Feature One",
        header2: "Feature Two",
        header3: "Feature Three",
        header4: "Feature Four",
        imgSrc1:
          "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt1: "Placeholder Image",
        imgLink1: "",
        }
    }},
  ];

const leftAlignedSlice = createSlice({
  name: 'leftAligned',
  initialState,
  reducers: {
    addLeftAligned: (state, action) => {
      state.push(action.payload);
    },
    removeLeftAligned: (state, action) => {
      return state.filter((leftAligned) => leftAligned.id !== action.payload);
    },
    selectLeftAligned: (state, action) => {
      return state.filter((leftAligned) => leftAligned.id === action.payload)
    },
  },
  
});

export const { addLeftAligned, removeLeftAligned, selectLeftAligned, } = leftAlignedSlice.actions;

export default leftAlignedSlice.reducer;
