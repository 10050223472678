import React from 'react'
import '../DashboardComponents/dashboard.css'
import { Row, Col, Accordion, Card, ListGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import DevRoleCard from '../ServiceComponents/AdminDashboard/DevRoleCard'

const SideBar = ({ isCollapsed, toggleCollapse }) => {

  return (
    <Col
      md={2}
      className={`bg-white text-white px-0 ${isCollapsed && 'collapsed'}`}
      id="side-bar-size">
      <Accordion flush defaultActiveKey="1" className="sticky-top">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Pages</Accordion.Header>
          <Accordion.Body className="p-0">
              <Link
                to="/dashboard/viewall"
                className="sidebar-nav-links ps-3 py-3 w-100"> 
                  View All Pages
              </Link>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Developer</Accordion.Header>
          <Accordion.Body className="" alwaysopen="true">
            <DevRoleCard />
            <Card className="mt-3">
              <Card.Body>
                <Card.Title className="mb-3">Dev Portal</Card.Title>
                <Row>
                  <Col className="d-flex">
                    <ListGroup>
                      <ListGroup.Item className='p-0 m-0'>
                        <Link
                          to="/dashboard/firstlogin"
                          className="text-decoration-none text-dark sidebar-nav-links px-3 py-2"
                        >
                          First Login
                        </Link>
                      </ListGroup.Item>
                      <ListGroup.Item className='p-0 m-0'>
                        <Link
                          to="/dashboard/nopages"
                          className="text-decoration-none text-dark sidebar-nav-links px-3 py-2"
                        >
                          No Pages
                        </Link>
                      </ListGroup.Item>
                      <ListGroup.Item className='p-0 m-0'>
                        <Link
                          to="/dashboard/homepage"
                          className="text-decoration-none text-dark sidebar-nav-links px-3 py-2"
                        >
                          Home Builder
                        </Link>
                      </ListGroup.Item>
                      <ListGroup.Item className='p-0 m-0'>
                        <Link
                          to="/dashboard/addpage"
                          className="text-decoration-none text-dark sidebar-nav-links px-3 py-2"
                        >
                          Add Page
                        </Link>
                      </ListGroup.Item>
                      <ListGroup.Item className='p-0 m-0'>
                        <Link
                          to="/dashboard/editfooter"
                          className="text-decoration-none text-dark sidebar-nav-links px-3 py-2"
                        >
                          Edit Footer
                        </Link>
                      </ListGroup.Item>
                      <ListGroup.Item className='p-0 m-0'>
                        <Link
                          to="/dashboard/editnavbar"
                          className="text-decoration-none text-dark sidebar-nav-links px-3 py-2"
                        >
                          Edit Navbar
                        </Link>
                      </ListGroup.Item>
                      <ListGroup.Item className='p-0 m-0'>
                        <Link
                          to="/dashboard/editpage"
                          className="text-decoration-none text-dark sidebar-nav-links px-3 py-2"
                        >
                          Edit Page
                        </Link>
                      </ListGroup.Item>
                      <ListGroup.Item className='p-0 m-0'>
                        <a
                          href="/dashboard/profile"
                          className="text-decoration-none text-dark sidebar-nav-links px-3 py-2"
                        >
                          Admin Portal
                        </a>
                      </ListGroup.Item>
                      <ListGroup.Item className='p-0 m-0'>
                        <Link
                          to="/body"
                          className="text-decoration-none text-dark sidebar-nav-links px-3 py-2"
                        >
                          Body Components
                        </Link>
                      </ListGroup.Item>
                    </ListGroup>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Col>
  );
}

export default SideBar
