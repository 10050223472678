import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { ChevronRight } from "react-bootstrap-icons";

const CA4 = ({ isPreview, holderState, selectedField }) => {
  const [centerAlign4, setCenterAlign4] = useState({
    headline:
      holderState?.headline !== undefined ? holderState.headline : "Tagline",
    showHeadline:
      holderState?.showHeadline !== undefined ? holderState.showHeadline : true,
    text1:
      holderState?.text1Value !== undefined
        ? holderState.text1Value
        : "Long headline to turn your visitors into users",
    showText1:
      holderState?.showText1 !== undefined ? holderState.showText1 : true,
    text2:
      holderState?.text2Value !== undefined
        ? holderState.text2Value
        : "Separated they live in Bookmarksgrove right at the coast of the famous Semantics, large language ocean and many more stuff and more more more",
    showText2:
      holderState?.showText2 !== undefined ? holderState.showText2 : true,
    link1Title:
      holderState?.link1Title !== undefined
        ? holderState.link1Title
        : "First Action",
    link1: holderState?.link1Value !== undefined ? holderState.link1Value : "",
    showLink1:
      holderState?.showLink1 !== undefined ? holderState.showLink1 : true,
    link2Title:
      holderState?.link2Title !== undefined
        ? holderState.link2Title
        : "Second Action",
    link2Value:
      holderState?.link2Value !== undefined ? holderState.link2Value : "",
    showLink2:
      holderState?.showLink2 !== undefined ? holderState.showLink2 : true,
  });

  useEffect(() => {
    setCenterAlign4((prevCA4) => ({
      ...prevCA4,
      headline:
        holderState?.headline !== undefined ? holderState.headline : "Tagline",
      showHeadline:
        holderState?.showHeadline !== undefined
          ? holderState.showHeadline
          : true,
      text1:
        holderState?.text1Value !== undefined
          ? holderState.text1Value
          : "Long headline to turn your visitors into users",
      showText1:
        holderState?.showText1 !== undefined ? holderState.showText1 : true,
      text2:
        holderState?.text2Value !== undefined
          ? holderState.text2Value
          : "Separated they live in Bookmarksgrove right at the coast of the famous Semantics, large language ocean and many more stuff and more more more",
      showText2:
        holderState?.showText2 !== undefined ? holderState.showText2 : true,
      link1Title:
        holderState?.link1Title !== undefined
          ? holderState.link1Title
          : "First Action",
      link1Value:
        holderState?.link1Value !== undefined ? holderState.link1Value : "",
      showLink1:
        holderState?.showLink1 !== undefined ? holderState.showLink1 : true,
      link2Title:
        holderState?.link2Title !== undefined
          ? holderState.link2Title
          : "Second Action",
      link2Value:
        holderState?.link2Value !== undefined ? holderState.link2Value : "",
      showLink2:
        holderState?.showLink2 !== undefined ? holderState.showLink2 : true,
    }));
  }, [
    holderState?.showText1,
    holderState?.text1Value,
    holderState?.showText2,
    holderState?.text2Value,
    holderState?.link1Title,
    holderState?.link1Value,
    holderState?.showLink1,
    holderState?.link2Title,
    holderState?.link2Value,
    holderState?.showLink2,
    holderState?.headline,
    holderState?.showHeadline,
  ]);

  return (
    <Row
      sm={12}
      md={12}
      lg={10}
      xl={10}
      xxl={8}
      style={{ "--bs-gutter-x": "0" }}
      className={`d-flex justify-content-center bg-white ${
        isPreview ? "p-3" : "p-5"
      }`}
    >
      <Col
        xs={12}
        md={12}
        lg={10}
        xl={10}
        className="d-flex flex-column align-items-center justify-content-center"
      >
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={10}
          xl={8}
          xxl={8}
          className="pt-3 text-center"
        >
          {centerAlign4.showHeadline && (
            <p
              className={`fw-semibold pt-3 ${
                isPreview ? "preview-scaling" : ""
              } ${selectedField === "headline" ? "focus-field" : ""}`}
            >
              {centerAlign4.headline}
            </p>
          )}
          {centerAlign4.showText1 && (
            <h1
              className={`fw-bold ${isPreview ? "preview-scaling" : ""} ${
                selectedField === "text1" ? "focus-field" : ""
              }`}
            >
              {centerAlign4.text1}
            </h1>
          )}
        </Col>
        <Col
          xs={12}
          sm={12}
          md={11}
          lg={9}
          xl={6}
          xxl={6}
          className={`text-center ${isPreview ? "" : "py-3"}`}
        >
          {centerAlign4.showText2 && (
            <p
              className={` ${isPreview ? "preview-scaling mb-0" : "pb-3"} ${
                selectedField === "text2" ? "focus-field" : ""
              }`}
            >
              {centerAlign4.text2}
            </p>
          )}
          {centerAlign4.showLink1 && (
            <a
              className={`fw-bold link-offset-2 link-underline link-underline-opacity-0 link-underline-opacity-75-hover px-2 ${
                isPreview ? "preview-scaling" : ""
              } ${selectedField === "link1Value" ? "focus-field" : ""}`}
              href={"https://" + centerAlign4.link1Value}
              target="_blank"
              rel="noopener noreferrer"
            >
              {centerAlign4.link1Title} <ChevronRight />
            </a>
          )}
          {centerAlign4.showLink2 && (
            <a
              className={`fw-bold link-offset-2 link-underline link-underline-opacity-0 link-underline-opacity-75-hover px-2 ${
                isPreview ? "preview-scaling" : ""
              } ${selectedField === "link2Value" ? "focus-field" : ""}`}
              href={"https://" + centerAlign4.link2Value}
              target="_blank"
              rel="noopener noreferrer"
            >
              {centerAlign4.link2Title} <ChevronRight />
            </a>
          )}
        </Col>
      </Col>
    </Row>
  );
};

export default CA4;
