import React, { useState, useEffect } from "react";
import { Row, Col, Image } from "react-bootstrap";

const Gallery5 = ({ isPreview, holderState, selectedField }) => {
  const [gal5, setGal5] = useState({
    showImg1: holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
    showImg2: holderState?.showImg2 !== undefined ? holderState.showImg2 : true,
    showImg3: holderState?.showImg3 !== undefined ? holderState.showImg3 : true,
    showImg4: holderState?.showImg4 !== undefined ? holderState.showImg4 : true,
    imgLink1: holderState?.imgLink1 !== undefined ? holderState.imgLink1 : "",
    imgLink2: holderState?.imgLink2 !== undefined ? holderState.imgLink2 : "",
    imgLink3: holderState?.imgLink3 !== undefined ? holderState.imgLink3 : "",
    imgLink4: holderState?.imgLink4 !== undefined ? holderState.imgLink4 : "",
    imgSrc1:
      holderState?.imgSrc1 !== undefined
        ? holderState.imgSrc1
        : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt1: "Placeholder Img",
    imgSrc2:
      holderState?.imgSrc2 !== undefined
        ? holderState.imgSrc2
        : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt2: "Placeholder Img",
    imgSrc3:
      holderState?.imgSrc3 !== undefined
        ? holderState.imgSrc3
        : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt3: "Placeholder Img",
    imgSrc4:
      holderState?.imgSrc4 !== undefined
        ? holderState.imgSrc4
        : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt4: "Placeholder Img",
  });

  useEffect(() => {
    setGal5((prevGal5) => ({
      ...prevGal5,
      showImg1:
        holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
      showImg2:
        holderState?.showImg2 !== undefined ? holderState.showImg2 : true,
      showImg3:
        holderState?.showImg3 !== undefined ? holderState.showImg3 : true,
      showImg4:
        holderState?.showImg4 !== undefined ? holderState.showImg4 : true,
      imgLink1: holderState?.imgLink1 !== undefined ? holderState.imgLink1 : "",
      imgLink2: holderState?.imgLink2 !== undefined ? holderState.imgLink2 : "",
      imgLink3: holderState?.imgLink3 !== undefined ? holderState.imgLink3 : "",
      imgLink4: holderState?.imgLink4 !== undefined ? holderState.imgLink4 : "",
      imgSrc1:
        holderState?.imgSrc1 !== undefined
          ? holderState.imgSrc1
          : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgAlt1: "Placeholder Img",
      imgSrc2:
        holderState?.imgSrc2 !== undefined
          ? holderState.imgSrc2
          : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgAlt2: "Placeholder Img",
      imgSrc3:
        holderState?.imgSrc3 !== undefined
          ? holderState.imgSrc3
          : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgAlt3: "Placeholder Img",
      imgSrc4:
        holderState?.imgSrc4 !== undefined
          ? holderState.imgSrc4
          : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgAlt4: "Placeholder Img",
    }));
  }, [
    holderState?.showImg1,
    holderState?.showImg2,
    holderState?.showImg3,
    holderState?.showImg4,
    holderState?.imgLink1,
    holderState?.imgLink2,
    holderState?.imgLink3,
    holderState?.imgLink4,
    holderState?.imgSrc1,
    holderState?.imgSrc2,
    holderState?.imgSrc3,
    holderState?.imgSrc4,
  ]);

  return (
    <Row
      sm={12}
      md={10}
      className={`d-flex justify-content-center bg-white ${
        isPreview ? "px-3 pt-3" : "p-5"
      }`}
    >
      <Col
        xs={12}
        sm={3}
        className="d-flex align-items-center justify-content-center my-2"
      >
        {gal5.showImg1 && (
          <div
            className={`text-center ${
              selectedField === "imgSrc1" ? "focus-field" : ""
            }`}
          >
            {gal5.imgLink1 !== "" && (
              <a
                href={"https://" + gal5.imgLink1}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image src={gal5.imgSrc1} alt={gal5.imgAlt1} fluid />
              </a>
            )}
            {gal5.imgLink1 === "" && (
              <Image src={gal5.imgSrc1} alt={gal5.imgAlt1} fluid />
            )}
          </div>
        )}
      </Col>
      <Col
        xs={12}
        sm={3}
        className="d-flex align-items-center justify-content-center my-2"
      >
        {gal5.showImg2 && (
          <div
            className={`text-center ${
              selectedField === "imgSrc2" ? "focus-field" : ""
            }`}
          >
            {gal5.imgLink2 !== "" && (
              <a
                href={"https://" + gal5.imgLink2}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image src={gal5.imgSrc2} alt={gal5.imgAlt2} fluid />
              </a>
            )}
            {gal5.imgLink2 === "" && (
              <Image src={gal5.imgSrc2} alt={gal5.imgAlt2} fluid />
            )}
          </div>
        )}
      </Col>
      <Col
        xs={12}
        sm={3}
        className="d-flex align-items-center justify-content-center my-2"
      >
        {gal5.showImg3 && (
          <div
            className={`text-center ${
              selectedField === "imgSrc3" ? "focus-field" : ""
            }`}
          >
            {gal5.imgLink3 !== "" && (
              <a
                href={"https://" + gal5.imgLink3}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image src={gal5.imgSrc3} alt={gal5.imgAlt3} fluid />
              </a>
            )}
            {gal5.imgLink3 === "" && (
              <Image src={gal5.imgSrc3} alt={gal5.imgAlt3} fluid />
            )}
          </div>
        )}
      </Col>
      <Col
        xs={12}
        sm={3}
        className="d-flex align-items-center justify-content-center my-2"
      >
        {gal5.showImg4 && (
          <div
            className={`text-center ${
              selectedField === "imgSrc4" ? "focus-field" : ""
            }`}
          >
            {gal5.imgLink4 !== "" && (
              <a
                href={"https://" + gal5.imgLink4}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image src={gal5.imgSrc4} alt={gal5.imgAlt4} fluid />
              </a>
            )}
            {gal5.imgLink4 === "" && (
              <Image src={gal5.imgSrc4} alt={gal5.imgAlt4} fluid />
            )}
          </div>
        )}
      </Col>
    </Row>
  );
};

export default Gallery5;
