import React, { useEffect, useState } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import RenderIconComponent from '../../../ApplicationComponents/DashboardComponents/ComponentChoices/ChoicesComponents/RenderIconComponent';

const RA17 = ({ isPreview, holderState, selectedField }) => {
  const [rightAlign17, setRighttAlign17] = useState({
    text1:
      holderState?.text1Value !== undefined
        ? holderState.text1Value
        : "Medium length headline",
    showText1:
      holderState?.showText1 !== undefined ? holderState.showText1 : true,
    text2:
      holderState?.text2Value !== undefined
        ? holderState.text2Value
        : "Change the color to match your brand or vision, add your logo, choose the perfect thumbnail, remove the playbar, add speed controls, and more. Increase engagement with CTAs and custom end screens, or keep your video private and password-protected. Increase engagement with CTAs and custom end screens, or keep your video private and password-protected.",
    showText2:
      holderState?.showText2 !== undefined ? holderState.showText2 : true,
    text3:
      holderState?.text3Value !== undefined
        ? holderState.text3Value
        : "Gorgeous, high-quality video sharing on all devices",
    showText3:
      holderState?.showText3 !== undefined ? holderState.showText3 : true,
    text4:
      holderState?.text4Value !== undefined
        ? holderState.text4Value
        : "Gorgeous, high-quality video sharing on all devices",
    showText4:
      holderState?.showText4 !== undefined ? holderState.showText4 : true,
    header1Icon: {
      type:
        holderState?.header1Icon?.type !== undefined
          ? holderState.header1Icon.type
          : "CircleHalf",
      size:
        holderState?.header1Icon?.size !== undefined
          ? holderState.header1Icon.size
          : isPreview
          ? 12
          : 32,
    },
    header2Icon: {
      type:
        holderState?.header2Icon?.type !== undefined
          ? holderState.header2Icon.type
          : "UiRadiosGrid",
      size:
        holderState?.header2Icon?.size !== undefined
          ? holderState.header2Icon.size
          : isPreview
          ? 12
          : 32,
    },
    header1IconLink:
      holderState?.header1IconLink !== undefined
        ? holderState.header1IconLink
        : "",
    header2IconLink:
      holderState?.header2IconLink !== undefined
        ? holderState.header2IconLink
        : "",
    showHeader1Icon:
      holderState?.showHeader1Icon !== undefined
        ? holderState.showHeader1Icon
        : true,
    showHeader2Icon:
      holderState?.showHeader2Icon !== undefined
        ? holderState.showHeader2Icon
        : true,
    showImg1: holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
    imgLink1: holderState?.imgLink1 !== undefined ? holderState.imgLink1 : "",
    imgSrc1:
      holderState?.imgSrc1 !== undefined
        ? holderState.imgSrc1
        : "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt1: "Placeholder Image",
  });

  const [showMore, setShowMore] = useState(false);

  const imageContainerStyle = {
    display: "flex",
    alignItems: "center",
    height: "100%",
    maxHeight: "100%",
    borderRadius: "1rem",
    overflow: "hidden",
  };
  const imageStyle = {
    objectFit: "cover",
    height: "22rem",
    width: "100%",
    borderRadius: "1rem",
  };

  useEffect(() => {
    setRighttAlign17((prevRA17) => ({
      ...prevRA17,
      text1:
        holderState?.text1Value !== undefined
          ? holderState.text1Value
          : "Medium length headline",
      showText1:
        holderState?.showText1 !== undefined ? holderState.showText1 : true,
      text2:
        holderState?.text2Value !== undefined
          ? holderState.text2Value
          : "Change the color to match your brand or vision, add your logo, choose the perfect thumbnail, remove the playbar, add speed controls, and more. Increase engagement with CTAs and custom end screens, or keep your video private and password-protected. Increase engagement with CTAs and custom end screens, or keep your video private and password-protected.",
      showText2:
        holderState?.showText2 !== undefined ? holderState.showText2 : true,
      text3:
        holderState?.text3Value !== undefined
          ? holderState.text3Value
          : "Gorgeous, high-quality video sharing on all devices",
      showText3:
        holderState?.showText3 !== undefined ? holderState.showText3 : true,
      text4:
        holderState?.text4Value !== undefined
          ? holderState.text4Value
          : "Gorgeous, high-quality video sharing on all devices",
      showText4:
        holderState?.showText4 !== undefined ? holderState.showText4 : true,
      header1Icon: {
        type:
          holderState?.header1Icon?.type !== undefined
            ? holderState.header1Icon.type
            : "CircleHalf",
        size:
          holderState?.header1Icon?.size !== undefined
            ? holderState.header1Icon.size
            : isPreview
            ? 12
            : 32,
      },
      header2Icon: {
        type:
          holderState?.header2Icon?.type !== undefined
            ? holderState.header2Icon.type
            : "UiRadiosGrid",
        size:
          holderState?.header2Icon?.size !== undefined
            ? holderState.header2Icon.size
            : isPreview
            ? 12
            : 32,
      },
      header1IconLink:
        holderState?.header1IconLink !== undefined
          ? holderState.header1IconLink
          : "",
      header2IconLink:
        holderState?.header2IconLink !== undefined
          ? holderState.header2IconLink
          : "",
      showHeader1Icon:
        holderState?.showHeader1Icon !== undefined
          ? holderState.showHeader1Icon
          : true,
      showHeader2Icon:
        holderState?.showHeader2Icon !== undefined
          ? holderState.showHeader2Icon
          : true,
      showImg1:
        holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
      imgLink1: holderState?.imgLink1 !== undefined ? holderState.imgLink1 : "",
      imgSrc1:
        holderState?.imgSrc1 !== undefined
          ? holderState.imgSrc1
          : "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgAlt1: "Placeholder Image",
    }));
  }, [
    holderState?.showText1,
    holderState?.text1Value,
    holderState?.showText2,
    holderState?.text2Value,
    holderState?.showText3,
    holderState?.text3Value,
    holderState?.showText4,
    holderState?.text4Value,
    holderState?.showImg1,
    holderState?.imgLink1,
    holderState?.showHeader1Icon,
    holderState?.showHeader2Icon,
    holderState?.header1IconLink,
    holderState?.header2IconLink,
    holderState?.header1Icon,
    holderState?.header2Icon,
    holderState?.header1Icon?.size,
    holderState?.header1Icon?.type,
    holderState?.header2Icon?.size,
    holderState?.header2Icon?.type,
    holderState?.imgSrc1,
    isPreview
  ]);

  return (
    <Row
      sm={12}
      md={12}
      lg={10}
      xl={10}
      xxl={8}
      style={{ "--bs-gutter-x": "0" }}
      className={`d-flex justify-content-center bg-white gap-5 ${
        isPreview ? "p-3" : "p-5"
      }`}
    >
      <Col
        xs={12}
        sm={10}
        md={5}
        lg={5}
        xl={5}
        xxl={4}
        className={`d-flex align-items-center justify-content-center my-auto ${
          isPreview ? "preview-scaling" : ""
        }`}
      >
        {rightAlign17.showImg1 && (
          <Col
            className={`text-center justify-content-center ${
              selectedField === "imgSrc1" ? "focus-field" : ""
            }`}
            style={imageContainerStyle}
          >
            {rightAlign17.imgLink1 !== "" && (
              <a
                href={"https://" + rightAlign17.imgLink1}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  src={rightAlign17.imgSrc1}
                  alt={rightAlign17.imgAlt1}
                  style={imageStyle}
                  fluid
                />
              </a>
            )}
            {rightAlign17.imgLink1 === "" && (
              <Image
                src={rightAlign17.imgSrc1}
                alt={rightAlign17.imgAlt1}
                style={imageStyle}
                fluid
              />
            )}
          </Col>
        )}
      </Col>
      <Col
        xs={12}
        sm={10}
        md={6}
        lg={5}
        xl={5}
        xxl={4}
        className="d-flex flex-column align-items-center justify-content-center"
      >
        <Col className="d-flex flex-column justify-content-center mx-2">
          {rightAlign17.showText1 && (
            <h5
              className={`fw-bold mb-3 ${
                isPreview ? "preview-scaling mt-1" : ""
              } ${selectedField === "text1" ? "focus-field" : ""}`}
            >
              {rightAlign17.text1}
            </h5>
          )}
          {rightAlign17.showText2 && (
            <p
              className={`mb-3 ${isPreview ? "preview-scaling" : ""} ${
                selectedField === "text2" ? "focus-field" : ""
              }`}
            >
              {showMore
                ? rightAlign17.text2
                : `${rightAlign17.text2.substring(0, 250)}`}
              <Link
                onClick={() => setShowMore(!showMore)}
                className="link-offset-2 link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                href="#"
              >
                {showMore ? "  Read less." : "  Read more."}
              </Link>
            </p>
          )}
        </Col>
        <Col
          md={11}
          className={`d-flex align-items-top mx-2 ${
            isPreview ? "gap-1" : "gap-2"
          }`}
        >
          <Col md={1}>
            {rightAlign17.showHeader1Icon && (
              <>
                {rightAlign17.header1IconLink !== "" && (
                  <a
                    href={"https://" + rightAlign17.header1IconLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`text-black ${
                      selectedField === "header1Icon" ? "focus-field" : ""
                    }`}
                  >
                    {RenderIconComponent({
                      iconType: rightAlign17.header1Icon.type,
                      iconSize:
                        isPreview === true ? 11 : rightAlign17.header1Icon.size,
                    })}
                  </a>
                )}
                {rightAlign17.header1IconLink === "" && (
                  <span
                    className={`${
                      selectedField === "header1Icon" ? "focus-field" : ""
                    }`}
                  >
                    {RenderIconComponent({
                      iconType: rightAlign17.header1Icon.type,
                      iconSize:
                        isPreview === true ? 11 : rightAlign17.header1Icon.size,
                    })}
                  </span>
                )}
              </>
            )}
          </Col>
          <Col md={4}>
            {rightAlign17.showText3 && (
              <p
                className={`${isPreview ? "preview-scaling" : ""} ${
                  selectedField === "text3" ? "focus-field" : ""
                }`}
              >
                {rightAlign17.text3}
              </p>
            )}
          </Col>
          <Col md={1}>
            {rightAlign17.showHeader2Icon && (
              <>
                {" "}
                {rightAlign17.header2IconLink !== "" && (
                  <a
                    href={"https://" + rightAlign17.header2IconLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`text-black ${
                      selectedField === "header2Icon" ? "focus-field" : ""
                    }`}
                  >
                    {RenderIconComponent({
                      iconType: rightAlign17.header2Icon.type,
                      iconSize:
                        isPreview === true ? 11 : rightAlign17.header2Icon.size,
                    })}
                  </a>
                )}
                {rightAlign17.header2IconLink === "" && (
                  <span
                    className={`${
                      selectedField === "header2Icon" ? "focus-field" : ""
                    }`}
                  >
                    {RenderIconComponent({
                      iconType: rightAlign17.header2Icon.type,
                      iconSize:
                        isPreview === true ? 11 : rightAlign17.header2Icon.size,
                    })}
                  </span>
                )}
              </>
            )}
          </Col>
          <Col md={4}>
            {rightAlign17.showText4 && (
              <p
                className={`${isPreview ? "preview-scaling" : ""} ${
                  selectedField === "text4" ? "focus-field" : ""
                }`}
              >
                {rightAlign17.text4}
              </p>
            )}
          </Col>
        </Col>
      </Col>
    </Row>
  );
};

export default RA17;