import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";

const CA6 = ({ isPreview, holderState, selectedField }) => {
  const [centerAlign6, setCenterAlign6] = useState({
    headline:
      holderState?.headline !== undefined ? holderState.headline : "Tagline",
    showHeadline:
      holderState?.showHeadline !== undefined ? holderState.showHeadline : true,
    text1:
      holderState?.text1Value !== undefined
        ? holderState.text1Value
        : "Long headline to turn your visitors into users",
    showText1:
      holderState?.showText1 !== undefined ? holderState.showText1 : true,
    text2:
      holderState?.text2Value !== undefined
        ? holderState.text2Value
        : "Change the color to match your brand or vision, add your logo, choose the perfect thumbnail, remove the playbar, add speed controls, and more. Increase engagement with CTAs and custom end screens, or keep your video private and password-protected.",
    showText2:
      holderState?.showText2 !== undefined ? holderState.showText2 : true,
  });

  useEffect(() => {
    setCenterAlign6((prevCA6) => ({
      ...prevCA6,
      headline:
        holderState?.headline !== undefined ? holderState.headline : "Tagline",
      showHeadline:
        holderState?.showHeadline !== undefined
          ? holderState.showHeadline
          : true,
      text1:
        holderState?.text1Value !== undefined
          ? holderState.text1Value
          : "Long headline to turn your visitors into users",
      showText1:
        holderState?.showText1 !== undefined ? holderState.showText1 : true,
      text2:
        holderState?.text2Value !== undefined
          ? holderState.text2Value
          : "Change the color to match your brand or vision, add your logo, choose the perfect thumbnail, remove the playbar, add speed controls, and more. Increase engagement with CTAs and custom end screens, or keep your video private and password-protected.",
      showText2:
        holderState?.showText2 !== undefined ? holderState.showText2 : true,
    }));
  }, [
    holderState?.showText1,
    holderState?.text1Value,
    holderState?.showText2,
    holderState?.text2Value,
    holderState?.headline,
    holderState?.showHeadline,
  ]);

  return (
    <Row
      sm={12}
      md={12}
      lg={10}
      xl={10}
      xxl={8}
      style={{ "--bs-gutter-x": "0" }}
      className={`d-flex justify-content-center bg-white ${
        isPreview ? "p-3" : "p-5"
      }`}
    >
      <Col
        xs={12}
        md={12}
        lg={10}
        xl={10}
        className="d-flex flex-column align-items-center justify-content-center"
      >
        <Col
          xs={12}
          sm={10}
          md={10}
          lg={7}
          xl={6}
          xxl={6}
          className="pt-3 text-center"
        >
          {centerAlign6.showHeadline && (
            <p
              className={`fw-semibold pt-3 ${
                isPreview ? "preview-scaling" : ""
              } ${selectedField === "headline" ? "focus-field" : ""}`}
            >
              {centerAlign6.headline}
            </p>
          )}
          {centerAlign6.showText1 && (
            <h2
              className={`fw-bold ${isPreview ? "preview-scaling" : ""} ${
                selectedField === "text1" ? "focus-field" : ""
              }`}
            >
              {centerAlign6.text1}
            </h2>
          )}
          {centerAlign6.showText2 && (
            <p
              className={`${isPreview ? "preview-scaling" : "p-3"} ${
                selectedField === "text2" ? "focus-field" : ""
              }`}
            >
              {centerAlign6.text2}
            </p>
          )}
        </Col>
      </Col>
    </Row>
  );
};

export default CA6;
