import React, { useState } from "react";
import { Form, Image, Card, Button, Modal } from "react-bootstrap";
import DisplayImageLibrary from "./DisplayImageLibrary";
import ImageUpload from "./ImageUpload";

const ImageSourceForm = ({
  showImg,
  setShowImg,
  setSelectedImage,
  imgSrc,
  imgSrcString,
  imgNumber,
  handleImageClick,
  imgLink,
  setImgLink,
  handleFileChange,
  handleDeleteClick,
  imgCollection,
  setSelectedField,
  selectedField,
}) => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
    setSelectedImage(imgSrcString);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <Card
      className={`col-3 px-0 ${
        selectedField === "imgSrc" + imgNumber
          ? "focus-field"
          : "focus-hover"
      }`}
      onClick={() => setSelectedField("imgSrc" + imgNumber)}
    >
      <Card.Body>
        <Card.Title>Image {imgNumber}</Card.Title>
        <Form.Group>
          <Form.Check
            type="checkbox"
            name="display image"
            value="display"
            label="Display"
            checked={showImg}
            onChange={(e) => setShowImg(e.target.checked)}
          />
          <ImageUpload
            handleFileChange={handleFileChange}
            imgSrcString={imgSrcString}
            setSelectedImage={setSelectedImage}
          />
          <div className="d-flex justify-content-center">
            <Button
              onClick={handleShowModal}
              variant="light"
              className="border text-dark fw-semibold px-4 mx-auto mb-3"
            >
              Image Library
            </Button>
          </div>
          {imgSrc && (
            <Image
              fluid
              src={imgSrc}
              alt={`Placeholder Text`}
              className="tiny-img"
              onClick={() => handleImageClick(imgSrc)}
              onLoad={() => handleImageClick(imgSrc)}
            />
          )}
          <Form.Control
            type="text"
            placeholder="Enter URL"
            value={imgLink}
            onChange={(e) => setImgLink(e.target.value)}
          />
          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Image Library</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <DisplayImageLibrary
                imgCollection={imgCollection}
                handleImageClick={handleImageClick}
                handleDeleteClick={handleDeleteClick}
                handleFileChange={handleFileChange}
                imgSrcString={imgSrcString}
                setSelectedImage={setSelectedImage}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </Form.Group>
      </Card.Body>
    </Card>
  );
};

export default ImageSourceForm;
