import React from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';

const Form8 = () => {

  return (
    <Row sm={12} md={12} lg={10} xl={10} xxl={8} style={{"--bs-gutter-x":'0'}} className='d-flex justify-content-center p-5 bg-white'>
        <Col xs={12} md={12} lg={10} xl={10} className="d-flex flex-column align-items-center justify-content-center">
        
            <Col xs={12} sm={8} md={8} lg={8} xl={8} xxl={5} className='d-flex flex-column gap-3'>
                <Form>
                    <Form.Group className="mb-3">
                            <Form.Label htmlFor='email' className='fw-semibold'>Email</Form.Label>
                            <Form.Control id='email' type="email" placeholder="name@mail.com" className='bg-light' autoComplete="email" />
                            <Form.Label htmlFor='name' className='mt-2 fw-semibold'>Your name</Form.Label>
                            <Form.Control id='name' type="text" placeholder="John Malkovitch" className='bg-light' autoComplete="name" />
                            <Form.Label htmlFor='topic' className='mt-2 fw-semibold'>Choose a topic</Form.Label>
                            <Form.Select id='topic' className='bg-light'>
                                <option>Choose topic</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </Form.Select>
                            <Form.Label htmlFor='feedback' className='mt-2 fw-semibold'>Label</Form.Label>
                            <Form.Control id='feedback' as="textarea" rows={3} placeholder='Feedback' className='bg-light' />
                        </Form.Group>    
                    <Button type='submit' className='d-flex w-100 justify-content-center'>Send Your Feedback</Button>
                </Form>
            </Col>

        </Col>
    </Row>
  )
}

export default Form8