import React from "react";
import {
  House,
  Gear,
  Person,
  Calendar,
  Envelope,
  Chat,
  Search,
  Star,
  Disc,
  Grid1x2,
  CircleHalf,
  UiRadiosGrid,
  CheckCircle,
  Android2,
  Apple,
  Paypal,
  SquareHalf,
  Instagram,
  Facebook,
  Pinterest,
  Twitter,
  Tiktok,
  Linkedin,
  Link45deg,
} from "react-bootstrap-icons";

const RenderIconComponent = ({ iconType, iconSize }) => {
    switch (iconType) {
      case "House":
        return <House size={iconSize} />;
      case "Gear":
        return <Gear size={iconSize} />;
      case "Person":
        return <Person size={iconSize} />;
      case "Calendar":
        return <Calendar size={iconSize} />;
      case "Envelope":
        return <Envelope size={iconSize} />;
      case "Chat":
        return <Chat size={iconSize} />;
      case "Search":
        return <Search size={iconSize} />;
      case "Star":
        return <Star size={iconSize} />;
      case "Disc":
        return <Disc size={iconSize} />;
      case "Grid1x2":
        return <Grid1x2 size={iconSize} />;
      case "CircleHalf":
        return <CircleHalf size={iconSize} />;
      case "UiRadiosGrid":
        return <UiRadiosGrid size={iconSize} />;
      case "CheckCircle":
        return <CheckCircle size={iconSize} />;
      case "Android2":
        return <Android2 size={iconSize} />;
      case "Apple":
        return <Apple size={iconSize} />;
      case "Paypal":
        return <Paypal size={iconSize} />;
      case "SquareHalf":
        return <SquareHalf size={iconSize} />;
      case "Instagram":
        return <Instagram size={iconSize} />;
      case "Facebook":
        return <Facebook size={iconSize} />;
      case "Pinterest":
        return <Pinterest size={iconSize} />;
      case "Twitter":
        return <Twitter size={iconSize} />;
      case "Tiktok":
        return <Tiktok size={iconSize} />;
      case "Linkedin":
        return <Linkedin size={iconSize} />;
      case "Link45deg":
        return <Link45deg size={iconSize} />;
      default:
        return null;
    }
};

export default RenderIconComponent;
