import { createSlice } from '@reduxjs/toolkit';

const initialState = [
  {
    id: 1,
    imgSrc1: "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
  },
  {
    id: 2,
    imgSrc1: "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
  },
  {
    id: 3,
    imgSrc1: "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
  },
];

const imgCollectionSlice = createSlice({
  name: 'imgCollection',
  initialState,
  reducers: {
    addImage: (state, action) => {
      const file = action.payload;
      const id = state.length + 1; // Generate unique IDs incrementally

      // Ensure that the file object is a valid type (Blob or File)
      if (file instanceof Blob || file instanceof File) {
        // Assuming you have a function that handles the file upload
        const imgSrc1 = URL.createObjectURL(file);

        state.push({ id, imgSrc1 });
      } else {
        console.error('Invalid file type:', file);
      }
    },
    deleteImage: (state, action) => {
      const idToDelete = action.payload;
      return state.filter((image) => image.id !== idToDelete);
    },    
  },
});

export const { addImage, deleteImage } = imgCollectionSlice.actions;
export default imgCollectionSlice.reducer;
