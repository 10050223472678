import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Modal, Button } from "react-bootstrap";
import "./dashboard.css";
import DashboardHeader from "../ServiceComponents/DashboardHeader";
import RenderFinishedPage from "./ComponentChoices/ChoicesComponents/RenderFinshedPage";
import { initializeExistingPage, removeExistingPage } from "../../../reducers/existingPageReducer";
import { PreviewComponent } from "./PreviewComponent";
import { deletePage, getPage } from "../../../Services/PageService";
import EditPageFlow from "./EditPageFlow";

const ViewAllPages = () => {
  const dispatch = useDispatch();
  const existingPages = useSelector((state) => state.existingPages);
  const [showPreview, setShowPreview] = useState(false);
  const [editPage, setEditPage] = useState(null)
  const [indexKey, setIndexKey] = useState(0);
  const [loading, setLoading] = useState(true);
  const [slide, setSlide] = useState(1)
  const [pageTitle, setPageTitle] = useState(
    existingPages[indexKey]?.pageName || null
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const fetchData = async () => {
    try {
      // Simulate asynchronous data fetching
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Fetch actual data
      await dispatch(initializeExistingPage());

      // Set loading to false after data is fetched
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const closePreview = () => {
    setShowPreview(false);
  };

  const handleDeleteClick = (pageName) => {
    // Set the index to delete when confirming
    setPageTitle(pageName);
    setShowDeleteModal(true);
  };

const handleDeleteConfirm = async (e) => {
  e.preventDefault();
  try {
    const deletedPageName = await deletePage(pageTitle);
    if (deletedPageName) {
      // Dispatch an action to remove the deleted page from the state
      dispatch(removeExistingPage(deletedPageName));
      console.log(`${deletedPageName} deleted successfully.`);

      // Fetch updated data after deletion
      await fetchData();
    }
  } catch (error) {
    console.error("An error occurred while deleting:", error);
  }
  setShowDeleteModal(false);
};

  const handleDeleteCancel = () => {
    // Close the modal
    setShowDeleteModal(false);
  };

const handleEditClick = async (pageName) => {
console.log(pageName)
  try {
    const response = await getPage(pageName);

    if (response.ok) {
      const responseData = await response.json();
      console.log(responseData);
      setSlide(2)
    } else {
      console.log("Error fetching page data");
    }
  } catch (error) {
    setSlide(2)
    setEditPage({
      id: 1,
      companyName: "TesterCompany",
      pageName: "Home Page",
      pageType: "Home",
      body: [
        {
          rowIdState: [
            {
              bodyState: {
                button1: undefined,
                button1Link: undefined,
                header1: undefined,
                header1Icon: undefined,
                header1IconLink: undefined,
                header2: undefined,
                header2Icon: undefined,
                header2IconLink: undefined,
                header3: undefined,
                header3Icon: undefined,
                header3IconLink: undefined,
                header4: undefined,
                header4Icon: undefined,
                header4IconLink: undefined,
                headline: "First Reducer",
                icon1: undefined,
                icon1Link: undefined,
                icon2: undefined,
                icon2Link: undefined,
                imgLink1: undefined,
                imgLink2: undefined,
                imgLink3: undefined,
                imgSrc1: undefined,
                imgSrc2: undefined,
                imgSrc3: undefined,
                link1Title: undefined,
                link1Value: undefined,
                list1: undefined,
                list2: undefined,
                list3: undefined,
                list4: undefined,
                showButton1: true,
                showHeader1: true,
                showHeader1Icon: true,
                showHeader2: true,
                showHeader2Icon: true,
                showHeader3: true,
                showHeader3Icon: true,
                showHeader4: true,
                showHeader4Icon: true,
                showHeadline: true,
                showIcon1: true,
                showIcon2: true,
                showImg1: true,
                showImg2: true,
                showImg3: true,
                showLink1: true,
                showList1: true,
                showList2: true,
                showList3: true,
                showList4: true,
                showText1: false,
                showText2: true,
                showText3: true,
                showText4: true,
                text1Value: undefined,
                text2Value: undefined,
                text3Value: undefined,
                text4Value: undefined,
              },
              rowId: 1,
            },
          ],
          selectedBodyRows: [
            {
              bodyRowComponent: {
                componentName: "LA1",
                props: {
                  headline: "Medium Length display headline",
                  imgAlt1: "Placeholder Img",
                  imgLink1: "",
                  imgSrc1:
                    "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
                  link1Title: "Action Link",
                  link1Value: "",
                  text1:
                    "Separated they live in Bookmarks right at the coast of the famous Semantics, large language ocean Seperated they live in the Bookmarks right",
                },
              },
              rowId: 1,
              name: "LA1",
            },
          ],
        },
      ],
      companyId: "DawsonTest",
      details: "testingDetails",
      footer: [
        {
          footerState: {
            cityValue: undefined,
            companyFooterValue: undefined,
            emailValue: undefined,
            fbLink: "",
            igLink: "",
            legLink: "",
            liLink: "",
            madeInValue: undefined,
            phoneValue: undefined,
            pinLink: "",
            privacyLink: "",
            showAddress: true,
            showCompanyFooter: true,
            showEmail: true,
            showFbChecked: true,
            showIGChecked: true,
            showLIChecked: true,
            showLanguage: true,
            showLegal: true,
            showMadeIn: true,
            showPINChecked: true,
            showPhone: true,
            showPrivacy: true,
            showSendEmail: true,
            showSiteMap: true,
            showText1: true,
            showTikChecked: true,
            showTos: true,
            showXChecked: true,
            siteLink: "",
            socialIconsChecked: true,
            streetValue: undefined,
            text1Value: "First",
            tikLink: "",
            tosLink: "",
            xLink: "",
          },
          selectedFooter: {
            footComponent: {
              componentName: "Footer1",
              props: {
                cityStateZip: "City, State, Zip",
                companyFooter: "2018 Acme. All rights reserved.",
                companyName: "ACME",
                email: "info@email.com",
                link1: "testhome",
                link1Title: "First Page",
                link2: "",
                link2Title: "Second Page",
                link3: "",
                link3Title: "Third Page",
                link4: "",
                link4Title: "Fourth Page",
                madeIn: "Made in Texas",
                phoneNumber: "800-555-5555",
                privacyPolicy: "Privacy Policy",
                privacyPolicyLink: "",
                socialLinkFB: "",
                socialLinkIG: "",
                socialLinkLI: "",
                socialLinkPIN: "",
                socialLinkTik: "",
                socialLinkX: "",
                streetAddress: "Street Address",
                termsOfService: "Terms of Service",
                termsOfServiceLink: "",
                text1:
                  "Change the color to match your band or vision, add your logo, choose the perfect thumbnail, remove the playbar, add speed controls, and more",
              },
              id: 1,
              name: "Footer1",
            },
          },
        },
      ],
      navbar: [
        {
          id: 1,
          name: "Navbar2",
          navComponent: {
            componentName: "Navbar2",
            props: {
              companyName: "Company",
            },
          },
        },
      ],
    });
    console.error("An error occurred during editing:", error);
  }
};

  useEffect(() => {
    fetchData(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return (
      <>
        <Container fluid className="d-flex flex-column">
          <Row className="d-flex align-items-center">
            <Col sm={3}>
              <DashboardHeader>View All Pages</DashboardHeader>
            </Col>
          </Row>
          <p>Loading...</p>
        </Container>
      </>
    );
  }

  if (slide === 1) {
  return (
    <>
      <Container fluid className="d-flex flex-column min-vh-100">
        <Row className="d-flex align-items-center">
          <Col sm={3}>
            <DashboardHeader>View All Pages</DashboardHeader>
          </Col>
        </Row>
        {existingPages.length > 0 && (
          <Row className="mx-0 d-flex justify-content-around">
            {existingPages.map((page, index) => (
              <Card key={index} className={`col-3 px-0 mx-3`}>
                <Card.Body>
                  <Card.Title className="text-center">
                    {page.pageName}
                  </Card.Title>
                  <PreviewComponent
                    selectedNavbar={page.navbar[0]}
                    selectedBodyRows={page.body[0].selectedBodyRows}
                    selectedFooter={page.footer[0].selectedFooter}
                    passedFooterState={page.footer[0].footerState}
                    passedRowIdState={page.body[0].rowIdState}
                    setShowPreview={setShowPreview}
                    setIndexKey={setIndexKey}
                    indexKey={index}
                    setPageTitle={setPageTitle}
                    pageTitle={page.pageName}
                  />
                  <div className="text-center mt-2">
                    <Button
                      variant="dark"
                      className="me-2"
                      onClick={() => handleDeleteClick(page.pageName)}
                    >
                      Delete
                    </Button>
                    <Button
                      variant="dark"
                      onClick={() => handleEditClick(page.pageName)}
                    >
                      Edit
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            ))}
          </Row>
        )}
      </Container>
      {existingPages.length === 0 && <p>No existing pages</p>}
      {/* Full Page Preview Modal */}
      <Modal show={showPreview} onHide={closePreview} centered size="xl">
        <Modal.Header className="justify-content-center">
          <Modal.Title>{pageTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {existingPages.length > 0 && (
            <>
              <RenderFinishedPage
                selectedNavbar={existingPages[indexKey].navbar[0]}
                selectedBodyRows={
                  existingPages[indexKey].body[0].selectedBodyRows
                }
                selectedFooter={
                  existingPages[indexKey].footer[0].selectedFooter
                }
                footerState={existingPages[indexKey].footer[0].footerState}
                rowIdState={existingPages[indexKey].body[0].rowIdState}
              />
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closePreview}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModal} onHide={handleDeleteCancel} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this page?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteCancel}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Confirm Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
            }
    if (slide === 2) {
        return (
          <EditPageFlow
            finishedNavbar={editPage.navbar[0]}
            finishedBodyRows={editPage.body[0].selectedBodyRows}
            finishedSelectedFooter={editPage.footer[0].selectedFooter}
            finishedFooterState={editPage.footer[0].footerState}
            finishedRowIdState={editPage.body[0].rowIdState}
          />
        );
    }
};

export default ViewAllPages;
