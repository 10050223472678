// IconDropdown.js

import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import {
  House,
  Gear,
  Person,
  Calendar,
  Envelope,
  Chat,
  Search,
  Star,
  Disc,
  Grid1x2,
  CircleHalf,
  UiRadiosGrid,
  CheckCircle,
  Android2,
  Apple,
  Paypal,
  SquareHalf,
  Instagram,
  Facebook,
  Pinterest,
  Twitter,
  Tiktok,
  Linkedin,
} from "react-bootstrap-icons";

const IconDropdown = ({ setIcon }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [iconDisplay, setIconDisplay] = useState(null);

  const handleOptionClick = (option) => {
    setIconDisplay(option.icon);
    setIcon &&
      setIcon(() => ({
          type: option.icon,
          size: 32,
      }));
    setIsOpen(false);
  };

  const renderIconComponent = (icon) => {
    switch (icon) {
      case "House":
        return <House />;
      case "Gear":
        return <Gear />;
      case "Person":
        return <Person />;
      case "Calendar":
        return <Calendar />;
      case "Envelope":
        return <Envelope />;
      case "Chat":
        return <Chat />;
      case "Search":
        return <Search />;
      case "Star":
        return <Star />;
      case "Disc":
        return <Disc />;
      case "Grid1x2":
        return <Grid1x2 />;
      case "CircleHalf":
        return <CircleHalf />;
      case "UiRadiosGrid":
        return <UiRadiosGrid />;
      case "CheckCircle":
        return <CheckCircle />;
      case "Android2":
        return <Android2 />;
      case "Apple":
        return <Apple />;
      case "Paypal":
        return <Paypal />;
      case "SquareHalf":
        return <SquareHalf />;
      case "Instagram":
        return <Instagram />;
      case "Facebook":
        return <Facebook />;
      case "Pinterest":
        return <Pinterest />;
      case "Twitter":
        return <Twitter />;
      case "Tiktok":
        return <Tiktok />;
      case "Linkedin":
        return <Linkedin />;
      default:
        return null;
    }
  };

  const iconOptions = [
    { label: "Home", icon: "House" },
    { label: "Settings", icon: "Gear" },
    { label: "User", icon: "Person" },
    { label: "Calendar", icon: "Calendar" },
    { label: "Mail", icon: "Envelope" },
    { label: "Chat", icon: "Chat" },
    { label: "Search", icon: "Search" },
    { label: "Star", icon: "Star" },
    { label: "Disc", icon: "Disc" },
    { label: "Grid 1x2", icon: "Grid1x2" },
    { label: "Half Circle", icon: "CircleHalf" },
    { label: "Radio Grid", icon: "UiRadiosGrid" },
    { label: "Circle Check", icon: "CheckCircle" },
    { label: "Android", icon: "Android2" },
    { label: "Apple", icon: "Apple" },
    { label: "Paypal", icon: "Paypal" },
    { label: "Square Half", icon: "SquareHalf" },
    { label: "Instagram", icon: "Instagram" },
    { label: "Facebook", icon: "Facebook" },
    { label: "Pinterest", icon: "Pinterest" },
    { label: "Twitter", icon: "Twitter" },
    { label: "Linkedin", icon: "Linkedin" },
    { label: "TikTok", icon: "Tiktok" },
  ];

  return (
    <Dropdown show={isOpen} onToggle={setIsOpen}>
      <Dropdown.Toggle id="dropdown-basic" variant="dark">
        Choose an Icon {iconDisplay}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {iconOptions.map((option, index) => (
          <Dropdown.Item
            key={index}
            eventKey={option.label}
            onClick={() => handleOptionClick(option)}
          >
            {renderIconComponent(option.icon)} {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default IconDropdown;
