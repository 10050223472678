import React from "react";
import { Form, Card } from "react-bootstrap";

const HeaderFormComponent = ({
  showHeader,
  setShowHeader,
  headerValue,
  setHeaderValue,
  headerNumber,
  setSelectedField,
  selectedField,
}) => {
  return (
    <Card
      className={`col-3 px-0 ${
        selectedField === "header" + headerNumber ? "focus-field" : "focus-hover"
      }`}
      onClick={() => setSelectedField("header" + headerNumber)}
    >
      <Card.Body>
        <Card.Title>Header {headerNumber}</Card.Title>
        <Form.Group>
          <Form.Check
            type="checkbox"
            name="header"
            value={"header" + headerNumber}
            label="Display"
            checked={showHeader}
            onChange={(e) => setShowHeader(e.target.checked)}
          />
          <Form.Control
            type="text"
            className="my-2"
            placeholder="Enter Text"
            value={headerValue}
            onChange={(e) => setHeaderValue(e.target.value)}
          />
        </Form.Group>
      </Card.Body>
    </Card>
  );
};

export default HeaderFormComponent;
