import React from 'react'
import { Link } from 'react-router-dom'
import RA1 from '../Components/CustomComponents/BodyComponents/Right-Aligned-Covers/RA1'
import RA2 from '../Components/CustomComponents/BodyComponents/Right-Aligned-Covers/RA2'
import RA3 from '../Components/CustomComponents/BodyComponents/Right-Aligned-Covers/RA3'
import RA4 from '../Components/CustomComponents/BodyComponents/Right-Aligned-Covers/RA4'
import RA5 from '../Components/CustomComponents/BodyComponents/Right-Aligned-Covers/RA5'
import RA6 from '../Components/CustomComponents/BodyComponents/Right-Aligned-Covers/RA6'
import RA7 from '../Components/CustomComponents/BodyComponents/Right-Aligned-Covers/RA7'
import RA8 from '../Components/CustomComponents/BodyComponents/Right-Aligned-Covers/RA8'
import RA9 from '../Components/CustomComponents/BodyComponents/Right-Aligned-Covers/RA9'
import RA10 from '../Components/CustomComponents/BodyComponents/Right-Aligned-Covers/RA10'
import RA11 from '../Components/CustomComponents/BodyComponents/Right-Aligned-Covers/RA11'
import RA12 from '../Components/CustomComponents/BodyComponents/Right-Aligned-Covers/RA12'
import RA13 from '../Components/CustomComponents/BodyComponents/Right-Aligned-Covers/RA13'
import RA14 from '../Components/CustomComponents/BodyComponents/Right-Aligned-Covers/RA14'
import RA15 from '../Components/CustomComponents/BodyComponents/Right-Aligned-Covers/RA15'
import RA16 from '../Components/CustomComponents/BodyComponents/Right-Aligned-Covers/RA16'
import RA17 from '../Components/CustomComponents/BodyComponents/Right-Aligned-Covers/RA17'
import RA18 from '../Components/CustomComponents/BodyComponents/Right-Aligned-Covers/RA18'
import LA1 from '../Components/CustomComponents/BodyComponents/Left-Aligned-Covers/LA1'
import LA2 from '../Components/CustomComponents/BodyComponents/Left-Aligned-Covers/LA2'
import LA3 from '../Components/CustomComponents/BodyComponents/Left-Aligned-Covers/LA3'
import LA4 from '../Components/CustomComponents/BodyComponents/Left-Aligned-Covers/LA4'
import LA5 from '../Components/CustomComponents/BodyComponents/Left-Aligned-Covers/LA5'
import LA6 from '../Components/CustomComponents/BodyComponents/Left-Aligned-Covers/LA6'
import LA7 from '../Components/CustomComponents/BodyComponents/Left-Aligned-Covers/LA7'
import LA8 from '../Components/CustomComponents/BodyComponents/Left-Aligned-Covers/LA8'
import LA9 from '../Components/CustomComponents/BodyComponents/Left-Aligned-Covers/LA9'
import LA10 from '../Components/CustomComponents/BodyComponents/Left-Aligned-Covers/LA10'
import LA11 from '../Components/CustomComponents/BodyComponents/Left-Aligned-Covers/LA11'
import LA12 from '../Components/CustomComponents/BodyComponents/Left-Aligned-Covers/LA12'
import LA13 from '../Components/CustomComponents/BodyComponents/Left-Aligned-Covers/LA13'
import LA14 from '../Components/CustomComponents/BodyComponents/Left-Aligned-Covers/LA14'
import LA15 from '../Components/CustomComponents/BodyComponents/Left-Aligned-Covers/LA15'
import LA16 from '../Components/CustomComponents/BodyComponents/Left-Aligned-Covers/LA16'
import LA17 from '../Components/CustomComponents/BodyComponents/Left-Aligned-Covers/LA17'
import LA18 from '../Components/CustomComponents/BodyComponents/Left-Aligned-Covers/LA18'
import CA1 from '../Components/CustomComponents/BodyComponents/Center-Aligned-Covers/CA1'
import CA2 from '../Components/CustomComponents/BodyComponents/Center-Aligned-Covers/CA2'
import CA3 from '../Components/CustomComponents/BodyComponents/Center-Aligned-Covers/CA3'
import CA4 from '../Components/CustomComponents/BodyComponents/Center-Aligned-Covers/CA4'
import CA5 from '../Components/CustomComponents/BodyComponents/Center-Aligned-Covers/CA5'
import CA6 from '../Components/CustomComponents/BodyComponents/Center-Aligned-Covers/CA6'
import CA7 from '../Components/CustomComponents/BodyComponents/Center-Aligned-Covers/CA7'
import CA8 from '../Components/CustomComponents/BodyComponents/Center-Aligned-Covers/CA8'
import CA9 from '../Components/CustomComponents/BodyComponents/Center-Aligned-Covers/CA9'
import CA10 from '../Components/CustomComponents/BodyComponents/Center-Aligned-Covers/CA10'
import CA11 from '../Components/CustomComponents/BodyComponents/Center-Aligned-Covers/CA11'
import CA12 from '../Components/CustomComponents/BodyComponents/Center-Aligned-Covers/CA12'
import CA13 from '../Components/CustomComponents/BodyComponents/Center-Aligned-Covers/CA13'
import CA14 from '../Components/CustomComponents/BodyComponents/Center-Aligned-Covers/CA14'
import CA15 from '../Components/CustomComponents/BodyComponents/Center-Aligned-Covers/CA15'
import CA16 from '../Components/CustomComponents/BodyComponents/Center-Aligned-Covers/CA16'
import CA17 from '../Components/CustomComponents/BodyComponents/Center-Aligned-Covers/CA17'
import CA18 from '../Components/CustomComponents/BodyComponents/Center-Aligned-Covers/CA18'
import CA19 from '../Components/CustomComponents/BodyComponents/Center-Aligned-Covers/CA19'
import Form1 from '../Components/CustomComponents/BodyComponents/Forms/Form1'
import Form2 from '../Components/CustomComponents/BodyComponents/Forms/Form2'
import Form3 from '../Components/CustomComponents/BodyComponents/Forms/Form3'
import Form4 from '../Components/CustomComponents/BodyComponents/Forms/Form4'
import Form5 from '../Components/CustomComponents/BodyComponents/Forms/Form5'
import Form6 from '../Components/CustomComponents/BodyComponents/Forms/Form6'
import Form7 from '../Components/CustomComponents/BodyComponents/Forms/Form7'
import Form8 from '../Components/CustomComponents/BodyComponents/Forms/Form8'
import Form9 from '../Components/CustomComponents/BodyComponents/Forms/Form9'
import Form10 from '../Components/CustomComponents/BodyComponents/Forms/Form10'
import Form11 from '../Components/CustomComponents/BodyComponents/Forms/Form11'
import Footer2 from '../Components/CustomComponents/FooterComponents/Footer2'
import Navbar4 from '../Components/CustomComponents/NavbarComponents/Navbar4'


// Testing all body components for symmetry and functionality
const BodyCompPage = () => {
  return (
    <>
     <div style={{backgroundColor:'#EDEDED'}}>
      <Link to='/dashboard'>Back to dash</Link>

        <h1 className='border-top'>Right & Left-Aligned-Covers</h1>
        <RA1 />
        <LA1 />
        <div className="p-3" /> 
        <RA2 />
        <LA2 />
        <div className="p-3" />
        <RA3 />
        <LA3 />
        <div className="p-3"/>
        <RA4 />
        <LA4 />
        <div className="p-3" />
        <RA5 />
        <LA5 />
        <div className='p-3' />
        <RA6 />
        <LA6 />
        <div className="p-3" />
        <RA7 />
        <LA7 />
        <div className="p-3" />
        <RA8 />
        <LA8 />
        <div className="p-3" />
        <RA9 />
        <LA9 />
        <div className="p-3" />
        <RA10 />
        <LA10 />
        <div className="p-3" />
        <RA11 />
        <LA11 />
        <div className="p-3" />
        <RA12 />
        <LA12 />
        <div className="p-3" />
        <RA13 />
        <LA13 />
        <div className="p-3" />
        <RA14 />
        <LA14 />
        <div className="p-3" />
        <RA15 />
        <LA15 />
        <div className="p-3" />
        <RA16 />
        <LA16 />
        <div className="p-3" />
        <RA17 />
        <LA17 />
        <div className="p-3" />
        <RA18 />
        <LA18 />
        <div className="p-5" />
        <h1>Center Aligned Covers</h1>
        <CA1 />
        <div className="p-3" />
        <CA2 />
        <div className="p-3" />
        <CA3 />
        <div className="p-3" />
        <CA4 />
        <div className="p-3" />
        <CA5 />
        <div className="p-3" />
        <CA6 />
        <div className="p-3" />
        <CA7 />
        <div className="p-3" />
        <CA8 />
        <div className="p-3" />
        <CA9 />
        <div className="p-3" />
        <CA10 />
        <div className="p-3" />
        <CA11 />
        <div className="p-3" />
        <CA12 />
        <div className="p-3" />
        <CA13 />
        <div className="p-3" />
        <CA14 />
        <div className="p-3" />
        <CA15 />
        <div className="p-3" />
        <CA16 />
        <div className="p-3" />
        <CA17 />
        <div className="p-3" />
        <CA18 />
        <div className="p-3" />
        <CA19 />
        <div className="p-5" />
        <h1>Forms</h1>
        <Form1 />
        <div className="p-3" />
        <Form2 />
        <div className="p-3" />
        <Form3 />
        <div className="p-3" />
        <Form4 />
        <div className="p-3" />
        <Form5 />
        <div className="p-3" />
        <Form6 />
        <div className="p-3" />
        <Form7 />
        <div className="p-3" />
        <Form8 />
        <div className="p-3" />
        <Form9 />
        <div className="p-3" />
        <Form10 />
        <div className="p-3" />
        <Form11 />
        <div className="p-3" />
        <h1>Mock Site</h1>
        <Navbar4 />
        <CA16 />
        <CA11 />
        <RA1></RA1>
        <LA10></LA10>
        <RA7></RA7>
        <Form8 />
        <Footer2 />

    </div>
    </>
  )
}

export default BodyCompPage
