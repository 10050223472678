import React from 'react'
import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { loginAuth } from '../../../Services/UserServices';
import { setAuthenticated } from '../../../Services/authSlice';
import {Button, Card, Form, Row, Col} from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { confirmEmail, resendConfirmationCode } from '../../../Services/RegisterService';
import { setIsLoading } from '../../../Services/loadingSlice';


const VerifyEmailCard = () => {
    const navigate = useNavigate();
    const [verificationCode, setVerificationCode] = useState('');
    const [showError, setShowError] = useState("")
    const [disabled, setDisabled] = useState(true);
    const dispatch = useDispatch();
    const email = sessionStorage.getItem('email');
    const password = sessionStorage.getItem('password');

    // Page mounts stop loading...
    useEffect(() => {
      dispatch(setIsLoading(false));
    },[dispatch])

    const handleChange = (e) => {
        setVerificationCode({
            verificationCode: e.target.value,
        });
    }

    const checkValid = (e) => {
      const isValid = 
        verificationCode.verificationCode.length === 6 &&
        /^\d{6}$/.test(verificationCode.verificationCode);  
      setShowError(isValid ? "" : "true");
      setDisabled(isValid ? false : true);
    };    

  const verifyEmailSubmit =  async e => {
    e.preventDefault();        
    const codeRegex = /^\d{6}$/;
    if (codeRegex.test(verificationCode.verificationCode) && verificationCode.verificationCode.length === 6) {
      dispatch(setIsLoading(true));
    }

    const userData = verificationCode.verificationCode;
    confirmEmail(userData).then(async response => {
      try {
        if (response.status === 200) {

          loginAuth(email, password).then(async response => {
            try {
              if (response.ok) {
                const responseData = await response.json();
                const accessToken = responseData.accessToken;
                sessionStorage.setItem('accessToken', accessToken);
      
                dispatch(setAuthenticated(true));
                sessionStorage.setItem('isAuthenticated', 'true');
                console.log("Login success");
                sessionStorage.removeItem('password');
                navigate('/dashboard/firstlogin');
              } else if (response.status === 401) {
                const errorMessage = await response.text();
                console.log(errorMessage);
              }
            } catch (error) {
              console.error("An error occurred durring login:", error)
            } 
          });

        } else {
          console.log("Email confirmation failed.");
        }
      } catch (error) {
        console.error("An error occurred durring email confirmation:", error)
      } finally {
        dispatch(setIsLoading(false));
      }
    }); 
  }

  const resendCode = async (e) => {
    e.preventDefault();
    resendConfirmationCode(email).then(async response => {
    try {
      if (response.status === 200) {
        const data = await response.json();
        console.log(data);
      } else {
        console.log('Failed to resend confirmation code');
      }
    } catch (error) {
      console.error('Error:', error);
      console.log('An error occurred');
    }
    })
  }; 
   
  return (
    <>
        <Card className="mx-auto mt-5 col-xl-4 col-lg-6 col-md-8 col-sm-10">
          <Card.Body>
            <Card.Title>Verify Your Email Address</Card.Title>
            <Form className="mb-3">
              <Row>
                <Col className="col-12 px-3 py-2">
                  Thank you for choosing to join our platform!
                </Col>
                <Col className="col-12 px-3 py-2">
                  To ensure the security of your account and complete
                  activation, we need to verify your email address
                </Col>
                <Col className="col-12 px-3 py-2">
                  An email with a verification code was sent to your email
                  address, enter it below.
                </Col>
              </Row>
              <Form.Label htmlFor='verificationCode' className="mt-3 pt-3">
                Enter Verification Code
              </Form.Label>
              <Form.Control id='verificationCode'
                onChange={handleChange}
                className=".form-control-lg"
                type='text'
                name='verificationCode'
                required
                onKeyUp={checkValid}
                maxLength={6}
                isInvalid={showError}
              />
              <Form.Control.Feedback type="invalid">
                Please enter 6 digit code
              </Form.Control.Feedback>
              <div className="text-center">
                <Button
                  onClick={verifyEmailSubmit}
                  className="mt-5"
                  variant="dark"
                  type="submit"
                  disabled={disabled}
                >
                  Verify My Email Address
                </Button>
              </div>
            </Form>
            <Link onClick={resendCode} className='infoColor log-label mt-5 mb-0'>
              Send me a new code
            </Link>
          </Card.Body>
        </Card>
    </>
  );
}

export default VerifyEmailCard