import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Tooltip,
  Modal,
  OverlayTrigger,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Eye, Pencil, CheckCircleFill, Trash3 } from "react-bootstrap-icons";
import "../DashboardComponents/dashboard.css";
import { Link } from "react-router-dom";
import { useMemo } from "react";
import RenderNavbarComponent from "./ComponentChoices/ChoicesComponents/RenderNavbarComponent";
import { publishPage } from "../../../Services/PageService";
import { setNavbarState } from "../../../reducers/navbarStateReducer";

const EditNavbarChoices = ({
  setSelectedNavbar,
  slideNumber,
  prevNavbarChoice,
}) => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(true);
  const [navbarChoice, setNavbarChoice] = useState(prevNavbarChoice || null);
  const [slide, setSlide] = useState(slideNumber || 1);
  const navbars = useSelector((state) => state.navbars);

  const navbarTemplates = useMemo(
    () => ({ navbarTemplates: navbars }),
    [navbars]
  );
  const selectedNavbar = useSelector((state) =>
    state.navbars.find((navbar) => navbar.name === navbarChoice)
  );
  const [, setShowButton] = useState(false);

  const handleChoiceChange = (e) => {
    setNavbarChoice(e.target.value);
    setDisabled(false);
  };


  const saveNavbarChanges = async () => {
    const pageObject = {
      pageName: "NAVBAR",
      body: [
        {
          rowIdState: [
            {
              bodyState: {
                button1: undefined,
                button1Link: undefined,
                header1: undefined,
                header1Icon: undefined,
                header1IconLink: undefined,
                header2: undefined,
                header2Icon: undefined,
                header2IconLink: undefined,
                header3: undefined,
                header3Icon: undefined,
                header3IconLink: undefined,
                header4: undefined,
                header4Icon: undefined,
                header4IconLink: undefined,
                headline: "Second Reducer",
                icon1: undefined,
                icon1Link: undefined,
                icon2: undefined,
                icon2Link: undefined,
                imgLink1: undefined,
                imgLink2: undefined,
                imgLink3: undefined,
                imgSrc1: undefined,
                imgSrc2: undefined,
                imgSrc3: undefined,
                link1Title: undefined,
                link1Value: undefined,
                list1: undefined,
                list2: undefined,
                list3: undefined,
                list4: undefined,
                showButton1: true,
                showHeader1: true,
                showHeader1Icon: true,
                showHeader2: true,
                showHeader2Icon: true,
                showHeader3: true,
                showHeader3Icon: true,
                showHeader4: true,
                showHeader4Icon: true,
                showHeadline: true,
                showIcon1: true,
                showIcon2: true,
                showImg1: true,
                showImg2: true,
                showImg3: true,
                showLink1: true,
                showList1: true,
                showList2: true,
                showList3: true,
                showList4: true,
                showText1: false,
                showText2: true,
                showText3: true,
                showText4: true,
                text1Value: undefined,
                text2Value: undefined,
                text3Value: undefined,
                text4Value: undefined,
              },
              rowId: 1,
            },
          ],
          selectedBodyRows: [
            {
              bodyRowComponent: {
                componentName: "RA1",
                props: {
                  headline: "Medium Length display headline",
                  imgAlt1: "Placeholder Img",
                  imgLink1: "",
                  imgSrc1:
                    "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
                  link1Title: "Action Link",
                  link1Value: "",
                  text1:
                    "Separated they live in Bookmarks right at the coast of the famous Semantics, large language ocean Seperated they live in the Bookmarks right",
                },
              },
              rowId: 1,
              name: "RA1",
            },
          ],
        },
      ],
      details: "testingDetails",
      footer: [
        {
          footerState: {
            cityValue: undefined,
            companyFooterValue: undefined,
            emailValue: undefined,
            fbLink: "",
            igLink: "",
            legLink: "",
            liLink: "",
            madeInValue: undefined,
            phoneValue: undefined,
            pinLink: "",
            privacyLink: "",
            showAddress: true,
            showCompanyFooter: true,
            showEmail: true,
            showFbChecked: true,
            showIGChecked: true,
            showLIChecked: true,
            showLanguage: true,
            showLegal: true,
            showMadeIn: true,
            showPINChecked: true,
            showPhone: true,
            showPrivacy: true,
            showSendEmail: true,
            showSiteMap: true,
            showText1: true,
            showTikChecked: true,
            showTos: true,
            showXChecked: true,
            siteLink: "",
            socialIconsChecked: true,
            streetValue: undefined,
            text1Value: "Second",
            tikLink: "",
            tosLink: "",
            xLink: "",
          },
          selectedFooter: {
            footComponent: {
              componentName: "Footer1",
              props: {
                cityStateZip: "City, State, Zip",
                companyFooter: "2018 Acme. All rights reserved.",
                companyName: "ACME",
                email: "info@email.com",
                link1: "testhome",
                link1Title: "First Page",
                link2: "",
                link2Title: "Second Page",
                link3: "",
                link3Title: "Third Page",
                link4: "",
                link4Title: "Fourth Page",
                madeIn: "Made in Texas",
                phoneNumber: "800-555-5555",
                privacyPolicy: "Privacy Policy",
                privacyPolicyLink: "",
                socialLinkFB: "",
                socialLinkIG: "",
                socialLinkLI: "",
                socialLinkPIN: "",
                socialLinkTik: "",
                socialLinkX: "",
                streetAddress: "Street Address",
                termsOfService: "Terms of Service",
                termsOfServiceLink: "",
                text1:
                  "Change the color to match your band or vision, add your logo, choose the perfect thumbnail, remove the playbar, add speed controls, and more",
              },
              id: 1,
              name: "Footer1",
            },
          },
        },
      ],
      navbar: [
        selectedNavbar,
      ],
    };
    ;
    try {
      console.log(pageObject)
      const response = await publishPage(pageObject);
      console.log("API Response:", response);
      dispatch(setNavbarState(selectedNavbar))
    } catch (error) {
      console.error("Error creating page:", error);
    }
  };

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const resetChoice = () => {
    setShowButton(false);
    setNavbarChoice(null);
    setDisabled(true);
    setSelectedNavbar(null);
    setSlide(1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSelectedNavbar(selectedNavbar);
    setSlide(2);
  };

  const renderTooltipEdit = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Edit This Pattern
    </Tooltip>
  );
  const renderTooltipView = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Live Preview
    </Tooltip>
  );
  const renderTooltipReady = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Ready To Publish
    </Tooltip>
  );
  const renderTooltipDelete = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Delete This
    </Tooltip>
  );

  return (
    <>
      <Container className="pt-3">
        {slide === 1 && (
          <>
            <p>Choose a style for your Navbar.</p>
            <div className="custom-label-class py-3">
              {navbarTemplates.navbarTemplates.length > 0 &&
                navbarTemplates.navbarTemplates.map((navbarTemplate) => (
                  <Form.Check
                    key={navbarTemplate.name}
                    className="py-2"
                    type="radio"
                    id={navbarTemplate.name}
                    name="navbarChoice"
                    value={navbarTemplate.name}
                    label={
                      <div className="unclickable">
                        {RenderNavbarComponent(
                          navbarTemplate.navComponent.componentName,
                          navbarTemplate.navComponent.props
                        )}
                      </div>
                    }
                    onChange={handleChoiceChange}
                  />
                ))}
            </div>
            <div className="d-flex justify-content-center py-5">
              <Button
                variant="dark"
                type="submit"
                disabled={disabled}
                onClick={handleSubmit}
              >
                CONTINUE
              </Button>
            </div>
          </>
        )}
        {slide === 2 && (
          <Row className="d-flex flex-column">
            <Col className="d-flex justify-content-between">
              <p className="">Navbar is ready to be published.</p>
              <OverlayTrigger
                placement="left"
                delay={{ show: 100, hide: 150 }}
                overlay={renderTooltipReady}
              >
                <CheckCircleFill size={36} style={{ color: "#28A745" }} />
              </OverlayTrigger>
            </Col>

            <Col className="py-3">
              <div className="crud-hover">
                {navbarTemplates.navbarTemplates.length > 0 &&
                  navbarTemplates.navbarTemplates.map((navbarTemplate) => (
                    <div key={navbarTemplate.name}>
                      {navbarChoice === navbarTemplate.name && (
                        <div className="position-relative">
                          <div className="content-wrapper">
                            {RenderNavbarComponent(
                              navbarTemplate.navComponent.componentName,
                              navbarTemplate.navComponent.props
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}

                <div className="crud-hover-overlay d-flex justify-content-center align-items-center">
                  <OverlayTrigger
                    placement="left"
                    delay={{ show: 100, hide: 150 }}
                    overlay={renderTooltipView}
                    className="view-button"
                  >
                    <button className="view-button hover">
                      <Eye onClick={openModal} size={20} />
                    </button>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 100, hide: 150 }}
                    overlay={renderTooltipEdit}
                  >
                    <button className="edit-button">
                      <Pencil onClick={resetChoice} size={20} />
                    </button>
                  </OverlayTrigger>
                </div>
              </div>
            </Col>

            <Col>
              <div className="d-flex justify-content-end">
                <OverlayTrigger
                  placement="left"
                  delay={{ show: 100, hide: 150 }}
                  overlay={renderTooltipDelete}
                >
                  <Link onClick={() => resetChoice()} className="text-end">
                    <Trash3 size={18} style={{ color: "black" }} />
                  </Link>
                </OverlayTrigger>
              </div>
            </Col>
            <Col className="d-flex justify-content-center">
              <Button
                variant="dark"
                onClick={() => saveNavbarChanges()}
              >
                Update Navbar
              </Button>
            </Col>
          </Row>
        )}
      </Container>

      <Modal show={showModal} onHide={closeModal} centered size="xl">
        <Modal.Header className="justify-content-center">
          <Modal.Title>Navbar Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {navbarChoice &&
            RenderNavbarComponent(
              selectedNavbar.navComponent.componentName,
              selectedNavbar.navComponent.props
            )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditNavbarChoices;
