import { React, useState, useEffect } from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import {
  CCircle,
  Instagram,
  Facebook,
  Twitter,
  Pinterest,
  Tiktok,
  Linkedin,
} from "react-bootstrap-icons";
import '../../ApplicationComponents/ServiceComponents/serviceComponents.css'

function Footer1({isPreview, holderState, selectedField}) {
      const [footer1, setFooter1] = useState({
        companyName: `ACME`,
        link1Title: "First Page",
        link1: "testhome",
        link2Title: "Second Page",
        link2: "",
        link3Title: "Third Page",
        link3: "",
        link4Title: "Fourth Page",
        link4: "",
        privacyPolicy: "Privacy Policy",
        privacyPolicyLink:
          holderState?.privacyLink !== undefined ? holderState.privacyLink : "",
        termsOfService: "Terms of Service",
        termsOfServiceLink:
          holderState?.tosLink !== undefined ? holderState.tosLink : "",
        socialLinkIG:
          holderState?.igLink !== undefined ? holderState.igLink : "",
        socialLinkFB:
          holderState?.fbLink !== undefined ? holderState.fbLink : "",
        socialLinkX: holderState?.xLink !== undefined ? holderState.xLink : "",
        socialLinkPIN:
          holderState?.pinLink !== undefined ? holderState.pinLink : "",
        socialLinkTik:
          holderState?.tikLink !== undefined ? holderState.tikLink : "",
        socialLinkLI:
          holderState?.liLink !== undefined ? holderState.liLink : "",
        text1:
          holderState?.text1Value !== undefined
            ? holderState.text1Value
            : "Change the color to match your band or vision, add your logo, choose the perfect thumbnail, remove the playbar, add speed controls, and more",
        streetAddress:
          holderState?.streetValue !== undefined
            ? holderState.streetValue
            : "Street Address",
        cityStateZip:
          holderState?.cityValue !== undefined
            ? holderState.cityValue
            : "City, State, Zip",
        phoneNumber:
          holderState?.phoneValue !== undefined
            ? holderState.phoneValue
            : "800-555-5555",
        email:
          holderState?.emailValue !== undefined
            ? holderState.emailValue
            : "info@email.com",
        madeIn:
          holderState?.madeInValue !== undefined
            ? holderState.madeInValue
            : "Made in Texas",
        companyFooter:
          holderState?.companyFooterValue !== undefined
            ? holderState.companyFooterValue
            : "2018 Acme. All rights reserved.",
        showSocials:
          holderState?.socialIconsChecked !== undefined
            ? holderState.socialIconsChecked
            : true,
        showIG:
          holderState?.showIGChecked !== undefined
            ? holderState.showIGChecked
            : true,
        showFB:
          holderState?.showFbChecked !== undefined
            ? holderState.showFbChecked
            : true,
        showX:
          holderState?.showXChecked !== undefined
            ? holderState.showXChecked
            : true,
        showPIN:
          holderState?.showPINChecked !== undefined
            ? holderState.showPINChecked
            : true,
        showTik:
          holderState?.showTikChecked !== undefined
            ? holderState.showTikChecked
            : true,
        showLI:
          holderState?.showLIChecked !== undefined
            ? holderState.showLIChecked
            : true,
        showPrivacy:
          holderState?.showPrivacy !== undefined
            ? holderState.showPrivacy
            : true,
        showCompanyFooter:
          holderState?.showCompanyFooter !== undefined
            ? holderState.showCompanyFooter
            : true,
        showTos:
          holderState?.showTos !== undefined ? holderState.showTos : true,
        showMadeIn:
          holderState?.showMadeIn !== undefined ? holderState.showMadeIn : true,
        showText1:
          holderState?.showText1 !== undefined ? holderState.showText1 : true,
        showAddress:
          holderState?.showAddress !== undefined
            ? holderState.showAddress
            : true,
        showPhone:
          holderState?.showPhone !== undefined ? holderState.showPhone : true,
        showEmail:
          holderState?.showEmail !== undefined ? holderState.showEmail : true,
      });

  useEffect(() => {
    setFooter1((prevFooter1) => ({
      ...prevFooter1,
      showSocials:
        holderState?.socialIconsChecked !== undefined
          ? holderState.socialIconsChecked
          : true,
      showIG:
        holderState?.showIGChecked !== undefined
          ? holderState.showIGChecked
          : true,
      showFB:
        holderState?.showFbChecked !== undefined
          ? holderState.showFbChecked
          : true,
      showX:
        holderState?.showXChecked !== undefined
          ? holderState.showXChecked
          : true,
      showPIN:
        holderState?.showPINChecked !== undefined
          ? holderState.showPINChecked
          : true,
      showTik:
        holderState?.showTikChecked !== undefined
          ? holderState.showTikChecked
          : true,
      showLI:
        holderState?.showLIChecked !== undefined
          ? holderState.showLIChecked
          : true,
      showPrivacy:
        holderState?.showPrivacy !== undefined ? holderState.showPrivacy : true,
      showCompanyFooter:
        holderState?.showCompanyFooter !== undefined
          ? holderState.showCompanyFooter
          : true,
      showTos: holderState?.showTos !== undefined ? holderState.showTos : true,
      showMadeIn:
        holderState?.showMadeIn !== undefined ? holderState.showMadeIn : true,
      showText1:
        holderState?.showText1 !== undefined ? holderState.showText1 : true,
      showEmail:
        holderState?.showEmail !== undefined ? holderState.showEmail : true,
      showAddress:
        holderState?.showAddress !== undefined ? holderState.showAddress : true,
      showPhone:
        holderState?.showPhone !== undefined ? holderState.showPhone : true,
      socialLinkIG: holderState?.igLink !== undefined ? holderState.igLink : "",
      socialLinkFB: holderState?.fbLink !== undefined ? holderState.fbLink : "",
      socialLinkX: holderState?.xLink !== undefined ? holderState.xLink : "",
      socialLinkPIN:
        holderState?.pinLink !== undefined ? holderState.pinLink : "",
      socialLinkTik:
        holderState?.tikLink !== undefined ? holderState.tikLink : "",
      socialLinkLI: holderState?.liLink !== undefined ? holderState.liLink : "",
      text1:
        holderState?.text1Value !== undefined
          ? holderState.text1Value
          : "Change the color to match your band or vision, add your logo, choose the perfect thumbnail, remove the playbar, add speed controls, and more",
      streetAddress:
        holderState?.streetValue !== undefined
          ? holderState.streetValue
          : "Street Address",
      cityStateZip:
        holderState?.cityValue !== undefined
          ? holderState.cityValue
          : "City, State, Zip",
      phoneNumber:
        holderState?.phoneValue !== undefined
          ? holderState.phoneValue
          : "800-555-5555",
      email:
        holderState?.emailValue !== undefined
          ? holderState.emailValue
          : "info@email.com",
      madeIn:
        holderState?.madeInValue !== undefined
          ? holderState.madeInValue
          : "Made in Texas",
      companyFooter:
        holderState?.companyFooterValue !== undefined
          ? holderState.companyFooterValue
          : "2018 Acme. All rights reserved.",
      privacyPolicyLink:
        holderState?.privacyLink !== undefined ? holderState.privacyLink : "",
      termsOfServiceLink:
        holderState?.tosLink !== undefined ? holderState.tosLink : "",
    }));
  }, [
    holderState?.socialIconsChecked,
    holderState?.showIGChecked,
    holderState?.showFbChecked,
    holderState?.showXChecked,
    holderState?.showPINChecked,
    holderState?.showTikChecked,
    holderState?.showLIChecked,
    holderState?.showPrivacy,
    holderState?.showCompanyFooter,
    holderState?.showTos,
    holderState?.showMadeIn,
    holderState?.showEmail,
    holderState?.showText1,
    holderState?.showAddress,
    holderState?.showPhone,
    holderState?.igLink,
    holderState?.fbLink,
    holderState?.xLink,
    holderState?.pinLink,
    holderState?.tikLink,
    holderState?.liLink,
    holderState?.text1Value,
    holderState?.streetValue,
    holderState?.cityValue,
    holderState?.phoneValue,
    holderState?.emailValue,
    holderState?.madeInValue,
    holderState?.companyFooterValue,
    holderState?.privacyLink,
    holderState?.tosLink,
  ]);

  return (
    <div className="border border-black">
      <footer className="">
        <Container fluid>
          <Row className="mt-4 mb-2 mx-0">
            {(footer1.showText1 ||
              (footer1.showSocials &&
                (footer1.showIG ||
                  footer1.showFB ||
                  footer1.showX ||
                  footer1.showPIN ||
                  footer1.showTik ||
                  footer1.showLI))) && (
              <Col
                xs={12}
                md={5}
                lg={4}
                className="offset-md-1 d-flex flex-column text-md-start text-center"
              >
                {footer1.showText1 && (
                  <h5
                    className={`${isPreview ? "preview-scaling" : ""} ${
                      selectedField === "Text 1" ? "focus-field" : ""
                    }`}
                  >
                    {footer1.text1}
                  </h5>
                )}
                {footer1.showSocials &&
                  (footer1.showIG ||
                    footer1.showFB ||
                    footer1.showX ||
                    footer1.showLI ||
                    footer1.showPIN ||
                    footer1.showTik) && (
                    <div
                      className={`my-2 my-md-0 d-flex ${
                        isPreview ? "preview-scaling" : ""
                      } ${selectedField === "Social Media Icons" ? "focus-field" : ""}`}
                    >
                      {footer1.showIG && (
                        <Nav.Link
                          href={"https://" + footer1.socialLinkIG}
                          target="_blank"
                          className={`text-black`}
                        >
                          <Instagram
                            className={`me-2 ${isPreview ? "col-2 mx-0" : ""} ${
                              selectedField === "Instagram" ? "focus-field" : ""
                            }`}
                          />
                        </Nav.Link>
                      )}
                      {footer1.showFB && (
                        <Nav.Link
                          href={"https://" + footer1.socialLinkFB}
                          target="_blank"
                          className="text-black"
                        >
                          <Facebook
                            className={`me-2 ${isPreview ? "col-2 mx-0" : ""} ${
                              selectedField === "Facebook" ? "focus-field" : ""
                            }`}
                          />
                        </Nav.Link>
                      )}
                      {footer1.showX && (
                        <Nav.Link
                          href={"https://" + footer1.socialLinkX}
                          target="_blank"
                          className="text-black"
                        >
                          <Twitter
                            className={`me-2 ${isPreview ? "col-2 mx-0" : ""} ${
                              selectedField === "X" ? "focus-field" : ""
                            }`}
                          />
                        </Nav.Link>
                      )}
                      {footer1.showPIN && (
                        <Nav.Link
                          href={"https://" + footer1.socialLinkPIN}
                          target="_blank"
                          className="text-black"
                        >
                          <Pinterest
                            className={`me-2 ${isPreview ? "col-2 mx-0" : ""} ${
                              selectedField === "Pinterest" ? "focus-field" : ""
                            }`}
                          />
                        </Nav.Link>
                      )}
                      {footer1.showTik && (
                        <Nav.Link
                          href={"https://" + footer1.socialLinkTik}
                          target="_blank"
                          className="text-black"
                        >
                          <Tiktok
                            className={`me-2 ${isPreview ? "col-2 mx-0" : ""} ${
                              selectedField === "TikTok" ? "focus-field" : ""
                            }`}
                          />
                        </Nav.Link>
                      )}
                      {footer1.showLI && (
                        <Nav.Link
                          href={"https://" + footer1.socialLinkLI}
                          target="_blank"
                          className="text-black"
                        >
                          <Linkedin
                            className={`me-2 ${isPreview ? "col-2 mx-0" : ""} ${
                              selectedField === "LinkedIn" ? "focus-field" : ""
                            }`}
                          />
                        </Nav.Link>
                      )}
                    </div>
                  )}
              </Col>
            )}
            <Col
              xs={4}
              md={2}
              className={`offset-2 offset-md-0 offset-lg-1 d-flex flex-column align-items-center align-items-md-end ${
                isPreview ? "preview-scaling" : ""
              }`}
            >
              <Nav href={footer1.link1}>{footer1.link1Title}</Nav>
              <Nav href={footer1.link2}>{footer1.link2Title}</Nav>
              <Nav href={footer1.link3}>{footer1.link3Title}</Nav>
              <Nav href={footer1.link4}>{footer1.link4Title}</Nav>
            </Col>
            <Col
              xs={4}
              md={2}
              className={`offset-md-1 d-flex flex-column align-items-center align-items-md-end ${
                isPreview ? "preview-scaling" : ""
              }`}
            >
              {footer1.showAddress && (
                <>
                  <h6
                    className={`${isPreview ? "preview-scaling" : ""} ${
                      selectedField === "Address" ? "focus-field" : ""
                    }`}
                  >
                    {footer1.streetAddress}
                  </h6>
                  <h6
                    className={`${isPreview ? "preview-scaling" : ""} ${
                      selectedField === "Address" ? "focus-field" : ""
                    }`}
                  >
                    {footer1.cityStateZip}
                  </h6>
                </>
              )}
              {footer1.showPhone && (
                <h6
                  className={`${isPreview ? "preview-scaling" : ""} ${
                    selectedField === "Phone Number" ? "focus-field" : ""
                  }`}
                >
                  Ph: {footer1.phoneNumber}
                </h6>
              )}
              {footer1.showEmail && (
                <h6
                  className={`${isPreview ? "preview-scaling" : ""} ${
                    selectedField === "Email" ? "focus-field" : ""
                  }`}
                >
                  {footer1.email}
                </h6>
              )}
            </Col>
            <Col
              xs={8}
              className={`mt-4 offset-1 ${isPreview ? "preview-scaling" : ""}`}
            >
              <div className="d-flex">
                {footer1.showCompanyFooter && (
                  <small
                    className={`${
                      selectedField === "Company Footer" ? "focus-field" : ""
                    }`}
                  >
                    {<CCircle />}
                    {footer1.companyFooter}
                  </small>
                )}
                {footer1.showPrivacy && (
                  <Nav.Link
                    href={"https://" + footer1.privacyPolicyLink}
                    target="_blank"
                    className={`text-muted text-decoration-none mx-4 ${
                      selectedField === "Privacy Policy" ? "focus-field" : ""
                    }`}
                  >
                    <small>{footer1.privacyPolicy}</small>
                  </Nav.Link>
                )}
                {footer1.showTos && (
                  <Nav.Link
                    href={"https://" + footer1.termsOfServiceLink}
                    target="_blank"
                    className={`text-muted text-decoration-none ${
                      selectedField === "Terms of Service" ? "focus-field" : ""
                    }`}
                  >
                    <small>{footer1.termsOfService}</small>
                  </Nav.Link>
                )}
              </div>
            </Col>
            <Col
              className={`col-2 d-flex justify-content-end mt-4 text-muted ${
                isPreview ? "preview-scaling" : ""
              } ${selectedField === "Made In" ? "focus-field" : ""}`}
            >
              {footer1.showMadeIn && <div>{footer1.madeIn}</div>}
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
}

export default Footer1;