import { createSlice } from '@reduxjs/toolkit';

const initialState = [
  {
      id: 1,
      name: "Gallery1",
      rowId: 0,
      bodyRowComponent: {
        componentName: "Gallery1",
        props: {
        imgLink1: "",
        imgSrc1:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt1: "Placeholder Img",
        }
    }},
  {
      id: 2,
      name: "Gallery2",
      rowId: 0,
      bodyRowComponent: {
        componentName: "Gallery2",
        props: {
        imgSrc1:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt1: "Placeholder Img",
        imgSrc2:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt2: "Placeholder Img",
        }
    }},
  {
      id: 3,
      name: "Gallery3",
      rowId: 0,
      bodyRowComponent: {
        componentName: "Gallery3",
        props: {
        imgSrc1:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt1: "Placeholder Img",
        imgSrc2:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt2: "Placeholder Img",
        imgSrc3:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt3: "Placeholder Img",
        }
    }},
  {
      id: 4,
      name: "Gallery4",
      rowId: 0,
      bodyRowComponent: {
        componentName: "Gallery4",
        props: {
        imgSrc1:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt1: "Placeholder Img",
        imgSrc2:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt2: "Placeholder Img",
        imgSrc3:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt3: "Placeholder Img",
        imgSrc4:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt4: "Placeholder Img",
        imgSrc5:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt5: "Placeholder Img",
        imgSrc6:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt6: "Placeholder Img",
        }
    }},
  {
      id: 5,
      name: "Gallery5",
      rowId: 0,
      bodyRowComponent: {
        componentName: "Gallery5",
        props: {
        imgSrc1:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt1: "Placeholder Img",
        imgSrc2:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt2: "Placeholder Img",
        imgSrc3:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt3: "Placeholder Img",
        imgSrc4:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt4: "Placeholder Img",
        }
    }},
  {
      id: 6,
      name: "Gallery6",
      rowId: 0,
      bodyRowComponent: {
        componentName: "Gallery6",
        props: {
        imgSrc1:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt1: "Placeholder Img",
        imgSrc2:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt2: "Placeholder Img",
        imgSrc3:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt3: "Placeholder Img",
        imgSrc4:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt4: "Placeholder Img",
        imgSrc5:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt5: "Placeholder Img",
        imgSrc6:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt6: "Placeholder Img",
        imgSrc7:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt7: "Placeholder Img",
        imgSrc8:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt8: "Placeholder Img",
        }
    }},
  {
      id: 7,
      name: "Gallery7",
      rowId: 0,
      bodyRowComponent: {
        componentName: "Gallery7",
        props: {
        imgSrc1:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt1: "Placeholder Img",
        imgSrc2:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt2: "Placeholder Img",
        imgSrc3:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt3: "Placeholder Img",
        }
    }},
  {
      id: 8,
      name: "Gallery8",
      rowId: 0,
      bodyRowComponent: {
        componentName: "Gallery8",
        props: {
        imgSrc1:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt1: "Placeholder Img",
        imgSrc2:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt2: "Placeholder Img",
        imgSrc3:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt3: "Placeholder Img",
        imgSrc4:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt4: "Placeholder Img",
        }
    }},
  {
      id: 9,
      name: "Gallery9",
      rowId: 0,
      bodyRowComponent: {
        componentName: "Gallery9",
        props: {
        imgSrc1:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt1: "Placeholder Img",
        imgSrc2:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt2: "Placeholder Img",
        }
    }},
  {
      id: 10,
      name: "Gallery10",
      rowId: 0,
      bodyRowComponent: {
        componentName: "Gallery10",
        props: {
        imgSrc1:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt1: "Placeholder Img",
        imgSrc2:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt2: "Placeholder Img",
        imgSrc3:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt3: "Placeholder Img",
        imgSrc4:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt4: "Placeholder Img",
        }
    }},
  {
      id: 11,
      name: "Gallery11",
      rowId: 0,
      bodyRowComponent: {
        componentName: "Gallery11",
        props: {
        imgSrc1:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt1: "Placeholder Img",
        imgSrc2:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt2: "Placeholder Img",
        imgSrc3:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt3: "Placeholder Img",
        imgSrc4:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt4: "Placeholder Img",
        imgSrc5:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt5: "Placeholder Img",
        imgSrc6:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt6: "Placeholder Img",
        imgSrc7:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt7: "Placeholder Img",
        imgSrc8:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt8: "Placeholder Img",
        imgTitle1: "First Slide Label",
        imgText1: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
        imgTitle2: "Second Slide Label",
        imgText2: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        imgTitle3: "Third Slide Label",
        imgText3:
          "Praesent commodo cursus magna, vel scelerisque nisl consectetur.",
        imgTitle4: "Fourth Slide Label",
        imgText4: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
        imgTitle5: "Fifth Slide Label",
        imgText5: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        imgTitle6: "Sixth Slide Label",
        imgText6:
          "Praesent commodo cursus magna, vel scelerisque nisl consectetur.",
        imgTitle7: "Seventh Slide Label",
        imgText7: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
        imgTitle8: "Eight Slide Label",
        imgText8: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        }
    }},
  {
      id: 12,
      name: "Gallery12",
      rowId: 0,
      bodyRowComponent: {
        componentName: "Gallery12",
        props: {
        imgSrc1:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt1: "Placeholder Img",
        imgSrc2:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt2: "Placeholder Img",
        imgSrc3:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt3: "Placeholder Img",
        imgSrc4:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt4: "Placeholder Img",
        imgSrc5:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt5: "Placeholder Img",
        imgSrc6:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt6: "Placeholder Img",
        imgSrc7:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt7: "Placeholder Img",
        imgSrc8:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt8: "Placeholder Img",
        imgTitle1: "First Slide Label",
        imgText1: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
        imgTitle2: "Second Slide Label",
        imgText2: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        imgTitle3: "Third Slide Label",
        imgText3:
          "Praesent commodo cursus magna, vel scelerisque nisl consectetur.",
        imgTitle4: "Fourth Slide Label",
        imgText4: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
        imgTitle5: "Fifth Slide Label",
        imgText5: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        imgTitle6: "Sixth Slide Label",
        imgText6:
          "Praesent commodo cursus magna, vel scelerisque nisl consectetur.",
        imgTitle7: "Seventh Slide Label",
        imgText7: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
        imgTitle8: "Eight Slide Label",
        imgText8: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        }
    }},
  {
      id: 13,
      name: "Gallery13",
      rowId: 0,
      bodyRowComponent: {
        componentName: "Gallery13",
        props: {
        imgSrc1:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt1: "Placeholder Img",
        imgSrc2:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt2: "Placeholder Img",
        imgSrc3:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt3: "Placeholder Img",
        imgSrc4:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt4: "Placeholder Img",
        imgSrc5:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt5: "Placeholder Img",
        imgSrc6:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt6: "Placeholder Img",
        imgSrc7:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt7: "Placeholder Img",
        imgSrc8:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt8: "Placeholder Img",
        }
    }},
  {
      id: 14,
      name: "Gallery14",
      rowId: 0,
      bodyRowComponent: {
        componentName: "Gallery14",
        props: {
        imgSrc1:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt1: "Placeholder Img",
        imgSrc2:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt2: "Placeholder Img",
        imgSrc3:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt3: "Placeholder Img",
        imgSrc4:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt4: "Placeholder Img",
        imgSrc5:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt5: "Placeholder Img",
        imgSrc6:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt6: "Placeholder Img",
        imgSrc7:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt7: "Placeholder Img",
        imgSrc8:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt8: "Placeholder Img",
        }
    }},
  {
      id: 15,
      name: "Gallery15",
      bodyRowComponent: {
        componentName: "Gallery15",
        props: {
        imgSrc1:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt1: "Placeholder Img",
        imgSrc2:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt2: "Placeholder Img",
        imgSrc3:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt3: "Placeholder Img",
        imgSrc4:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt4: "Placeholder Img",
        imgSrc5:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt5: "Placeholder Img",
        imgSrc6:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt6: "Placeholder Img",
        imgSrc7:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt7: "Placeholder Img",
        imgSrc8:
          "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt8: "Placeholder Img",
        }
    }},
  ];

const gallerySlice = createSlice({
  name: 'gallery',
  initialState,
  reducers: {
    addGallery: (state, action) => {
      state.push(action.payload);
    },
    removeGallery: (state, action) => {
      return state.filter((gallery) => gallery.id !== action.payload);
    },
    selectGallery: (state, action) => {
      return state.filter((gallery) => gallery.id === action.payload)
    },
  },
  
});

export const { addGallery, removeGallery, selectGallery, } = gallerySlice.actions;

export default gallerySlice.reducer;
