import React from "react";
import { Form, Card } from "react-bootstrap";
import "react-quill/dist/quill.snow.css";

const TextFormComponent = ({
  showText,
  setShowText,
  textValue,
  setTextValue,
  txtNumber,
  setSelectedField,
  selectedField,
}) => {
  return (
    <Card
      className={`col-3 px-0 ${
        selectedField === "text" + txtNumber ? "focus-field" : "focus-hover"
      }`}
      onClick={() => setSelectedField("text" + txtNumber)}
    >
      <Card.Body>
        <Card.Title>Text {txtNumber}</Card.Title>
        <Form.Group>
          <Form.Check
            type="checkbox"
            name="text"
            value={"text" + txtNumber}
            label="Display"
            checked={showText}
            onChange={(e) => setShowText(e.target.checked)}
          />
          <Form.Control
            type="text"
            className="my-2"
            placeholder="Enter Text"
            value={textValue}
            onChange={(e) => setTextValue(e.target.value)}
          />
        </Form.Group>
      </Card.Body>
    </Card>
  );
};

export default TextFormComponent;
