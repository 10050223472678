import { createSlice } from "@reduxjs/toolkit";
import { getPage } from "../Services/PageService";

export const initializeNavbarState = () => async (dispatch) => {
  try {
    const response = await getPage("NAVBAR");
    const responseBody = await response.text();
    const data = JSON.parse(responseBody);
    console.log(data.navbar[0]);
    // Dispatch the addExistingPage action to update the state
    dispatch(setNavbarState(data.navbar[0]));
  } catch (error) {
    // LOCAL TESTING
    dispatch(
      setNavbarState({
        id: 1,
        name: "Navbar5",
        navComponent: {
          componentName: "Navbar5",
          props: {
            companyName: "Company",
          },
        },
      })
    );
  }
};

const initialState = true;

const navbarStateSlice = createSlice({
  name: "navbarState",
  initialState,
  reducers: {
    setNavbarState: (state, action) => {
      return action.payload;
    },
    returnExistingNavbarState: (state, action) => {
      return state;
    },
  },
});

export const { setNavbarState, returnExistingState } = navbarStateSlice.actions;

export default navbarStateSlice.reducer;
