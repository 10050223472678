import React from "react";
import Navbar1 from "../../../../CustomComponents/NavbarComponents/Navbar1";
import Navbar2 from "../../../../CustomComponents/NavbarComponents/Navbar2";
import Navbar3 from "../../../../CustomComponents/NavbarComponents/Navbar3";
import Navbar4 from "../../../../CustomComponents/NavbarComponents/Navbar4";
import Navbar5 from "../../../../CustomComponents/NavbarComponents/Navbar5";
import "../../dashboard.css"


const RenderNavbarComponent = (componentName, props, preview ) => {
  switch (componentName) {
    case "Navbar1":
      return <Navbar1 {...props} isPreview={preview} />;
    case "Navbar2":
      return <Navbar2 {...props} isPreview={preview} />;
    case "Navbar3":
      return <Navbar3 {...props} isPreview={preview} />;
    case "Navbar4":
      return <Navbar4 {...props} isPreview={preview} />;
    case "Navbar5":
      return <Navbar5 {...props} isPreview={preview} />;
    // Add more cases for other Navbar components as needed
    default:
      return null;
  }
};

export default RenderNavbarComponent;
