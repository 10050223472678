import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { ChevronRight } from "react-bootstrap-icons";

const CA12 = ({ isPreview, holderState, selectedField }) => {
  const [centerAlign12, setCenterAlign12] = useState({
    headline:
      holderState?.headline !== undefined
        ? holderState.headline
        : "Short Headline",
    showHeadline:
      holderState?.showHeadline !== undefined ? holderState.showHeadline : true,
    link1Title:
      holderState?.link1Title !== undefined
        ? holderState.link1Title
        : "Action Link",
    link1Value:
      holderState?.link1Value !== undefined ? holderState.link1Value : "",
    showLink1:
      holderState?.showLink1 !== undefined ? holderState.showLink1 : true,
  });

  useEffect(() => {
    setCenterAlign12((prevCA12) => ({
      ...prevCA12,
      headline:
        holderState?.headline !== undefined
          ? holderState.headline
          : "Short Headline",
      showHeadline:
        holderState?.showHeadline !== undefined
          ? holderState.showHeadline
          : true,
      link1Title:
        holderState?.link1Title !== undefined
          ? holderState.link1Title
          : "Action Link",
      link1Value:
        holderState?.link1Value !== undefined ? holderState.link1Value : "",
      showLink1:
        holderState?.showLink1 !== undefined ? holderState.showLink1 : true,
    }));
  }, [
    holderState?.showHeadline,
    holderState?.headline,
    holderState?.link1Title,
    holderState?.link1Value,
    holderState?.showLink1,
  ]);

  return (
    <Row
      sm={12}
      md={12}
      lg={10}
      xl={10}
      xxl={8}
      style={{ "--bs-gutter-x": "0" }}
      className={`d-flex justify-content-center bg-white ${
        isPreview ? "p-3" : "p-5"
      }`}
    >
      <Col
        xs={12}
        md={12}
        lg={10}
        xl={10}
        className="d-flex flex-column align-items-center justify-content-center"
      >
        <Col
          xs={12}
          sm={10}
          md={10}
          lg={7}
          xl={6}
          xxl={6}
          className="pt-3 text-center"
        >
          {centerAlign12.showHeadline && (
            <h2
              className={`fw-bold ${isPreview ? "preview-scaling" : "py-3"} ${
                selectedField === "headline" ? "focus-field" : ""
              }`}
            >
              {centerAlign12.headline}
            </h2>
          )}
        </Col>
        <Col xxl={7} className={`text-center ${isPreview ? "" : "py-3"} `}>
          {centerAlign12.showLink1 && (
            <a
              className={`fw-bold link-offset-2 link-underline link-underline-opacity-0 link-underline-opacity-75-hover ${
                isPreview ? "preview-scaling" : ""
              } ${selectedField === "link1Value" ? "focus-field" : ""}`}
              href={"https://" + centerAlign12.link1Value}
              target="_blank"
              rel="noopener noreferrer"
            >
              {centerAlign12.link1Title} <ChevronRight />
            </a>
          )}
        </Col>
      </Col>
    </Row>
  );
};

export default CA12;
