import React, { useEffect } from "react";
import { useState } from "react";
import { Row, Col, Image } from "react-bootstrap";
import RenderIconComponent from "../../../ApplicationComponents/DashboardComponents/ComponentChoices/ChoicesComponents/RenderIconComponent";

const RA8 = ({ isPreview, holderState, selectedField }) => {
  const [rightAlign8, setRightAlign8] = useState({
    headline:
      holderState?.headline !== undefined ? holderState.headline : "Tagline",
    showHeadline:
      holderState?.showHeadline !== undefined ? holderState.showHeadline : true,
    text1:
      holderState?.text1Value !== undefined
        ? holderState.text1Value
        : "Long headline to turn your visitors into users",
    showText1:
      holderState?.showText1 !== undefined ? holderState.showText1 : true,
    text2:
      holderState?.text2Value !== undefined
        ? holderState.text2Value
        : "Gorgeous, high-quality video sharing on desktop, mobile, tablet",
    showText2:
      holderState?.showText2 !== undefined ? holderState.showText2 : true,
    text3:
      holderState?.text3Value !== undefined
        ? holderState.text3Value
        : "Change the color to match your brand or vision",
    showText3:
      holderState?.showText3 !== undefined ? holderState.showText3 : true,
    icon1: {
      type:
        holderState?.icon1?.type !== undefined
          ? holderState.icon1.type
          : "CircleHalf",
      size:
        holderState?.icon1?.size !== undefined
          ? holderState.icon1.size
          : isPreview
          ? 11
          : 32,
    },
    icon1Link:
      holderState?.icon1Link !== undefined ? holderState.icon1Link : "",
    showIcon1:
      holderState?.showIcon1 !== undefined ? holderState.showIcon1 : true,
    icon2: {
      type:
        holderState?.icon2?.type !== undefined
          ? holderState.icon2.type
          : "UiRadiosGrid",
      size:
        holderState?.icon2?.size !== undefined
          ? holderState.icon2.size
          : isPreview
          ? 11
          : 32,
    },
    icon2Link:
      holderState?.icon2Link !== undefined ? holderState.icon2Link : "",
    showIcon2:
      holderState?.showIcon2 !== undefined ? holderState.showIcon2 : true,
    showImg1: holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
    imgLink1: holderState?.imgLink1 !== undefined ? holderState.imgLink1 : "",
    imgSrc1:
      holderState?.imgSrc1 !== undefined
        ? holderState.imgSrc1
        : "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt1: "Placeholder Image",
  });

  const imageContainerStyle = {
    display: "flex",
    alignItems: "center",
    height: "100%",
    maxHeight: "100%",
    borderRadius: "1rem",
    overflow: "hidden",
  };
  const imageStyle = {
    objectFit: "cover",
    height: "22rem",
    width: "100%",
    borderRadius: "1rem",
  };

  useEffect(() => {
    setRightAlign8((prevRA8) => ({
      ...prevRA8,
      headline:
        holderState?.headline !== undefined ? holderState.headline : "Tagline",
      showHeadline:
        holderState?.showHeadline !== undefined
          ? holderState.showHeadline
          : true,
      text1:
        holderState?.text1Value !== undefined
          ? holderState.text1Value
          : "Long headline to turn your visitors into users",
      showText1:
        holderState?.showText1 !== undefined ? holderState.showText1 : true,
      text2:
        holderState?.text2Value !== undefined
          ? holderState.text2Value
          : "Gorgeous, high-quality video sharing on desktop, mobile, tablet",
      showText2:
        holderState?.showText2 !== undefined ? holderState.showText2 : true,
      text3:
        holderState?.text3Value !== undefined
          ? holderState.text3Value
          : "Change the color to match your brand or vision",
      showText3:
        holderState?.showText3 !== undefined ? holderState.showText3 : true,
      icon1: {
        type:
          holderState?.icon1?.type !== undefined
            ? holderState.icon1.type
            : "CircleHalf",
        size:
          holderState?.icon1?.size !== undefined
            ? holderState.icon1.size
            : isPreview
            ? 11
            : 32,
      },
      icon1Link:
        holderState?.icon1Link !== undefined ? holderState.icon1Link : "",
      showIcon1:
        holderState?.showIcon1 !== undefined ? holderState.showIcon1 : true,
      icon2: {
        type:
          holderState?.icon2?.type !== undefined
            ? holderState.icon2.type
            : "UiRadiosGrid",
        size:
          holderState?.icon2?.size !== undefined
            ? holderState.icon2.size
            : isPreview
            ? 11
            : 32,
      },
      icon2Link:
        holderState?.icon2Link !== undefined ? holderState.icon2Link : "",
      showIcon2:
        holderState?.showIcon2 !== undefined ? holderState.showIcon2 : true,
      showImg1:
        holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
      imgLink1: holderState?.imgLink1 !== undefined ? holderState.imgLink1 : "",
      imgSrc1:
        holderState?.imgSrc1 !== undefined
          ? holderState.imgSrc1
          : "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgAlt1: "Placeholder Image",
    }));
  }, [
    holderState?.showHeadline,
    holderState?.headline,
    holderState?.showText1,
    holderState?.text1Value,
    holderState?.showText2,
    holderState?.text2Value,
    holderState?.showText3,
    holderState?.text3Value,
    holderState?.showImg1,
    holderState?.imgLink1,
    holderState?.showIcon1,
    holderState?.icon1?.size,
    holderState?.icon1?.type,
    holderState?.icon2?.size,
    holderState?.icon2?.type,
    holderState?.icon1Link,
    holderState?.showIcon2,
    holderState?.icon2Link,
    holderState?.imgSrc1,
    isPreview,
  ]);

  return (
    <Row
      sm={12}
      md={12}
      lg={10}
      xl={10}
      xxl={8}
      style={{ "--bs-gutter-x": "0" }}
      className={`d-flex justify-content-center bg-white gap-5 ${
        isPreview ? "p-3" : "p-5"
      }`}
    >
      <Col
        xs={12}
        sm={10}
        md={5}
        lg={5}
        xl={5}
        xxl={4}
        className={`d-flex align-items-center justify-content-center my-auto ${
          isPreview ? "preview-scaling" : ""
        }`}
      >
        {rightAlign8.showImg1 && (
          <Col
            className={`text-center justify-content-center ${
              selectedField === "imgSrc1" ? "focus-field" : ""
            }`}
            style={imageContainerStyle}
          >
            {rightAlign8.imgLink1 !== "" && (
              <a
                href={"https://" + rightAlign8.imgLink1}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  src={rightAlign8.imgSrc1}
                  alt={rightAlign8.imgAlt1}
                  style={imageStyle}
                  fluid
                />
              </a>
            )}
            {rightAlign8.imgLink1 === "" && (
              <Image
                src={rightAlign8.imgSrc1}
                alt={rightAlign8.imgAlt1}
                style={imageStyle}
                fluid
              />
            )}
          </Col>
        )}
      </Col>
      <Col
        xs={12}
        sm={10}
        md={6}
        lg={5}
        xl={5}
        xxl={4}
        className="d-flex flex-column align-items-center justify-content-center"
      >
        <Col className="d-flex flex-column justify-content-center mx-2 mb-3">
          {rightAlign8.showHeadline && (
            <p
              className={`fw-semibold ${isPreview ? "preview-scaling" : ""} ${
                selectedField === "headline" ? "focus-field" : ""
              }`}
            >
              {rightAlign8.headline}
            </p>
          )}
          {rightAlign8.showText1 && (
            <h1
              className={`fw-bold ${isPreview ? "preview-scaling" : ""} ${
                selectedField === "text1" ? "focus-field" : ""
              }`}
            >
              {rightAlign8.text1}
            </h1>
          )}
        </Col>
        <Col md={11} className="d-flex align-items-top gap-2 mx-2">
          {rightAlign8.showIcon1 && (
            <Col md={1}>
              {rightAlign8.icon1Link !== "" && (
                <a
                  href={"https://" + rightAlign8.icon1Link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`text-black ${
                    selectedField === "icon1" ? "focus-field" : ""
                  }`}
                >
                  {RenderIconComponent({
                    iconType: rightAlign8.icon1.type,
                    iconSize: isPreview === true ? 11 : rightAlign8.icon1.size,
                  })}
                </a>
              )}
              {rightAlign8.icon1Link === "" && (
                <span
                  className={`${
                    selectedField === "icon1" ? "focus-field" : ""
                  }`}
                >
                  {RenderIconComponent({
                    iconType: rightAlign8.icon1.type,
                    iconSize: isPreview === true ? 11 : rightAlign8.icon1.size,
                  })}
                </span>
              )}
            </Col>
          )}
          <Col md={4}>
            {rightAlign8.showText2 && (
              <p
                className={`mb-0 ${isPreview ? "preview-scaling" : ""} ${
                  selectedField === "text2" ? "focus-field" : ""
                }`}
              >
                {rightAlign8.text2}
              </p>
            )}
          </Col>
          {rightAlign8.showIcon2 && (
            <Col md={1}>
              {rightAlign8.icon2Link !== "" && (
                <a
                  href={"https://" + rightAlign8.icon2Link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`text-black ${
                    selectedField === "icon2" ? "focus-field" : ""
                  }`}
                >
                  {RenderIconComponent({
                    iconType: rightAlign8.icon2.type,
                    iconSize: isPreview === true ? 11 : rightAlign8.icon2.size,
                  })}
                </a>
              )}
              {rightAlign8.icon2Link === "" && (
                <span
                  className={`${
                    selectedField === "icon2" ? "focus-field" : ""
                  }`}
                >
                  {RenderIconComponent({
                    iconType: rightAlign8.icon2.type,
                    iconSize: isPreview === true ? 11 : rightAlign8.icon2.size,
                  })}
                </span>
              )}
            </Col>
          )}
          <Col md={4}>
            {rightAlign8.showText3 && (
              <p
                className={`mb-0 ${isPreview ? "preview-scaling" : ""} ${
                  selectedField === "text3" ? "focus-field" : ""
                }`}
              >
                {rightAlign8.text3}
              </p>
            )}
          </Col>
        </Col>
      </Col>
    </Row>
  );
};

export default RA8;
