import React from "react";
import { Form, Container, Row, Col, Card, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setAuthenticated } from '../../../Services/authSlice';
import { setIsLoading } from '../../../Services/loadingSlice';
import { loginAuth } from "../../../Services/UserServices";

const LoginCard = () => {
  const [disabled, setDisabled] = useState(true);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [error, setError] = useState(false);
  const [loginObj, setLoginObj] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsLoading(false));
  },[dispatch])

  function handleLogin(e) {
    e.preventDefault();
        if (loginObj.email.includes("error")) {
      setError(true);
    } else {
      dispatch(setIsLoading(true));
    }
    
    loginAuth(loginObj.email, loginObj.password).then(async response => {
      try {
        if (response.ok) {
          const responseData = await response.json();
          const accessToken = responseData.accessToken;
          const role = responseData.user.role;
          sessionStorage.setItem('accessToken', accessToken);
          sessionStorage.setItem('email', loginObj.email)
          sessionStorage.setItem('role', role)

          dispatch(setAuthenticated(true));
          sessionStorage.setItem('isAuthenticated', 'true');
          console.log("Login success");
          navigate('/dashboard');
        } else if (!response.ok) {
          const errorMessage = await response.text();
          setError(errorMessage);
        }
      } catch (error) {
        console.error("An error occurred durring login:", error)
        dispatch(setIsLoading(false));
      } 
    });
  };

  const handleError = (e) => {
    e.preventDefault();
    setError(false)
    dispatch(setIsLoading(false));
  };  

  const handleChange = (e) => {
    setLoginObj({
      ...loginObj,
      [e.target.name]: e.target.value,
    });
  };

  const checkValid = (e) => {
    const passwordRegex =
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]{12,}$/;
    if (
      loginObj.email.includes("@") &&
      loginObj.email.endsWith(".com") &&
      loginObj.password.length > 5 &&
      passwordRegex.test(loginObj.password)
    ) {
      setEmailError("");
      setPasswordError("");
      setDisabled(false);
    } else if (
      loginObj.email.includes("@") &&
      loginObj.email.endsWith(".com")
    ) {
      setEmailError("");
      setPasswordError("true");
    } else if (
      loginObj.password.length > 5 &&
      passwordRegex.test(loginObj.password)
    ) {
      setEmailError("true");
      setPasswordError("");
    } else {
      setEmailError("true");
      setPasswordError("true");
    }
  };

  return (
    <>
      <Container>
        <Row>
          <Col
            lg={{ span: 4, offset: 4 }}
            md={{ span: 6, offset: 3 }}
            sm={{ span: 8, offset: 2 }}
          >
            <Card className="mx-auto mt-4">
              <Card.Body className="mx-auto">
                <Form>
                  <Form.Group className="mb-4 mt-4 form-control-lg">
                    <Form.Label htmlFor="username">
                      Username/Email Address
                    </Form.Label>
                    <Form.Control
                      id="username"
                      onChange={handleChange}
                      onKeyUp={checkValid}
                      name="email"
                      className="log-input"
                      type="email"
                      placeholder="name@example.com"
                      autoComplete="username email"
                      required
                      isInvalid={emailError}
                    />
                    <Form.Control.Feedback type="invalid">
                      Must include "@" and end in ".com"
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-4 mt-4 form-control-lg">
                    <Form.Label htmlFor="password" className="mt-3">
                      Password
                    </Form.Label>
                    <Form.Control
                      id="password"
                      onChange={handleChange}
                      onKeyUp={checkValid}
                      name="password"
                      className="log-input"
                      type="password"
                      placeholder="********"
                      isInvalid={passwordError}
                    />
                    <Form.Control.Feedback type="invalid">
                      Password length must be {">"} 12 {<br></br>}
                      contain a number, symbol, and both uppercase / lowercase
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div className="text-center mb-4">
                    <Button
                      disabled={disabled}
                      onClick={handleLogin}
                      className="log-label"
                      variant="dark"
                      type="submit"
                    >
                      Log in
                    </Button>
                  </div>
                </Form>
                <Link
                  className="infoColor log-label"
                  to="/forgotpassword"
                >
                  Forgot Password
                </Link>
                <Link
                  className="infoColor mt-4 log-label"
                  to="/register"
                >
                  Sign-Up for an Account
                </Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Modal size="md" show={error} onHide={() => {setError(false); dispatch(setIsLoading(false));}}>
          <Modal.Dialog className="mx-auto text-center error-font">
            <Modal.Header closeButton>
              <Modal.Title className="ms-auto ps-4 text-center error-color">
                Unable to Sign-in
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="mb-4">
                That email and password combination didn't work. Try again.
              </div>
              <div className="mb-4">
                <Button
                  onClick={handleError}
                  className="error-small"
                  variant="dark"
                >
                  Try different combination
                </Button>
              </div>
              <div className="mt-2">
                <Link to="/register">
                  <Button
                    className="error-small"
                    variant="dark"
                    disabled={disabled}
                  >
                    Sign up for Account
                  </Button>
                </Link>
              </div>
              <Link
                to={"/verifyemail"}
                className="infoColor log-label mt-5 mb-0"
              >
                Not confirmed ? Verify email
              </Link>
            </Modal.Body>
          </Modal.Dialog>
        </Modal>
      </Container>
    </>
  );
};

export default LoginCard;
