import React from 'react';
import { useEffect, useState } from 'react';
import { Row, Col, Nav, Container } from 'react-bootstrap';
import { Facebook, Instagram, CCircle, Twitter, Pinterest, Linkedin, Tiktok } from 'react-bootstrap-icons';

const Footer8 = ({ isPreview, holderState, selectedField }) => {
  const [footer8, setFooter8] = useState({
    companyName: `ACME`,
    legal: "Legal",
    legalLink: holderState?.legLink !== undefined ? holderState.legLink : "",
    siteMap: "Site Map",
    siteMapLink:
      holderState?.siteLink !== undefined ? holderState.siteLink : "",
    privacyPolicy: "Privacy Policy",
    privacyPolicyLink:
      holderState?.privacyLink !== undefined ? holderState.privacyLink : "",
    termsOfService: "Terms of Service",
    termsOfServiceLink:
      holderState?.tosLink !== undefined ? holderState.tosLink : "",
    socialLinkIG: holderState?.igLink !== undefined ? holderState.igLink : "",
    socialLinkFB: holderState?.fbLink !== undefined ? holderState.fbLink : "",
    socialLinkX: holderState?.xLink !== undefined ? holderState.xLink : "",
    socialLinkPIN:
      holderState?.pinLink !== undefined ? holderState.pinLink : "",
    socialLinkTik:
      holderState?.tikLink !== undefined ? holderState.tikLink : "",
    socialLinkLI: holderState?.liLink !== undefined ? holderState.liLink : "",
    companyFooter:
      holderState?.companyFooterValue !== undefined
        ? holderState.companyFooterValue
        : "2018 Acme. All rights reserved.",
    showSocials:
      holderState?.socialIconsChecked !== undefined
        ? holderState.socialIconsChecked
        : true,
    showIG:
      holderState?.showIGChecked !== undefined
        ? holderState.showIGChecked
        : true,
    showFB:
      holderState?.showFbChecked !== undefined
        ? holderState.showFbChecked
        : true,
    showX:
      holderState?.showXChecked !== undefined ? holderState.showXChecked : true,
    showPIN:
      holderState?.showPINChecked !== undefined
        ? holderState.showPINChecked
        : true,
    showTik:
      holderState?.showTikChecked !== undefined
        ? holderState.showTikChecked
        : true,
    showLI:
      holderState?.showLIChecked !== undefined
        ? holderState.showLIChecked
        : true,
    showPrivacy:
      holderState?.showPrivacy !== undefined ? holderState.showPrivacy : true,
    showCompanyFooter:
      holderState?.showCompanyFooter !== undefined
        ? holderState.showCompanyFooter
        : true,
    showTos: holderState?.showTos !== undefined ? holderState.showTos : true,
    showLegal:
      holderState?.showLegal !== undefined ? holderState.showLegal : true,
    showSiteMap:
      holderState?.showSiteMap !== undefined ? holderState.showSiteMap : true,
  });

  useEffect(() => {
    setFooter8((prevFooter8) => ({
      ...prevFooter8,
      showSocials:
        holderState?.socialIconsChecked !== undefined
          ? holderState.socialIconsChecked
          : true,
      showIG:
        holderState?.showIGChecked !== undefined
          ? holderState.showIGChecked
          : true,
      showFB:
        holderState?.showFbChecked !== undefined
          ? holderState.showFbChecked
          : true,
      showX:
        holderState?.showXChecked !== undefined
          ? holderState.showXChecked
          : true,
      showPIN:
        holderState?.showPINChecked !== undefined
          ? holderState.showPINChecked
          : true,
      showTik:
        holderState?.showTikChecked !== undefined
          ? holderState.showTikChecked
          : true,
      showLI:
        holderState?.showLIChecked !== undefined
          ? holderState.showLIChecked
          : true,
      showPrivacy:
        holderState?.showPrivacy !== undefined ? holderState.showPrivacy : true,
      showCompanyFooter:
        holderState?.showCompanyFooter !== undefined
          ? holderState.showCompanyFooter
          : true,
      showTos: holderState?.showTos !== undefined ? holderState.showTos : true,
      showLegal:
        holderState?.showLegal !== undefined ? holderState.showLegal : true,
      showSiteMap:
        holderState?.showSiteMap !== undefined ? holderState.showSiteMap : true,
      legalLink: holderState?.legLink !== undefined ? holderState.legLink : "",
      siteMapLink:
        holderState?.siteLink !== undefined ? holderState.siteLink : "",
      privacyPolicyLink:
        holderState?.privacyLink !== undefined ? holderState.privacyLink : "",
      termsOfServiceLink:
        holderState?.tosLink !== undefined ? holderState.tosLink : "",
      socialLinkIG: holderState?.igLink !== undefined ? holderState.igLink : "",
      socialLinkFB: holderState?.fbLink !== undefined ? holderState.fbLink : "",
      socialLinkX: holderState?.xLink !== undefined ? holderState.xLink : "",
      socialLinkPIN:
        holderState?.pinLink !== undefined ? holderState.pinLink : "",
      socialLinkTik:
        holderState?.tikLink !== undefined ? holderState.tikLink : "",
      socialLinkLI: holderState?.liLink !== undefined ? holderState.liLink : "",
      companyFooter:
        holderState?.companyFooterValue !== undefined
          ? holderState.companyFooterValue
          : "2018 Acme. All rights reserved.",
    }));
  }, [
    holderState?.socialIconsChecked,
    holderState?.showIGChecked,
    holderState?.showFbChecked,
    holderState?.showXChecked,
    holderState?.showPINChecked,
    holderState?.showTikChecked,
    holderState?.showLIChecked,
    holderState?.showPrivacy,
    holderState?.showCompanyFooter,
    holderState?.showTos,
    holderState?.showLegal,
    holderState?.showSiteMap,
    holderState?.igLink,
    holderState?.fbLink,
    holderState?.xLink,
    holderState?.pinLink,
    holderState?.tikLink,
    holderState?.liLink,
    holderState?.companyFooterValue,
    holderState?.privacyLink,
    holderState?.tosLink,
    holderState?.legLink,
    holderState?.siteLink,
  ]);

  return (
    <Container fluid>
      <Row className={`bg-white p-3 ${isPreview ? "preview-scaling" : ""}`}>
        {footer8.showCompanyFooter && (
          <Col sm={5} md={5} lg={4} className="d-flex mx-auto">
            <small
              className={`${
                selectedField === "Company Footer" ? "focus-field" : ""
              }`}
            >
              <CCircle size={12} />
              {footer8.companyFooter}
            </small>
          </Col>
        )}
        {(footer8.showPrivacy ||
          footer8.showTos ||
          footer8.showLegal ||
          footer8.showSiteMap ||
          footer8.showIG ||
          footer8.showFB ||
          footer8.showX ||
          footer8.showLI ||
          footer8.showPIN ||
          footer8.showTik) && (
          <Col
            sm={7}
            md={7}
            lg={8}
            className="gap-3 d-flex justify-content-center align-items-center mx-auto"
          >
            {footer8.showPrivacy && (
              <Nav.Link
                target="_blank"
                href={"https://" + footer8.privacyPolicyLink}
              >
                <small
                  className={`${
                    selectedField === "Privacy Policy" ? "focus-field" : ""
                  }`}
                >
                  Privacy Policy
                </small>
              </Nav.Link>
            )}
            {footer8.showTos && (
              <Nav.Link
                target="_blank"
                href={"https://" + footer8.termsOfServiceLink}
              >
                <small
                  className={`${
                    selectedField === "Terms of Service" ? "focus-field" : ""
                  }`}
                >
                  Terms of Service
                </small>
              </Nav.Link>
            )}
            {footer8.showLegal && (
              <Nav.Link href={"https://" + footer8.legalLink} target="_blank">
                <small
                  className={`${
                    selectedField === "Legal" ? "focus-field" : ""
                  }`}
                >
                  {footer8.legal}
                </small>
              </Nav.Link>
            )}
            {footer8.showSiteMap && (
              <Nav.Link href={"https://" + footer8.siteMapLink} target="_blank">
                <small
                  className={`${
                    selectedField === "Site Map" ? "focus-field" : ""
                  }`}
                >
                  {footer8.siteMap}
                </small>
              </Nav.Link>
            )}
            {footer8.showSocials &&
              (footer8.showIG ||
                footer8.showFB ||
                footer8.showX ||
                footer8.showLI ||
                footer8.showPIN ||
                footer8.showTik) && (
                <>
                  {footer8.showIG && (
                    <Nav.Link
                      href={"https://" + footer8.socialLinkIG}
                      target="_blank"
                      className="text-black"
                    >
                      <Instagram
                        className={`${
                          selectedField === "Instagram" ? "focus-field" : ""
                        }`}
                      />
                    </Nav.Link>
                  )}
                  {footer8.showFB && (
                    <Nav.Link
                      href={"https://" + footer8.socialLinkFB}
                      target="_blank"
                      className="text-black"
                    >
                      <Facebook
                        className={`${
                          selectedField === "Facebook" ? "focus-field" : ""
                        }`}
                      />
                    </Nav.Link>
                  )}
                  {footer8.showX && (
                    <Nav.Link
                      href={"https://" + footer8.socialLinkX}
                      target="_blank"
                      className="text-black"
                    >
                      <Twitter
                        className={`${
                          selectedField === "X" ? "focus-field" : ""
                        }`}
                      />
                    </Nav.Link>
                  )}
                  {footer8.showPIN && (
                    <Nav.Link
                      href={"https://" + footer8.socialLinkPIN}
                      target="_blank"
                      className="text-black"
                    >
                      <Pinterest
                        className={`${
                          selectedField === "Pinterest" ? "focus-field" : ""
                        }`}
                      />
                    </Nav.Link>
                  )}
                  {footer8.showTik && (
                    <Nav.Link
                      href={"https://" + footer8.socialLinkTik}
                      target="_blank"
                      className="text-black"
                    >
                      <Tiktok
                        className={`${
                          selectedField === "TikTok" ? "focus-field" : ""
                        }`}
                      />
                    </Nav.Link>
                  )}
                  {footer8.showLI && (
                    <Nav.Link
                      href={"https://" + footer8.socialLinkLI}
                      target="_blank"
                      className="text-black"
                    >
                      <Linkedin
                        className={`${
                          selectedField === "LinkedIn" ? "focus-field" : ""
                        }`}
                      />
                    </Nav.Link>
                  )}
                </>
              )}
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default Footer8;
