import React, { useEffect, useState } from "react";
import { Row, Col, Image } from "react-bootstrap";

const Gallery7 = ({ isPreview, holderState, selectedField }) => {
  const [gal7, setGal7] = useState({
    showImg1: holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
    showImg2: holderState?.showImg2 !== undefined ? holderState.showImg2 : true,
    showImg3: holderState?.showImg3 !== undefined ? holderState.showImg3 : true,
    imgLink1: holderState?.imgLink1 !== undefined ? holderState.imgLink1 : "",
    imgLink2: holderState?.imgLink2 !== undefined ? holderState.imgLink2 : "",
    imgLink3: holderState?.imgLink3 !== undefined ? holderState.imgLink3 : "",
    imgSrc1:
      holderState?.imgSrc1 !== undefined
        ? holderState.imgSrc1
        : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt1: "Placeholder Img",
    imgSrc2:
      holderState?.imgSrc2 !== undefined
        ? holderState.imgSrc2
        : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt2: "Placeholder Img",
    imgSrc3:
      holderState?.imgSrc3 !== undefined
        ? holderState.imgSrc3
        : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt3: "Placeholder Img",
  });

  useEffect(() => {
    setGal7((prevGal7) => ({
      ...prevGal7,
      showImg1:
        holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
      showImg2:
        holderState?.showImg2 !== undefined ? holderState.showImg2 : true,
      showImg3:
        holderState?.showImg3 !== undefined ? holderState.showImg3 : true,
      imgLink1: holderState?.imgLink1 !== undefined ? holderState.imgLink1 : "",
      imgLink2: holderState?.imgLink2 !== undefined ? holderState.imgLink2 : "",
      imgLink3: holderState?.imgLink3 !== undefined ? holderState.imgLink3 : "",
      imgSrc1:
        holderState?.imgSrc1 !== undefined
          ? holderState.imgSrc1
          : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgAlt1: "Placeholder Img",
      imgSrc2:
        holderState?.imgSrc2 !== undefined
          ? holderState.imgSrc2
          : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgAlt2: "Placeholder Img",
      imgSrc3:
        holderState?.imgSrc3 !== undefined
          ? holderState.imgSrc3
          : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgAlt3: "Placeholder Img",
    }));
  }, [
    holderState?.showImg1,
    holderState?.showImg2,
    holderState?.showImg3,
    holderState?.imgLink1,
    holderState?.imgLink2,
    holderState?.imgLink3,
    holderState?.imgSrc1,
    holderState?.imgSrc2,
    holderState?.imgSrc3,
  ]);

  return (
    <Row
      className={`d-flex justify-content-center bg-white ${
        isPreview ? "px-3 pt-3" : "p-5"
      }`}
    >
      <Col
        xs={12}
        sm={6}
        className="d-flex align-items-center justify-content-center mb-4"
      >
        {gal7.showImg1 && (
          <div
            className={`text-center ${
              selectedField === "imgSrc1" ? "focus-field" : ""
            }`}
          >
            {gal7.imgLink1 !== "" && (
              <a
                href={"https://" + gal7.imgLink1}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image src={gal7.imgSrc1} alt={gal7.imgAlt1} fluid />
              </a>
            )}
            {gal7.imgLink1 === "" && (
              <Image src={gal7.imgSrc1} alt={gal7.imgAlt1} fluid />
            )}
          </div>
        )}
      </Col>
      <Col xs={12} sm={6}>
        <Row>
          <Col
            xs={12}
            className="d-flex align-items-center justify-content-center mb-4"
          >
            {gal7.showImg2 && (
              <div
                className={`text-center ${
                  selectedField === "imgSrc2" ? "focus-field" : ""
                }`}
              >
                {gal7.imgLink2 !== "" && (
                  <a
                    href={"https://" + gal7.imgLink2}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image src={gal7.imgSrc2} alt={gal7.imgAlt2} fluid />
                  </a>
                )}
                {gal7.imgLink2 === "" && (
                  <Image src={gal7.imgSrc2} alt={gal7.imgAlt2} fluid />
                )}
              </div>
            )}
          </Col>
          <Col
            xs={12}
            className="d-flex align-items-center justify-content-center mb-4"
          >
            {gal7.showImg3 && (
              <div
                className={`text-center ${
                  selectedField === "imgSrc3" ? "focus-field" : ""
                }`}
              >
                {gal7.imgLink3 !== "" && (
                  <a
                    href={"https://" + gal7.imgLink3}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image src={gal7.imgSrc3} alt={gal7.imgAlt3} fluid />
                  </a>
                )}
                {gal7.imgLink3 === "" && (
                  <Image src={gal7.imgSrc3} alt={gal7.imgAlt3} fluid />
                )}
              </div>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Gallery7;
