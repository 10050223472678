import React, { useEffect, useState } from "react";
import { Row, Col, Image } from "react-bootstrap";
import RenderIconComponent from "../../../ApplicationComponents/DashboardComponents/ComponentChoices/ChoicesComponents/RenderIconComponent";

const LA13 = ({ isPreview, holderState, selectedField }) => {
  const [leftAlign13, setLeftAlign13] = useState({
    text1:
      holderState?.text1Value !== undefined
        ? holderState.text1Value
        : "Long headline on two lines to turn your visitors into users and achieve more",
    showText1:
      holderState?.showText1 !== undefined ? holderState.showText1 : true,
    text2:
      holderState?.text2Value !== undefined
        ? holderState.text2Value
        : "Separated they live in Bookmarks right at the coast of the famous Semantics, large language ocean Separated they live in Bookmarks right at the coast",
    showText2:
      holderState?.showText2 !== undefined ? holderState.showText2 : true,
    header1:
      holderState?.header1 !== undefined ? holderState.header1 : "Paypal",
    showHeader1:
      holderState?.showHeader1 !== undefined ? holderState.showHeader1 : true,
    header2: holderState?.header2 !== undefined ? holderState.header2 : "Pay",
    showHeader2:
      holderState?.showHeader2 !== undefined ? holderState.showHeader2 : true,
    header3: holderState?.header3 !== undefined ? holderState.header3 : "Pay",
    showHeader3:
      holderState?.showHeader3 !== undefined ? holderState.showHeader3 : true,
    header4:
      holderState?.header4 !== undefined ? holderState.header4 : "SAMSUNG PAY",
    showHeader4:
      holderState?.showHeader4 !== undefined ? holderState.showHeader4 : true,
    header1Icon: {
      type:
        holderState?.header1Icon?.type !== undefined
          ? holderState.header1Icon.type
          : "Paypal",
      size:
        holderState?.header1Icon?.size !== undefined
          ? holderState.header1Icon.size
          : isPreview
          ? 12
          : 22,
    },
    showHeader1Icon:
      holderState?.showHeader1Icon !== undefined
        ? holderState.showHeader1Icon
        : true,
    header2Icon: {
      type:
        holderState?.header2Icon?.type !== undefined
          ? holderState.header2Icon.type
          : "Apple",
      size:
        holderState?.header2Icon?.size !== undefined
          ? holderState.header2Icon.size
          : isPreview
          ? 12
          : 22,
    },
    showHeader2Icon:
      holderState?.showHeader2Icon !== undefined
        ? holderState.showHeader2Icon
        : true,
    header3Icon: {
      type:
        holderState?.header3Icon?.type !== undefined
          ? holderState.header3Icon.type
          : "Android2",
      size:
        holderState?.header3Icon?.size !== undefined
          ? holderState.header3Icon.size
          : isPreview
          ? 12
          : 22,
    },
    showHeader3Icon:
      holderState?.showHeader3Icon !== undefined
        ? holderState.showHeader3Icon
        : true,
    header4Icon: {
      type:
        holderState?.header4Icon?.type !== undefined
          ? holderState.header4Icon.type
          : "",
      size:
        holderState?.header4Icon?.size !== undefined
          ? holderState.header4Icon.size
          : isPreview
          ? 12
          : 22,
    },
    showHeader4Icon:
      holderState?.showHeader4Icon !== undefined
        ? holderState.showHeader4Icon
        : true,
    showImg1: holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
    imgLink1: holderState?.imgLink1 !== undefined ? holderState.imgLink1 : "",
    imgSrc1:
      holderState?.imgSrc1 !== undefined
        ? holderState.imgSrc1
        : "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt1: "Placeholder Image",
  });

  const imageContainerStyle = {
    display: "flex",
    alignItems: "center",
    height: "100%",
    maxHeight: "100%",
    borderRadius: "1rem",
    overflow: "hidden",
  };
  const imageStyle = {
    objectFit: "cover",
    height: "22rem",
    width: "100%",
    borderRadius: "1rem",
  };

  useEffect(() => {
    setLeftAlign13((prevLA13) => ({
      ...prevLA13,
      text1:
        holderState?.text1Value !== undefined
          ? holderState.text1Value
          : "Long headline on two lines to turn your visitors into users and achieve more",
      showText1:
        holderState?.showText1 !== undefined ? holderState.showText1 : true,
      text2:
        holderState?.text2Value !== undefined
          ? holderState.text2Value
          : "Separated they live in Bookmarks right at the coast of the famous Semantics, large language ocean Separated they live in Bookmarks right at the coast",
      showText2:
        holderState?.showText2 !== undefined ? holderState.showText2 : true,
      header1:
        holderState?.header1 !== undefined ? holderState.header1 : "Paypal",
      showHeader1:
        holderState?.showHeader1 !== undefined ? holderState.showHeader1 : true,
      header2: holderState?.header2 !== undefined ? holderState.header2 : "Pay",
      showHeader2:
        holderState?.showHeader2 !== undefined ? holderState.showHeader2 : true,
      header3: holderState?.header3 !== undefined ? holderState.header3 : "Pay",
      showHeader3:
        holderState?.showHeader3 !== undefined ? holderState.showHeader3 : true,
      header4:
        holderState?.header4 !== undefined
          ? holderState.header4
          : "SAMSUNG PAY",
      showHeader4:
        holderState?.showHeader4 !== undefined ? holderState.showHeader4 : true,
      header1Icon: {
        type:
          holderState?.header1Icon?.type !== undefined
            ? holderState.header1Icon.type
            : "Paypal",
        size:
          holderState?.header1Icon?.size !== undefined
            ? holderState.header1Icon.size
            : isPreview
            ? 12
            : 22,
      },
      showHeader1Icon:
        holderState?.showHeader1Icon !== undefined
          ? holderState.showHeader1Icon
          : true,
      header2Icon: {
        type:
          holderState?.header2Icon?.type !== undefined
            ? holderState.header2Icon.type
            : "Apple",
        size:
          holderState?.header2Icon?.size !== undefined
            ? holderState.header2Icon.size
            : isPreview
            ? 12
            : 22,
      },
      showHeader2Icon:
        holderState?.showHeader2Icon !== undefined
          ? holderState.showHeader2Icon
          : true,
      header3Icon: {
        type:
          holderState?.header3Icon?.type !== undefined
            ? holderState.header3Icon.type
            : "Android2",
        size:
          holderState?.header3Icon?.size !== undefined
            ? holderState.header3Icon.size
            : isPreview
            ? 12
            : 22,
      },
      showHeader3Icon:
        holderState?.showHeader3Icon !== undefined
          ? holderState.showHeader3Icon
          : true,
      header4Icon: {
        type:
          holderState?.header4Icon?.type !== undefined
            ? holderState.header4Icon.type
            : "",
        size:
          holderState?.header4Icon?.size !== undefined
            ? holderState.header4Icon.size
            : isPreview
            ? 12
            : 22,
      },
      showHeader4Icon:
        holderState?.showHeader4Icon !== undefined
          ? holderState.showHeader4Icon
          : true,
      showImg1:
        holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
      imgLink1: holderState?.imgLink1 !== undefined ? holderState.imgLink1 : "",
      imgSrc1:
        holderState?.imgSrc1 !== undefined
          ? holderState.imgSrc1
          : "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgAlt1: "Placeholder Image",
    }));
  }, [
    holderState?.showText1,
    holderState?.text1Value,
    holderState?.showText2,
    holderState?.text2Value,
    holderState?.showImg1,
    holderState?.imgLink1,
    holderState?.header1,
    holderState?.showHeader1,
    holderState?.header2,
    holderState?.showHeader2,
    holderState?.header3,
    holderState?.showHeader3,
    holderState?.header4,
    holderState?.showHeader4,
    holderState?.showHeader1Icon,
    holderState?.showHeader2Icon,
    holderState?.showHeader3Icon,
    holderState?.showHeader4Icon,
    holderState?.header1Icon,
    holderState?.header2Icon,
    holderState?.header3Icon,
    holderState?.header4Icon,
    holderState?.header1Icon?.size,
    holderState?.header1Icon?.type,
    holderState?.header2Icon?.size,
    holderState?.header2Icon?.type,
    holderState?.header3Icon?.size,
    holderState?.header3Icon?.type,
    holderState?.header4Icon?.size,
    holderState?.header4Icon?.type,
    holderState?.imgSrc1,
    isPreview,
  ]);

  return (
    <Row
      sm={12}
      md={12}
      lg={10}
      xl={10}
      xxl={8}
      style={{ "--bs-gutter-x": "0" }}
      className={`d-flex justify-content-center bg-white align-items-center gap-5 ${
        isPreview ? "p-3" : "p-5"
      }`}
    >
      <Col
        xs={12}
        sm={10}
        md={6}
        lg={5}
        xl={5}
        xxl={4}
        className="d-flex flex-column align-items-center justify-content-center"
      >
        <Col className="d-flex flex-column justify-content-center mx-2 gap-2">
          {leftAlign13.showText1 && (
            <h3
              className={`fw-bold ${isPreview ? "preview-scaling" : ""} ${
                selectedField === "text1" ? "focus-field" : ""
              }`}
            >
              {leftAlign13.text1}
            </h3>
          )}
          {leftAlign13.showText2 && (
            <p
              className={`${isPreview ? "preview-scaling" : ""} ${
                selectedField === "text2" ? "focus-field" : ""
              }`}
            >
              {leftAlign13.text2}
            </p>
          )}
          <Col
            className={`d-flex ${
              isPreview ? "preview-scaling gap-2" : "gap-5"
            }`}
          >
            <p className={`fw-semibold ${isPreview ? "mb-0" : ""}`}>
              {leftAlign13.showHeader1Icon && (
                <span
                  className={`${
                    selectedField === "header1Icon" ? "focus-field" : ""
                  }`}
                >
                  {RenderIconComponent({
                    iconType: leftAlign13.header1Icon.type,
                    iconSize:
                      isPreview === true ? 11 : leftAlign13.header1Icon.size,
                  })}
                </span>
              )}
              {leftAlign13.showHeader1 && (
                <span
                  className={`${
                    selectedField === "header1" ? "focus-field" : ""
                  }`}
                >
                  {leftAlign13.header1}
                </span>
              )}
            </p>
            <p className={`fw-semibold ${isPreview ? "mb-0" : ""}`}>
              {leftAlign13.showHeader2Icon && (
                <span
                  className={`${
                    selectedField === "header2Icon" ? "focus-field" : ""
                  }`}
                >
                  {RenderIconComponent({
                    iconType: leftAlign13.header2Icon.type,
                    iconSize:
                      isPreview === true ? 11 : leftAlign13.header2Icon.size,
                  })}
                </span>
              )}
              {leftAlign13.showHeader2 && (
                <span
                  className={`${
                    selectedField === "header2" ? "focus-field" : ""
                  }`}
                >
                  {leftAlign13.header2}
                </span>
              )}
            </p>
            <p className={`fw-semibold ${isPreview ? "mb-0" : ""}`}>
              {leftAlign13.showHeader3Icon && (
                <span
                  className={`${
                    selectedField === "header3Icon" ? "focus-field" : ""
                  }`}
                >
                  {RenderIconComponent({
                    iconType: leftAlign13.header3Icon.type,
                    iconSize:
                      isPreview === true ? 11 : leftAlign13.header3Icon.size,
                  })}
                </span>
              )}
              {leftAlign13.showHeader3 && (
                <span
                  className={`${
                    selectedField === "header3" ? "focus-field" : ""
                  }`}
                >
                  {leftAlign13.header3}
                </span>
              )}
            </p>
            <p className={`fw-semibold ${isPreview ? "mb-0" : ""}`}>
              {leftAlign13.showHeader4Icon && (
                <span
                  className={`${
                    selectedField === "header4Icon" ? "focus-field" : ""
                  }`}
                >
                  {RenderIconComponent({
                    iconType: leftAlign13.header4Icon.type,
                    iconSize:
                      isPreview === true ? 11 : leftAlign13.header4Icon.size,
                  })}
                </span>
              )}
              {leftAlign13.showHeader4 && (
                <span
                  className={`${
                    selectedField === "header4" ? "focus-field" : ""
                  }`}
                >
                  {leftAlign13.header4}
                </span>
              )}
            </p>
          </Col>
        </Col>
      </Col>
      <Col
        xs={12}
        sm={10}
        md={5}
        lg={5}
        xl={5}
        xxl={4}
        className={`d-flex align-items-center justify-content-center my-auto ${
          isPreview ? "preview-scaling" : ""
        }`}
      >
        {leftAlign13.showImg1 && (
          <Col
            className={`text-center justify-content-center ${
              selectedField === "imgSrc1" ? "focus-field" : ""
            }`}
            style={imageContainerStyle}
          >
            {leftAlign13.imgLink1 !== "" && (
              <a
                href={"https://" + leftAlign13.imgLink1}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  src={leftAlign13.imgSrc1}
                  alt={leftAlign13.imgAlt1}
                  style={imageStyle}
                  fluid
                />
              </a>
            )}
            {leftAlign13.imgLink1 === "" && (
              <Image
                src={leftAlign13.imgSrc1}
                alt={leftAlign13.imgAlt1}
                style={imageStyle}
                fluid
              />
            )}
          </Col>
        )}
      </Col>
    </Row>
  );
};

export default LA13;
