import "bootstrap/dist/css/bootstrap.css";
import './Pages/main.css';
import React, { useEffect, lazy } from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { setAuthenticated } from "./Services/authSlice";
import { useDispatch, useSelector } from "react-redux";
import LoadingLarge from "./Components/ApplicationComponents/ServiceComponents/LoadingAnimations/LoadingLarge";
import RegistrationPage from "./Pages/RegistrationPage";
import ForgetPwPage from './Pages/ForgetPwPage';
import LoginForm from "./Pages/LoginPage";
import VerifyEmailPage from "./Pages/VerifyEmailPage";
import ResetPwCard from "./Components/ApplicationComponents/ServiceComponents/ResetPwCard";
import Dashboard from "./Pages/Dashboard";
import HomePageFlow from './Components/ApplicationComponents/DashboardComponents/HomePageFlow';
import FirstLogin from "./Components/ApplicationComponents/DashboardComponents/FirstLogin";
import NoPages from "./Components/ApplicationComponents/DashboardComponents/NoPagesFlow";
import AboutPageFlow from "./Components/ApplicationComponents/DashboardComponents/AboutPageFlow";
import ContactPageFlow from "./Components/ApplicationComponents/DashboardComponents/ContactPageFlow";

//===== Test / Component Views =====
import BodyCompPage from "./DawsonsTest/BodyCompPage";
import SettingsPage from "./Pages/SettingsPage";
import AddPageFlow from "./Components/ApplicationComponents/DashboardComponents/AddPageFlow";
import EditFooterFlow from "./Components/ApplicationComponents/DashboardComponents/EditFooterFlow";
import EditNavbarFlow from "./Components/ApplicationComponents/DashboardComponents/EditNavbarFlow";
import EditPageFlow from "./Components/ApplicationComponents/DashboardComponents/EditPageFlow";
import ViewAllPages from "./Components/ApplicationComponents/DashboardComponents/ViewAllPages";
import LiveSite from "./Pages/LiveSite";
import PageFactory from "./Pages/PageFactory";

const UserProfilePage = lazy(() => import("./Pages/UserProfilePage"));

function App() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading.isLoading)

  useEffect(() => {
    const isAuthenticated = sessionStorage.getItem('isAuthenticated') === 'true';
    dispatch(setAuthenticated(isAuthenticated));
  }, [dispatch]);

  return (
    <>
    <LoadingLarge active={loading} children={''} />

    <BrowserRouter>
      <Routes>
        {/* AUTH PATHWAYS */}
        <Route path="/" element={<LoginForm />} />
        <Route path="register" element={<RegistrationPage />} />
        <Route path="forgotpassword" element={<ForgetPwPage />} />
        <Route path="resetpassword" element={<ResetPwCard />} />
        <Route path="verifyemail" element={<VerifyEmailPage />} />

        {/* PAGES TO EDIT SITE */}
        {/* <Route path="dashboard" element={isAuthenticated ? <Dashboard /> : <LoginForm />} >  */}
        <Route path="dashboard" element={<Dashboard />}>
          <Route path="profile" element={<UserProfilePage />} />
          <Route path="settings" element={<SettingsPage />} />
          <Route path="firstlogin" element={<FirstLogin />} />
          <Route path="nopages" element={<NoPages />} />
          <Route path='homepage' element={<HomePageFlow />} />
          <Route path='viewall' element={<ViewAllPages />} />
          <Route path='aboutpage' element={<AboutPageFlow />} />
          <Route path='contactpage' element={<ContactPageFlow />} />
          <Route path='addpage' element={<AddPageFlow />} />
          <Route path='editfooter' element={<EditFooterFlow />} />
          <Route path='editnavbar' element={<EditNavbarFlow />} />
          <Route path='editpage' element={<EditPageFlow />} />
        </Route>

        {/* ROUTES TO VIEW LIVE DATA */}
        <Route path="live" element={<LiveSite />} >
          <Route path=":path" element={<PageFactory />} />
        </Route>

        {/* VIEW FOR ALTERING BODY COMPONENTS DEV */}
        <Route path="body" element={<BodyCompPage />} />

      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;