import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Accordion,
  FormControl,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import PagePatternChoices from "./ComponentChoices/PagePatternChoices";
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./dashboard.css";
import DashboardHeader from "../ServiceComponents/DashboardHeader";
import { Eye, Pencil, Trash3 } from "react-bootstrap-icons";
import { removeBodyRow } from "../../../reducers/bodyRowsReducer";
import { setRowId } from "../../../reducers/rowIdReducer";
import { Link } from "react-router-dom";
import { ReactComponent as PlusSignRight } from "../../../images/plusSignRight.svg";
import { ReactComponent as PlusSignLeft } from "../../../images/plusSignLeft.svg";
// import RenderFinishedPage from "./ComponentChoices/ChoicesComponents/RenderFinshedPage";
import RenderRowComponent from "./ComponentChoices/ChoicesComponents/RenderRowComponent";
import RenderFinishedPage from "./ComponentChoices/ChoicesComponents/RenderFinshedPage";

const EditPageFlow = ({finishedBodyRows, finishedRowIdState, finishedNavbar, finishedSelectedFooter, finishedFooterState}) => {
  const dispatch = useDispatch();
  const galleryState = useSelector((state) => state.gallerys);
  const leftAlignedState = useSelector((state) => state.leftAligneds);
  const rightAlignedState = useSelector((state) => state.rightAligneds);
  const centerAlignedState = useSelector((state) => state.centerAligneds);
  const existingPages = useSelector((state) => state.existingPages);
  const [activeKeys, setActiveKeys] = useState(["0"]);
  const [selectedNavbar] = useState(
    finishedNavbar || {
      id: 1,
      name: "Navbar1",
      navComponent: {
        componentName: "Navbar1",
        props: {
          companyName: "Company",
        },
      },
    }
  );
  const [prevSelection, setPrevSelection] = useState(null);
  const [stage, setStage] = useState(2);
  const [selectedBodyRow, setSelectedBodyRow] = useState(null);
  const [selectedFooter] = useState(
    finishedSelectedFooter || {
      id: 1,
      name: "Footer1",
      footComponent: {
        componentName: "Footer1",
        props: {
          companyName: `ACME`,
          link1Title: "First Page",
          link1: "testhome",
          link2Title: "Second Page",
          link2: "",
          link3Title: "Third Page",
          link3: "",
          link4Title: "Fourth Page",
          link4: "",
          privacyPolicy: "Privacy Policy",
          privacyPolicyLink: "",
          termsOfService: "Terms of Service",
          termsOfServiceLink: "",
          socialLinkIG: "reducer",
          socialLinkFB: "",
          socialLinkX: "",
          socialLinkPIN: "",
          socialLinkTik: "",
          socialLinkLI: "",
          streetAddress: "Street Address",
          cityStateZip: "City, State, Zip",
          phoneNumber: "800-555-5555",
          email: "info@email.com",
          companyFooter: "2018 Acme. All rights reserved.",
          madeIn: "Made in Texas",
          text1:
            "Change the color to match your band or vision, add your logo, choose the perfect thumbnail, remove the playbar, add speed controls, and more",
        },
      },
    }
  );
  const [componentPreview, setComponentPreview] = useState(null);
  const [showOverlay, setShowOverlay] = useState(true);
  const [pageName, setPageName] = useState("");
  const [selectedPageType, setSelectedPageType] = useState("");
  const rowId = useSelector((state) => state.rowId);
  const [showPreview, setShowPreview] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const selectedBodyRows = useSelector((state) => finishedBodyRows || state.bodyRows);
  const [newAccordionItems, setNewAccordionItems] = useState([]);
  const [countTabs, setCountTabs] = useState(2);
  const footerState = useSelector(
    (state) => finishedFooterState || state.footerStates
  );
  const rowIdState = useSelector((state) => finishedRowIdState || state.rowIdState);
  const handleAccordionSelect = (eventKey) => {
    if (activeKeys.includes(eventKey)) {
      setActiveKeys(activeKeys.filter((key) => key !== eventKey));
    } else {
      setActiveKeys([...activeKeys, eventKey]);
    }
  };

  const handleAddAccordionItem = () => {
    const newItem = {
      id: Date.now(),
    };
    newItem.eventKey = newItem.id.toString();
    setNewAccordionItems([...newAccordionItems, newItem]);
    setActiveKeys((prevActiveKeys) => {
      return [...prevActiveKeys, newItem.eventKey];
    });
    setSelectedBodyRow(null);
    setCountTabs(countTabs + 1);
    setShowOverlay(false);
  };

  const handleRemoveAccordionItem = (itemId) => {
    setNewAccordionItems((prevItems) => {
      setSelectedBodyRow("Not empty");
      return prevItems.filter((item) => item.id !== itemId);
    });
    setShowOverlay(true);
  };

  const pageTypes = [
    "Home",
    "About Us",
    "Contact Us",
    "Services",
    "Portfolio",
    "Blog",
    "FAQ",
    "Testimonials",
    "Shop",
    "Events",
  ];

  const renderAccordionItems = () => {
    const items = newAccordionItems.map((item) => {
      const matchingGallery = galleryState
        ? galleryState.find((gallery) => gallery.name === item.name)
        : null;
      const matchingLeftAligned = leftAlignedState
        ? leftAlignedState.find((leftAligned) => leftAligned.name === item.name)
        : null;
      const matchingRightAligned = leftAlignedState
        ? rightAlignedState.find(
            (rightAligned) => rightAligned.name === item.name
          )
        : null;
      const matchingCenterAligned = centerAlignedState
        ? centerAlignedState.find(
            (centerAligned) => centerAligned.name === item.name
          )
        : null;
      const prevRowIdState = rowIdState
        ? rowIdState.find((rowIdState) => rowIdState.rowId === item.rowId)
        : null;
      if (matchingGallery) {
        return (
          <Accordion.Item eventKey={item.eventKey} key={item.eventKey}>
            <Accordion.Header>Edit Pattern</Accordion.Header>
            <Accordion.Body>
              <div
                className={`${
                  selectedBodyRow ? "d-none" : "d-flex justify-content-end"
                }`}
              ></div>
              <PagePatternChoices
                setSelectedBodyRow={setSelectedBodyRow}
                selectedBodyRow={selectedBodyRow}
                selectedBodyRows={selectedBodyRows}
                setRowId={setRowId}
                rowId={`${item.rowId ? item.rowId : rowId}`}
                existingRowId={item.rowId}
                itemId={item.id}
                handleRemoveAccordionItem={handleRemoveAccordionItem}
                slideName={"image-patterns"}
                prevSelection={item.name}
                setPrevSelection={setPrevSelection}
                stage={stage}
                setStage={setStage}
                prevRowIdState={prevRowIdState.bodyState}
              />
            </Accordion.Body>
          </Accordion.Item>
        );
      } else if (matchingLeftAligned) {
        return (
          <Accordion.Item eventKey={item.eventKey} key={item.eventKey}>
            <Accordion.Header>Edit Pattern</Accordion.Header>
            <Accordion.Body>
              <div
                className={`${
                  selectedBodyRow ? "d-none" : "d-flex justify-content-end"
                }`}
              ></div>
              <PagePatternChoices
                setSelectedBodyRow={setSelectedBodyRow}
                selectedBodyRow={selectedBodyRow}
                selectedBodyRows={selectedBodyRows}
                setRowId={setRowId}
                rowId={`${item.rowId ? item.rowId : rowId}`}
                existingRowId={item.rowId}
                itemId={item.id}
                handleRemoveAccordionItem={handleRemoveAccordionItem}
                slideName={"left-aligned"}
                prevSelection={item.name}
                setPrevSelection={setPrevSelection}
                stage={stage}
                setStage={setStage}
                prevRowIdState={prevRowIdState.bodyState}
              />
            </Accordion.Body>
          </Accordion.Item>
        );
      } else if (matchingRightAligned) {
        return (
          <Accordion.Item eventKey={item.eventKey} key={item.eventKey}>
            <Accordion.Header>Edit Pattern</Accordion.Header>
            <Accordion.Body>
              <div
                className={`${
                  selectedBodyRow ? "d-none" : "d-flex justify-content-end"
                }`}
              ></div>
              <PagePatternChoices
                setSelectedBodyRow={setSelectedBodyRow}
                selectedBodyRow={selectedBodyRow}
                selectedBodyRows={selectedBodyRows}
                setRowId={setRowId}
                rowId={`${item.rowId ? item.rowId : rowId}`}
                existingRowId={item.rowId}
                itemId={item.id}
                handleRemoveAccordionItem={handleRemoveAccordionItem}
                slideName={"right-aligned"}
                prevSelection={item.name}
                setPrevSelection={setPrevSelection}
                stage={stage}
                setStage={setStage}
                prevRowIdState={prevRowIdState.bodyState}
              />
            </Accordion.Body>
          </Accordion.Item>
        );
      } else if (matchingCenterAligned) {
        return (
          <Accordion.Item eventKey={item.eventKey} key={item.eventKey}>
            <Accordion.Header>Edit Pattern</Accordion.Header>
            <Accordion.Body>
              <div
                className={`${
                  selectedBodyRow ? "d-none" : "d-flex justify-content-end"
                }`}
              ></div>
              <PagePatternChoices
                setSelectedBodyRow={setSelectedBodyRow}
                selectedBodyRow={selectedBodyRow}
                selectedBodyRows={selectedBodyRows}
                setRowId={setRowId}
                rowId={`${item.rowId ? item.rowId : rowId}`}
                existingRowId={item.rowId}
                itemId={item.id}
                handleRemoveAccordionItem={handleRemoveAccordionItem}
                slideName={"center-aligned"}
                prevSelection={item.name}
                setPrevSelection={setPrevSelection}
                stage={stage}
                setStage={setStage}
                prevRowIdState={prevRowIdState.bodyState}
              />
            </Accordion.Body>
          </Accordion.Item>
        );
      } else {
        return (
          <Accordion.Item eventKey={item.eventKey} key={item.eventKey}>
            <Accordion.Header>Add Another Pattern</Accordion.Header>
            <Accordion.Body>
              <div
                className={`${
                  selectedBodyRow ? "d-none" : "d-flex justify-content-end"
                }`}
              ></div>
              <PagePatternChoices
                setSelectedBodyRow={setSelectedBodyRow}
                selectedBodyRow={selectedBodyRow}
                selectedBodyRows={selectedBodyRows}
                setRowId={setRowId}
                rowId={`${item.rowId ? item.rowId : rowId}`}
                existingRowId={item.rowId}
                itemId={item.id}
                setPrevSelection={setPrevSelection}
                setStage={setStage}
                handleRemoveAccordionItem={handleRemoveAccordionItem}
              />
            </Accordion.Body>
          </Accordion.Item>
        );
      }
    });
    return items;
  };

  const closePreview = () => {
    setShowPreview(false);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const openModal = (X) => {
    setComponentPreview(X);
    setShowModal(true);
  };

  // filter through reducers to find matching name, assign rowId, remove row from bodyRows
  const editableAccord = (x) => {
    const newItem = {
      id: 3,
      rowId: x.rowId,
      name: x.name,
    };
    newItem.eventKey = newItem.id.toString();
    setNewAccordionItems([...newAccordionItems, newItem]);
    setActiveKeys((prevActiveKeys) => {
      // Add the event key to activeKeys
      return [...prevActiveKeys, newItem.eventKey];
    });
    setSelectedBodyRow(null);
    setCountTabs(countTabs + 1);
    setShowOverlay(false);
    dispatch(removeBodyRow(x.rowId));
  };

  const renderTooltipEdit = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Edit This Pattern
    </Tooltip>
  );
  const renderTooltipView = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Live Preview
    </Tooltip>
  );

  const logExisting = () => {
    console.log("***************")
    console.log(existingPages);
    console.log("selectedNavbar", selectedNavbar)
    console.log("bodyrows", selectedBodyRows)
    console.log("footer", selectedFooter)
    console.log("idstate", rowIdState)
    console.log("footerState", footerState)
  };

  const handlePageTypeSelect = (pageType) => {
    setSelectedPageType(pageType);
  };

  useEffect(() => {
    let updatedKeys = [...activeKeys];

    if (!selectedNavbar) {
      updatedKeys = updatedKeys.filter((key) => key !== "1");
    } else if (!activeKeys.includes("1")) {
      updatedKeys.push("1");
    }

    if (!selectedNavbar || !selectedBodyRow) {
      updatedKeys = updatedKeys.filter((key) => key !== "2");
    } else if (!activeKeys.includes("2")) {
      updatedKeys.push("2");
    }
    setActiveKeys(updatedKeys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNavbar, selectedBodyRow, selectedFooter, rowId]);

  return (
    <>
      <Container fluid className="d-flex flex-column min-vh-100">
        <Row className="d-flex align-items-center">
          <Col sm={3}>
            <DashboardHeader>Edit PAGENAMEVARIABLE</DashboardHeader>
          </Col>
          <Col sm={3}>
            <FormControl
              type="text"
              placeholder={pageName}
              value={pageName}
              onChange={(e) => setPageName(e.target.value)}
            />
          </Col>
          <Col sm={3} className="d-flex justify-content-center">
            <DropdownButton
              id="page-type-dropdown"
              title={selectedPageType || "Select Page Type"}
            >
              {pageTypes.map((pageType) => (
                <Dropdown.Item
                  key={pageType}
                  onClick={() => handlePageTypeSelect(pageType)}
                >
                  {pageType}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </Col>
          <Col sm={3} className="d-flex justify-content-end">
            <Button
              onClick={() =>
                console.log({
                  companyName: "Test",
                  pageType: selectedPageType,
                  pageName: pageName,
                  selectedNavbar,
                  selectedBodyRows,
                  selectedFooter,
                  footerState,
                  rowIdState,
                })
              }
            >
              Publish
            </Button>
            <Button onClick={() => logExisting()}>Log State</Button>
          </Col>
        </Row>
        <Row className="flex-grow-1 mx-0">
          <Col md={12} className="mb-5 px-0">
            <Accordion
              defaultActiveKey={activeKeys}
              activeKey={activeKeys}
              onSelect={handleAccordionSelect}
            >
              {(selectedBodyRow ||
                countTabs === 1 ||
                selectedBodyRows.length > 0) && (
                <Accordion.Item
                  eventKey="1"
                  className={`${selectedNavbar ? "" : "d-none"}`}
                >
                  <Accordion.Header>Page Patterns</Accordion.Header>
                  <Accordion.Body>
                    {!selectedBodyRow && countTabs === 1 && (
                      <PagePatternChoices
                        setSelectedBodyRow={setSelectedBodyRow}
                        selectedBodyRow={selectedBodyRow}
                        selectedBodyRows={selectedBodyRows}
                        setRowId={setRowId}
                        rowId={rowId}
                        itemId={1}
                        prevSelection={prevSelection}
                        setPrevSelection={setPrevSelection}
                        setStage={setStage}
                      />
                    )}

                    {(countTabs > 1 || selectedBodyRow) &&
                      selectedBodyRows
                        .slice() // Create a copy of the array to avoid modifying the original
                        .sort((a, b) => a.rowId - b.rowId) // Sort by ascending rowId
                        .map((selectedBodyRow) => (
                          <div key={selectedBodyRow.rowId}>
                            <div className="crud-hover">
                              {rowIdState.map((rowIdStateItem) => {
                                if (
                                  rowIdStateItem.rowId === selectedBodyRow.rowId
                                ) {
                                  return RenderRowComponent(
                                    selectedBodyRow.bodyRowComponent
                                      .componentName,
                                    { holderState: rowIdStateItem.bodyState },
                                    false,
                                    false,
                                    rowIdStateItem.rowId
                                  );
                                }
                                return null;
                              })}
                              {showOverlay && (
                                <div className="crud-hover-overlay d-flex justify-content-center align-items-center">
                                  <OverlayTrigger
                                    placement="left"
                                    delay={{ show: 100, hide: 150 }}
                                    overlay={renderTooltipView}
                                  >
                                    <button className="view-button hover">
                                      <Eye
                                        onClick={() =>
                                          openModal(selectedBodyRow)
                                        }
                                        size={20}
                                      />
                                    </button>
                                  </OverlayTrigger>

                                  <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 100, hide: 150 }}
                                    overlay={renderTooltipEdit}
                                  >
                                    <button className="edit-button">
                                      <Pencil
                                        onClick={() =>
                                          editableAccord(selectedBodyRow)
                                        }
                                        size={20}
                                      />
                                    </button>
                                  </OverlayTrigger>
                                </div>
                              )}
                            </div>

                            {selectedBodyRow.rowId !== 1 && (
                              <div className="d-flex justify-content-end">
                                <Link
                                  onClick={() =>
                                    dispatch(
                                      removeBodyRow(selectedBodyRow.rowId)
                                    )
                                  }
                                  className="text-end"
                                >
                                  <Trash3
                                    size={18}
                                    style={{ color: "black" }}
                                  />
                                </Link>
                              </div>
                            )}
                          </div>
                        ))}
                    {selectedBodyRow && (
                      <div className="new-page-button-container">
                        <PlusSignLeft
                          onClick={() => handleAddAccordionItem()}
                          className="new-pattern-button-left"
                          size={28}
                        />
                        <PlusSignRight
                          onClick={() => handleAddAccordionItem()}
                          className="new-pattern-button-right"
                          size={28}
                        />
                      </div>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              )}

              {renderAccordionItems()}
            </Accordion>
          </Col>
          {/* <StickyPreviewComponent
            setShowPreview={setShowPreview}
            selectedBodyRows={selectedBodyRows}
            selectedFooter={selectedFooter}
            selectedNavbar={selectedNavbar}
          /> */}
        </Row>
      </Container>
      {/* Full Page Preview Modal */}
      <Modal show={showPreview} onHide={closePreview} centered size="xl">
        <Modal.Header className="justify-content-center">
          <Modal.Title>Page Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RenderFinishedPage
            selectedNavbar={selectedNavbar}
            selectedBodyRows={selectedBodyRows}
            selectedFooter={selectedFooter}
            footerState={footerState}
            rowIdState={rowIdState}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closePreview}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for Body Patterns */}
      <Modal show={showModal} onHide={closeModal} centered size="xl">
        <Modal.Header className="justify-content-center">
          <Modal.Title>Pattern Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {componentPreview && (
            <>
              {rowIdState.map((rowIdStateItem) => {
                if (rowIdStateItem.rowId === componentPreview.rowId) {
                  return (
                    <div key={rowIdStateItem.rowId}>
                      {" "}
                      {/* Add this line with a unique key */}
                      {RenderRowComponent(
                        componentPreview.bodyRowComponent.componentName,
                        { holderState: rowIdStateItem.bodyState },
                        false,
                        false,
                        rowIdStateItem.rowId
                      )}
                    </div>
                  );
                }
                return null;
              })}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditPageFlow;
