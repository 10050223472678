import React from "react";
import { Form, Card } from "react-bootstrap";

const LinkFormComponent = ({
  showLink,
  setShowLink,
  linkTitle,
  setLinkTitle,
  linkValue,
  setLinkValue,
  linkNumber,
  setSelectedField,
  selectedField,
}) => {

  return (
    <Card
      className={`col-3 px-0 ${
        selectedField === "link" + linkNumber + "Value" ? "focus-field" : "focus-hover"
      }`}
      onClick={() => setSelectedField("link" + linkNumber + "Value")}
    >
      <Card.Body>
        <Card.Title>Link {linkNumber}</Card.Title>
        <Form.Group>
          <Form.Check
            type="checkbox"
            name="link"
            value={"link" + linkNumber}
            label="Display"
            checked={showLink}
            onChange={(e) => setShowLink(e.target.checked)}
          />
          <Form.Control
            type="text"
            className="my-2"
            placeholder="Link Text"
            value={linkTitle}
            onChange={(e) => setLinkTitle(e.target.value)}
          />
          <Form.Control
            type="text"
            placeholder="Enter URL"
            value={linkValue}
            onChange={(e) => setLinkValue(e.target.value)}
          />
        </Form.Group>
      </Card.Body>
    </Card>
  );
};

export default LinkFormComponent;
