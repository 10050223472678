import { createSlice } from '@reduxjs/toolkit';

const initialState = [
  {
      id: 1,
      name: "Footer1",
      footComponent: {
        componentName: "Footer1",
        props: {
        companyName: `ACME`,
        link1Title: "First Page",
        link1: "testhome",
        link2Title: "Second Page",
        link2: "",
        link3Title: "Third Page",
        link3: "",
        link4Title: "Fourth Page",
        link4: "",
        privacyPolicy: "Privacy Policy",
        privacyPolicyLink: "",
        termsOfService: "Terms of Service",
        termsOfServiceLink: "",
        socialLinkIG: "reducer",
        socialLinkFB: "",
        socialLinkX: "",
        socialLinkPIN: "",
        socialLinkTik: "",
        socialLinkLI: "",
        streetAddress: "Street Address",
        cityStateZip: "City, State, Zip",
        phoneNumber: "800-555-5555",
        email: "info@email.com",
        companyFooter: "2018 Acme. All rights reserved.",
        madeIn: "Made in Texas",
        text1: "Change the color to match your band or vision, add your logo, choose the perfect thumbnail, remove the playbar, add speed controls, and more",
        }
    }},
{
      id: 2,
      name: "Footer2",
      footComponent: {
        componentName: "Footer2",
        props: {
        companyName: `ACME`,
        link1Title: 'First Page',
        link1: 'testhome',
        link2Title: 'Second Page',
        link2: '',
        link3Title: 'Third Page',
        link3: '',
        link4Title: 'Fourth Page',
        link4: '',
        link5Title: 'Fifth Page',
        link5: '',
        privacyPolicy: 'Privacy Policy',
        privacyPolicyLink: '',
        termsOfService: 'Terms of Service',
        termsOfServiceLink: '',
        socialLinkIG: "",
        socialLinkFB: "",
        socialLinkX: "",
        socialLinkPIN: "",
        socialLinkTik: "",
        socialLinkLI: "",
        companyFooter: '2018 Acme. All rights reserved.',
        }
    }},
{
      id: 3,
      name: "Footer3",
      footComponent: {
        componentName: "Footer3",
        props: {
        companyName: `ACME`,
        link1Title: "First Page",
        link1: "testhome",
        link2Title: "Second Page",
        link2: "",
        link3Title: "Third Page",
        link3: "",
        link4Title: "Fourth Page",
        link4: "",
        link5Title: "Fifth Page",
        link5: "",
        language: "English Version",
        legal: "Legal",
        legalLink: "",
        siteMap: "Site Map",
        siteLink: "",
        privacyPolicy: "Privacy Policy",
        privacyPolicyLink: "",
        termsOfService: "Terms of Service",
        termsOfServiceLink: "",
        socialLinkIG: "",
        socialLinkFB: "",
        socialLinkX: "",
        socialLinkPIN: "",
        socialLinkTik: "",
        socialLinkLI: "",
        companyFooter: "2018 Acme. All rights reserved.",
        }
    }},
{
      id: 4,
      name: "Footer4",
      footComponent: {
        componentName: "Footer4",
        props: {
        companyName: `ACME`,
        link1Title: "First Page",
        link1: "testhome",
        link1T1: "First Page",
        link1T1L: "testhome",
        link1T2: "Second Page",
        link1T2L: "testhome",
        link1T3: "Third Page",
        link1T3L: "testhome",
        link1T4: "Fourth Page",
        link1T4L: "testhome",
        link2Title: "Second Page",
        link2: "",
        link2T1: "First Page",
        link2T1L: "testhome",
        link2T2: "Second Page",
        link2T2L: "testhome",
        link2T3: "Third Page",
        link2T3L: "testhome",
        link3Title: "Third Page",
        link3: "",
        link3T1: "First Page",
        link3T1L: "testhome",
        link3T2: "Second Page",
        link3T2L: "testhome",
        link3T3: "Third Page",
        link3T3L: "testhome",
        sendEmail: "info@email.com",
        privacyPolicy: "Privacy Policy",
        privacyPolicyLink: "",
        termsOfService: "Terms of Service",
        termsOfServiceLink: "",
        socialLinkIG: "",
        socialLinkFB: "",
        socialLinkX: "",
        socialLinkPIN: "",
        socialLinkTik: "",
        socialLinkLI: "",
        companyFooter: "2018 Acme. All rights reserved.",
        text1: "Join our Newsletter to stay up to date on features and releases",
        }
    }},
{
      id: 5,
      name: "Footer5",
      footComponent: {
        componentName: "Footer5",
        props: {
        companyName: `ACME`,
        link1Title: "First Page",
        link1: "testhome",
        link1T1: "First Page",
        link1T1L: "testhome",
        link1T2: "Second Page",
        link1T2L: "testhome",
        link1T3: "Third Page",
        link1T3L: "testhome",
        link1T4: "Fourth Page",
        link1T4L: "testhome",
        link2Title: "Second Page",
        link2: "",
        link2T1: "First Page",
        link2T1L: "testhome",
        link2T2: "Second Page",
        link2T2L: "testhome",
        link2T3: "Third Page",
        link2T3L: "testhome",
        link3Title: "Third Page",
        link3: "",
        link3T1: "First Page",
        link3T1L: "testhome",
        link3T2: "Second Page",
        link3T2L: "testhome",
        link3T3: "Third Page",
        link3T3L: "testhome",
        streetAddress: "Street Address",
        cityStateZip: "City, State, Zip",
        phoneNumber: "800-555-5555",
        email: "info@email.com",
        privacyPolicy: "Privacy Policy",
        privacyPolicyLink: "",
        termsOfService: "Terms of Service",
        termsOfServiceLink: "",
        socialLinkIG: "",
        socialLinkFB: "",
        socialLinkX: "",
        socialLinkPIN: "",
        socialLinkTik: "",
        socialLinkLI: "",
        companyFooter: "2018 Acme. All rights reserved.",
        }
    }},
{
      id: 6,
      name: "Footer6",
      footComponent: {
        componentName: "Footer6",
        props: {
        companyName: `ACME`,
        legal: "Legal",
        legalLink: "Legal",
        siteMap: "Site Map",
        siteMapLink: "Site Map",
        privacyPolicy: "Privacy Policy",
        privacyPolicyLink: "",
        termsOfService: "Terms of Service",
        termsOfServiceLink: "",
        socialLinkIG: "",
        socialLinkFB: "",
        socialLinkX: "",
        socialLinkPIN: "",
        socialLinkTik: "",
        socialLinkLI: "",
        companyFooter: "2018 Acme. All rights reserved.",
        }
    }},
{
      id: 7,
      name: "Footer7",
      footComponent: {
        componentName: "Footer7",
        props: {
        companyName: `ACME`,
        privacyPolicy: "Privacy Policy",
        privacyPolicyLink: "",
        termsOfService: "Terms of Service",
        termsOfServiceLink: "",
        companyFooter: "2018 Acme. All rights reserved.",
        }
    }},
{
      id: 8,
      name: "Footer8",
      footComponent: {
        componentName: "Footer8",
        props: {
       companyName: `ACME`,
        legal: "Legal",
        legalLink: "Legal",
        siteMap: "Site Map",
        siteMapLink: "Site Map",
        privacyPolicy: "Privacy Policy",
        privacyPolicyLink: "",
        termsOfService: "Terms of Service",
        termsOfServiceLink: "",
        socialLinkIG: "",
        socialLinkFB: "",
        socialLinkX: "",
        socialLinkPIN: "",
        socialLinkTik: "",
        socialLinkLI: "",
        companyFooter: "2018 Acme. All rights reserved.",
        }
    }},
{
      id: 9,
      name: "Footer9",
      footComponent: {
        componentName: "Footer9",
        props: {
        companyName: `ACME`,
        link1Title: "First Page",
        link1: "testhome",
        link1T1: "First Page",
        link1T1L: "testhome",
        link1T2: "Second Page",
        link1T2L: "testhome",
        link1T3: "Third Page",
        link1T3L: "testhome",
        link1T4: "Fourth Page",
        link1T4L: "testhome",
        link2Title: "Second Page",
        link2: "",
        link2T1: "First Page",
        link2T1L: "testhome",
        link2T2: "Second Page",
        link2T2L: "testhome",
        link2T3: "Third Page",
        link2T3L: "testhome",
        link3Title: "Third Page",
        link3: "",
        link3T1: "First Page",
        link3T1L: "testhome",
        link3T2: "Second Page",
        link3T2L: "testhome",
        link3T3: "Third Page",
        link3T3L: "testhome",
        link4Title: "Fourth Page",
        link4: "",
        link5Title: "Fifth Page",
        link5: "",
        link6Title: "Sixth Page",
        link6: "",
        link7Title: "Seventh Page",
        link7: "",
        sendEmail: "info@email.com",
        privacyPolicy: "Privacy Policy",
        privacyPolicyLink: "",
        termsOfService: "Terms of Service",
        termsOfServiceLink: "",
        socialLinkIG: "",
        socialLinkFB: "",
        socialLinkX: "",
        socialLinkPIN: "",
        socialLinkTik: "",
        socialLinkLI: "",
        companyFooter: "2018 Acme. All rights reserved.",
        text1: "Join our Newsletter to stay up to date on features and releases",
        }
    }},
  ];

const footerSlice = createSlice({
  name: "footer",
  initialState,
  reducers: {
    addFooter: (state, action) => {
      // Add a new webpage footer to the state

      state.push(action.payload);
    },
    removeFooter: (state, action) => {
      // Remove a webpage Footer from the state by its ID
      return state.filter((footer) => footer.id !== action.payload);
    },
    selectFooter: (state, action) => {
      // Update the selected footer in the store
      return state.filter((footer) => footer.id === action.payload);
    },
    // NOT CURRENTLY IN USE - MAY NOT BE APPLICABLE
    updateFooterHtml: (state, action) => {
      const { id, html, name } = action.payload;
      const footerToUpdate = state.find((footer) => footer.id === id);
      if (footerToUpdate) {
        footerToUpdate.html = html;
        footerToUpdate.name = name;
      }
    },
    clearSelectedFooter: (state) => {
      state.selectedFooter = null;
    },
    updateCompanyName: (state, action) => {
      state.companyName = action.payload;
    },
    setFooterState: (state, action) => {
      return action.payload;
    },
  },
});

export const { addFooter, removeFooter, selectFooter, updateFooterHtml, updateCompanyName } = footerSlice.actions;

export default footerSlice.reducer;
