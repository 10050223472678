
export async function publishPage(pageObject) {
    const response = await fetch(`${window.env.REACT_APP_API_URL}api/publishPage`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ sessionStorage.getItem('accessToken')
        },
        body: JSON.stringify(pageObject),
    });
    return response;
}

export async function getPage(pageName) {
    const response = await fetch(`${window.env.REACT_APP_API_URL}api/getPage/${pageName}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ sessionStorage.getItem('accessToken')
        }
    });
    return response;
}

export async function getAllPages() {
    const response = await fetch(`${window.env.REACT_APP_API_URL}api/getAllPages`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Beared '+ sessionStorage.getItem('accessToken')
        }
    });
    return response;
}

export async function deletePage(pageName) {
    const response = await fetch(`${window.env.REACT_APP_API_URL}api/delete/${pageName}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ sessionStorage.getItem('accessToken')
        }
    });
    return response;
}