import React, { useEffect, useState } from 'react';
import { Row, Col, Image } from 'react-bootstrap';

const CA19 = ({ isPreview, holderState, selectedField }) => {
  const [centerAlign19, setCenterAlign19] = useState({
    text1:
      holderState?.text1Value !== undefined
        ? holderState.text1Value
        : "Long headline to turn your visitors into users",
    showText1:
      holderState?.showText1 !== undefined ? holderState.showText1 : true,
    text2:
      holderState?.text2Value !== undefined
        ? holderState.text2Value
        : "With the new AirPlay 2, you can control your home audio system and the speakers throughout your house. You can play a song in the living room and your kitchen at the same time.",
    showText2:
      holderState?.showText2 !== undefined ? holderState.showText2 : true,
    showImg1: holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
    imgLink1: holderState?.imgLink1 !== undefined ? holderState.imgLink1 : "",
    imgSrc1:
      holderState?.imgSrc1 !== undefined
        ? holderState.imgSrc1
        : "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt1: "Placeholder Image Text",
  });

  const imageContainerStyle = {
    display: "flex",
    alignItems: "center",
    height: "100%",
    width: "100%",
    maxHeight: isPreview ? "5rem" : "22rem",
    borderRadius: "1rem",
    overflow: "hidden",
  };
  const imageStyle = {
    objectFit: "cover",
    height: "22rem",
    width: "100%",
    borderRadius: "1rem",
  };

  useEffect(() => {
    setCenterAlign19((prevCA19) => ({
      ...prevCA19,
      text1:
        holderState?.text1Value !== undefined
          ? holderState.text1Value
          : "Long headline to turn your visitors into users",
      showText1:
        holderState?.showText1 !== undefined ? holderState.showText1 : true,
      text2:
        holderState?.text2Value !== undefined
          ? holderState.text2Value
          : "With the new AirPlay 2, you can control your home audio system and the speakers throughout your house. You can play a song in the living room and your kitchen at the same time.",
      showText2:
        holderState?.showText2 !== undefined ? holderState.showText2 : true,
      showImg1:
        holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
      imgLink1: holderState?.imgLink1 !== undefined ? holderState.imgLink1 : "",
      imgSrc1:
        holderState?.imgSrc1 !== undefined
          ? holderState.imgSrc1
          : "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgAlt1: "Placeholder Image Text",
    }));
  }, [
    holderState?.showText1,
    holderState?.text1Value,
    holderState?.showText2,
    holderState?.text2Value,
    holderState?.showImg1,
    holderState?.imgLink1,
    holderState?.imgSrc1,
  ]);

  return (
    <Row
      sm={12}
      md={12}
      lg={10}
      xl={10}
      xxl={8}
      style={{ "--bs-gutter-x": "0" }}
      className={`d-flex justify-content-center bg-white ${
        isPreview ? "p-3" : "p-5"
      }`}
    >
      <Col
        xs={12}
        md={12}
        lg={10}
        xl={10}
        className="d-flex flex-column align-items-center justify-content-center"
      >
        {centerAlign19.showImg1 && (
          <Col
            className={`d-flex flex-column text-center justify-content-center ${
              selectedField === "imgSrc1" ? "focus-field" : ""
            }`}
            style={imageContainerStyle}
          >
            {centerAlign19.imgLink1 !== "" && (
              <a
                href={"https://" + centerAlign19.imgLink1}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  src={centerAlign19.imgSrc1}
                  alt={centerAlign19.imgAlt1}
                  style={imageStyle}
                  fluid
                />
              </a>
            )}
            {centerAlign19.imgLink1 === "" && (
              <Image
                src={centerAlign19.imgSrc1}
                alt={centerAlign19.imgAlt1}
                style={imageStyle}
                fluid
              />
            )}
          </Col>
        )}
        <Col
          xs={12}
          sm={10}
          md={10}
          lg={7}
          xl={6}
          xxl={6}
          className="text-center pt-3"
        >
          {centerAlign19.showText1 && (
            <h5
              className={`fw-semibold ${isPreview ? "preview-scaling" : ""} ${
                selectedField === "text1" ? "focus-field" : ""
              }`}
            >
              {centerAlign19.text1}
            </h5>
          )}
          {centerAlign19.showText2 && (
            <small
              className={`mb-0 ${isPreview ? "preview-scaling" : "pt-3"} ${
                selectedField === "text2" ? "focus-field" : ""
              }`}
            >
              {centerAlign19.text2}
            </small>
          )}
        </Col>
      </Col>
    </Row>
  );
};

export default CA19