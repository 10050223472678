import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { ChevronRight } from "react-bootstrap-icons";

const CA14 = ({ isPreview, holderState, selectedField }) => {
  const [centerAlign14, setCenterAlign14] = useState({
    text1:
      holderState?.text1Value !== undefined
        ? holderState.text1Value
        : "Medium Length Title",
    showText1:
      holderState?.showText1 !== undefined ? holderState.showText1 : true,
    link1Title:
      holderState?.link1Title !== undefined ? holderState.link1Title : "Link",
    link1Value:
      holderState?.link1Value !== undefined ? holderState.link1Value : "",
    showLink1:
      holderState?.showLink1 !== undefined ? holderState.showLink1 : true,
  });

  useEffect(() => {
    setCenterAlign14((prevCA14) => ({
      ...prevCA14,
      text1:
        holderState?.text1Value !== undefined
          ? holderState.text1Value
          : "With the new AirPlay 2, you can control your home audio system and the speakers throughout your house.4 You can play a song in the living room and your kitchen at the same time. Adjust the volume in any room.",
      showText1:
        holderState?.showText1 !== undefined ? holderState.showText1 : true,
      link1Title:
        holderState?.link1Title !== undefined ? holderState.link1Title : "Link",
      link1Value:
        holderState?.link1Value !== undefined ? holderState.link1Value : "",
      showLink1:
        holderState?.showLink1 !== undefined ? holderState.showLink1 : true,
    }));
  }, [
    holderState?.text1Value,
    holderState?.showText1,
    holderState?.link1Title,
    holderState?.link1Value,
    holderState?.showLink1,
  ]);

  return (
    <Row
      sm={12}
      md={12}
      lg={10}
      xl={10}
      xxl={8}
      style={{ "--bs-gutter-x": "0" }}
      className={`d-flex justify-content-center bg-white ${
        isPreview ? "p-3" : "p-5"
      }`}
    >
      <Col
        xs={12}
        md={12}
        lg={10}
        xl={10}
        className="d-flex flex-column align-items-center justify-content-center"
      >
        <Col
          xs={12}
          sm={10}
          md={10}
          lg={7}
          xl={6}
          xxl={6}
          className="pt-3 text-center"
        >
          {centerAlign14.showText1 && (
            <p
              className={`${isPreview ? "preview-scaling" : "pt-3"} ${
                selectedField === "text1" ? "focus-field" : ""
              }`}
            >
              {centerAlign14.text1}
            </p>
          )}
        </Col>
        <Col xxl={7} className={`text-center ${isPreview ? "" : "py-3"} `}>
          {centerAlign14.showLink1 && (
            <a
              className={`fw-bold link-offset-2 link-underline link-underline-opacity-0 link-underline-opacity-75-hover ${
                isPreview ? "preview-scaling" : ""
              } ${selectedField === "link1Value" ? "focus-field" : ""}`}
              href={"https://" + centerAlign14.link1Value}
              target="_blank"
              rel="noopener noreferrer"
            >
              {centerAlign14.link1Title} <ChevronRight />
            </a>
          )}
        </Col>
      </Col>
    </Row>
  );
};

export default CA14;
