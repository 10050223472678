import { createSlice } from '@reduxjs/toolkit';

const initialState = [
  {
      id: 'image-patterns',
      name: "IMAGE GALLERIES",
      bodyRowComponent: {
        componentName: "Gallery1",
    }},
  {
      id: 'left-aligned',
      name: "LEFT ALIGNED COVERS",
      bodyRowComponent: {
        componentName: "LA1",
    }},
  {
      id: 'right-aligned',
      name: "RIGHT ALIGNED COVERS",
      bodyRowComponent: {
        componentName: "RA1",
    }},
  {
      id: 'center-aligned',
      name: "CENTER ALIGNED COVERS",
      bodyRowComponent: {
        componentName: "CA1",
    }},
  ];

const bodyOptionSlice = createSlice({
  name: 'bodyOption',
  initialState,
  reducers: {
    addBodyOption: (state, action) => {
      state.push(action.payload);
    },
    removeBodyOption: (state, action) => {
      return state.filter((bodyOption) => bodyOption.id !== action.payload);
    },
    selectBodyOption: (state, action) => {
      return state.filter((bodyOption) => bodyOption.id === action.payload)
    },
  },
  
});

export const { addBodyOption, removeBodyOption, selectBodyOption, } = bodyOptionSlice.actions;

export default bodyOptionSlice.reducer;
