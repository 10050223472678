import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";

const CA8 = ({ isPreview, holderState, selectedField }) => {
  const [centerAlign8, setCenterAlign8] = useState({
    text1:
      holderState?.text1Value !== undefined
        ? holderState.text1Value
        : "Long headline to turn your visitors into users",
    showText1:
      holderState?.showText1 !== undefined ? holderState.showText1 : true,
    text2:
      holderState?.text2Value !== undefined
        ? holderState.text2Value
        : "With the new AirPlay 2, you can control your home audio system and the speakers throughout your house.4 You can play a song in the living room and your kitchen at the same time.",
    showText2:
      holderState?.showText2 !== undefined ? holderState.showText2 : true,
    button1:
      holderState?.button1 !== undefined ? holderState.button1 : "Action",
    button1Link:
      holderState?.button1Link !== undefined ? holderState.button1Link : "",
    showButton1:
      holderState?.showButton1 !== undefined ? holderState.showButton1 : true,
  });

  useEffect(() => {
    setCenterAlign8((prevCA8) => ({
      ...prevCA8,
      text1:
        holderState?.text1Value !== undefined
          ? holderState.text1Value
          : "Long headline to turn your visitors into users",
      showText1:
        holderState?.showText1 !== undefined ? holderState.showText1 : true,
      text2:
        holderState?.text2Value !== undefined
          ? holderState.text2Value
          : "With the new AirPlay 2, you can control your home audio system and the speakers throughout your house.4 You can play a song in the living room and your kitchen at the same time.",
      showText2:
        holderState?.showText2 !== undefined ? holderState.showText2 : true,
      button1:
        holderState?.button1 !== undefined ? holderState.button1 : "Action",
      button1Link:
        holderState?.button1Link !== undefined ? holderState.button1Link : "",
      showButton1:
        holderState?.showButton1 !== undefined ? holderState.showButton1 : true,
    }));
  }, [
    holderState?.showText1,
    holderState?.text1Value,
    holderState?.showText2,
    holderState?.text2Value,
    holderState?.button1,
    holderState?.button1Link,
    holderState?.showButton1,
  ]);

  return (
    <Row
      sm={12}
      md={12}
      lg={10}
      xl={10}
      xxl={8}
      style={{ "--bs-gutter-x": "0" }}
      className={`d-flex justify-content-center bg-white ${
        isPreview ? "p-3" : "p-5"
      }`}
    >
      <Col
        xs={12}
        md={12}
        lg={10}
        xl={10}
        className="d-flex flex-column align-items-center justify-content-center"
      >
        <Col
          xs={12}
          sm={10}
          md={10}
          lg={7}
          xl={6}
          xxl={6}
          className="pt-3 text-center"
        >
          {centerAlign8.showText1 && (
            <h2
              className={`fw-bold ${isPreview ? "preview-scaling" : ""} ${
                selectedField === "text1" ? "focus-field" : ""
              }`}
            >
              {centerAlign8.text1}
            </h2>
          )}
          {centerAlign8.showText2 && (
            <p
              className={`px-3 py-2 ${
                isPreview ? "preview-scaling mb-1" : ""
              } ${selectedField === "text2" ? "focus-field" : ""}`}
            >
              {centerAlign8.text2}
            </p>
          )}
          {centerAlign8.showButton1 && (
            <Button
              className={`h-auto ${isPreview ? "preview-scaling p-1" : ""} ${
                selectedField === "button1" ? "focus-field" : ""
              }`}
              href={"https://" + centerAlign8.button1Link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {centerAlign8.button1}
            </Button>
          )}
        </Col>
      </Col>
    </Row>
  );
};

export default CA8;
