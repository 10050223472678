import React from 'react';
import { Link } from 'react-router-dom';

const ButtonLink = ({ to, children }) => {
// Combined React Routers Link Functionality into a button to use over bootstraps Button href.

    const buttonLink = {
        display: 'inline-block',
        padding: '8px 16px',
        backgroundColor: '#198754',
        color: '#fff',
        border: 'none',
        borderRadius: '.5rem',
        textAlign: 'center',
        textDecoration: 'none',
        fontSize:'16px',
        margin: '4px 2px',
        cursor: 'pointer',
      };
    
  return (
    <Link to={to} style={buttonLink}>
      {children}
    </Link>
  );
};

export default ButtonLink;
