import React from "react";
import { Navbar, Nav, NavDropdown, Button } from "react-bootstrap";
import "../../ApplicationComponents/ServiceComponents/serviceComponents.css";
function Navbar1({isPreview}) {
  return (
    <Navbar
      expand="lg"
      className={`bg-white border overflow-hidden ${
        isPreview ? "preview-scaling" : ""
      }`}
    >
      <Navbar.Brand
        className={`${isPreview ? "preview-scaling-logo ps-3" : "ps-4"}`}
      >
        ACME
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <div className="col-5 justify-content-end">
          <Nav>
            <Nav.Link href="#">First</Nav.Link>
            <Nav.Link href="#">Second</Nav.Link>
            <Nav.Link href="#">Third</Nav.Link>
            <NavDropdown title="Fourth" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">
                Dropdown Item 1
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Dropdown Item 2
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">
                Dropdown Item 3
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="#">Fifth</Nav.Link>
            <Nav.Link href="#">Sixth</Nav.Link>
          </Nav>
        </div>
        <div
          className={`col-2 offset-5 d-flex justify-content-center ${
            isPreview ? "preview-scaling" : ""
          }`}
        >
          {/* <div className="col-2 offset-5 d-flex justify-content-center"> */}
          <Button variant="primary" className="me-4">
            Action
          </Button>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Navbar1;
