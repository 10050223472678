import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import ButtonLink from "../ServiceComponents/ButtonLink";
import DashboardHeader from "../ServiceComponents/DashboardHeader";

const NoPages = () => {

  return (
    <Container fluid className="d-flex flex-column min-vh-100">
      <Row>
        <Col className="bg-seconday">
            <DashboardHeader>DASHBOARD</DashboardHeader>
            <h2 className="display-6 ps-4">Your site has no pages.</h2>
            <h6 className="ps-4">Create pages by using provided patterns.</h6>
        </Col>     
      </Row>
      <Row className="px-4 mb-4">
        <Col md={6} className="mb-3">
          <Card className="my-4">
            <Card.Body className="d-flex p-0">
              <div className="first-section col-4 d-flex justify-content-center align-items-center bg-secondary text-white">
                <h1>Hm</h1>
              </div>
              <div className="second-section col-8 px-4 py-2 mb-3">
                <h5 className="fw-semibold">Home Pages</h5>
                <p>
                  The Home Page serves as the entry point to the entire website
                  and plays a crucial role in providing visitors with a first
                  impression of the site's content, purpose, and design.
                </p>
                <ButtonLink to="/dashboard/homepage">Start a Home Page</ButtonLink>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} className="mb-3">
          <Card className="my-4">
            <Card.Body className="d-flex p-0">
              <div className="first-section col-4 d-flex justify-content-center align-items-center bg-secondary text-white">
                <h1>Abt</h1>
              </div>
              <div className="second-section col-8 px-4 py-2 mb-3">
                <h5 className="fw-semibold">About Pages</h5>
                <p>
                  The About Page is a crutical section of a website that provides visitors with information about the website's owner, organization, or the individuals behind it.
                </p>
                <ButtonLink to="/dashboard/aboutpage">Start an About Page</ButtonLink>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} className="mb-3">
          <Card className="my-4">
            <Card.Body className="d-flex p-0">
              <div className="first-section col-4 d-flex justify-content-center align-items-center bg-secondary text-white">
                <h1>Cntc</h1>
              </div>
              <div className="second-section col-8 px-4 py-2 mb-4">
                <h5 className="fw-semibold">Contact Pages</h5>
                <p>
                  The Contact Page provides visitors with a means to get in touch with the website owner, organization, or individuals behind the site.
                </p>
                <ButtonLink to="/dashboard/contactpage">Start a Contact Page</ButtonLink>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default NoPages;