import React, { useRef } from "react";
import { Row, Col, InputGroup, Button } from "react-bootstrap";

const ImageUpload = ({ handleFileChange, setSelectedImage, imgSrcString }) => {
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
    setSelectedImage(imgSrcString)
  };

  return (
    <Row className="d-flex justify-content-center">
      <Col xxl={8} className="mx-0 px-0">
        <input
          ref={fileInputRef}
          type="file"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        <InputGroup className="my-3">
          <Button
            id="button-addon2"
            variant="light"
            className="border text-dark fw-semibold px-4 mx-auto"
            onClick={handleButtonClick}
          >
            Upload Image
          </Button>
        </InputGroup>
      </Col>
    </Row>
  );
};

export default ImageUpload;
