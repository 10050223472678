import React from 'react';
import './loading.css';
import { Spinner } from 'react-bootstrap';

const LoadingLarge = ({ active, children }) => {
  return (
    active && (
      <div className='lg-screen-overlay lg-screen-overlay-transition'>
        <Spinner animation='border' variant='light' style={{ height: '5em', width: '5em', borderWidth: '.5em' }} />
        <p className='p-5 text-light fs-2'>{children ? children : ''}</p>
      </div>
    )
  );
};

export default LoadingLarge;
