import React from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Form1 = () => {
  return (
    <Row sm={12} md={12} lg={10} xl={10} xxl={8} style={{"--bs-gutter-x":'0'}} className='d-flex justify-content-center p-5 bg-white'>
        <Col xs={12} md={12} lg={10} xl={10} className="d-flex flex-column align-items-center justify-content-center">
        
            <Col xs={12} sm={8} md={5} lg={5} xl={4} xxl={3} className='d-flex flex-column gap-3'>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Control id='email' type="email" placeholder="Email" className='bg-light' autoComplete="email" />
                    </Form.Group>
                    <Button type='submit' className='d-flex w-100 justify-content-center'>Action</Button>
                </Form>
                <p className='text-center'>
                    <small>By signing in you agree with the {}
                        <Link href='#' className='link-offset-2 link-underline link-underline-opacity-0 link-underline-opacity-75-hover text-secondary'>
                            Terms and Conditions
                        </Link> and {}
                        <Link href='#' className='link-offset-2 link-underline link-underline-opacity-0 link-underline-opacity-75-hover text-secondary'>
                            Privacy Policy
                        </Link>
                    </small>
                </p>
            </Col>

        </Col>
    </Row>
  )
}

export default Form1