import React from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap';

const Form10 = () => {

  return (
    <Row sm={12} md={12} lg={10} xl={10} xxl={8} style={{"--bs-gutter-x":'0'}} className='d-flex justify-content-center p-5 bg-white'>
        <Col className='d-flex flex-row justify-content-center flex-wrap'>
            <Col sm={12} md={6} xxl={4} className='d-flex flex-column p-5 border-end'>
                <h4>Medium length headline</h4>
                <p>By creating an Account on our service, you agree to subscribe to newsletters.</p>
                <Form>
                    <Form.Group className="mb-3 w-75">
                        <Form.Control id='email' type="email" placeholder="Email" className='mb-2 bg-light' autoComplete="email" />
                        <Form.Control id='password' type='password' placeholder='Password' className='mt-3 bg-light' />
                    </Form.Group>
                    <Button type='submit' className='d-flex w-75 justify-content-center'>Sign in</Button>
                </Form>
            </Col>
            <Col sm={12} md={6} xxl={4} className='p-5'>
                <h4 className='mb-3'>New Customers</h4>
                <p className='mb-4'>By creating an Account on our service, you agree to subscribe to newsletters, marketing or promotional materials.</p>
                <Button type='submit' className='d-flex w-75 justify-content-center'>Sign in</Button>
            </Col>
        </Col>
    </Row>
  )
}

export default Form10