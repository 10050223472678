import React, { useEffect, useState } from 'react';
import { Row, Col, Image, Button } from 'react-bootstrap';
import { ChevronRight } from 'react-bootstrap-icons';

const LA9 = ({isPreview, holderState, selectedField}) => {
  const [leftAlign9, setLeftAlign9] = useState({
    text1:
      holderState?.text1Value !== undefined
        ? holderState.text1Value
        : "Long headline on two lines to turn your visitors into users and achieve more",
    showText1:
      holderState?.showText1 !== undefined ? holderState.showText1 : true,
    text2:
      holderState?.text2Value !== undefined
        ? holderState.text2Value
        : "Separated they live in Bookmarks right at the coast of the famous Semantics, large language ocean Seperated they live in the Bookmarks right at the coast",
    showText2:
      holderState?.showText2 !== undefined ? holderState.showText2 : true,
    button1:
      holderState?.button1 !== undefined ? holderState.button1 : "Action",
    button1Link:
      holderState?.button1Link !== undefined ? holderState.button1Link : "",
    showButton1:
      holderState?.showButton1 !== undefined ? holderState.showButton1 : true,
    link1Title:
      holderState?.link1Title !== undefined
        ? holderState.link1Title
        : "Action Link",
    link1Value:
      holderState?.link1Value !== undefined ? holderState.link1Value : "",
    showLink1:
      holderState?.showLink1 !== undefined ? holderState.showLink1 : true,
    showImg1: holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
    imgLink1: holderState?.imgLink1 !== undefined ? holderState.imgLink1 : "",
    imgSrc1:
      holderState?.imgSrc1 !== undefined
        ? holderState.imgSrc1
        : "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt1: "Placeholder Image",
  });  

  const imageContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    maxHeight: '100%',
    borderRadius: '1rem',
    overflow: 'hidden'
  };
  const imageStyle = {
    objectFit: 'cover',
    height: '22rem',
    width: '100%',
    borderRadius: '1rem',
  }

  useEffect(() => {
    setLeftAlign9((prevLA9) => ({
      ...prevLA9,
      text1:
        holderState?.text1Value !== undefined
          ? holderState.text1Value
          : "Long headline on two lines to turn your visitors into users and achieve more",
      showText1:
        holderState?.showText1 !== undefined ? holderState.showText1 : true,
      text2:
        holderState?.text2Value !== undefined
          ? holderState.text2Value
          : "Separated they live in Bookmarks right at the coast of the famous Semantics, large language ocean Seperated they live in the Bookmarks right at the coast",
      showText2:
        holderState?.showText2 !== undefined ? holderState.showText2 : true,
      button1:
        holderState?.button1 !== undefined ? holderState.button1 : "Action",
      button1Link:
        holderState?.button1Link !== undefined ? holderState.button1Link : "",
      showButton1:
        holderState?.showButton1 !== undefined ? holderState.showButton1 : true,
      link1Title:
        holderState?.link1Title !== undefined
          ? holderState.link1Title
          : "Action Link",
      link1Value:
        holderState?.link1Value !== undefined ? holderState.link1Value : "",
      showLink1:
        holderState?.showLink1 !== undefined ? holderState.showLink1 : true,
      showImg1:
        holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
      imgLink1: holderState?.imgLink1 !== undefined ? holderState.imgLink1 : "",
      imgSrc1:
        holderState?.imgSrc1 !== undefined
          ? holderState.imgSrc1
          : "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgAlt1: "Placeholder Image",
    }));
  }, [
    holderState?.showText1,
    holderState?.text1Value,
    holderState?.showText2,
    holderState?.text2Value,
    holderState?.link1Title,
    holderState?.link1Value,
    holderState?.showLink1,
    holderState?.showImg1,
    holderState?.imgLink1,
    holderState?.button1,
    holderState?.button1Link,
    holderState?.showButton1,
    holderState?.imgSrc1,
  ]);

  return (
    <Row
      sm={12}
      md={12}
      lg={10}
      xl={10}
      xxl={8}
      style={{ "--bs-gutter-x": "0" }}
      className={`d-flex justify-content-center bg-white gap-5 ${
        isPreview ? "p-3" : "p-5"
      }`}
    >
      <Col
        xs={12}
        sm={10}
        md={6}
        lg={5}
        xl={5}
        xxl={4}
        className="d-flex flex-column align-items-center justify-content-center"
      >
        <Col className="d-flex flex-column justify-content-center mx-2 gap-3">
          {leftAlign9.showText1 && (
            <h3
              className={`fw-bold ${isPreview ? "preview-scaling" : ""} ${
                selectedField === "text1" ? "focus-field" : ""
              }`}
            >
              {leftAlign9.text1}
            </h3>
          )}
          {leftAlign9.showText2 && (
            <p
              className={`${isPreview ? "preview-scaling" : ""} ${
                selectedField === "text2" ? "focus-field" : ""
              }`}
            >
              {leftAlign9.text2}
            </p>
          )}
          <div className={`${isPreview ? "preview-scaling" : ""}`}>
            {leftAlign9.showButton1 && (
              <Button
                className={`${
                  isPreview ? "preview-scaling me-1" : "h-auto me-3"
                } ${selectedField === "button1" ? "focus-field" : ""}`}
                href={"https://" + leftAlign9.button1Link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {leftAlign9.button1}
              </Button>
            )}
            {leftAlign9.showLink1 && (
              <a
                className={`fw-bold link-offset-2 link-underline link-underline-opacity-0 link-underline-opacity-75-hover ${
                selectedField === "link1Value" ? "focus-field" : ""
              }`}
                href={"https://" + leftAlign9.link1Value}
                target="_blank"
                rel="noopener noreferrer"
              >
                {leftAlign9.link1Title} <ChevronRight />
              </a>
            )}
          </div>
        </Col>
      </Col>
      <Col
        xs={12}
        sm={10}
        md={5}
        lg={5}
        xl={5}
        xxl={4}
        className={`d-flex align-items-center justify-content-center ${
          isPreview ? "preview-scaling my-auto" : ""
        }`}
      >
        {leftAlign9.showImg1 && (
          <Col
            className={`text-center justify-content-center ${
              selectedField === "imgSrc1" ? "focus-field" : ""
            }`}
            style={imageContainerStyle}
          >
            {leftAlign9.imgLink1 !== "" && (
              <a
                href={"https://" + leftAlign9.imgLink1}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  src={leftAlign9.imgSrc1}
                  alt={leftAlign9.imgAlt1}
                  style={imageStyle}
                  fluid
                />
              </a>
            )}
            {leftAlign9.imgLink1 === "" && (
              <Image
                src={leftAlign9.imgSrc1}
                alt={leftAlign9.imgAlt1}
                style={imageStyle}
                fluid
              />
            )}
          </Col>
        )}
      </Col>
    </Row>
  );
}

export default LA9;
