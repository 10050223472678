import React, { useEffect } from "react";
import { useState } from "react";
import { Row, Col, Image, Form, Button, InputGroup } from "react-bootstrap";

const RA3 = ({ isPreview, holderState, selectedField }) => {
  const [rightAlign3, setRightAlign3] = useState({
    headline:
      holderState?.headline !== undefined ? holderState.headline : "Tagline",
    showHeadline:
      holderState?.showHeadline !== undefined ? holderState.showHeadline : true,
    text1:
      holderState?.text1Value !== undefined
        ? holderState.text1Value
        : "Medium Length Display headline",
    showText1:
      holderState?.showText1 !== undefined ? holderState.showText1 : true,
    text2:
      holderState?.text2Value !== undefined
        ? holderState.text2Value
        : "By clicking the Button you are confirming that you're agree with our following",
    showText2:
      holderState?.showText2 !== undefined ? holderState.showText2 : true,
    button1:
      holderState?.button1 !== undefined ? holderState.button1 : "Action",
    button1Link:
      holderState?.button1Link !== undefined ? holderState.button1Link : "",
    showButton1:
      holderState?.showButton1 !== undefined ? holderState.showButton1 : true,
    link1Title:
      holderState?.link1Title !== undefined
        ? holderState.link1Title
        : "Terms and Conditions",
    link1Value:
      holderState?.link1Value !== undefined ? holderState.link1Value : "",
    showLink1:
      holderState?.showLink1 !== undefined ? holderState.showLink1 : true,
    showImg1: holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
    imgLink1: holderState?.imgLink1 !== undefined ? holderState.imgLink1 : "",
    imgSrc1:
      holderState?.imgSrc1 !== undefined
        ? holderState.imgSrc1
        : "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt1: "Placeholder Image",
  });

  const imageContainerStyle = {
    display: "flex",
    alignItems: "center",
    height: "100%",
    maxHeight: "22rem",
    borderRadius: "1rem",
    overflow: "hidden",
  };
  const imageStyle = {
    objectFit: "cover",
    height: "22rem",
    width: "100%",
    borderRadius: "1rem",
  };

  useEffect(() => {
    setRightAlign3((prevRA3) => ({
      ...prevRA3,
      headline:
        holderState?.headline !== undefined ? holderState.headline : "Tagline",
      showHeadline:
        holderState?.showHeadline !== undefined
          ? holderState.showHeadline
          : true,
      text1:
        holderState?.text1Value !== undefined
          ? holderState.text1Value
          : "Medium Length Display headline",
      showText1:
        holderState?.showText1 !== undefined ? holderState.showText1 : true,
      text2:
        holderState?.text2Value !== undefined
          ? holderState.text2Value
          : "By clicking the Button you are confirming that you're agree with our following",
      showText2:
        holderState?.showText2 !== undefined ? holderState.showText2 : true,
      button1:
        holderState?.button1 !== undefined ? holderState.button1 : "Action",
      button1Link:
        holderState?.button1Link !== undefined ? holderState.button1Link : "",
      showButton1:
        holderState?.showButton1 !== undefined ? holderState.showButton1 : true,
      link1Title:
        holderState?.link1Title !== undefined
          ? holderState.link1Title
          : "Terms and Conditions",
      link1Value:
        holderState?.link1Value !== undefined ? holderState.link1Value : "",
      showLink1:
        holderState?.showLink1 !== undefined ? holderState.showLink1 : true,
      showImg1:
        holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
      imgLink1: holderState?.imgLink1 !== undefined ? holderState.imgLink1 : "",
      imgSrc1:
        holderState?.imgSrc1 !== undefined
          ? holderState.imgSrc1
          : "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgAlt1: "Placeholder Image",
    }));
  }, [
    holderState?.showText1,
    holderState?.text1Value,
    holderState?.showText2,
    holderState?.text2Value,
    holderState?.showHeadline,
    holderState?.headline,
    holderState?.link1Title,
    holderState?.link1Value,
    holderState?.showLink1,
    holderState?.showImg1,
    holderState?.imgLink1,
    holderState?.button1,
    holderState?.button1Link,
    holderState?.showButton1,
    holderState?.imgSrc1,
  ]);

  return (
    <Row
      sm={12}
      md={12}
      lg={10}
      xl={10}
      xxl={8}
      style={{ "--bs-gutter-x": "0" }}
      className={`d-flex justify-content-center bg-white gap-5 ${
        isPreview ? "p-3" : "p-5"
      }`}
    >
      <Col
        xs={12}
        sm={10}
        md={5}
        lg={5}
        xl={5}
        xxl={4}
        className={`d-flex align-items-center justify-content-center my-auto ${
          isPreview ? "preview-scaling" : ""
        }`}
      >
        {rightAlign3.showImg1 && (
          <Col
            className={`text-center justify-content-center ${
              selectedField === "imgSrc1" ? "focus-field" : ""
            }`}
            style={imageContainerStyle}
          >
            {rightAlign3.imgLink1 !== "" && (
              <a
                href={"https://" + rightAlign3.imgLink1}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  src={rightAlign3.imgSrc1}
                  alt={rightAlign3.imgAlt1}
                  style={imageStyle}
                  fluid
                />
              </a>
            )}
            {rightAlign3.imgLink1 === "" && (
              <Image
                src={rightAlign3.imgSrc1}
                alt={rightAlign3.imgAlt1}
                style={imageStyle}
                fluid
              />
            )}
          </Col>
        )}
      </Col>
      <Col
        xs={12}
        sm={10}
        md={6}
        lg={5}
        xl={5}
        xxl={4}
        className="d-flex flex-column align-items-center justify-content-center"
      >
        <Col className="d-flex flex-column justify-content-center mx-2">
          {rightAlign3.showHeadline && (
            <p
              className={`fw-semibold ${
                isPreview ? "preview-scaling mb-0" : ""
              } ${selectedField === "headline" ? "focus-field" : ""}`}
            >
              {rightAlign3.headline}
            </p>
          )}
          {rightAlign3.showText1 && (
            <h1
              className={`fw-bold ${isPreview ? "preview-scaling" : ""} ${
                selectedField === "text1" ? "focus-field" : ""
              }`}
            >
              {rightAlign3.text1}
            </h1>
          )}
        </Col>
        {rightAlign3.showButton1 && (
          <Col
            xs={12}
            sm={12}
            md={11}
            className={`d-flex ${isPreview ? "preview-scaling" : ""}`}
          >
            <InputGroup
              className={`${isPreview ? "my-1" : "my-3"} ${
                selectedField === "button1" ? "focus-field" : ""
              }`}
            >
              <Form.Control
                aria-describedby="basic-addon2"
                varient="dark"
                className={`bg-light w-75 ${
                  isPreview ? "preview-scaling" : ""
                }`}
                size="lg"
                placeholder="Placeholder"
              />
              <Button
                className={`fw-bold ${isPreview ? "preview-scaling" : ""} `}
                id="button-addon2"
                href={"https://" + rightAlign3.button1Link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {rightAlign3.button1}
              </Button>
            </InputGroup>
          </Col>
        )}
        <Col md={11}>
          <small className={`${isPreview ? "preview-scaling " : ""}`}>
            {rightAlign3.showText2 && (
              <span
                className={`${selectedField === "text2" ? "focus-field" : ""}`}
              >
                {rightAlign3.text2}{" "}
              </span>
            )}
            {rightAlign3.showLink1 && (
              <a
                href={"https://" + rightAlign3.link1Value}
                target="_blank"
                rel="noopener noreferrer"
                className={`${
                  selectedField === "link1Value" ? "focus-field" : ""
                }`}
              >
                {rightAlign3.link1Title}
              </a>
            )}
          </small>
        </Col>
      </Col>
    </Row>
  );
};

export default RA3;
