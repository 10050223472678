import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import isLoadingReducer from './loadingSlice';
import footerReducer from '../reducers/footerReducer';
import navbarReducer from '../reducers/navbarReducer';
import galleryReducer from '../reducers/galleryReducer'
import bodyOptionsReducer from '../reducers/bodyComponentPatterns';
import bodyRowsReducer from '../reducers/bodyRowsReducer';
import leftAlignedReducer from '../reducers/leftAlignedReducer';
import rightAlignedReducer from '../reducers/rightAlignedReducer'
import centerAlignedReducer from '../reducers/centerAlignedReducer';
import footerStateReducer from '../reducers/footerStateReducer';
import rowIdReducer from '../reducers/rowIdReducer';
import bodyStateReducer from '../reducers/bodyStateReducer';
import rowIdStateReducer from '../reducers/rowIdStateReducer';
import imgCollectionReducer from '../reducers/imgCollectionReducer';
import existingPageReducer from '../reducers/existingPageReducer';
import navbarStateReducer from '../reducers/navbarStateReducer';

const store = configureStore({
  reducer: {
    auth: authReducer,
    loading: isLoadingReducer,
    navbars: navbarReducer,
    footers: footerReducer,
    gallerys: galleryReducer,
    bodyRowOptions: bodyOptionsReducer,
    bodyRows: bodyRowsReducer,
    leftAligneds: leftAlignedReducer,
    rightAligneds: rightAlignedReducer,
    centerAligneds: centerAlignedReducer,
    footerStates: footerStateReducer,
    bodyStates: bodyStateReducer,
    rowId: rowIdReducer,
    rowIdState: rowIdStateReducer,
    imgCollection: imgCollectionReducer,
    existingPages: existingPageReducer,
    navbarState: navbarStateReducer,
  },
});

export default store;
