import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ChevronDoubleLeft } from "react-bootstrap-icons";
import {
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { addBodyRow, removeBodyRow } from '../../../../reducers/bodyRowsReducer'
import { incrementRowId } from "../../../../reducers/rowIdReducer";
import { pushToRowIdState } from "../../../../reducers/rowIdStateReducer";
import { setBodyState } from "../../../../reducers/bodyStateReducer";
import { addImage, deleteImage, } from "../../../../reducers/imgCollectionReducer";
import ImageSourceForm from "./ChoicesComponents/ImageSourceForm";
import ImageTitleForm from "./ChoicesComponents/ImageTitleForm";
import ImageTextForm from "./ChoicesComponents/ImageTextForm";
import RenderRowComponent from "./ChoicesComponents/RenderRowComponent";

const GalleryChoices = ({
  setHome,
  setSelectedBodyRow,
  selectedBodyRows,
  rowId,
  initialRowId,
  itemId,
  handleRemoveAccordionItem,
  prevSelection,
  stage,
  setStage,
  handleBack,
  setPrevSelection,
  prevRowIdState,
}) => {
  const [slide, setSlide] = useState(stage || 1);
  const [galleryChoice, setGalleryChoice] = useState(prevSelection ?? null);
  const [updatedGal, setUpdatedGal] = useState();
  const [showImg1, setShowImg1] = useState(
    prevRowIdState ? prevRowIdState.showImg1 : true
  );
  const [showImg2, setShowImg2] = useState(
    prevRowIdState ? prevRowIdState.showImg2 : true
  );
  const [showImg3, setShowImg3] = useState(
    prevRowIdState ? prevRowIdState.showImg3 : true
  );
  const [showImg4, setShowImg4] = useState(
    prevRowIdState ? prevRowIdState.showImg4 : true
  );
  const [showImg5, setShowImg5] = useState(
    prevRowIdState ? prevRowIdState.showImg5 : true
  );
  const [showImg6, setShowImg6] = useState(
    prevRowIdState ? prevRowIdState.showImg6 : true
  );
  const [showImg7, setShowImg7] = useState(
    prevRowIdState ? prevRowIdState.showImg7 : true
  );
  const [showImg8, setShowImg8] = useState(
    prevRowIdState ? prevRowIdState.showImg2 : true
  );
  const [imgLink1, setImgLink1] = useState(
    prevRowIdState ? prevRowIdState.imgLink1 : undefined
  );
  const [imgLink2, setImgLink2] = useState(
    prevRowIdState ? prevRowIdState.imgLink2 : undefined
  );
  const [imgLink3, setImgLink3] = useState(
    prevRowIdState ? prevRowIdState.imgLink3 : undefined
  );
  const [imgLink4, setImgLink4] = useState(
    prevRowIdState ? prevRowIdState.imgLink4 : undefined
  );
  const [imgLink5, setImgLink5] = useState(
    prevRowIdState ? prevRowIdState.imgLink5 : undefined
  );
  const [imgLink6, setImgLink6] = useState(
    prevRowIdState ? prevRowIdState.imgLink6 : undefined
  );
  const [imgLink7, setImgLink7] = useState(
    prevRowIdState ? prevRowIdState.imgLink7 : undefined
  );
  const [imgLink8, setImgLink8] = useState(
    prevRowIdState ? prevRowIdState.imgLink8 : undefined
  );
  const [showImgTitle1, setShowImgTitle1] = useState(
    prevRowIdState ? prevRowIdState.showImgTitle1 : true
  );
  const [showImgTitle2, setShowImgTitle2] = useState(
    prevRowIdState ? prevRowIdState.showImgTitle2 : true
  );
  const [showImgTitle3, setShowImgTitle3] = useState(
    prevRowIdState ? prevRowIdState.showImgTitle3 : true
  );
  const [showImgTitle4, setShowImgTitle4] = useState(
    prevRowIdState ? prevRowIdState.showImgTitle4 : true
  );
  const [showImgTitle5, setShowImgTitle5] = useState(
    prevRowIdState ? prevRowIdState.showImgTitle5 : true
  );
  const [showImgTitle6, setShowImgTitle6] = useState(
    prevRowIdState ? prevRowIdState.showImgTitle6 : true
  );
  const [showImgTitle7, setShowImgTitle7] = useState(
    prevRowIdState ? prevRowIdState.showImgTitle7 : true
  );
  const [showImgTitle8, setShowImgTitle8] = useState(
    prevRowIdState ? prevRowIdState.showImgTitle8 : true
  );
  const [imgTitle1, setImgTitle1] = useState(
    prevRowIdState ? prevRowIdState.imgTitle1 : undefined
  );
  const [imgTitle2, setImgTitle2] = useState(
    prevRowIdState ? prevRowIdState.imgTitle2 : undefined
  );
  const [imgTitle3, setImgTitle3] = useState(
    prevRowIdState ? prevRowIdState.imgTitle3 : undefined
  );
  const [imgTitle4, setImgTitle4] = useState(
    prevRowIdState ? prevRowIdState.imgTitle4 : undefined
  );
  const [imgTitle5, setImgTitle5] = useState(
    prevRowIdState ? prevRowIdState.imgTitle5 : undefined
  );
  const [imgTitle6, setImgTitle6] = useState(
    prevRowIdState ? prevRowIdState.imgTitle6 : undefined
  );
  const [imgTitle7, setImgTitle7] = useState(
    prevRowIdState ? prevRowIdState.imgTitle7 : undefined
  );
  const [imgTitle8, setImgTitle8] = useState(
    prevRowIdState ? prevRowIdState.imgTitle8 : undefined
  );
  const [showImgText1, setShowImgText1] = useState(
    prevRowIdState ? prevRowIdState.showImgText1 : true
  );
  const [showImgText2, setShowImgText2] = useState(
    prevRowIdState ? prevRowIdState.showImgText2 : true
  );
  const [showImgText3, setShowImgText3] = useState(
    prevRowIdState ? prevRowIdState.showImgText3 : true
  );
  const [showImgText4, setShowImgText4] = useState(
    prevRowIdState ? prevRowIdState.showImgText4 : true
  );
  const [showImgText5, setShowImgText5] = useState(
    prevRowIdState ? prevRowIdState.showImgText5 : true
  );
  const [showImgText6, setShowImgText6] = useState(
    prevRowIdState ? prevRowIdState.showImgText6 : true
  );
  const [showImgText7, setShowImgText7] = useState(
    prevRowIdState ? prevRowIdState.showImgText7 : true
  );
  const [showImgText8, setShowImgText8] = useState(
    prevRowIdState ? prevRowIdState.showImgText8 : true
  );
  const [imgText1, setImgText1] = useState(
    prevRowIdState ? prevRowIdState.imgText1 : undefined
  );
  const [imgText2, setImgText2] = useState(
    prevRowIdState ? prevRowIdState.imgText2 : undefined
  );
  const [imgText3, setImgText3] = useState(
    prevRowIdState ? prevRowIdState.imgText3 : undefined
  );
  const [imgText4, setImgText4] = useState(
    prevRowIdState ? prevRowIdState.imgText4 : undefined
  );
  const [imgText5, setImgText5] = useState(
    prevRowIdState ? prevRowIdState.imgText5 : undefined
  );
  const [imgText6, setImgText6] = useState(
    prevRowIdState ? prevRowIdState.imgText6 : undefined
  );
  const [imgText7, setImgText7] = useState(
    prevRowIdState ? prevRowIdState.imgText7 : undefined
  );
  const [imgText8, setImgText8] = useState(
    prevRowIdState ? prevRowIdState.imgText8 : undefined
  );
  const [imgSrc1, setImgSrc1] = useState(
    prevRowIdState ? prevRowIdState.imgSrc1 : undefined
  );
  const [imgSrc2, setImgSrc2] = useState(
    prevRowIdState ? prevRowIdState.imgSrc2 : undefined
  );
  const [imgSrc3, setImgSrc3] = useState(
    prevRowIdState ? prevRowIdState.imgSrc3 : undefined
  );
  const [imgSrc4, setImgSrc4] = useState(
    prevRowIdState ? prevRowIdState.imgSrc4 : undefined
  );
  const [imgSrc5, setImgSrc5] = useState(
    prevRowIdState ? prevRowIdState.imgSrc5 : undefined
  );
  const [imgSrc6, setImgSrc6] = useState(
    prevRowIdState ? prevRowIdState.imgSrc6 : undefined
  );
  const [imgSrc7, setImgSrc7] = useState(
    prevRowIdState ? prevRowIdState.imgSrc7 : undefined
  );
  const [imgSrc8, setImgSrc8] = useState(
    prevRowIdState ? prevRowIdState.imgSrc8 : undefined
  );
  const [selectedImage, setSelectedImage] = useState("imgSrc1");
  const [selectedField, setSelectedField] = useState(null);
  
  const holderStateSelector = useSelector((state) => state.bodyStates);
  const imgCollection = useSelector((state) => state.imgCollection);
  const holderState = prevRowIdState ? prevRowIdState : holderStateSelector;
  const galleries = useSelector((state) => state.gallerys);

  const galleryTemplates = useMemo(
    () => ({ galleryTemplates: galleries }),
    [galleries]
  );
  const selectedGallery = useSelector((state) =>
    state.gallerys.find((gallery) => gallery.name === galleryChoice)
  );
  const dispatch = useDispatch();

const handleFileChange = (event) => {
  console.log("File selected");
  const file = event.target.files[0];

  if (file) {
    console.log("Selected File:", file);

    // Assuming you are using Redux for state management
    dispatch(addImage(file));

    const reader = new FileReader();

    reader.onload = (e) => {
      const fileData = e.target.result;
      selectedImage === "imgSrc1" && setImgSrc1(fileData);
      selectedImage === "imgSrc2" && setImgSrc2(fileData);
      selectedImage === "imgSrc3" && setImgSrc3(fileData);
      selectedImage === "imgSrc4" && setImgSrc4(fileData);
      selectedImage === "imgSrc5" && setImgSrc5(fileData);
      selectedImage === "imgSrc6" && setImgSrc6(fileData);
      selectedImage === "imgSrc7" && setImgSrc7(fileData);
      selectedImage === "imgSrc8" && setImgSrc8(fileData);
    };

    reader.readAsDataURL(file);

    // Here, you can perform additional actions like uploading the file to a server
  } else {
    console.log("No file selected");
  }
};

  const submitPattern = () => {
    setStage(2);
    setPrevSelection(null);
    const updatedSelectedGallery = { ...selectedGallery, rowId: initialRowId };
    setSelectedBodyRow(updatedSelectedGallery);
    dispatch(addBodyRow(updatedSelectedGallery));
    setSlide(3);
    setUpdatedGal(updatedSelectedGallery);
    dispatch(incrementRowId());
    dispatch(
      pushToRowIdState({
        rowId: initialRowId,
        data: {
          bodyState: holderState,
        },
      })
    );
    if (rowId !== 1) {
      handleRemoveAccordionItem(itemId);
    }
  };

  const handleUpload = (x) => {
    if (x) {
      console.log("Selected File:", x);
      dispatch(addImage(x));
      // Here, you can perform additional actions like uploading the file to a server
    } else {
      console.log("No file selected");
    }
  };

  const handleImageClick = (x) => {
    selectedImage === 'imgSrc1' && (setImgSrc1(x))
    selectedImage === 'imgSrc2' && (setImgSrc2(x))
    selectedImage === 'imgSrc3' && (setImgSrc3(x))
    selectedImage === 'imgSrc4' && (setImgSrc4(x))
    selectedImage === 'imgSrc5' && (setImgSrc5(x))
    selectedImage === 'imgSrc6' && (setImgSrc6(x))
    selectedImage === 'imgSrc7' && (setImgSrc7(x))
    selectedImage === 'imgSrc8' && (setImgSrc8(x))
  };


  const handleDeleteClick = (id) => {
      const isConfirmed = window.confirm(
        "Are you sure you want to delete this image?"
      );
      if (isConfirmed) {
      dispatch(deleteImage(id));
      }
  };

  const handleSelect = (galleryName) => {
    setGalleryChoice(galleryName);
    setSelectedBodyRow(null);
    setHome(false);
    setSlide(2);
  };

  const goBack = (e) => {
    setSelectedBodyRow(null);
    if (updatedGal) {
      dispatch(removeBodyRow(updatedGal.rowId));
    }
    setHome(true);
    setSlide(1);
  };

  useEffect(() => {
    const bodyState = {
      showImg1: showImg1,
      showImg2: showImg2,
      showImg3: showImg3,
      showImg4: showImg4,
      showImg5: showImg5,
      showImg6: showImg6,
      showImg7: showImg7,
      showImg8: showImg8,
      imgLink1: imgLink1,
      imgLink2: imgLink2,
      imgLink3: imgLink3,
      imgLink4: imgLink4,
      imgLink5: imgLink5,
      imgLink6: imgLink6,
      imgLink7: imgLink7,
      imgLink8: imgLink8,
      showImgTitle1: showImgTitle1,
      showImgTitle2: showImgTitle2,
      showImgTitle3: showImgTitle3,
      showImgTitle4: showImgTitle4,
      showImgTitle5: showImgTitle5,
      showImgTitle6: showImgTitle6,
      showImgTitle7: showImgTitle7,
      showImgTitle8: showImgTitle8,
      showImgText1: showImgText1,
      showImgText2: showImgText2,
      showImgText3: showImgText3,
      showImgText4: showImgText4,
      showImgText5: showImgText5,
      showImgText6: showImgText6,
      showImgText7: showImgText7,
      showImgText8: showImgText8,
      imgText1: imgText1,
      imgText2: imgText2,
      imgText3: imgText3,
      imgText4: imgText4,
      imgText5: imgText5,
      imgText6: imgText6,
      imgText7: imgText7,
      imgText8: imgText8,
      imgTitle1: imgTitle1,
      imgTitle2: imgTitle2,
      imgTitle3: imgTitle3,
      imgTitle4: imgTitle4,
      imgTitle5: imgTitle5,
      imgTitle6: imgTitle6,
      imgTitle7: imgTitle7,
      imgTitle8: imgTitle8,
      imgSrc1: imgSrc1,
      imgSrc2: imgSrc2,
      imgSrc3: imgSrc3,
      imgSrc4: imgSrc4,
      imgSrc5: imgSrc5,
      imgSrc6: imgSrc6,
      imgSrc7: imgSrc7,
      imgSrc8: imgSrc8,
    };
    dispatch(setBodyState(bodyState));
    dispatch(
      pushToRowIdState({
        rowId: initialRowId,
        data: {
          bodyState: bodyState,
        },
      })
    );
  }, [
    dispatch,
    showImg1,
    showImg2,
    showImg3,
    showImg4,
    showImg5,
    showImg6,
    showImg7,
    showImg8,
    imgLink1,
    imgLink2,
    imgLink3,
    imgLink4,
    imgLink5,
    imgLink6,
    imgLink7,
    imgLink8,
    showImgTitle1,
    showImgTitle2,
    showImgTitle3,
    showImgTitle4,
    showImgTitle5,
    showImgTitle6,
    showImgTitle7,
    showImgTitle8,
    showImgText1,
    showImgText2,
    showImgText3,
    showImgText4,
    showImgText5,
    showImgText6,
    showImgText7,
    showImgText8,
    imgText1,
    imgText2,
    imgText3,
    imgText4,
    imgText5,
    imgText6,
    imgText7,
    imgText8,
    imgTitle1,
    imgTitle2,
    imgTitle3,
    imgTitle4,
    imgTitle5,
    imgTitle6,
    imgTitle7,
    imgTitle8,
    imgSrc1,
    imgSrc2,
    imgSrc3,
    imgSrc4,
    imgSrc5,
    imgSrc6,
    imgSrc7,
    imgSrc8,
    initialRowId,
  ]);

  return (
    <>
      {slide === 1 && (
        <Col className="d-flex justify-content-end">
          <Button variant="dark" className="mb-5" onClick={handleBack}>
            <ChevronDoubleLeft size={18} /> GO BACK
          </Button>
        </Col>
      )}
      {galleryTemplates.galleryTemplates.length > 0 &&
        slide === 1 &&
        galleryTemplates.galleryTemplates.map((galleryTemplate) => (
          <div key={galleryTemplate.id} className="my-4">
            <div onClick={() => handleSelect(galleryTemplate.name)}>
              <div className="unclickable">
                {RenderRowComponent(
                  galleryTemplate.bodyRowComponent.componentName,
                  galleryTemplate.bodyRowComponent.props
                )}
              </div>
            </div>
          </div>
        ))}
      {slide === 2 && (
        <>
          <Col className="d-flex justify-content-end">
            <Button variant="dark" className="mb-5" onClick={goBack}>
              <ChevronDoubleLeft size={18} /> GO BACK
            </Button>
          </Col>
          <div className="crud-hover">
            {galleryChoice.length > 0 && (
              <div className="unclickable">
                {RenderRowComponent(
                  selectedGallery.bodyRowComponent.componentName,
                  { holderState: holderState },
                  false,
                  selectedField
                )}
              </div>
            )}
          </div>
          <Row className="d-flex flex-column align-items-center">
            <Row className="gap-4 my-3 d-flex justify-content-around">
              {selectedGallery.bodyRowComponent.props.imgSrc1 && (
                <ImageSourceForm
                  showImg={showImg1}
                  setShowImg={setShowImg1}
                  imgSrc={imgSrc1}
                  imgSrcString={"imgSrc1"}
                  imgNumber="1"
                  handleImageClick={handleImageClick}
                  imgLink={imgLink1}
                  setImgLink={setImgLink1}
                  setSelectedImage={setSelectedImage}
                  handleFileChange={handleFileChange}
                  handleUpload={handleUpload}
                  handleDeleteClick={handleDeleteClick}
                  imgCollection={imgCollection}
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedGallery.bodyRowComponent.props.imgSrc2 && (
                <ImageSourceForm
                  showImg={showImg2}
                  setShowImg={setShowImg2}
                  imgSrc={imgSrc2}
                  imgSrcString={"imgSrc2"}
                  imgNumber="2"
                  handleImageClick={handleImageClick}
                  imgLink={imgLink2}
                  setImgLink={setImgLink2}
                  setSelectedImage={setSelectedImage}
                  handleFileChange={handleFileChange}
                  handleUpload={handleUpload}
                  handleDeleteClick={handleDeleteClick}
                  imgCollection={imgCollection}
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedGallery.bodyRowComponent.props.imgSrc3 && (
                <ImageSourceForm
                  showImg={showImg3}
                  setShowImg={setShowImg3}
                  imgSrc={imgSrc3}
                  imgSrcString={"imgSrc3"}
                  imgNumber="3"
                  handleImageClick={handleImageClick}
                  imgLink={imgLink3}
                  setImgLink={setImgLink3}
                  setSelectedImage={setSelectedImage}
                  handleFileChange={handleFileChange}
                  handleUpload={handleUpload}
                  handleDeleteClick={handleDeleteClick}
                  imgCollection={imgCollection}
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedGallery.bodyRowComponent.props.imgSrc4 && (
                <ImageSourceForm
                  showImg={showImg4}
                  setShowImg={setShowImg4}
                  imgSrc={imgSrc4}
                  imgSrcString={"imgSrc4"}
                  imgNumber="4"
                  handleImageClick={handleImageClick}
                  imgLink={imgLink4}
                  setImgLink={setImgLink4}
                  setSelectedImage={setSelectedImage}
                  handleFileChange={handleFileChange}
                  handleUpload={handleUpload}
                  handleDeleteClick={handleDeleteClick}
                  imgCollection={imgCollection}
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedGallery.bodyRowComponent.props.imgSrc5 && (
                <ImageSourceForm
                  showImg={showImg5}
                  setShowImg={setShowImg5}
                  imgSrc={imgSrc5}
                  imgSrcString={"imgSrc5"}
                  imgNumber="5"
                  handleImageClick={handleImageClick}
                  imgLink={imgLink5}
                  setImgLink={setImgLink5}
                  setSelectedImage={setSelectedImage}
                  handleFileChange={handleFileChange}
                  handleUpload={handleUpload}
                  handleDeleteClick={handleDeleteClick}
                  imgCollection={imgCollection}
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedGallery.bodyRowComponent.props.imgSrc6 && (
                <ImageSourceForm
                  showImg={showImg6}
                  setShowImg={setShowImg6}
                  imgSrc={imgSrc6}
                  imgSrcString={"imgSrc6"}
                  imgNumber="6"
                  handleImageClick={handleImageClick}
                  imgLink={imgLink6}
                  setImgLink={setImgLink6}
                  setSelectedImage={setSelectedImage}
                  handleFileChange={handleFileChange}
                  handleUpload={handleUpload}
                  handleDeleteClick={handleDeleteClick}
                  imgCollection={imgCollection}
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedGallery.bodyRowComponent.props.imgSrc7 && (
                <ImageSourceForm
                  showImg={showImg7}
                  setShowImg={setShowImg7}
                  imgSrc={imgSrc7}
                  imgSrcString={"imgSrc7"}
                  imgNumber="7"
                  handleImageClick={handleImageClick}
                  imgLink={imgLink7}
                  setImgLink={setImgLink7}
                  setSelectedImage={setSelectedImage}
                  handleFileChange={handleFileChange}
                  handleUpload={handleUpload}
                  handleDeleteClick={handleDeleteClick}
                  imgCollection={imgCollection}
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedGallery.bodyRowComponent.props.imgSrc8 && (
                <ImageSourceForm
                  showImg={showImg8}
                  setShowImg={setShowImg8}
                  imgSrc={imgSrc8}
                  imgSrcString={"imgSrc8"}
                  imgNumber="8"
                  handleImageClick={handleImageClick}
                  imgLink={imgLink8}
                  setImgLink={setImgLink8}
                  setSelectedImage={setSelectedImage}
                  handleFileChange={handleFileChange}
                  handleUpload={handleUpload}
                  handleDeleteClick={handleDeleteClick}
                  imgCollection={imgCollection}
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedGallery.bodyRowComponent.props.imgTitle1 && (
                <ImageTitleForm
                  showImgTitle={showImgTitle1}
                  setShowImgTitle={setShowImgTitle1}
                  imgTitle={imgTitle1}
                  setImgTitle={setImgTitle1}
                  imgNumber="1"
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedGallery.bodyRowComponent.props.imgTitle2 && (
                <ImageTitleForm
                  showImgTitle={showImgTitle2}
                  setShowImgTitle={setShowImgTitle2}
                  imgTitle={imgTitle2}
                  setImgTitle={setImgTitle2}
                  imgNumber="2"
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedGallery.bodyRowComponent.props.imgTitle3 && (
                <ImageTitleForm
                  showImgTitle={showImgTitle3}
                  setShowImgTitle={setShowImgTitle3}
                  imgTitle={imgTitle3}
                  setImgTitle={setImgTitle3}
                  imgNumber="3"
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedGallery.bodyRowComponent.props.imgTitle4 && (
                <ImageTitleForm
                  showImgTitle={showImgTitle4}
                  setShowImgTitle={setShowImgTitle4}
                  imgTitle={imgTitle4}
                  setImgTitle={setImgTitle4}
                  imgNumber="4"
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedGallery.bodyRowComponent.props.imgTitle5 && (
                <ImageTitleForm
                  showImgTitle={showImgTitle5}
                  setShowImgTitle={setShowImgTitle5}
                  imgTitle={imgTitle5}
                  setImgTitle={setImgTitle5}
                  imgNumber="5"
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedGallery.bodyRowComponent.props.imgTitle6 && (
                <ImageTitleForm
                  showImgTitle={showImgTitle6}
                  setShowImgTitle={setShowImgTitle6}
                  imgTitle={imgTitle6}
                  setImgTitle={setImgTitle6}
                  imgNumber="6"
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedGallery.bodyRowComponent.props.imgTitle7 && (
                <ImageTitleForm
                  showImgTitle={showImgTitle7}
                  setShowImgTitle={setShowImgTitle7}
                  imgTitle={imgTitle7}
                  setImgTitle={setImgTitle7}
                  imgNumber="7"
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedGallery.bodyRowComponent.props.imgTitle8 && (
                <ImageTitleForm
                  showImgTitle={showImgTitle8}
                  setShowImgTitle={setShowImgTitle8}
                  imgTitle={imgTitle8}
                  setImgTitle={setImgTitle8}
                  imgNumber="8"
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedGallery.bodyRowComponent.props.imgText1 && (
                <ImageTextForm
                  showImgText={showImgText1}
                  setShowImgText={setShowImgText1}
                  imgText={imgText1}
                  setImgText={setImgText1}
                  imgNumber="1"
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedGallery.bodyRowComponent.props.imgText2 && (
                <ImageTextForm
                  showImgText={showImgText2}
                  setShowImgText={setShowImgText2}
                  imgText={imgText2}
                  setImgText={setImgText2}
                  imgNumber="2"
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedGallery.bodyRowComponent.props.imgText3 && (
                <ImageTextForm
                  showImgText={showImgText3}
                  setShowImgText={setShowImgText3}
                  imgText={imgText3}
                  setImgText={setImgText3}
                  imgNumber="3"
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedGallery.bodyRowComponent.props.imgText4 && (
                <ImageTextForm
                  showImgText={showImgText4}
                  setShowImgText={setShowImgText4}
                  imgText={imgText4}
                  setImgText={setImgText4}
                  imgNumber="4"
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedGallery.bodyRowComponent.props.imgText5 && (
                <ImageTextForm
                  showImgText={showImgText5}
                  setShowImgText={setShowImgText5}
                  imgText={imgText5}
                  setImgText={setImgText5}
                  imgNumber="5"
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedGallery.bodyRowComponent.props.imgText6 && (
                <ImageTextForm
                  showImgText={showImgText6}
                  setShowImgText={setShowImgText6}
                  imgText={imgText6}
                  setImgText={setImgText6}
                  imgNumber="6"
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedGallery.bodyRowComponent.props.imgText7 && (
                <ImageTextForm
                  showImgText={showImgText7}
                  setShowImgText={setShowImgText7}
                  imgText={imgText7}
                  setImgText={setImgText7}
                  imgNumber="7"
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
              {selectedGallery.bodyRowComponent.props.imgText8 && (
                <ImageTextForm
                  showImgText={showImgText8}
                  setShowImgText={setShowImgText8}
                  imgText={imgText8}
                  setImgText={setImgText8}
                  imgNumber="8"
                  setSelectedField={setSelectedField}
                  selectedField={selectedField}
                />
              )}
            </Row>
          </Row>
          <div className="text-center">
            <Button
              onClick={submitPattern}
              variant="dark"
              className="mt-2 mb-4"
            >
              ADD THIS PATTERN
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default GalleryChoices;
