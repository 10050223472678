import React, { useState, useEffect } from 'react';
import { publishPage } from '../../../Services/PageService';
import { Button, Row, Col } from 'react-bootstrap';
import { CheckCircleFill } from 'react-bootstrap-icons';
import '../DashboardComponents/dashboard.css';

const Progressbar = ({
  progressStage,
  setProgressStage,
  selectedNavbar,
  selectedBodyRows,
  selectedFooter,
  footerState,
  rowIdState,
}) => {
  const [disabled, setDisabled] = useState(true);
  const [, setStep] = useState(0);
  const [height, setHeight] = useState(0);
  const [icon1Color, setIcon1Color] = useState("#000");
  const [icon2Color, setIcon2Color] = useState("#B0B6BA");
  const [icon3Color, setIcon3Color] = useState("#B0B6BA");
  const [textColor1, setTextColor1] = useState("#000");
  const [textColor2, setTextColor2] = useState("#B0B6BA");
  const [textColor3, setTextColor3] = useState("#B0B6BA");

  const [pageName, setPageName] = useState("");

  const postPage = async () => {
    const pageObject = {
      pageName: pageName,
      navbar: [selectedNavbar],
      footer: [{
        selectedFooter,
        footerState,
      }],
      body: [{
        selectedBodyRows,
        rowIdState,
      }],
      details: "testingDetails"
    };
    try {
      const response = await publishPage(pageObject);
      console.log('API Response:', response);
    } catch (error) {
      console.error('Error creating page:', error);
    }
  };
  

  
  useEffect(() => {
    if (progressStage === 0) {
      setHeight(0);
      setIcon1Color("#000");
      setIcon2Color("#B0B6BA");
      setIcon3Color("#B0B6BA");

      setTextColor1("#000");
      setTextColor2("#B0B6BA");
      setTextColor3("#B0B6BA");
      setDisabled(true);
    } else if (progressStage === 1) {
      setHeight(50);
      setIcon1Color("#28A745");
      setIcon2Color("#000");
      setIcon3Color("#B0B6BA");

      setTextColor1("#000");
      setTextColor2("#000");
      setTextColor3("#B0B6BA");
    } else if (progressStage === 2) {
      setHeight(100);
      setIcon1Color("#28A745");
      setIcon2Color("#28A745");
      setIcon3Color("#000");

      setTextColor1("#000");
      setTextColor2("#000");
      setTextColor3("#000");
    } else if (progressStage === 3) {
      setIcon1Color("#28A745");
      setIcon2Color("#28A745");
      setIcon3Color("#28A745");

      setTextColor1("#000");
      setTextColor2("#000");
      setTextColor3("#000");
      setDisabled(false);
    } else if (progressStage === 4) {
      setStep(0);
    }
  }, [progressStage]);

  return (
    <Row className="d-flex flex-column gap-4">
      <Col className="bg-seconday opacity-50">
        <h6 className="fw-semibold">PROGRESS</h6>
      </Col>
      <Col className="d-flex ">
        <div className="progress-container ms-4">
          <div className="progress-bar" style={{ height: height + "%" }} />
        </div>
        <div className="d-flex flex-column justify-content-between icons">
          <div className="step-container">
            <CheckCircleFill size={24} style={{ color: icon1Color }} />
          </div>
          <div className="step-container">
            <CheckCircleFill size={24} style={{ color: icon2Color }} />
          </div>
          <div className="step-container">
            <CheckCircleFill size={24} style={{ color: icon3Color }} />
          </div>
        </div>

        <Col className="d-flex flex-column justify-content-between">
          <p className="m-0" style={{ color: textColor1 }}>
            1: Select Navbar
          </p>
          <p className="m-0" style={{ color: textColor2 }}>
            2: Select Page Pattern
          </p>
          <p className="m-0" style={{ color: textColor3 }}>
            3: Select Footer
          </p>
        </Col>
      </Col>
      <Col className="text-center">
        <input type="text" placeholder='Page Name:' onChange={(e) => setPageName(e.target.value)} />
      </Col>
      <Col className="text-center">
        <Button
          variant="dark"
          disabled={disabled}
          onClick={() => postPage()}
        >
          PUBLISH
        </Button>
      </Col>
    </Row>
  );
};

export default Progressbar;