import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { ChevronRight } from 'react-bootstrap-icons';

const CA1 = ({isPreview, holderState, selectedField}) => {
  const [centerAlign1, setCenterAlign1] = useState({
    headline:
      holderState?.headline !== undefined ? holderState.headline : "Tagline",
    showHeadline:
      holderState?.showHeadline !== undefined ? holderState.showHeadline : true,
    text1:
      holderState?.text1Value !== undefined
        ? holderState.text1Value
        : "Long headline to turn your visitors into users",
    showText1:
      holderState?.showText1 !== undefined ? holderState.showText1 : true,
    link1Title:
      holderState?.link1Title !== undefined
        ? holderState.link1Title
        : "Action Link",
    link1Value: holderState?.link1Value !== undefined ? holderState.link1Value : "",
    showLink1:
      holderState?.showLink1 !== undefined ? holderState.showLink1 : true,
    showImg1: holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
  });

  useEffect(() => {
    setCenterAlign1((prevCA1) => ({
      ...prevCA1,
      headline:
        holderState?.headline !== undefined ? holderState.headline : "Tagline",
      showHeadline:
        holderState?.showHeadline !== undefined
          ? holderState.showHeadline
          : true,
      text1:
        holderState?.text1Value !== undefined
          ? holderState.text1Value
          : "Long headline to turn your visitors into users",
      showText1:
        holderState?.showText1 !== undefined ? holderState.showText1 : true,
      link1Title:
        holderState?.link1Title !== undefined
          ? holderState.link1Title
          : "Action Link",
      link1Value:
        holderState?.link1Value !== undefined ? holderState.link1Value : "",
      showLink1:
        holderState?.showLink1 !== undefined ? holderState.showLink1 : true,
      showImg1:
        holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
    }));
  }, [
    holderState?.showText1,
    holderState?.text1Value,
    holderState?.showHeadline,
    holderState?.headline,
    holderState?.link1Title,
    holderState?.link1Value,
    holderState?.showLink1,
    holderState?.showImg1,
  ]);  

  return (
    <Row
      sm={12}
      md={12}
      lg={10}
      xl={10}
      xxl={8}
      style={{ "--bs-gutter-x": "0" }}
      className={`d-flex justify-content-center bg-white ${
        isPreview ? "p-3" : "p-5"
      }`}
    >
      <Col
        xs={12}
        md={12}
        lg={10}
        xl={10}
        className="d-flex flex-column align-items-center justify-content-center"
      >
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={10}
          xl={8}
          xxl={8}
          className="pt-3 text-center"
        >
          {centerAlign1.showHeadline && (
            <p
              className={`fw-semibold ${isPreview ? "preview-scaling" : ""} ${
                selectedField === "headline" ? "focus-field" : ""
              }`}
            >
              {centerAlign1.headline}
            </p>
          )}
          {centerAlign1.showText1 && (
            <h1
              className={`fw-bold ${isPreview ? "preview-scaling" : "py-3"} ${
                selectedField === "text1" ? "focus-field" : ""
              }`}
            >
              {centerAlign1.text1}
            </h1>
          )}
        </Col>
        <Col xxl={7} className={`text-center ${isPreview ? "" : "py-3"}`}>
          {centerAlign1.showLink1 && (
            <a
              className={`fw-bold link-offset-2 link-underline link-underline-opacity-0 link-underline-opacity-75-hover ${
                isPreview ? "preview-scaling" : ""
              } ${selectedField === "link1Value" ? "focus-field" : ""}`}
              href={"https://" + centerAlign1.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {centerAlign1.link1Title} <ChevronRight />
            </a>
          )}
        </Col>
      </Col>
    </Row>
  );
}

export default CA1