import React from 'react'
import { Outlet } from 'react-router-dom';
import { Container } from 'react-bootstrap';

const LiveSite = () => {

  return (
    <Container fluid className="min-vh-100 p-0">
        <Outlet />
    </Container>
  )
}

export default LiveSite