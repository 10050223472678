import React from 'react'
import VerifyEmailCard from '../Components/ApplicationComponents/ServiceComponents/VerifyEmailCard'

const VerifyEmailPage = () => {
  return (
    <>
        <VerifyEmailCard />
    </>
  )
}

export default VerifyEmailPage