import { createSlice } from "@reduxjs/toolkit";
import { getAllPages } from "../Services/PageService";

export const initializeExistingPage = () => async (dispatch) => {
  try {
    const response = await getAllPages();
    const responseBody = await response.text();
    const data = JSON.parse(responseBody);
    // Dispatch the addExistingPage action to update the state
    dispatch(setExistingPage(data));
  } catch (error) {
    // LOCAL TESTING
    dispatch(
      setExistingPage([

        {
          id: 1,
          companyName: "TesterCompany",
          pageName: "Home Page",
          pageType: "Home",
          body: [
            {
              rowIdState: [
                {
                  bodyState: {
                    button1: undefined,
                    button1Link: undefined,
                    header1: undefined,
                    header1Icon: undefined,
                    header1IconLink: undefined,
                    header2: undefined,
                    header2Icon: undefined,
                    header2IconLink: undefined,
                    header3: undefined,
                    header3Icon: undefined,
                    header3IconLink: undefined,
                    header4: undefined,
                    header4Icon: undefined,
                    header4IconLink: undefined,
                    headline: "First Reducer",
                    icon1: undefined,
                    icon1Link: undefined,
                    icon2: undefined,
                    icon2Link: undefined,
                    imgLink1: undefined,
                    imgLink2: undefined,
                    imgLink3: undefined,
                    imgSrc1: undefined,
                    imgSrc2: undefined,
                    imgSrc3: undefined,
                    link1Title: undefined,
                    link1Value: undefined,
                    list1: undefined,
                    list2: undefined,
                    list3: undefined,
                    list4: undefined,
                    showButton1: true,
                    showHeader1: true,
                    showHeader1Icon: true,
                    showHeader2: true,
                    showHeader2Icon: true,
                    showHeader3: true,
                    showHeader3Icon: true,
                    showHeader4: true,
                    showHeader4Icon: true,
                    showHeadline: true,
                    showIcon1: true,
                    showIcon2: true,
                    showImg1: true,
                    showImg2: true,
                    showImg3: true,
                    showLink1: true,
                    showList1: true,
                    showList2: true,
                    showList3: true,
                    showList4: true,
                    showText1: false,
                    showText2: true,
                    showText3: true,
                    showText4: true,
                    text1Value: undefined,
                    text2Value: undefined,
                    text3Value: undefined,
                    text4Value: undefined,
                  },
                  rowId: 1,
                },
              ],
              selectedBodyRows: [
                {
                  bodyRowComponent: {
                    componentName: "LA1",
                    props: {
                      headline: "Medium Length display headline",
                      imgAlt1: "Placeholder Img",
                      imgLink1: "",
                      imgSrc1:
                        "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
                      link1Title: "Action Link",
                      link1Value: "",
                      text1:
                        "Separated they live in Bookmarks right at the coast of the famous Semantics, large language ocean Seperated they live in the Bookmarks right",
                    },
                  },
                  rowId: 1,
                  name: "LA1",
                },
              ],
            },
          ],
          companyId: "DawsonTest",
          details: "testingDetails",
          footer: [
            {
              footerState: {
                cityValue: undefined,
                companyFooterValue: undefined,
                emailValue: undefined,
                fbLink: "",
                igLink: "",
                legLink: "",
                liLink: "",
                madeInValue: undefined,
                phoneValue: undefined,
                pinLink: "",
                privacyLink: "",
                showAddress: true,
                showCompanyFooter: true,
                showEmail: true,
                showFbChecked: true,
                showIGChecked: true,
                showLIChecked: true,
                showLanguage: true,
                showLegal: true,
                showMadeIn: true,
                showPINChecked: true,
                showPhone: true,
                showPrivacy: true,
                showSendEmail: true,
                showSiteMap: true,
                showText1: true,
                showTikChecked: true,
                showTos: true,
                showXChecked: true,
                siteLink: "",
                socialIconsChecked: true,
                streetValue: undefined,
                text1Value: "First",
                tikLink: "",
                tosLink: "",
                xLink: "",
              },
              selectedFooter: {
                footComponent: {
                  componentName: "Footer1",
                  props: {
                    cityStateZip: "City, State, Zip",
                    companyFooter: "2018 Acme. All rights reserved.",
                    companyName: "ACME",
                    email: "info@email.com",
                    link1: "testhome",
                    link1Title: "First Page",
                    link2: "",
                    link2Title: "Second Page",
                    link3: "",
                    link3Title: "Third Page",
                    link4: "",
                    link4Title: "Fourth Page",
                    madeIn: "Made in Texas",
                    phoneNumber: "800-555-5555",
                    privacyPolicy: "Privacy Policy",
                    privacyPolicyLink: "",
                    socialLinkFB: "",
                    socialLinkIG: "",
                    socialLinkLI: "",
                    socialLinkPIN: "",
                    socialLinkTik: "",
                    socialLinkX: "",
                    streetAddress: "Street Address",
                    termsOfService: "Terms of Service",
                    termsOfServiceLink: "",
                    text1:
                      "Change the color to match your band or vision, add your logo, choose the perfect thumbnail, remove the playbar, add speed controls, and more",
                  },
                  id: 1,
                  name: "Footer1",
                },
              },
            },
          ],
          navbar: [
            {
              id: 1,
              name: "Navbar1",
              navComponent: {
                componentName: "Navbar1",
                props: {
                  companyName: "Company",
                },
              },
            },
          ],
        },
        {
          id: 2,
          companyName: "Second",
          pageName: "About",
          pageType: "Second",
          body: [
            {
              rowIdState: [
                {
                  bodyState: {
                    button1: undefined,
                    button1Link: undefined,
                    header1: undefined,
                    header1Icon: undefined,
                    header1IconLink: undefined,
                    header2: undefined,
                    header2Icon: undefined,
                    header2IconLink: undefined,
                    header3: undefined,
                    header3Icon: undefined,
                    header3IconLink: undefined,
                    header4: undefined,
                    header4Icon: undefined,
                    header4IconLink: undefined,
                    headline: "Second Reducer",
                    icon1: undefined,
                    icon1Link: undefined,
                    icon2: undefined,
                    icon2Link: undefined,
                    imgLink1: undefined,
                    imgLink2: undefined,
                    imgLink3: undefined,
                    imgSrc1: undefined,
                    imgSrc2: undefined,
                    imgSrc3: undefined,
                    link1Title: undefined,
                    link1Value: undefined,
                    list1: undefined,
                    list2: undefined,
                    list3: undefined,
                    list4: undefined,
                    showButton1: true,
                    showHeader1: true,
                    showHeader1Icon: true,
                    showHeader2: true,
                    showHeader2Icon: true,
                    showHeader3: true,
                    showHeader3Icon: true,
                    showHeader4: true,
                    showHeader4Icon: true,
                    showHeadline: true,
                    showIcon1: true,
                    showIcon2: true,
                    showImg1: true,
                    showImg2: true,
                    showImg3: true,
                    showLink1: true,
                    showList1: true,
                    showList2: true,
                    showList3: true,
                    showList4: true,
                    showText1: false,
                    showText2: true,
                    showText3: true,
                    showText4: true,
                    text1Value: undefined,
                    text2Value: undefined,
                    text3Value: undefined,
                    text4Value: undefined,
                  },
                  rowId: 1,
                },
              ],
              selectedBodyRows: [
                {
                  bodyRowComponent: {
                    componentName: "RA1",
                    props: {
                      headline: "Medium Length display headline",
                      imgAlt1: "Placeholder Img",
                      imgLink1: "",
                      imgSrc1:
                        "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
                      link1Title: "Action Link",
                      link1Value: "",
                      text1:
                        "Separated they live in Bookmarks right at the coast of the famous Semantics, large language ocean Seperated they live in the Bookmarks right",
                    },
                  },
                  rowId: 1,
                  name: "RA1",
                },
              ],
            },
          ],
          companyId: "DawsonTest",
          details: "testingDetails",
          footer: [
            {
              footerState: {
                cityValue: undefined,
                companyFooterValue: undefined,
                emailValue: undefined,
                fbLink: "",
                igLink: "",
                legLink: "",
                liLink: "",
                madeInValue: undefined,
                phoneValue: undefined,
                pinLink: "",
                privacyLink: "",
                showAddress: true,
                showCompanyFooter: true,
                showEmail: true,
                showFbChecked: true,
                showIGChecked: true,
                showLIChecked: true,
                showLanguage: true,
                showLegal: true,
                showMadeIn: true,
                showPINChecked: true,
                showPhone: true,
                showPrivacy: true,
                showSendEmail: true,
                showSiteMap: true,
                showText1: true,
                showTikChecked: true,
                showTos: true,
                showXChecked: true,
                siteLink: "",
                socialIconsChecked: true,
                streetValue: undefined,
                text1Value: "Second",
                tikLink: "",
                tosLink: "",
                xLink: "",
              },
              selectedFooter: {
                footComponent: {
                  componentName: "Footer1",
                  props: {
                    cityStateZip: "City, State, Zip",
                    companyFooter: "2018 Acme. All rights reserved.",
                    companyName: "ACME",
                    email: "info@email.com",
                    link1: "testhome",
                    link1Title: "First Page",
                    link2: "",
                    link2Title: "Second Page",
                    link3: "",
                    link3Title: "Third Page",
                    link4: "",
                    link4Title: "Fourth Page",
                    madeIn: "Made in Texas",
                    phoneNumber: "800-555-5555",
                    privacyPolicy: "Privacy Policy",
                    privacyPolicyLink: "",
                    socialLinkFB: "",
                    socialLinkIG: "",
                    socialLinkLI: "",
                    socialLinkPIN: "",
                    socialLinkTik: "",
                    socialLinkX: "",
                    streetAddress: "Street Address",
                    termsOfService: "Terms of Service",
                    termsOfServiceLink: "",
                    text1:
                      "Change the color to match your band or vision, add your logo, choose the perfect thumbnail, remove the playbar, add speed controls, and more",
                  },
                  id: 1,
                  name: "Footer1",
                },
              },
            },
          ],
          navbar: [
            {
              id: 1,
              name: "Navbar1",
              navComponent: {
                componentName: "Navbar1",
                props: {
                  companyName: "Company",
                },
              },
            },
          ],
        },
        {
          id: 2,
          companyName: "Second",
          pageName: "About",
          pageType: "Second",
          body: [
            {
              rowIdState: [
                {
                  bodyState: {
                    button1: undefined,
                    button1Link: undefined,
                    header1: undefined,
                    header1Icon: undefined,
                    header1IconLink: undefined,
                    header2: undefined,
                    header2Icon: undefined,
                    header2IconLink: undefined,
                    header3: undefined,
                    header3Icon: undefined,
                    header3IconLink: undefined,
                    header4: undefined,
                    header4Icon: undefined,
                    header4IconLink: undefined,
                    headline: "Second Reducer",
                    icon1: undefined,
                    icon1Link: undefined,
                    icon2: undefined,
                    icon2Link: undefined,
                    imgLink1: undefined,
                    imgLink2: undefined,
                    imgLink3: undefined,
                    imgSrc1: undefined,
                    imgSrc2: undefined,
                    imgSrc3: undefined,
                    link1Title: undefined,
                    link1Value: undefined,
                    list1: undefined,
                    list2: undefined,
                    list3: undefined,
                    list4: undefined,
                    showButton1: true,
                    showHeader1: true,
                    showHeader1Icon: true,
                    showHeader2: true,
                    showHeader2Icon: true,
                    showHeader3: true,
                    showHeader3Icon: true,
                    showHeader4: true,
                    showHeader4Icon: true,
                    showHeadline: true,
                    showIcon1: true,
                    showIcon2: true,
                    showImg1: true,
                    showImg2: true,
                    showImg3: true,
                    showLink1: true,
                    showList1: true,
                    showList2: true,
                    showList3: true,
                    showList4: true,
                    showText1: false,
                    showText2: true,
                    showText3: true,
                    showText4: true,
                    text1Value: undefined,
                    text2Value: undefined,
                    text3Value: undefined,
                    text4Value: undefined,
                  },
                  rowId: 1,
                },
              ],
              selectedBodyRows: [
                {
                  bodyRowComponent: {
                    componentName: "RA1",
                    props: {
                      headline: "Medium Length display headline",
                      imgAlt1: "Placeholder Img",
                      imgLink1: "",
                      imgSrc1:
                        "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
                      link1Title: "Action Link",
                      link1Value: "",
                      text1:
                        "Separated they live in Bookmarks right at the coast of the famous Semantics, large language ocean Seperated they live in the Bookmarks right",
                    },
                  },
                  rowId: 1,
                  name: "RA1",
                },
              ],
            },
          ],
          companyId: "DawsonTest",
          details: "testingDetails",
          footer: [
            {
              footerState: {
                cityValue: undefined,
                companyFooterValue: undefined,
                emailValue: undefined,
                fbLink: "",
                igLink: "",
                legLink: "",
                liLink: "",
                madeInValue: undefined,
                phoneValue: undefined,
                pinLink: "",
                privacyLink: "",
                showAddress: true,
                showCompanyFooter: true,
                showEmail: true,
                showFbChecked: true,
                showIGChecked: true,
                showLIChecked: true,
                showLanguage: true,
                showLegal: true,
                showMadeIn: true,
                showPINChecked: true,
                showPhone: true,
                showPrivacy: true,
                showSendEmail: true,
                showSiteMap: true,
                showText1: true,
                showTikChecked: true,
                showTos: true,
                showXChecked: true,
                siteLink: "",
                socialIconsChecked: true,
                streetValue: undefined,
                text1Value: "Second",
                tikLink: "",
                tosLink: "",
                xLink: "",
              },
              selectedFooter: {
                footComponent: {
                  componentName: "Footer1",
                  props: {
                    cityStateZip: "City, State, Zip",
                    companyFooter: "2018 Acme. All rights reserved.",
                    companyName: "ACME",
                    email: "info@email.com",
                    link1: "testhome",
                    link1Title: "First Page",
                    link2: "",
                    link2Title: "Second Page",
                    link3: "",
                    link3Title: "Third Page",
                    link4: "",
                    link4Title: "Fourth Page",
                    madeIn: "Made in Texas",
                    phoneNumber: "800-555-5555",
                    privacyPolicy: "Privacy Policy",
                    privacyPolicyLink: "",
                    socialLinkFB: "",
                    socialLinkIG: "",
                    socialLinkLI: "",
                    socialLinkPIN: "",
                    socialLinkTik: "",
                    socialLinkX: "",
                    streetAddress: "Street Address",
                    termsOfService: "Terms of Service",
                    termsOfServiceLink: "",
                    text1:
                      "Change the color to match your band or vision, add your logo, choose the perfect thumbnail, remove the playbar, add speed controls, and more",
                  },
                  id: 1,
                  name: "Footer1",
                },
              },
            },
          ],
          navbar: [
            {
              id: 1,
              name: "Navbar1",
              navComponent: {
                componentName: "Navbar1",
                props: {
                  companyName: "Company",
                },
              },
            },
          ],
        },
        
      ]
      )
    );
  }
};

const initialState = [{
  id: 1,
  companyName: "TesterCompany",
  pageName: "HomePage",
  pageType: "Home",
  body: [
    {
      rowIdState: [
        {
          bodyState: {
            button1: undefined,
            button1Link: undefined,
            header1: undefined,
            header1Icon: undefined,
            header1IconLink: undefined,
            header2: undefined,
            header2Icon: undefined,
            header2IconLink: undefined,
            header3: undefined,
            header3Icon: undefined,
            header3IconLink: undefined,
            header4: undefined,
            header4Icon: undefined,
            header4IconLink: undefined,
            headline: "From the Reducer",
            icon1: undefined,
            icon1Link: undefined,
            icon2: undefined,
            icon2Link: undefined,
            imgLink1: undefined,
            imgLink2: undefined,
            imgLink3: undefined,
            imgSrc1: undefined,
            imgSrc2: undefined,
            imgSrc3: undefined,
            link1Title: undefined,
            link1Value: undefined,
            list1: undefined,
            list2: undefined,
            list3: undefined,
            list4: undefined,
            showButton1: true,
            showHeader1: true,
            showHeader1Icon: true,
            showHeader2: true,
            showHeader2Icon: true,
            showHeader3: true,
            showHeader3Icon: true,
            showHeader4: true,
            showHeader4Icon: true,
            showHeadline: true,
            showIcon1: true,
            showIcon2: true,
            showImg1: true,
            showImg2: true,
            showImg3: true,
            showLink1: true,
            showList1: true,
            showList2: true,
            showList3: true,
            showList4: true,
            showText1: false,
            showText2: true,
            showText3: true,
            showText4: true,
            text1Value: undefined,
            text2Value: undefined,
            text3Value: undefined,
            text4Value: undefined,
          },
          rowId: 1,
        },
      ],
      selectedBodyRows: [
        {
          bodyRowComponent: {
            componentName: "LA1",
            props: {
              headline: "Medium Length display headline",
              imgAlt1: "Placeholder Img",
              imgLink1: "",
              imgSrc1:
                "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
              link1Title: "Action Link",
              link1Value: "",
              text1:
                "Separated they live in Bookmarks right at the coast of the famous Semantics, large language ocean Seperated they live in the Bookmarks right",
            },
          },
          rowId: 1,
          name: "LA1",
        },
      ],
    },
  ],
  companyId: "DawsonTest",
  details: "testingDetails",
  footer: [
    {
      footerState: {
        cityValue: undefined,
        companyFooterValue: undefined,
        emailValue: undefined,
        fbLink: "",
        igLink: "",
        legLink: "",
        liLink: "",
        madeInValue: undefined,
        phoneValue: undefined,
        pinLink: "",
        privacyLink: "",
        showAddress: true,
        showCompanyFooter: true,
        showEmail: true,
        showFbChecked: true,
        showIGChecked: true,
        showLIChecked: true,
        showLanguage: true,
        showLegal: true,
        showMadeIn: true,
        showPINChecked: true,
        showPhone: true,
        showPrivacy: true,
        showSendEmail: true,
        showSiteMap: true,
        showText1: true,
        showTikChecked: true,
        showTos: true,
        showXChecked: true,
        siteLink: "",
        socialIconsChecked: true,
        streetValue: undefined,
        text1Value: undefined,
        tikLink: "",
        tosLink: "",
        xLink: "",
      },
      selectedFooter: {
        footComponent: {
          componentName: "Footer1",
          props: {
            cityStateZip: "City, State, Zip",
            companyFooter: "2018 Acme. All rights reserved.",
            companyName: "ACME",
            email: "info@email.com",
            link1: "testhome",
            link1Title: "First Page",
            link2: "",
            link2Title: "Second Page",
            link3: "",
            link3Title: "Third Page",
            link4: "",
            link4Title: "Fourth Page",
            madeIn: "Made in Texas",
            phoneNumber: "800-555-5555",
            privacyPolicy: "Privacy Policy",
            privacyPolicyLink: "",
            socialLinkFB: "",
            socialLinkIG: "",
            socialLinkLI: "",
            socialLinkPIN: "",
            socialLinkTik: "",
            socialLinkX: "",
            streetAddress: "Street Address",
            termsOfService: "Terms of Service",
            termsOfServiceLink: "",
            text1:
              "Change the color to match your band or vision, add your logo, choose the perfect thumbnail, remove the playbar, add speed controls, and more",
          },
          id: 1,
          name: "Footer1",
        },
      },
    },
  ],
  navbar: [
    {
      id: 1,
      name: "Navbar1",
      navComponent: {
        componentName: "Navbar1",
        props: {
          companyName: "Company",
        },
      },
    },
  ],
}];

const existingPageSlice = createSlice({
  name: "existingPage",
  initialState,
  reducers: {
    setExistingPage: (state, action) => {
      return action.payload; // Set the state to the payload directly
    },
    addExistingPage: (state, action) => {
      return {
        ...state,
        body: [...state.body, ...action.payload.body],
        footer: [...state.footer, ...action.payload.footer],
        navbar: [...state.navbar, ...action.payload.navbar],
      };
    },
    removeExistingPage: (state, action) => {
      const deletedPageName = action.payload;
      return state.filter((page) => page.pageName !== deletedPageName);
    },
    selectExistingPage: (state, action) => {
      return state;
    },
    updateExistingPage: (state, action) => {
      const { id, updatedPage } = action.payload;
      const existingPageIndex = state.findIndex((page) => page.id === id);

      if (existingPageIndex !== -1) {
        // If the existing page is found, update it with the new data
        state[existingPageIndex] = {
          ...state[existingPageIndex],
          ...updatedPage,
        };
      }

      return state;
    },
  },
});

export const { addExistingPage, removeExistingPage, selectExistingPage, updateExistingPage, setExistingPage } =
  existingPageSlice.actions;

export default existingPageSlice.reducer;
