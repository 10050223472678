import React from "react";
import { Col } from "react-bootstrap";
import Gallery1 from "../../../../CustomComponents/BodyComponents/Gallery/Gallery1";
import RenderFooterComponent from "./RenderFooterComponent";
import RenderNavbarComponent from "./RenderNavbarComponent";
import RenderRowComponent from "./RenderRowComponent";

const RenderFinishedPage = ({
  selectedNavbar,
  selectedBodyRows,
  selectedFooter,
  footerState,
  rowIdState,
}) => {
  return (
    <>
      {selectedNavbar !== null && (
        <Col>
          {RenderNavbarComponent(
            selectedNavbar.navComponent.componentName,
            selectedNavbar.navComponent.props
          )}
        </Col>
      )}
      {selectedBodyRows !== undefined &&
        selectedBodyRows
          .slice() // Create a copy of the array to avoid modifying the original
          .sort((a, b) => a.rowId - b.rowId) // Sort by ascending rowId
          .map((selectedBodyRow) => (
            <div key={selectedBodyRow.rowId} className="my-4">
              {rowIdState.map((rowIdStateItem) => {
                if (rowIdStateItem.rowId === selectedBodyRow.rowId) {
                  return RenderRowComponent(
                    selectedBodyRow.bodyRowComponent.componentName,
                    { holderState: rowIdStateItem.bodyState },
                    false,
                    false,
                    rowIdStateItem.rowId
                  );
                }
                return null;
              })}
            </div>
          ))}
        {selectedBodyRows === undefined && (
          <Gallery1 />
        )}
      {selectedFooter !== null && (
        <>
          {RenderFooterComponent(
            selectedFooter.footComponent.componentName,
            { holderState: footerState },
            false
          )}
        </>
      )}
    </>
  );
};

export default RenderFinishedPage;
