import { createSlice } from '@reduxjs/toolkit';

const rowIdSlice = createSlice({
  name: 'rowId',
  initialState: 1, // Initial rowId value
  reducers: {
    setRowId: (state, action) => action.payload,
    incrementRowId: (state) => state + 1,
  },
});

export const { setRowId, incrementRowId } = rowIdSlice.actions;

export default rowIdSlice.reducer;