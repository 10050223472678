import React from 'react'
import { Link } from 'react-router-dom';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { ChevronRight } from 'react-bootstrap-icons';

const Form11 = () => {

  return (
    <Row sm={12} md={12} lg={10} xl={10} xxl={8} style={{"--bs-gutter-x":'0'}} className='d-flex justify-content-center p-5 bg-white'>
        <Col className='d-flex flex-row justify-content-center flex-wrap'>
            <Col sm={12} md={6} xxl={4} className='d-flex flex-column p-5 border-end'>
                <h5 className='fw-semibold mb-3'>Look it up with your account</h5>
                <Form>
                    <Form.Group className="mb-4 w-75">
                        <Form.Control id='email' type="email" placeholder="Email" className='mb-2 bg-light' autoComplete="email" />
                        <Form.Control id='password' type='password' placeholder='Password' className='mt-3 bg-light' />
                    </Form.Group>
                    <Button type='submit' className='d-flex w-75 justify-content-center'>Sign in</Button>
                </Form>
                <Link href='#' className='link-offset-2 link-underline link-underline-opacity-0 link-underline-opacity-75-hover py-3'>
                    Forgot your password <ChevronRight size={12} />
                </Link>                  
            </Col>
            <Col sm={12} md={6} xxl={4} className='d-flex flex-column p-5'>
                <h5 className='fw-semibold mb-4'>Look it up with your order number</h5>
                <Form>
                    <Form.Group className="mb-3 w-75">
                        <Form.Control id='text' type="orderNumber" placeholder="Order number" className='mb-2 bg-light' autoComplete="email" />
                        <Form.Control id='email' type='email' placeholder='Email' className='mt-3 bg-light' />
                    </Form.Group>
                    <Button type='submit' className='d-flex w-75 justify-content-center'>Sign in</Button>
                </Form>
                <Link href='#' className='link-offset-2 link-underline link-underline-opacity-0 link-underline-opacity-75-hover py-3'>
                    How to find your order number <ChevronRight size={12} />
                </Link>
            </Col>
        </Col>
    </Row>
  )
}

export default Form11