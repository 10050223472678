import React, { useState } from 'react';
import { Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

const Form4 = () => {
    const [showPassword, setShowPassword] = useState(false);

  return (
    <Row sm={12} md={12} lg={10} xl={10} xxl={8} style={{"--bs-gutter-x":'0'}} className='d-flex justify-content-center p-5 bg-white'>
        <Col xs={12} md={12} lg={10} xl={10} className="d-flex flex-column align-items-center justify-content-center">
        
            <Col xs={12} sm={8} md={5} lg={5} xl={4} xxl={3} className='d-flex flex-column gap-3'>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Control id='email' type="email" placeholder="Email" className='bg-light' autoComplete="email" />
                        <Form.Control id='phone' type="phone" placeholder="Phone Number" className='mt-3 bg-light' autoComplete="phone" />
                        <InputGroup className='mt-3 bg-light'>
                            <Form.Control
                                id='password'
                                aria-describedby='password'
                                type={showPassword ? 'text' : 'password'}
                                className='bg-light border-end-0'
                                placeholder='Password'
                            />
                            <InputGroup.Text id='password' className='border-start-0' onClick={(e) => setShowPassword(!showPassword)}>
                            {showPassword ? <EyeSlashFill /> : <EyeFill />}
                            </InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                    <div className='d-flex justify-content-center gap-3'>
                        <Button type='submit' variant='outline-primary' className='w-100'>Sign In</Button>
                        <Button type='submit' className='w-100'>Sign in</Button>
                    </div>
                </Form>
                <p className='text-center'>
                    <small>By signing in you agree with the {}
                        <Link href='#' className='link-offset-2 link-underline link-underline-opacity-0 link-underline-opacity-75-hover text-secondary'>
                            Terms and Conditions
                        </Link> and {}
                        <Link href='#' className='link-offset-2 link-underline link-underline-opacity-0 link-underline-opacity-75-hover text-secondary'>
                            Privacy Policy
                        </Link>
                    </small>
                </p>
            </Col>

        </Col>
    </Row>
  )
}

export default Form4