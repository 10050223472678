import React from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';

const Form6 = () => {
  return (
    <Row sm={12} md={12} lg={10} xl={10} xxl={8} style={{"--bs-gutter-x":'0'}} className='d-flex justify-content-center p-5 bg-white'>
        <Col xs={12} md={12} lg={10} xl={10} className="d-flex flex-column align-items-center justify-content-center">
        
            <Col xs={12} sm={8} md={5} lg={5} xl={4} xxl={3} className='d-flex flex-column gap-3'>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Control id='name' type="text" placeholder="Name" className='bg-light' autoComplete="name" />
                        <Form.Select id='topic' className='mt-3 bg-light'>
                            <option>Choose topic</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </Form.Select>
                        <Form.Control as="textarea" rows={3} placeholder='Feedback' className='mt-3 bg-light' />
                    </Form.Group>
                    <Button type='submit' className='d-flex w-100 justify-content-center'>Send Your Feedback</Button>
                </Form>
            </Col>

        </Col>
    </Row>
  )
}

export default Form6