import React from "react";
import Footer1 from "../../../../CustomComponents/FooterComponents/Footer1";
import Footer2 from "../../../../CustomComponents/FooterComponents/Footer2";
import Footer3 from "../../../../CustomComponents/FooterComponents/Footer3";
import Footer4 from "../../../../CustomComponents/FooterComponents/Footer4";
import Footer5 from "../../../../CustomComponents/FooterComponents/Footer5";
import Footer6 from "../../../../CustomComponents/FooterComponents/Footer6";
import Footer7 from "../../../../CustomComponents/FooterComponents/Footer7";
import Footer8 from "../../../../CustomComponents/FooterComponents/Footer8";
import Footer9 from "../../../../CustomComponents/FooterComponents/Footer9";


const RenderFooterComponent = (componentName, props, preview, selectedField) => {
  switch (componentName) {
    case "Footer1":
      return <Footer1 {...props} isPreview={preview} selectedField={selectedField} />;
    case "Footer2":
      return <Footer2 {...props} isPreview={preview} selectedField={selectedField} />;
    case "Footer3":
      return <Footer3 {...props} isPreview={preview} selectedField={selectedField} />;
    case "Footer4":
      return <Footer4 {...props} isPreview={preview} selectedField={selectedField} />;
    case "Footer5":
      return <Footer5 {...props} isPreview={preview} selectedField={selectedField} />;
    case "Footer6":
      return <Footer6 {...props} isPreview={preview} selectedField={selectedField} />;
    case "Footer7":
      return <Footer7 {...props} isPreview={preview} selectedField={selectedField} />;
    case "Footer8":
      return <Footer8 {...props} isPreview={preview} selectedField={selectedField} />;
    case "Footer9":
      return <Footer9 {...props} isPreview={preview} selectedField={selectedField} />;
    default:
      return null;
  }
};

export default RenderFooterComponent;
