import React from "react";
import { Form, Card } from "react-bootstrap";
import IconDropdown from "./IconDropdown"; // Adjust the import based on your project structure

const HeaderIconFormComponent = ({
  showHeaderIcon,
  setShowHeaderIcon,
  setHeaderIcon,
  headerIconLink,
  setHeaderIconLink,
  headerIconNumber,
  setSelectedField,
  selectedField,
}) => {
  return (
    <Card
      className={`col-3 px-0 ${
        selectedField === "header" + headerIconNumber + "Icon" ? "focus-field" : "focus-hover"
      }`}
      onClick={() => setSelectedField("header" + headerIconNumber + "Icon")}
    >
      <Card.Body>
        <Card.Title>Header Icon {headerIconNumber}</Card.Title>
        <Form.Group>
          <Form.Check
            type="checkbox"
            name="headerIcon"
            value="headerIcon"
            label="Display"
            checked={showHeaderIcon}
            onChange={(e) => setShowHeaderIcon(e.target.checked)}
          />
          <IconDropdown setIcon={setHeaderIcon} />
          <Form.Control
            type="text"
            className="my-2"
            placeholder="Enter URL"
            value={headerIconLink}
            onChange={(e) => setHeaderIconLink(e.target.value)}
          />
        </Form.Group>
      </Card.Body>
    </Card>
  );
};

export default HeaderIconFormComponent;
