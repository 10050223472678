import React from "react";
import Gallery1 from "../../../../CustomComponents/BodyComponents/Gallery/Gallery1";
import Gallery2 from "../../../../CustomComponents/BodyComponents/Gallery/Gallery2";
import Gallery3 from "../../../../CustomComponents/BodyComponents/Gallery/Gallery3";
import Gallery4 from "../../../../CustomComponents/BodyComponents/Gallery/Gallery4";
import Gallery5 from "../../../../CustomComponents/BodyComponents/Gallery/Gallery5";
import Gallery6 from "../../../../CustomComponents/BodyComponents/Gallery/Gallery6";
import Gallery7 from "../../../../CustomComponents/BodyComponents/Gallery/Gallery7";
import Gallery8 from "../../../../CustomComponents/BodyComponents/Gallery/Gallery8";
import Gallery9 from "../../../../CustomComponents/BodyComponents/Gallery/Gallery9";
import Gallery10 from "../../../../CustomComponents/BodyComponents/Gallery/Gallery10";
import Gallery11 from "../../../../CustomComponents/BodyComponents/Gallery/Gallery11";
import Gallery12 from "../../../../CustomComponents/BodyComponents/Gallery/Gallery12";
import Gallery13 from "../../../../CustomComponents/BodyComponents/Gallery/Gallery13";
import Gallery14 from "../../../../CustomComponents/BodyComponents/Gallery/Gallery14";
import Gallery15 from "../../../../CustomComponents/BodyComponents/Gallery/Gallery15";
import LA1 from "../../../../CustomComponents/BodyComponents/Left-Aligned-Covers/LA1";
import LA2 from "../../../../CustomComponents/BodyComponents/Left-Aligned-Covers/LA2";
import LA3 from "../../../../CustomComponents/BodyComponents/Left-Aligned-Covers/LA3";
import LA4 from "../../../../CustomComponents/BodyComponents/Left-Aligned-Covers/LA4";
import LA5 from "../../../../CustomComponents/BodyComponents/Left-Aligned-Covers/LA5";
import LA6 from "../../../../CustomComponents/BodyComponents/Left-Aligned-Covers/LA6";
import LA7 from "../../../../CustomComponents/BodyComponents/Left-Aligned-Covers/LA7";
import LA8 from "../../../../CustomComponents/BodyComponents/Left-Aligned-Covers/LA8";
import LA9 from "../../../../CustomComponents/BodyComponents/Left-Aligned-Covers/LA9";
import LA10 from "../../../../CustomComponents/BodyComponents/Left-Aligned-Covers/LA10";
import LA11 from "../../../../CustomComponents/BodyComponents/Left-Aligned-Covers/LA11";
import LA12 from "../../../../CustomComponents/BodyComponents/Left-Aligned-Covers/LA12";
import LA13 from "../../../../CustomComponents/BodyComponents/Left-Aligned-Covers/LA13";
import LA14 from "../../../../CustomComponents/BodyComponents/Left-Aligned-Covers/LA14";
import LA15 from "../../../../CustomComponents/BodyComponents/Left-Aligned-Covers/LA15";
import LA16 from "../../../../CustomComponents/BodyComponents/Left-Aligned-Covers/LA16";
import LA17 from "../../../../CustomComponents/BodyComponents/Left-Aligned-Covers/LA17";
import LA18 from "../../../../CustomComponents/BodyComponents/Left-Aligned-Covers/LA18";
import RA1 from "../../../../CustomComponents/BodyComponents/Right-Aligned-Covers/RA1";
import RA2 from "../../../../CustomComponents/BodyComponents/Right-Aligned-Covers/RA2";
import RA3 from "../../../../CustomComponents/BodyComponents/Right-Aligned-Covers/RA3";
import RA4 from "../../../../CustomComponents/BodyComponents/Right-Aligned-Covers/RA4";
import RA5 from "../../../../CustomComponents/BodyComponents/Right-Aligned-Covers/RA5";
import RA6 from "../../../../CustomComponents/BodyComponents/Right-Aligned-Covers/RA6";
import RA7 from "../../../../CustomComponents/BodyComponents/Right-Aligned-Covers/RA7";
import RA8 from "../../../../CustomComponents/BodyComponents/Right-Aligned-Covers/RA8";
import RA9 from "../../../../CustomComponents/BodyComponents/Right-Aligned-Covers/RA9";
import RA10 from "../../../../CustomComponents/BodyComponents/Right-Aligned-Covers/RA10";
import RA11 from "../../../../CustomComponents/BodyComponents/Right-Aligned-Covers/RA11";
import RA12 from "../../../../CustomComponents/BodyComponents/Right-Aligned-Covers/RA12";
import RA13 from "../../../../CustomComponents/BodyComponents/Right-Aligned-Covers/RA13";
import RA14 from "../../../../CustomComponents/BodyComponents/Right-Aligned-Covers/RA14";
import RA15 from "../../../../CustomComponents/BodyComponents/Right-Aligned-Covers/RA15";
import RA16 from "../../../../CustomComponents/BodyComponents/Right-Aligned-Covers/RA16";
import RA17 from "../../../../CustomComponents/BodyComponents/Right-Aligned-Covers/RA17";
import RA18 from "../../../../CustomComponents/BodyComponents/Right-Aligned-Covers/RA18";
import CA1 from "../../../../CustomComponents/BodyComponents/Center-Aligned-Covers/CA1";
import CA2 from "../../../../CustomComponents/BodyComponents/Center-Aligned-Covers/CA2";
import CA3 from "../../../../CustomComponents/BodyComponents/Center-Aligned-Covers/CA3";
import CA4 from "../../../../CustomComponents/BodyComponents/Center-Aligned-Covers/CA4";
import CA5 from "../../../../CustomComponents/BodyComponents/Center-Aligned-Covers/CA5";
import CA6 from "../../../../CustomComponents/BodyComponents/Center-Aligned-Covers/CA6";
import CA7 from "../../../../CustomComponents/BodyComponents/Center-Aligned-Covers/CA7";
import CA8 from "../../../../CustomComponents/BodyComponents/Center-Aligned-Covers/CA8";
import CA9 from "../../../../CustomComponents/BodyComponents/Center-Aligned-Covers/CA9";
import CA10 from "../../../../CustomComponents/BodyComponents/Center-Aligned-Covers/CA10";
import CA11 from "../../../../CustomComponents/BodyComponents/Center-Aligned-Covers/CA11";
import CA12 from "../../../../CustomComponents/BodyComponents/Center-Aligned-Covers/CA12";
import CA13 from "../../../../CustomComponents/BodyComponents/Center-Aligned-Covers/CA13";
import CA14 from "../../../../CustomComponents/BodyComponents/Center-Aligned-Covers/CA14";
import CA15 from "../../../../CustomComponents/BodyComponents/Center-Aligned-Covers/CA15";
import CA16 from "../../../../CustomComponents/BodyComponents/Center-Aligned-Covers/CA16";
import CA17 from "../../../../CustomComponents/BodyComponents/Center-Aligned-Covers/CA17";
import CA18 from "../../../../CustomComponents/BodyComponents/Center-Aligned-Covers/CA18";
import CA19 from "../../../../CustomComponents/BodyComponents/Center-Aligned-Covers/CA19";

const RenderRowComponent = (
  componentName,
  props,
  preview,
  selectedField,
  key
) => {
  switch (componentName) {
    case "Gallery1":
      return (
        <Gallery1
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "Gallery2":
      return (
        <Gallery2
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "Gallery3":
      return (
        <Gallery3
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "Gallery4":
      return (
        <Gallery4
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "Gallery5":
      return (
        <Gallery5
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "Gallery6":
      return (
        <Gallery6
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "Gallery7":
      return (
        <Gallery7
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "Gallery8":
      return (
        <Gallery8
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "Gallery9":
      return (
        <Gallery9
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "Gallery10":
      return (
        <Gallery10
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "Gallery11":
      return (
        <Gallery11
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "Gallery12":
      return (
        <Gallery12
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "Gallery13":
      return (
        <Gallery13
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "Gallery14":
      return (
        <Gallery14
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "Gallery15":
      return (
        <Gallery15
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "LA1":
      return (
        <LA1
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "LA2":
      return (
        <LA2
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "LA3":
      return (
        <LA3
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "LA4":
      return (
        <LA4
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "LA5":
      return (
        <LA5
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "LA6":
      return (
        <LA6
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "LA7":
      return (
        <LA7
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "LA8":
      return (
        <LA8
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "LA9":
      return (
        <LA9
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "LA10":
      return (
        <LA10
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "LA11":
      return (
        <LA11
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "LA12":
      return (
        <LA12
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "LA13":
      return (
        <LA13
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "LA14":
      return (
        <LA14
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "LA15":
      return (
        <LA15
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "LA16":
      return (
        <LA16
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "LA17":
      return (
        <LA17
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "LA18":
      return (
        <LA18
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "RA1":
      return (
        <RA1
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "RA2":
      return (
        <RA2
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "RA3":
      return (
        <RA3
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "RA4":
      return (
        <RA4
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "RA5":
      return (
        <RA5
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "RA6":
      return (
        <RA6
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "RA7":
      return (
        <RA7
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "RA8":
      return (
        <RA8
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "RA9":
      return (
        <RA9
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "RA10":
      return (
        <RA10
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "RA11":
      return (
        <RA11
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "RA12":
      return (
        <RA12
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "RA13":
      return (
        <RA13
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "RA14":
      return (
        <RA14
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "RA15":
      return (
        <RA15
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "RA16":
      return (
        <RA16
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "RA17":
      return (
        <RA17
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "RA18":
      return (
        <RA18
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "CA1":
      return (
        <CA1
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "CA2":
      return (
        <CA2
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "CA3":
      return (
        <CA3
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "CA4":
      return (
        <CA4
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "CA5":
      return (
        <CA5
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "CA6":
      return (
        <CA6
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "CA7":
      return (
        <CA7
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "CA8":
      return (
        <CA8
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "CA9":
      return (
        <CA9
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "CA10":
      return (
        <CA10
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "CA11":
      return (
        <CA11
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "CA12":
      return (
        <CA12
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "CA13":
      return (
        <CA13
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "CA14":
      return (
        <CA14
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "CA15":
      return (
        <CA15
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "CA16":
      return (
        <CA16
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "CA17":
      return (
        <CA17
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "CA18":
      return (
        <CA18
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    case "CA19":
      return (
        <CA19
          {...props}
          isPreview={preview}
          selectedField={selectedField}
          key={key}
        />
      );
    default:
      return null;
  }
};

export default RenderRowComponent;
