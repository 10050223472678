import React, { useState } from 'react';
import { Button, Container, Nav, Navbar, Offcanvas, Form, Fade } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';

const Navbar4 = ({isPreview}) => {
  const [hide, setHide] = useState(true);

  return (
    <Navbar
      expand="md"
      className={`bg-white border ${isPreview ? "preview-scaling" : ""}`}
    >
      <Container fluid>
        <Navbar.Brand
          className={`${isPreview ? "preview-scaling-logo" : ""}`}
          href="#"
        >
          ACME
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="offcanvasNavbar-expand-md" />
        <Navbar.Offcanvas
          id="offcanvasNavbar-expand-md"
          aria-labelledby="offcanvasNavbarLabel-expand-md"
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="offcanvasNavbarLabel-expand-md">
              Offcanvas
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3 align-items-center">
              <Nav.Link href="#">First</Nav.Link>
              <Nav.Link href="#">Second</Nav.Link>
              <Nav.Link href="#">Third</Nav.Link>
              <Nav.Link href="#">Fourth</Nav.Link>
              <Nav.Link href="#">Fifth</Nav.Link>
              <Nav.Link href="#">Sixth</Nav.Link>
            </Nav>
            <Fade in={!hide}>
              <Form className="d-flex">
                <Form.Control
                  hidden={hide}
                  type="search"
                  placeholder="Search"
                  className=""
                  aria-label="Search"
                />
              </Form>
            </Fade>
            <Button
              className='d-flex align-items-center'
              variant=""
              onClick={() => setHide(!hide)}
            >
              <Search size={18} />
            </Button>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default Navbar4;
