import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const bodyRowsSlice = createSlice({
  name: "bodyRows",
  initialState,
  reducers: {
    addBodyRow: (state, action) => {
      state.push(action.payload);
    },
    removeBodyRow: (state, action) => {
      return state.filter((row) => row.rowId !== action.payload);
    },
    resetBodyRows: (state) => {
      return [];
    },
    setNewBodyRows: (state, action) => {
      return action.payload; // Assuming action.payload is the new state you want to set
    },
  },
});

export const { addBodyRow, removeBodyRow, resetBodyRows, setNewBodyRows } = bodyRowsSlice.actions;
export default bodyRowsSlice.reducer;
