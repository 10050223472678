import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

const Gallery15 = ({ isPreview, holderState, selectedField }) => {
  const [gal15, setGal15] = useState({
    showImg1: holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
    showImg2: holderState?.showImg2 !== undefined ? holderState.showImg2 : true,
    showImg3: holderState?.showImg3 !== undefined ? holderState.showImg3 : true,
    showImg4: holderState?.showImg4 !== undefined ? holderState.showImg4 : true,
    showImg5: holderState?.showImg5 !== undefined ? holderState.showImg5 : true,
    showImg6: holderState?.showImg6 !== undefined ? holderState.showImg6 : true,
    showImg7: holderState?.showImg7 !== undefined ? holderState.showImg7 : true,
    showImg8: holderState?.showImg8 !== undefined ? holderState.showImg8 : true,
    imgLink1: holderState?.imgLink1 !== undefined ? holderState.imgLink1 : "",
    imgLink2: holderState?.imgLink2 !== undefined ? holderState.imgLink2 : "",
    imgLink3: holderState?.imgLink3 !== undefined ? holderState.imgLink3 : "",
    imgLink4: holderState?.imgLink4 !== undefined ? holderState.imgLink4 : "",
    imgLink5: holderState?.imgLink5 !== undefined ? holderState.imgLink5 : "",
    imgLink6: holderState?.imgLink6 !== undefined ? holderState.imgLink6 : "",
    imgLink7: holderState?.imgLink7 !== undefined ? holderState.imgLink7 : "",
    imgLink8: holderState?.imgLink8 !== undefined ? holderState.imgLink8 : "",
    imgSrc1:
      holderState?.imgSrc1 !== undefined
        ? holderState.imgSrc1
        : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt1: "Placeholder Img",
    imgSrc2:
      holderState?.imgSrc2 !== undefined
        ? holderState.imgSrc2
        : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt2: "Placeholder Img",
    imgSrc3:
      holderState?.imgSrc3 !== undefined
        ? holderState.imgSrc3
        : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt3: "Placeholder Img",
    imgSrc4:
      holderState?.imgSrc4 !== undefined
        ? holderState.imgSrc4
        : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt4: "Placeholder Img",
    imgSrc5:
      holderState?.imgSrc5 !== undefined
        ? holderState.imgSrc5
        : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt5: "Placeholder Img",
    imgSrc6:
      holderState?.imgSrc6 !== undefined
        ? holderState.imgSrc6
        : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt6: "Placeholder Img",
    imgSrc7:
      holderState?.imgSrc7 !== undefined
        ? holderState.imgSrc7
        : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt7: "Placeholder Img",
    imgSrc8:
      holderState?.imgSrc8 !== undefined
        ? holderState.imgSrc8
        : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt8: "Placeholder Img",
  });

  useEffect(() => {
    setGal15((prevGal15) => ({
      ...prevGal15,
      showImg1:
        holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
      showImg2:
        holderState?.showImg2 !== undefined ? holderState.showImg2 : true,
      showImg3:
        holderState?.showImg3 !== undefined ? holderState.showImg3 : true,
      showImg4:
        holderState?.showImg4 !== undefined ? holderState.showImg4 : true,
      showImg5:
        holderState?.showImg5 !== undefined ? holderState.showImg5 : true,
      showImg6:
        holderState?.showImg6 !== undefined ? holderState.showImg6 : true,
      showImg7:
        holderState?.showImg7 !== undefined ? holderState.showImg7 : true,
      showImg8:
        holderState?.showImg8 !== undefined ? holderState.showImg8 : true,
      imgLink1: holderState?.imgLink1 !== undefined ? holderState.imgLink1 : "",
      imgLink2: holderState?.imgLink2 !== undefined ? holderState.imgLink2 : "",
      imgLink3: holderState?.imgLink3 !== undefined ? holderState.imgLink3 : "",
      imgLink4: holderState?.imgLink4 !== undefined ? holderState.imgLink4 : "",
      imgLink5: holderState?.imgLink5 !== undefined ? holderState.imgLink5 : "",
      imgLink6: holderState?.imgLink6 !== undefined ? holderState.imgLink6 : "",
      imgLink7: holderState?.imgLink7 !== undefined ? holderState.imgLink7 : "",
      imgLink8: holderState?.imgLink8 !== undefined ? holderState.imgLink8 : "",
      imgSrc1:
        holderState?.imgSrc1 !== undefined
          ? holderState.imgSrc1
          : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgAlt1: "Placeholder Img",
      imgSrc2:
        holderState?.imgSrc2 !== undefined
          ? holderState.imgSrc2
          : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgAlt2: "Placeholder Img",
      imgSrc3:
        holderState?.imgSrc3 !== undefined
          ? holderState.imgSrc3
          : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgAlt3: "Placeholder Img",
      imgSrc4:
        holderState?.imgSrc4 !== undefined
          ? holderState.imgSrc4
          : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgAlt4: "Placeholder Img",
      imgSrc5:
        holderState?.imgSrc5 !== undefined
          ? holderState.imgSrc5
          : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgAlt5: "Placeholder Img",
      imgSrc6:
        holderState?.imgSrc6 !== undefined
          ? holderState.imgSrc6
          : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgAlt6: "Placeholder Img",
      imgSrc7:
        holderState?.imgSrc7 !== undefined
          ? holderState.imgSrc7
          : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgAlt7: "Placeholder Img",
      imgSrc8:
        holderState?.imgSrc8 !== undefined
          ? holderState.imgSrc8
          : "https://placehold.jp/EAF3FF/EAF3FF/400x300.png?css=%7B%22border-radius%22%3A%2215px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgAlt8: "Placeholder Img",
    }));
  }, [
    holderState?.showImg1,
    holderState?.showImg2,
    holderState?.showImg3,
    holderState?.showImg4,
    holderState?.showImg5,
    holderState?.showImg6,
    holderState?.showImg7,
    holderState?.showImg8,
    holderState?.imgLink1,
    holderState?.imgLink2,
    holderState?.imgLink3,
    holderState?.imgLink4,
    holderState?.imgLink5,
    holderState?.imgLink6,
    holderState?.imgLink7,
    holderState?.imgLink8,
    holderState?.imgSrc1,
    holderState?.imgSrc2,
    holderState?.imgSrc3,
    holderState?.imgSrc4,
    holderState?.imgSrc5,
    holderState?.imgSrc6,
    holderState?.imgSrc7,
    holderState?.imgSrc8,
  ]);

  return (
    <Row
      xs={12}
      sm={8}
      className={`d-flex justify-content-center bg-white ${
        isPreview ? "px-3 pt-3" : "p-5"
      }`}
    >
      <Container fluid>
        <Row className="image-row flex-nowrap overflow-auto">
          {gal15.showImg1 && (
            <Col xs="3">
              <div
                className={`text-center ${
                  selectedField === "imgSrc1" ? "focus-field" : ""
                }`}
              >
                {gal15.imgLink1 !== "" && (
                  <a
                    href={"https://" + gal15.imgLink1}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image src={gal15.imgSrc1} alt={gal15.imgAlt1} fluid />
                  </a>
                )}
                {gal15.imgLink1 === "" && (
                  <Image src={gal15.imgSrc1} alt={gal15.imgAlt1} fluid />
                )}
              </div>
            </Col>
          )}
          {gal15.showImg2 && (
            <Col xs="3">
              <div
                className={`text-center ${
                  selectedField === "imgSrc2" ? "focus-field" : ""
                }`}
              >
                {gal15.imgLink2 !== "" && (
                  <a
                    href={"https://" + gal15.imgLink2}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image src={gal15.imgSrc2} alt={gal15.imgAlt2} fluid />
                  </a>
                )}
                {gal15.imgLink2 === "" && (
                  <Image src={gal15.imgSrc2} alt={gal15.imgAlt2} fluid />
                )}
              </div>
            </Col>
          )}
          {gal15.showImg3 && (
            <Col xs="3">
              <div
                className={`text-center ${
                  selectedField === "imgSrc3" ? "focus-field" : ""
                }`}
              >
                {gal15.imgLink3 !== "" && (
                  <a
                    href={"https://" + gal15.imgLink3}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image src={gal15.imgSrc3} alt={gal15.imgAlt3} fluid />
                  </a>
                )}
                {gal15.imgLink3 === "" && (
                  <Image src={gal15.imgSrc3} alt={gal15.imgAlt3} fluid />
                )}
              </div>
            </Col>
          )}
          {gal15.showImg4 && (
            <Col xs="3">
              <div
                className={`text-center ${
                  selectedField === "imgSrc4" ? "focus-field" : ""
                }`}
              >
                {gal15.imgLink4 !== "" && (
                  <a
                    href={"https://" + gal15.imgLink4}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image src={gal15.imgSrc4} alt={gal15.imgAlt4} fluid />
                  </a>
                )}
                {gal15.imgLink4 === "" && (
                  <Image src={gal15.imgSrc4} alt={gal15.imgAlt4} fluid />
                )}
              </div>
            </Col>
          )}
          {gal15.showImg5 && (
            <Col xs="3">
              <div
                className={`text-center ${
                  selectedField === "imgSrc5" ? "focus-field" : ""
                }`}
              >
                {gal15.imgLink5 !== "" && (
                  <a
                    href={"https://" + gal15.imgLink5}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image src={gal15.imgSrc5} alt={gal15.imgAlt5} fluid />
                  </a>
                )}
                {gal15.imgLink5 === "" && (
                  <Image src={gal15.imgSrc5} alt={gal15.imgAlt5} fluid />
                )}
              </div>
            </Col>
          )}
          {gal15.showImg6 && (
            <Col xs="3">
              <div
                className={`text-center ${
                  selectedField === "imgSrc6" ? "focus-field" : ""
                }`}
              >
                {gal15.imgLink6 !== "" && (
                  <a
                    href={"https://" + gal15.imgLink6}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image src={gal15.imgSrc6} alt={gal15.imgAlt6} fluid />
                  </a>
                )}
                {gal15.imgLink6 === "" && (
                  <Image src={gal15.imgSrc6} alt={gal15.imgAlt6} fluid />
                )}
              </div>
            </Col>
          )}
          {gal15.showImg7 && (
            <Col xs="3">
              <div
                className={`text-center ${
                  selectedField === "imgSrc7" ? "focus-field" : ""
                }`}
              >
                {gal15.imgLink7 !== "" && (
                  <a
                    href={"https://" + gal15.imgLink7}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image src={gal15.imgSrc7} alt={gal15.imgAlt7} fluid />
                  </a>
                )}
                {gal15.imgLink7 === "" && (
                  <Image src={gal15.imgSrc7} alt={gal15.imgAlt7} fluid />
                )}
              </div>
            </Col>
          )}
          {gal15.showImg8 && (
            <Col xs="3">
              <div
                className={`text-center ${
                  selectedField === "imgSrc8" ? "focus-field" : ""
                }`}
              >
                {gal15.imgLink8 !== "" && (
                  <a
                    href={"https://" + gal15.imgLink8}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image src={gal15.imgSrc8} alt={gal15.imgAlt8} fluid />
                  </a>
                )}
                {gal15.imgLink8 === "" && (
                  <Image src={gal15.imgSrc8} alt={gal15.imgAlt8} fluid />
                )}
              </div>
            </Col>
          )}
        </Row>
      </Container>
    </Row>
  );
};

export default Gallery15;
