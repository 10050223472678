import React, { useEffect, useState } from "react";
import { Row, Col, Image } from "react-bootstrap";
import RenderIconComponent from "../../../ApplicationComponents/DashboardComponents/ComponentChoices/ChoicesComponents/RenderIconComponent";

const LA18 = ({ isPreview, holderState, selectedField }) => {
  const [leftAlign18, setLeftAlign18] = useState({
    text1:
      holderState?.text1Value !== undefined
        ? holderState.text1Value
        : "Gorgeous, high-quality video sharing on desktop, mobile, tablet",
    showText1:
      holderState?.showText1 !== undefined ? holderState.showText1 : true,
    text2:
      holderState?.text2Value !== undefined
        ? holderState.text2Value
        : "Gorgeous, high-quality video sharing on desktop, mobile, tablet",
    showText2:
      holderState?.showText2 !== undefined ? holderState.showText2 : true,
    text3:
      holderState?.text3Value !== undefined
        ? holderState.text3Value
        : "Gorgeous, high-quality video sharing on desktop, mobile, tablet",
    showText3:
      holderState?.showText3 !== undefined ? holderState.showText3 : true,
    text4:
      holderState?.text4Value !== undefined
        ? holderState.text4Value
        : "Gorgeous, high-quality video sharing on desktop, mobile, tablet",
    showText4:
      holderState?.showText4 !== undefined ? holderState.showText4 : true,
    header1Icon: {
      type:
        holderState?.header1Icon?.type !== undefined
          ? holderState.header1Icon.type
          : "Grid1x2",
      size:
        holderState?.header1Icon?.size !== undefined
          ? holderState.header1Icon.size
          : isPreview
          ? 12
          : 32,
    },
    header2Icon: {
      type:
        holderState?.header2Icon?.type !== undefined
          ? holderState.header2Icon.type
          : "CircleHalf",
      size:
        holderState?.header2Icon?.size !== undefined
          ? holderState.header2Icon.size
          : isPreview
          ? 12
          : 32,
    },
    header3Icon: {
      type:
        holderState?.header3Icon?.type !== undefined
          ? holderState.header3Icon.type
          : "SquareHalf",
      size:
        holderState?.header3Icon?.size !== undefined
          ? holderState.header3Icon.size
          : isPreview
          ? 12
          : 32,
    },
    header4Icon: {
      type:
        holderState?.header4Icon?.type !== undefined
          ? holderState.header4Icon.type
          : "UiRadiosGrid",
      size:
        holderState?.header4Icon?.size !== undefined
          ? holderState.header4Icon.size
          : isPreview
          ? 12
          : 32,
    },
    header1IconLink:
      holderState?.header1IconLink !== undefined
        ? holderState.header1IconLink
        : "",
    header2IconLink:
      holderState?.header2IconLink !== undefined
        ? holderState.header2IconLink
        : "",
    header3IconLink:
      holderState?.header3IconLink !== undefined
        ? holderState.header3IconLink
        : "",
    header4IconLink:
      holderState?.header4IconLink !== undefined
        ? holderState.header4IconLink
        : "",
    showHeader1Icon:
      holderState?.showHeader1Icon !== undefined
        ? holderState.showHeader1Icon
        : true,
    showHeader2Icon:
      holderState?.showHeader2Icon !== undefined
        ? holderState.showHeader2Icon
        : true,
    showHeader3Icon:
      holderState?.showHeader3Icon !== undefined
        ? holderState.showHeader3Icon
        : true,
    showHeader4Icon:
      holderState?.showHeader4Icon !== undefined
        ? holderState.showHeader4Icon
        : true,
    header1:
      holderState?.header1 !== undefined ? holderState.header1 : "Feature One",
    showHeader1:
      holderState?.showHeader1 !== undefined ? holderState.showHeader1 : true,
    header2:
      holderState?.header2 !== undefined ? holderState.header2 : "Feature Two",
    showHeader2:
      holderState?.showHeader2 !== undefined ? holderState.showHeader2 : true,
    header3:
      holderState?.header3 !== undefined
        ? holderState.header3
        : "Feature Three",
    showHeader3:
      holderState?.showHeader3 !== undefined ? holderState.showHeader3 : true,
    header4:
      holderState?.header4 !== undefined ? holderState.header4 : "Feature Four",
    showHeader4:
      holderState?.showHeader4 !== undefined ? holderState.showHeader4 : true,
    showImg1: holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
    imgLink1: holderState?.imgLink1 !== undefined ? holderState.imgLink1 : "",
    imgSrc1:
      holderState?.imgSrc1 !== undefined
        ? holderState.imgSrc1
        : "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt1: "Placeholder Image",
  });

  const imageContainerStyle = {
    display: "flex",
    alignItems: "center",
    height: "100%",
    maxHeight: "100%",
    borderRadius: "1rem",
    overflow: "hidden",
  };
  const imageStyle = {
    objectFit: "cover",
    height: "22rem",
    width: "100%",
    borderRadius: "1rem",
  };

  useEffect(() => {
    setLeftAlign18((prevLA18) => ({
      ...prevLA18,
      text1:
        holderState?.text1Value !== undefined
          ? holderState.text1Value
          : "Gorgeous, high-quality video sharing on desktop, mobile, tablet",
      showText1:
        holderState?.showText1 !== undefined ? holderState.showText1 : true,
      text2:
        holderState?.text2Value !== undefined
          ? holderState.text2Value
          : "Gorgeous, high-quality video sharing on desktop, mobile, tablet",
      showText2:
        holderState?.showText2 !== undefined ? holderState.showText2 : true,
      text3:
        holderState?.text3Value !== undefined
          ? holderState.text3Value
          : "Gorgeous, high-quality video sharing on desktop, mobile, tablet",
      showText3:
        holderState?.showText3 !== undefined ? holderState.showText3 : true,
      text4:
        holderState?.text4Value !== undefined
          ? holderState.text4Value
          : "Gorgeous, high-quality video sharing on desktop, mobile, tablet",
      showText4:
        holderState?.showText4 !== undefined ? holderState.showText4 : true,
      header1Icon: {
        type:
          holderState?.header1Icon?.type !== undefined
            ? holderState.header1Icon.type
            : "Grid1x2",
        size:
          holderState?.header1Icon?.size !== undefined
            ? holderState.header1Icon.size
            : isPreview
            ? 12
            : 32,
      },
      header2Icon: {
        type:
          holderState?.header2Icon?.type !== undefined
            ? holderState.header2Icon.type
            : "CircleHalf",
        size:
          holderState?.header2Icon?.size !== undefined
            ? holderState.header2Icon.size
            : isPreview
            ? 12
            : 32,
      },
      header3Icon: {
        type:
          holderState?.header3Icon?.type !== undefined
            ? holderState.header3Icon.type
            : "SquareHalf",
        size:
          holderState?.header3Icon?.size !== undefined
            ? holderState.header3Icon.size
            : isPreview
            ? 12
            : 32,
      },
      header4Icon: {
        type:
          holderState?.header4Icon?.type !== undefined
            ? holderState.header4Icon.type
            : "UiRadiosGrid",
        size:
          holderState?.header4Icon?.size !== undefined
            ? holderState.header4Icon.size
            : isPreview
            ? 12
            : 32,
      },
      header1IconLink:
        holderState?.header1IconLink !== undefined
          ? holderState.header1IconLink
          : "",
      header2IconLink:
        holderState?.header2IconLink !== undefined
          ? holderState.header2IconLink
          : "",
      header3IconLink:
        holderState?.header3IconLink !== undefined
          ? holderState.header3IconLink
          : "",
      header4IconLink:
        holderState?.header4IconLink !== undefined
          ? holderState.header4IconLink
          : "",
      showHeader1Icon:
        holderState?.showHeader1Icon !== undefined
          ? holderState.showHeader1Icon
          : true,
      showHeader2Icon:
        holderState?.showHeader2Icon !== undefined
          ? holderState.showHeader2Icon
          : true,
      showHeader3Icon:
        holderState?.showHeader3Icon !== undefined
          ? holderState.showHeader3Icon
          : true,
      showHeader4Icon:
        holderState?.showHeader4Icon !== undefined
          ? holderState.showHeader4Icon
          : true,
      header1:
        holderState?.header1 !== undefined
          ? holderState.header1
          : "Feature One",
      showHeader1:
        holderState?.showHeader1 !== undefined ? holderState.showHeader1 : true,
      header2:
        holderState?.header2 !== undefined
          ? holderState.header2
          : "Feature Two",
      showHeader2:
        holderState?.showHeader2 !== undefined ? holderState.showHeader2 : true,
      header3:
        holderState?.header3 !== undefined
          ? holderState.header3
          : "Feature Three",
      showHeader3:
        holderState?.showHeader3 !== undefined ? holderState.showHeader3 : true,
      header4:
        holderState?.header4 !== undefined
          ? holderState.header4
          : "Feature Four",
      showHeader4:
        holderState?.showHeader4 !== undefined ? holderState.showHeader4 : true,
      showImg1:
        holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
      imgLink1: holderState?.imgLink1 !== undefined ? holderState.imgLink1 : "",
      imgSrc1:
        holderState?.imgSrc1 !== undefined
          ? holderState.imgSrc1
          : "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgAlt1: "Placeholder Image",
    }));
  }, [
    holderState?.showText1,
    holderState?.text1Value,
    holderState?.showText2,
    holderState?.text2Value,
    holderState?.showText3,
    holderState?.text3Value,
    holderState?.showText4,
    holderState?.text4Value,
    holderState?.showImg1,
    holderState?.imgLink1,
    holderState?.header1,
    holderState?.showHeader1,
    holderState?.header2,
    holderState?.showHeader2,
    holderState?.header3,
    holderState?.showHeader3,
    holderState?.header4,
    holderState?.showHeader4,
    holderState?.showHeader1Icon,
    holderState?.showHeader2Icon,
    holderState?.showHeader3Icon,
    holderState?.showHeader4Icon,
    holderState?.header1IconLink,
    holderState?.header2IconLink,
    holderState?.header3IconLink,
    holderState?.header4IconLink,
    holderState?.header1Icon?.size,
    holderState?.header1Icon?.type,
    holderState?.header2Icon?.size,
    holderState?.header2Icon?.type,
    holderState?.header3Icon?.size,
    holderState?.header3Icon?.type,
    holderState?.header4Icon?.size,
    holderState?.header4Icon?.type,
    holderState?.header1Icon,
    holderState?.header2Icon,
    holderState?.header3Icon,
    holderState?.header4Icon,
    holderState?.imgSrc1,
    isPreview,
  ]);

  return (
    <Row
      sm={12}
      md={12}
      lg={10}
      xl={10}
      xxl={8}
      style={{ "--bs-gutter-x": "0" }}
      className={`d-flex justify-content-center bg-white gap-5 ${
        isPreview ? "p-3" : "p-5"
      }`}
    >
      <Col
        xs={12}
        sm={10}
        md={6}
        lg={5}
        xl={5}
        xxl={4}
        className="d-flex flex-column align-items-center justify-content-center"
      >
        <Col md={12} className="d-flex mx-2 gap-4 mt-2">
          <Col className="p-2">
            {leftAlign18.showHeader1Icon && (
              <Col className="mb-2">
                {leftAlign18.header1IconLink !== "" && (
                  <a
                    href={"https://" + leftAlign18.header1IconLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`text-black ${
                      selectedField === "header1Icon" ? "focus-field" : ""
                    }`}
                  >
                    {RenderIconComponent({
                      iconType: leftAlign18.header1Icon.type,
                      iconSize:
                        isPreview === true ? 11 : leftAlign18.header1Icon.size,
                    })}
                  </a>
                )}
                {leftAlign18.header1IconLink === "" && (
                  <span
                    className={`${
                      selectedField === "header1Icon" ? "focus-field" : ""
                    }`}
                  >
                    {RenderIconComponent({
                      iconType: leftAlign18.header1Icon.type,
                      iconSize:
                        isPreview === true ? 11 : leftAlign18.header1Icon.size,
                    })}
                  </span>
                )}
              </Col>
            )}
            <Col>
              {leftAlign18.showHeader1 && (
                <p
                  className={`fw-bold mb-0 ${
                    isPreview ? "preview-scaling" : ""
                  } ${selectedField === "header1" ? "focus-field" : ""}`}
                >
                  {leftAlign18.header1}
                </p>
              )}
              {leftAlign18.showText1 && (
                <p
                  className={`${isPreview ? "preview-scaling" : ""} ${
                    selectedField === "text1" ? "focus-field" : ""
                  }`}
                >
                  {leftAlign18.text1}
                </p>
              )}
            </Col>
          </Col>
          <Col className="p-2">
            {leftAlign18.showHeader2Icon && (
              <Col className="mb-2">
                {leftAlign18.header2IconLink !== "" && (
                  <a
                    href={"https://" + leftAlign18.header2IconLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`text-black ${
                      selectedField === "header2Icon" ? "focus-field" : ""
                    }`}
                  >
                    {RenderIconComponent({
                      iconType: leftAlign18.header2Icon.type,
                      iconSize:
                        isPreview === true ? 11 : leftAlign18.header2Icon.size,
                    })}
                  </a>
                )}
                {leftAlign18.header2IconLink === "" && (
                  <span
                    className={`${
                      selectedField === "header2Icon" ? "focus-field" : ""
                    }`}
                  >
                    {RenderIconComponent({
                      iconType: leftAlign18.header2Icon.type,
                      iconSize:
                        isPreview === true ? 11 : leftAlign18.header2Icon.size,
                    })}
                  </span>
                )}
              </Col>
            )}
            <Col>
              {leftAlign18.showHeader2 && (
                <p
                  className={`fw-bold mb-0 ${
                    isPreview ? "preview-scaling" : ""
                  } ${selectedField === "header2" ? "focus-field" : ""}`}
                >
                  {leftAlign18.header2}
                </p>
              )}
              {leftAlign18.showText2 && (
                <p
                  className={`${isPreview ? "preview-scaling" : ""} ${
                    selectedField === "text2" ? "focus-field" : ""
                  }`}
                >
                  {leftAlign18.text2}
                </p>
              )}
            </Col>
          </Col>
        </Col>
        <Col md={12} className="d-flex mx-2 gap-4 mt-2">
          <Col className="p-2">
            {leftAlign18.showHeader3Icon && (
              <Col className="mb-2">
                {leftAlign18.header3IconLink !== "" && (
                  <a
                    href={"https://" + leftAlign18.header3IconLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`text-black ${
                      selectedField === "header3Icon" ? "focus-field" : ""
                    }`}
                  >
                    {RenderIconComponent({
                      iconType: leftAlign18.header3Icon.type,
                      iconSize:
                        isPreview === true ? 11 : leftAlign18.header3Icon.size,
                    })}
                  </a>
                )}
                {leftAlign18.header3IconLink === "" && (
                  <span
                    className={`${
                      selectedField === "header3Icon" ? "focus-field" : ""
                    }`}
                  >
                    {RenderIconComponent({
                      iconType: leftAlign18.header3Icon.type,
                      iconSize:
                        isPreview === true ? 11 : leftAlign18.header3Icon.size,
                    })}
                  </span>
                )}
              </Col>
            )}
            <Col>
              {leftAlign18.showHeader3 && (
                <p
                  className={`fw-bold mb-0 ${
                    isPreview ? "preview-scaling" : ""
                  } ${selectedField === "header3" ? "focus-field" : ""}`}
                >
                  {leftAlign18.header3}
                </p>
              )}
              {leftAlign18.showText3 && (
                <p
                  className={`${isPreview ? "preview-scaling" : ""} ${
                    selectedField === "text3" ? "focus-field" : ""
                  }`}
                >
                  {leftAlign18.text3}
                </p>
              )}
            </Col>
          </Col>
          <Col className="p-2">
            {leftAlign18.showHeader4Icon && (
              <Col className="mb-2">
                {leftAlign18.header4IconLink !== "" && (
                  <a
                    href={"https://" + leftAlign18.header4IconLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`text-black ${
                      selectedField === "header4Icon" ? "focus-field" : ""
                    }`}
                  >
                    {RenderIconComponent({
                      iconType: leftAlign18.header4Icon.type,
                      iconSize:
                        isPreview === true ? 11 : leftAlign18.header4Icon.size,
                    })}
                  </a>
                )}
                {leftAlign18.header4IconLink === "" && (
                  <span
                    className={`${
                      selectedField === "header4Icon" ? "focus-field" : ""
                    }`}
                  >
                    {RenderIconComponent({
                      iconType: leftAlign18.header4Icon.type,
                      iconSize:
                        isPreview === true ? 11 : leftAlign18.header4Icon.size,
                    })}
                  </span>
                )}
              </Col>
            )}
            <Col>
              {leftAlign18.showHeader4 && (
                <p
                  className={`fw-bold mb-0 ${
                    isPreview ? "preview-scaling" : ""
                  } ${selectedField === "header4" ? "focus-field" : ""}`}
                >
                  {leftAlign18.header4}
                </p>
              )}
              {leftAlign18.showText4 && (
                <p
                  className={`${isPreview ? "preview-scaling" : ""} ${
                    selectedField === "text4" ? "focus-field" : ""
                  }`}
                >
                  {leftAlign18.text4}
                </p>
              )}
            </Col>
          </Col>
        </Col>
      </Col>
      <Col
        xs={12}
        sm={10}
        md={5}
        lg={5}
        xl={5}
        xxl={4}
        className={`d-flex align-items-center justify-content-center my-auto ${
          isPreview ? "preview-scaling" : ""
        }`}
      >
        {leftAlign18.showImg1 && (
          <Col
            className={`text-center justify-content-center ${
              selectedField === "imgSrc1" ? "focus-field" : ""
            }`}
            style={imageContainerStyle}
          >
            {leftAlign18.imgLink1 !== "" && (
              <a
                href={"https://" + leftAlign18.imgLink1}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  src={leftAlign18.imgSrc1}
                  alt={leftAlign18.imgAlt1}
                  style={imageStyle}
                  fluid
                />
              </a>
            )}
            {leftAlign18.imgLink1 === "" && (
              <Image
                src={leftAlign18.imgSrc1}
                alt={leftAlign18.imgAlt1}
                style={imageStyle}
                fluid
              />
            )}
          </Col>
        )}
      </Col>
    </Row>
  );
};

export default LA18;
