import RegisterCard from '../Components/ApplicationComponents/ServiceComponents/RegisterCard'
import '../Components/ApplicationComponents/ServiceComponents/serviceComponents.css'

const RegistrationPage = () => {
  
  return (
        <RegisterCard />
  )
}

export default RegistrationPage