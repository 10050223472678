import React from 'react'
import { Container, Row, Col, Accordion } from 'react-bootstrap';
import DashboardHeader from '../ServiceComponents/DashboardHeader';
import HomePageProgBar from './HomePageProgBar';

const ContactPageFlow = () => {
  return (
    <>
    <Container fluid className="d-flex flex-column min-vh-100">
      <DashboardHeader>CONTACT PAGE</DashboardHeader>
      <Row className="flex-grow-1 mx-0">
        <Col md={9} className="mb-5 px-0">
          <Accordion alwaysOpen={true} defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Contact Page</Accordion.Header>
              <Accordion.Body>
                <h1>Contact Pages</h1>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
        <Col md={3}>
          <div className="sticky-top">
            <HomePageProgBar />
          </div>
        </Col>
      </Row>
    </Container>
  </>
  )
};

export default ContactPageFlow