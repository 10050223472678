import React from "react";
import { Form, Card } from "react-bootstrap";

const HeadlineFormComponent = ({
  showHeadline,
  setShowHeadline,
  headline,
  setHeadline,
  setSelectedField,
  selectedField,
}) => {

  return (
    <Card
      className={`col-3 px-0  ${selectedField === "headline" ? "focus-field" : "focus-hover"}`}
      onClick={() => setSelectedField("headline")}
    >
      <Card.Body>
        <Card.Title>Headline</Card.Title>
        <Form.Group>
          <Form.Check
            type="checkbox"
            name="headline"
            value="Headline"
            label="Display"
            checked={showHeadline}
            onChange={(e) => setShowHeadline(e.target.checked)}
          />
          <Form.Control
            type="text"
            className="my-2"
            placeholder="Enter Text"
            value={headline}
            onChange={(e) => setHeadline(e.target.value)}
          />
        </Form.Group>
      </Card.Body>
    </Card>
  );
};

export default HeadlineFormComponent;
