import React from "react";
import { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { setIsLoading } from '../../../Services/loadingSlice';
import { Form, Container, Row, Col, Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { resetPassword } from "../../../Services/UserServices";

const ResetPwCard = () => {
  const [passwordError, setPasswordError] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [confirmationCode, setConfirmationCode ] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const email = sessionStorage.getItem('email');

  // Page mounts stop loading...
  useEffect(() => {
    dispatch(setIsLoading(false));
  },[dispatch])


  function handleSubmit(e) {
    e.preventDefault();
    dispatch(setIsLoading(true));
    try {
    resetPassword(email, password, confirmationCode).then(async response => {
      if (response.ok){
        sessionStorage.removeItem('email');
        navigate("/");
        }
        })
      } catch (error) {
        console.log(error)
      } finally {
        dispatch(setIsLoading(false));
      }
  }

  const checkValid = (e) => {
    const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]{12,}$/;
    if (password.length > 12 && passwordRegex.test(password)) {
      setPasswordError("");
      setDisabled(false);
    } else {
      setPasswordError("true")
    }
  };

  return (
    <>
      <Container>
          <Row>
            <Col
              lg={{ span: 4, offset: 4 }}
              md={{ span: 6, offset: 3 }}
              sm={{ span: 8, offset: 2 }}
            >
              <Card className="mx-auto mt-4 reset-card">
                <Card.Body className="">
                  <Form>
                    <Form.Group className="form-control-lg">
                      <Form.Text className="text-center reset-font d-block mt-1 mb-3">
                        Reset Password For {email}
                      </Form.Text>
                      <Form.Label className="reset-label">
                        Enter Confirmation Code
                      </Form.Label>
                      <Form.Control
                        className="reset-input"
                        onChange={(e) => setConfirmationCode(e.target.value)}
                        type="text"
                        placeholder="Confirmation Code"
                        name="confirmationCode"
                      />
                      <Form.Label className="reset-label">
                        Enter New Password
                      </Form.Label>
                      <Form.Control
                        className="reset-input"
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyUp={checkValid}
                        type="text"
                        placeholder="********"
                        name="newPassword"
                        isInvalid={passwordError}
                      />
                      <Form.Control.Feedback type="invalid">
                        Password length must be {">"} 12 {<br></br>}
                        contain a number, symbol, and both uppercase / lowercase
                      </Form.Control.Feedback>
                    </Form.Group>
                    <div className="text-center mb-4 mt-4">
                      <Button
                        disabled={disabled}
                        onClick={handleSubmit}
                        className="reset-label mt-2"
                        variant="dark"
                        type="submit"
                      >
                        Reset Password
                      </Button>
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
      </Container>
    </>
  );
};

export default ResetPwCard;
