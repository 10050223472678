import React, {useEffect, useState} from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { ChevronRight } from 'react-bootstrap-icons';
import "../../../ApplicationComponents/ServiceComponents/serviceComponents.css";

const LA1 = ({isPreview, holderState, selectedField}) => {
  const [leftAlign1, setLeftAlign1] = useState({
    showImg1: holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
    imgLink1: holderState?.imgLink1 !== undefined ? holderState.imgLink1 : "",
    imgSrc1:
      holderState?.imgSrc1 !== undefined
        ? holderState.imgSrc1
        : "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt1: "Placeholder Img",
    headline:
      holderState?.headline !== undefined
        ? holderState.headline
        : "Medium Length display headline",
    showHeadline:
      holderState?.showHeadline !== undefined ? holderState.showHeadline : true,
    text1:
      holderState?.text1Value !== undefined
        ? holderState.text1Value
        : "Separated they live in Bookmarks right at the coast of the famous Semantics, large language ocean Seperated they live in the Bookmarks right",
    showText1:
      holderState?.showText1 !== undefined ? holderState.showText1 : true,
    link1Title:
      holderState?.link1Title !== undefined
        ? holderState.link1Title
        : "Action Link",
    link1Value:
      holderState?.link1Value !== undefined ? holderState.link1Value : "",
    showLink1:
      holderState?.showLink1 !== undefined ? holderState.showLink1 : true,
  });
  
  const imageContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    maxHeight: '22rem',
    borderRadius: '1rem',
    overflow: 'hidden'
  };
  const imageStyle = {
    objectFit: 'cover',
    height: '22rem',
    width: '100%',
    borderRadius: '1rem',
  }

  useEffect(() => {
    setLeftAlign1((prevLA1) => ({
      ...prevLA1,
      headline:
        holderState?.headline !== undefined
          ? holderState.headline
          : "Medium Length display headline",
      showHeadline:
        holderState?.showHeadline !== undefined
          ? holderState.showHeadline
          : true,
      showText1:
        holderState?.showText1 !== undefined ? holderState.showText1 : true,
      text1:
        holderState?.text1Value !== undefined
          ? holderState.text1Value
          : "Change the color to match your band or vision, add your logo, choose the perfect thumbnail, remove the playbar, add speed controls, and more",
      link1Title:
        holderState?.link1Title !== undefined
          ? holderState.link1Title
          : "Action Link",
      link1Value:
        holderState?.link1Value !== undefined ? holderState.link1Value : "",
      showLink1:
        holderState?.showLink1 !== undefined ? holderState.showLink1 : true,
      showImg1:
        holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
      imgLink1: holderState?.imgLink1 !== undefined ? holderState.imgLink1 : "",
      imgSrc1:
        holderState?.imgSrc1 !== undefined
          ? holderState.imgSrc1
          : "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    }));
  }, [
    holderState?.showText1,
    holderState?.text1Value,
    holderState?.showHeadline,
    holderState?.headline,
    holderState?.link1Title,
    holderState?.link1Value,
    holderState?.showLink1,
    holderState?.showImg1,
    holderState?.imgLink1,
    holderState?.imgSrc1,
  ]);  

  return (
    <Row
      sm={12}
      md={12}
      lg={10}
      xl={10}
      xxl={8}
      style={{ "--bs-gutter-x": "0" }}
      className={`d-flex justify-content-center bg-white gap-5 ${
        isPreview ? "p-3" : "p-5"
      }`}
    >
      <Col
        xs={12}
        sm={10}
        md={6}
        lg={5}
        xl={5}
        xxl={4}
        className="d-flex flex-column align-items-center justify-content-center"
      >
        <Col className="d-flex flex-column justify-content-center ">
          {leftAlign1.showHeadline && (
            <h1
              className={`fw-bold ${isPreview ? "preview-scaling" : ""} ${
                selectedField === "headline" ? "focus-field" : ""
              }`}
            >
              {leftAlign1.headline}
            </h1>
          )}
          {leftAlign1.showText1 && (
            <p
              className={`${isPreview ? "preview-scaling" : ""} ${
                selectedField === "text1" ? "focus-field" : ""
              }`}
            >
              {leftAlign1.text1}
            </p>
            //  USE THIS FOR RICH TEXT
            // <div dangerouslySetInnerHTML={{ __html: leftAlign1.text1 }} >
            // </div>
          )}
          {leftAlign1.showLink1 && (
            <a
              className={`fw-bold link-offset-2 link-underline link-underline-opacity-0 link-underline-opacity-75-hover ${
                isPreview ? "preview-scaling" : "mt-5"
              } ${selectedField === "link1Value" ? "focus-field" : ""}`}
              href={"https://" + leftAlign1.link1Value}
              target="_blank"
              rel="noopener noreferrer"
            >
              {leftAlign1.link1Title} <ChevronRight />
            </a>
          )}
        </Col>
      </Col>
      <Col
        xs={12}
        sm={10}
        md={5}
        lg={5}
        xl={5}
        xxl={4}
        className={`d-flex align-items-center justify-content-center my-auto ${
          isPreview ? "preview-scaling" : ""
        }`}
      >
        {leftAlign1.showImg1 && (
          <Col
            className={`text-center justify-content-center ${selectedField === "imgSrc1" ? "focus-field" : ""}`}
            style={imageContainerStyle}
          >
            {leftAlign1.imgLink1 !== "" && (
              <a
                href={"https://" + leftAlign1.imgLink1}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  src={leftAlign1.imgSrc1}
                  alt={leftAlign1.imgAlt1}
                  style={imageStyle}
                  fluid
                />
              </a>
            )}
            {leftAlign1.imgLink1 === "" && (
              <Image
                src={leftAlign1.imgSrc1}
                alt={leftAlign1.imgAlt1}
                style={imageStyle}
                fluid
              />
            )}
          </Col>
        )}
      </Col>
    </Row>
  );
}

export default LA1
