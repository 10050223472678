import React, { useState } from "react";
import {
  Navbar,
  Nav,
  Offcanvas,
  Button,
  Form,
  Fade,
  Container,
} from "react-bootstrap";
import { List, Search } from "react-bootstrap-icons";

function Navbar5({ isPreview }) {
  const [hide, setHide] = useState(true);

  return (
    <Navbar
      expand="md"
      className={`bg-white border ${isPreview ? "preview-scaling" : ""}`}
    >
      <Container fluid>
        {/* First Section (Left) */}
        <Nav className="col-4">
          <Nav.Link href="#">
            <List size={32} />
          </Nav.Link>
        </Nav>

        {/* Second Section (Center) */}
        <Nav className="col-4 justify-content-center">
          <Navbar.Brand
            href="#home"
            className={`${
              isPreview
                ? "preview-scaling-logo ps-3 text-center"
                : "text-center"
            }`}
          >
            ACME
          </Navbar.Brand>
        </Nav>

        {/* Third Section (Right) */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="justify-content-center">
          <Nav className="col-4 justify-content-end">
            {/* Offcanvas and Search Components */}
            <Navbar.Offcanvas
              id="offcanvasNavbar-expand-md"
              aria-labelledby="offcanvasNavbarLabel-expand-md"
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id="offcanvasNavbarLabel-expand-md">
                  Offcanvas
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3 align-items-center">
                  <Nav.Link href="#">First</Nav.Link>
                  <Nav.Link href="#">Second</Nav.Link>
                </Nav>
                <Fade in={!hide}>
                  <Form className="d-flex">
                    <Form.Control
                      hidden={hide}
                      type="search"
                      placeholder="Search"
                      className=""
                      aria-label="Search"
                    />
                  </Form>
                </Fade>
                <Button
                  className="d-flex align-items-center"
                  variant=""
                  onClick={() => setHide(!hide)}
                >
                  <Search size={18} />
                </Button>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navbar5;
