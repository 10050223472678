import React from 'react'
import { useState, useEffect } from 'react'
import { CCircle } from 'react-bootstrap-icons'
import { Row, Col, Nav, Container } from 'react-bootstrap'

const Footer7 = ({ isPreview, holderState, selectedField }) => {
  const [footer7, setFooter7] = useState({
    companyName: `ACME`,
    legal: "Legal",
    legalLink: "Legal",
    siteMap: "Site Map",
    siteMapLink: "Site Map",
    privacyPolicy: "Privacy Policy",
    privacyPolicyLink:
      holderState?.privacyLink !== undefined ? holderState.privacyLink : "",
    termsOfService: "Terms of Service",
    termsOfServiceLink:
      holderState?.tosLink !== undefined ? holderState.tosLink : "",
    socialLinkIG: "",
    socialLinkFB: "",
    socialLinkX: "",
    socialLinkPIN: "",
    socialLinkTik: "",
    companyFooter:
      holderState?.companyFooterValue !== undefined
        ? holderState.companyFooterValue
        : "2018 Acme. All rights reserved.",
    showSocials:
      holderState?.socialIconsChecked !== undefined
        ? holderState.socialIconsChecked
        : true,
    showIG:
      holderState?.showIGChecked !== undefined
        ? holderState.showIGChecked
        : true,
    showFB:
      holderState?.showFbChecked !== undefined
        ? holderState.showFbChecked
        : true,
    showX:
      holderState?.showXChecked !== undefined ? holderState.showXChecked : true,
    showPIN:
      holderState?.showPINChecked !== undefined
        ? holderState.showPINChecked
        : true,
    showTik:
      holderState?.showTikChecked !== undefined
        ? holderState.showTikChecked
        : true,
    showLI:
      holderState?.showLIChecked !== undefined
        ? holderState.showLIChecked
        : true,
    showPrivacy:
      holderState?.showPrivacy !== undefined ? holderState.showPrivacy : true,
    showCompanyFooter:
      holderState?.showCompanyFooter !== undefined
        ? holderState.showCompanyFooter
        : true,
    showTos: holderState?.showTos !== undefined ? holderState.showTos : true,
    showLegal:
      holderState?.showLegal !== undefined ? holderState.showLegal : true,
    showSiteMap:
      holderState?.showSiteMap !== undefined ? holderState.showSiteMap : true,
  });

  useEffect(() => {
    setFooter7((prevFooter7) => ({
      ...prevFooter7,
      showSocials:
        holderState?.socialIconsChecked !== undefined
          ? holderState.socialIconsChecked
          : true,
      showIG:
        holderState?.showIGChecked !== undefined
          ? holderState.showIGChecked
          : true,
      showFB:
        holderState?.showFbChecked !== undefined
          ? holderState.showFbChecked
          : true,
      showX:
        holderState?.showXChecked !== undefined
          ? holderState.showXChecked
          : true,
      showPIN:
        holderState?.showPINChecked !== undefined
          ? holderState.showPINChecked
          : true,
      showTik:
        holderState?.showTikChecked !== undefined
          ? holderState.showTikChecked
          : true,
      showLI:
        holderState?.showLIChecked !== undefined
          ? holderState.showLIChecked
          : true,
      showPrivacy:
        holderState?.showPrivacy !== undefined ? holderState.showPrivacy : true,
      showCompanyFooter:
        holderState?.showCompanyFooter !== undefined
          ? holderState.showCompanyFooter
          : true,
      showTos: holderState?.showTos !== undefined ? holderState.showTos : true,
      showLegal:
        holderState?.showLegal !== undefined ? holderState.showLegal : true,
      showSiteMap:
        holderState?.showSiteMap !== undefined ? holderState.showSiteMap : true,
      privacyPolicyLink:
        holderState?.privacyLink !== undefined ? holderState.privacyLink : "",
      termsOfServiceLink:
        holderState?.tosLink !== undefined ? holderState.tosLink : "",
      companyFooter:
        holderState?.companyFooterValue !== undefined
          ? holderState.companyFooterValue
          : "2018 Acme. All rights reserved.",
    }));
  }, [
    holderState?.socialIconsChecked,
    holderState?.showIGChecked,
    holderState?.showFbChecked,
    holderState?.showXChecked,
    holderState?.showPINChecked,
    holderState?.showTikChecked,
    holderState?.showLIChecked,
    holderState?.showPrivacy,
    holderState?.showCompanyFooter,
    holderState?.showTos,
    holderState?.showLegal,
    holderState?.showSiteMap,
    holderState?.privacyLink,
    holderState?.tosLink,
    holderState?.companyFooterValue,
  ]);

  return (
    <Container fluid>
      <Row className={`bg-white p-3 ${isPreview ? "preview-scaling" : ""}`}>
        {footer7.showCompanyFooter && (
          <Col sm={3} md={6} className="d-flex justify-content-center mx-auto">
            <small
              className={`${
                selectedField === "Company Footer" ? "focus-field" : ""
              }`}
            >
              <CCircle size={12} />
              {footer7.companyFooter}
            </small>
          </Col>
        )}
        {(footer7.showPrivacy || footer7.showTos) && (
          <Col
            sm={6}
            md={6}
            className="gap-3 d-flex justify-content-end align-items-center"
          >
            {footer7.showPrivacy && (
              <Nav.Link
                href={"https://" + footer7.privacyPolicyLink}
                target="_blank"
              >
                <small
                  className={`${
                    selectedField === "Privacy Policy" ? "focus-field" : ""
                  }`}
                >
                  {footer7.privacyPolicy}
                </small>
              </Nav.Link>
            )}
            {footer7.showTos && (
              <Nav.Link
                href={"https://" + footer7.termsOfServiceLink}
                target="_blank"
              >
                <small
                  className={`${
                    selectedField === "Terms of Service" ? "focus-field" : ""
                  }`}
                >
                  {footer7.termsOfService}
                </small>
              </Nav.Link>
            )}
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default Footer7