import { createSlice } from '@reduxjs/toolkit';

const initialState = [
  {
      id: 1,
      name: "CA1",
      rowId: 0,
      bodyRowComponent: {
        componentName: "CA1",
        props: {
        headline: "Tagline",
        text1: "Long headline to turn your visitors into users",
        link1Value: "",
        link1Title: "Action Link",
        }
    }},
  {
      id: 2,
      name: "CA2",
      rowId: 0,
      bodyRowComponent: {
        componentName: "CA2",
        props: {
        text1: "Long headline to turn your visitors into users",
        text2: "Separated they live in Bookmarksgrove right at the coast of the famous Semantics, large language ocean and many more stuff and more more more",
        button1Link: "",
        button1: "Action",
        button2Link: "",
        button2: "Action",
        }
    }},
  {
      id: 3,
      name: "CA3",
      rowId: 0,
      bodyRowComponent: {
        componentName: "CA3",
        props: {
        text1: "Long headline to turn your visitors into users",
        text2:
          "Separated they live in Bookmarksgrove right at the coast of the famous Semantics, large language ocean and many more stuff and more more more",
        text3: "Seperated they live in Bookmarkgrove right.",
        button1: "Action",
        button1Link: "",
        link1Value: "",
        link1Title: "Link",
        }
    }},
  {
      id: 4,
      name: "CA4",
      rowId: 0,
      bodyRowComponent: {
        componentName: "CA4",
        props: {
        headline: "Tagline",
        text1: "Long headline to turn your visitors into users",
        text2:
          "Separated they live in Bookmarksgrove right at the coast of the famous Semantics, large language ocean and many more stuff and more more more",
        link1Value: "",
        link1Title: "First Action",
        link2Value: "",
        link2Title: "Second Action",
        }
    }},
  {
      id: 5,
      name: "CA5",
      rowId: 0,
      bodyRowComponent: {
        componentName: "CA5",
        props: {
        headline: "Tagline",
        text1: "Long headline to turn your visitors into users",
        text2: "By clicking the Button you are confirming that you're agree with our following",
        button1: "Action",
        button1Link: "",
        link1Value: "",
        link1Title: "Terms and Conditions",
        }
    }},
  {
      id: 6,
      name: "CA6",
      rowId: 0,
      bodyRowComponent: {
        componentName: "CA6",
        props: {
        headline: "Tagline",
        text1: "Long headline to turn your visitors into users",
        text2: "Change the color to match your brand or vision, add your logo, choose the perfect thumbnail, remove the playbar, add speed controls, and more. Increase engagement with CTAs and custom end screens, or keep your video private and password-protected.",
        }
    }},
  {
      id: 7,
      name: "CA7",
      rowId: 0,
      bodyRowComponent: {
        componentName: "CA7",
        props: {
        headline: "Tagline",
        text1: "Long headline to turn your visitors into users",
        button1Link: "",
        button1: "Action",
        }
    }},
  {
      id: 8,
      name: "CA8",
      rowId: 0,
      bodyRowComponent: {
        componentName: "CA8",
        props: {
        text1: "Long headline to turn your visitors into users",
        text2: "With the new AirPlay 2, you can control your home audio system and the speakers throughout your house.4 You can play a song in the living room and your kitchen at the same time.",
        button1: "Action",
        button1Link: "",
        }
    }},
  {
      id: 9,
      name: "CA9",
      rowId: 0,
      bodyRowComponent: {
        componentName: "CA9",
        props: {
        icon1: {
          type: "Grid1x2",
          size: 32,
        },
        text1: "Medium length headline",
        text2: "Change the color to match your brand or vision, add your logo, choose the perfect thumbnail, remove the playbar, add speed controls, and more. Increase engagement with CTAs and custom end screens, or keep your video private and password-protected.",
        }
    }},
  {
      id: 10,
      name: "CA10",
      rowId: 0,
      bodyRowComponent: {
        componentName: "CA10",
        props: {
        text1: "Long headline to turn your visitors into users",
        text2: "Share:",
        icon1: {
          type: "Facebook",
          size: 24,
        },
        icon1Link: "",
        icon2: {
          type: "Twitter",
          size: 24,
        },
        icon2Link: "",
        icon3: {
          type: "Link45deg",
          size: 24,
        },
        icon3Link: "",
        }
    }},
  {
      id: 11,
      name: "CA11",
      rowId: 0,
      bodyRowComponent: {
        componentName: "CA11",
        props: {
        text1: "Long headline to turn your visitors into users",
        text2: "With the new AirPlay 2, you can control your home audio system and the speakers throughout your house.4 You can play a song in the living room and your kitchen at the same time.",
        header1: "Pay",
        header2: "Pay",
        header3: "Pay",
        header4: "SAMSUNG PAY",
        header1Icon: {
          type: "Paypal",
          size: 22,
        },
        header2Icon: {
          type: "Apple",
          size: 22,
        },
        header3Icon: {
          type: "Android2",
          size: 22,
        },
        header4Icon: {
          type: "",
          size: 22,
        },
        header1IconLink: "",
        header2IconLink: "",
        header3IconLink: "",
        header4IconLink: "",
        }
    }},
  {
      id: 12,
      name: "CA12",
      rowId: 0,
      bodyRowComponent: {
        componentName: "CA12",
        props: {
        headline: "Short Headline",
        link1Value: "",
        link1Title: "Action Link",
        }
    }},
  {
      id: 13,
      name: "CA13",
      rowId: 0,
      bodyRowComponent: {
        componentName: "CA13",
        props: {
        headline: "Long headline on two lines to turn your visitors into users and achieve more",
        link1Value: "",
        link1Title: "Action Link",
        }
    }},
  {
      id: 14,
      name: "CA14",
      rowId: 0,
      bodyRowComponent: {
        componentName: "CA14",
        props: {
        text1: "With the new AirPlay 2, you can control your home audio system and the speakers throughout your house.4 You can play a song in the living room and your kitchen at the same time. Adjust the volume in any room.",
        link1Value: "",
        link1Title: "Link",
        }
    }},
  {
      id: 15,
      name: "CA15",
      bodyRowComponent: {
        componentName: "CA15",
        props: {
        icon1: {
          type: "Grid1x2",
          size: 36,
        },
        text1: "Medium Length Title",
        text2: "Change the color to match your brand or vision, add your logo, choose the perfect thumbnail, remove the playbar, add speed controls, and more. Increase engagement with CTAs and custom end screens, or keep your video private and password-protected.",
        link1Value: "",
        link1Title: "Long text on Link",
        }
    }},
  {
      id: 16,
      name: "CA16",
      bodyRowComponent: {
        componentName: "CA16",
        props: {
        headline: "Tagline",
        text1: "Main headline to turn your visitors into users",
        text2: "Separated they live in Bookmarksgrove right at the coast of the famous Semantics, large language ocean and many more stuff and more more more",
        link1Value: "",
        link1Title: "Learn More",
        imgLink1: "",
        imgSrc1:
          "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt1: "Placeholder Image Text",
        }
    }},
  {
      id: 17,
      name: "CA17",
      bodyRowComponent: {
        componentName: "CA17",
        props: {
        text1: "Long headline for two lines for attention and evolving",
        text2:
          "With the new AirPlay 2, you can control your home audio system and the speakers throughout your house. You can play a song in the living room and your kitchen at the same time.",
        button1: "Action",
        button1Link: "",
        imgSrc1:
          "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt1: "Placeholder Image Text",
        }
    }},
  {
      id: 18,
      name: "CA18",
      bodyRowComponent: {
        componentName: "CA18",
        props: {
        text1: "With the new AirPlay 2, you can control your home audio system and the speakers throughout your house.4 You can play a song in the living room and your kitchen at the same time. Adjust the volume in any room.",
        text2:
          "All base UI elements are made using Nested Symbols and shared styles that are logically connected with one another.",
        link1Value: "",
        link1Title: "Link",
        imgSrc1:
          "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt1: "Placeholder Image Text",
        }
    }},
      {
      id: 19,
      name: "CA19",
      bodyRowComponent: {
        componentName: "CA19",
        props: {
        text1: "Long headline to turn your visitors into users",
        text2:
          "With the new AirPlay 2, you can control your home audio system and the speakers throughout your house. You can play a song in the living room and your kitchen at the same time.",
        imgSrc1:
          "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
        imgAlt1: "Placeholder Image Text",
        }
    }},
  ];

const centerAlignedSlice = createSlice({
  name: 'centerAligned',
  initialState,
  reducers: {
    addCenterAligned: (state, action) => {
      state.push(action.payload);
    },
    removeCenterAligned: (state, action) => {
      return state.filter((centerAligned) => centerAligned.id !== action.payload);
    },
    selectCenterAligned: (state, action) => {
      return state.filter((centerAligned) => centerAligned.id === action.payload)
    },
  },
  
});

export const { addCenterAligned, removeCenterAligned, selectCenterAligned, } = centerAlignedSlice.actions;

export default centerAlignedSlice.reducer;
