import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Person } from "react-bootstrap-icons";

const Navbar3 = ({isPreview}) => {
  return (
    <Navbar
      expand="lg"
      className={`bg-white border ${isPreview ? "preview-scaling" : ""}`}
    >
      <Navbar.Brand
        className={`${
          isPreview ? "preview-scaling-logo ps-3" : "ps-4"
        }`}
        href="#home"
      >
        ACME
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mx-auto">
          <Nav.Link href="#first">First</Nav.Link>
          <Nav.Link href="#second">Second</Nav.Link>
          <Nav.Link href="#third">Third</Nav.Link>
          <Nav.Link href="#fourth">Fourth</Nav.Link>
          <Nav.Link href="#fifth">Fifth</Nav.Link>
          <Nav.Link href="#sixth">Sixth</Nav.Link>
        </Nav>
        <Nav className="ml-auto">
          <Nav.Link href="#profile">
            <Person /> Profile
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navbar3;
