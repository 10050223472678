import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form, InputGroup } from "react-bootstrap";

const CA5 = ({ isPreview, holderState, selectedField }) => {
  const [centerAlign5, setCenterAlign5] = useState({
    headline:
      holderState?.headline !== undefined ? holderState.headline : "Tagline",
    showHeadline:
      holderState?.showHeadline !== undefined ? holderState.showHeadline : true,
    text1:
      holderState?.text1Value !== undefined
        ? holderState.text1Value
        : "Long headline to turn your visitors into users",
    showText1:
      holderState?.showText1 !== undefined ? holderState.showText1 : true,
    text2:
      holderState?.text2Value !== undefined
        ? holderState.text2Value
        : "By clicking the Button you are confirming that you're agree with our following",
    showText2:
      holderState?.showText2 !== undefined ? holderState.showText2 : true,
    link1Title:
      holderState?.link1Title !== undefined
        ? holderState.link1Title
        : "Terms and Conditions",
    link1Value:
      holderState?.link1Value !== undefined ? holderState.link1Value : "",
    showLink1:
      holderState?.showLink1 !== undefined ? holderState.showLink1 : true,
    button1:
      holderState?.button1 !== undefined ? holderState.button1 : "Action",
    button1Link:
      holderState?.button1Link !== undefined ? holderState.button1Link : "",
    showButton1:
      holderState?.showButton1 !== undefined ? holderState.showButton1 : true,
  });

  useEffect(() => {
    setCenterAlign5((prevCA5) => ({
      ...prevCA5,
      headline:
        holderState?.headline !== undefined ? holderState.headline : "Tagline",
      showHeadline:
        holderState?.showHeadline !== undefined
          ? holderState.showHeadline
          : true,
      text1:
        holderState?.text1Value !== undefined
          ? holderState.text1Value
          : "Long headline to turn your visitors into users",
      showText1:
        holderState?.showText1 !== undefined ? holderState.showText1 : true,
      text2:
        holderState?.text2Value !== undefined
          ? holderState.text2Value
          : "By clicking the Button you are confirming that you're agree with our following",
      showText2:
        holderState?.showText2 !== undefined ? holderState.showText2 : true,
      link1Title:
        holderState?.link1Title !== undefined
          ? holderState.link1Title
          : "Terms and Conditions",
      link1Value:
        holderState?.link1Value !== undefined ? holderState.link1Value : "",
      showLink1:
        holderState?.showLink1 !== undefined ? holderState.showLink1 : true,
      button1:
        holderState?.button1 !== undefined ? holderState.button1 : "Action",
      button1Link:
        holderState?.button1Link !== undefined ? holderState.button1Link : "",
      showButton1:
        holderState?.showButton1 !== undefined ? holderState.showButton1 : true,
    }));
  }, [
    holderState?.showText1,
    holderState?.text1Value,
    holderState?.showText2,
    holderState?.text2Value,
    holderState?.link1Title,
    holderState?.link1Value,
    holderState?.showLink1,
    holderState?.button1,
    holderState?.button1Link,
    holderState?.showButton1,
    holderState?.headline,
    holderState?.showHeadline,
  ]);

  return (
    <Row
      sm={12}
      md={12}
      lg={10}
      xl={10}
      xxl={8}
      style={{ "--bs-gutter-x": "0" }}
      className={`d-flex justify-content-center bg-white ${
        isPreview ? "p-3" : "p-5"
      }`}
    >
      <Col
        xs={12}
        md={12}
        lg={10}
        xl={10}
        className="d-flex flex-column align-items-center justify-content-center"
      >
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={10}
          xl={8}
          xxl={8}
          className="pt-3 text-center"
        >
          {centerAlign5.showHeadline && (
            <p
              className={`fw-semibold pt-3 ${
                isPreview ? "preview-scaling" : ""
              } ${selectedField === "headline" ? "focus-field" : ""}`}
            >
              {centerAlign5.headline}
            </p>
          )}
          {centerAlign5.showText1 && (
            <h1
              className={`fw-bold ${isPreview ? "preview-scaling" : ""} ${
                selectedField === "text1" ? "focus-field" : ""
              }`}
            >
              {centerAlign5.text1}
            </h1>
          )}
        </Col>
        <Col
          xs={12}
          sm={12}
          md={8}
          lg={8}
          xl={6}
          xxl={5}
          className={`d-flex flex-column justify-content-center text-center ${
            isPreview ? "" : "py-3"
          }`}
        >
          {centerAlign5.showButton1 && (
            <InputGroup className={`${isPreview ? "" : "my-3"}`}>
              <Form.Control
                aria-describedby="basic-addon2"
                varient="dark"
                className={`bg-light ${
                  isPreview ? "preview-scaling p-1" : "w-75"
                }`}
                size="lg"
                placeholder="Placeholder"
              />
              <Button
                className={`${isPreview ? "preview-scaling p-1" : ""} ${
                  selectedField === "button1" ? "focus-field" : ""
                }`}
                id="button-addon2"
                href={"https://" + centerAlign5.button1Link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {centerAlign5.button1}
              </Button>
            </InputGroup>
          )}
          <Col
            xxl={8}
            className="d-flex justify-content-center align-items-center text-center align-self-center"
          >
            <p className="mb-0 pt-2">
              <small className={`${isPreview ? "preview-scaling" : ""}`}>
                {centerAlign5.showText2 && (
                  <span
                    className={`${
                      selectedField === "text2" ? "focus-field" : ""
                    }`}
                  >
                    {centerAlign5.text2}
                  </span>
                )}
                {centerAlign5.showLink1 && (
                  <a
                    href={"https://" + centerAlign5.link1Value}
                    className={`fw-semibold link-offset-2 link-underline link-underline-opacity-0 link-underline-opacity-75-hover px-2 ${
                      selectedField === "link1Value" ? "focus-field" : ""
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {centerAlign5.link1Title}
                  </a>
                )}
              </small>
            </p>
          </Col>
        </Col>
      </Col>
    </Row>
  );
};

export default CA5;
