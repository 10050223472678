import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { Button, Card, Form, Row, Col } from "react-bootstrap";
import { registerUser } from "../../../Services/RegisterService";
import Modal from "react-bootstrap/Modal";
import { setIsLoading } from "../../../Services/loadingSlice";

const RegisterCard = () => {
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(true);
  const [firstError, setFirstError] = useState("");
  const [lastError, setLastError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [confirmEmailError, setConfirmEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [formPhone, setFormPhone] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const [registrationForm, setRegistrationForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    phone: "",
    password: "",
  });

  useEffect(() => {
    dispatch(setIsLoading(false));
  },[dispatch])

  const handleError = (e) => {
    e.preventDefault();
    setError(!error);
  };

  const handleChange = (e) => {
    setRegistrationForm({
      ...registrationForm,
      [e.target.name]: e.target.value.trim(),
    });
    setFormPhone(registrationForm.phone);
    checkValid();
  };

  const valueDisplay = (x) => {
    const phoneRegex = /^\d{10}$/;
    return x.length === 10 && phoneRegex.test(x) ? formPhone : registrationForm.phone.replace(/[^0-9]/g, "");
  };
  
  const checkValid = (e) => {
    const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]{12,}$/;
    const nameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ-' ]{1,30}$/;
    const phoneRegex = /^\d{10}$/;
    switch (e) {
      case e:
        setFirstError(nameRegex.test(registrationForm.firstName) ? "" : "true");
        setLastError(nameRegex.test(registrationForm.lastName) ? "" : "true");
        setEmailError(registrationForm.email.includes("@") && registrationForm.email.endsWith(".com") ? "" : "true");
        setConfirmEmailError(registrationForm.email === registrationForm.confirmEmail ? "" : "true");
        setPasswordError(registrationForm.password.length > 5 && passwordRegex.test(registrationForm.password) ? "" : "true");
        const formatPhone = registrationForm.phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
        setPhoneError(registrationForm.phone.length === 10 && phoneRegex.test(registrationForm.phone) ? "" : "true");
        setFormPhone(registrationForm.phone.length === 10 && phoneRegex.test(registrationForm.phone) ? formatPhone : "");
        setDisabled(
          nameRegex.test(registrationForm.firstName) &&
          nameRegex.test(registrationForm.lastName) &&
          registrationForm.email.includes("@") &&
          registrationForm.email.endsWith(".com") &&
          registrationForm.email === registrationForm.confirmEmail &&
          registrationForm.password.length > 5 &&
          passwordRegex.test(registrationForm.password) ? false : true
        );
        break;
      default: console.log("Error - Didn't complete switch cases");
    }
  };
  

  const submitRegistration = (e) => {
    e.preventDefault();
    dispatch(setIsLoading(true));
        const userData = JSON.stringify({
          firstName: registrationForm.firstName,
          lastName: registrationForm.lastName,
          phoneNumber: "+1" + registrationForm.phone,
          email: registrationForm.email,
          password: registrationForm.password,
        });
        registerUser(userData).then(async (response) => {
          try {
            const errorMessage = await response.text();
            if (response.ok) {
              sessionStorage.setItem("email", registrationForm.email )
              sessionStorage.setItem("password", registrationForm.password )
              navigate("/verifyemail");
            } else if (errorMessage === "A user with this email already exists") {
              setError(!error);
              dispatch(setIsLoading(false));
            }
          } catch (error) {
            console.log(error);
          }
        });
  };

  return (
    <>
        <Card className="mx-auto mt-5 col-xl-4 col-lg-6 col-md-8 col-sm-10">
          <Card.Body>
            <Card.Title></Card.Title>
            <Form className="mb-3">
              <Form.Group className="form-control-lg">
                <Row>
                  <Col>
                    <Form.Label htmlFor="firstName" className="reg-label">First Name</Form.Label>
                    <Form.Control id="firstName"
                      onChange={handleChange}
                      onKeyUp={checkValid}
                      className="reg-input"
                      type="text"
                      name="firstName"
                      isInvalid={firstError}
                      required
                    />
                    <Form.Text className=""></Form.Text>
                  </Col>
                  <Col>
                    <Form.Label htmlFor="lastName" className="reg-label">Last Name</Form.Label>
                    <Form.Control id="lastName"
                      onChange={handleChange}
                      onKeyUp={checkValid}
                      className="reg-input"
                      type="text"
                      name="lastName"
                      isInvalid={lastError}
                      required
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Control.Feedback type="invalid">
                no special characters
              </Form.Control.Feedback>
              <Form.Group className="form-control-lg">
                <Form.Label htmlFor="email" className="reg-label">Email</Form.Label>
                <Form.Control id="email"
                  onChange={handleChange}
                  onKeyUp={checkValid}
                  className="reg-input"
                  type="email"
                  name="email"
                  autoComplete="email"
                  isInvalid={emailError}
                />
                <Form.Control.Feedback type="invalid">
                  Must include "@" and end in ".com"
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="form-control-lg">
                <Form.Label htmlFor="confirmEmail" className="reg-label">
                  Verify Email Address
                </Form.Label>
                <Form.Control id="confirmEmail"
                  onChange={handleChange}
                  onKeyUp={checkValid}
                  className="reg-input"
                  type="email"
                  name="confirmEmail"
                  autoComplete="email"
                  isInvalid={confirmEmailError}
                />
                <Form.Control.Feedback type="invalid">
                  Email address' must match
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="form-control-lg">
                <Form.Label htmlFor="phone" className="reg-label">Phone</Form.Label>
                <Form.Control id="phone"
                  onChange={handleChange}
                  onKeyUp={checkValid}
                  className="reg-input"
                  type="text"
                  value={valueDisplay(registrationForm.phone)}
                  name="phone"
                  isInvalid={phoneError}
                  maxLength={12}
                  autoComplete="phone"
                  required
                />
                <Form.Text className=""></Form.Text>
                <Form.Control.Feedback type="invalid">
                  Phone numbers must be 10 digits
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="form-control-lg">
                <Form.Label htmlFor="password" className="reg-label">Password</Form.Label>
                <Form.Control id="password"
                  onChange={handleChange}
                  onKeyUp={checkValid}
                  className="reg-input"
                  type="password"
                  name="password"
                  isInvalid={passwordError}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Password length must be {">"} 12 {<br></br>}
                  contain a number, symbol, and both uppercase / lowercase
                </Form.Control.Feedback>
              </Form.Group>
              <div className="text-center">
                <Button
                  onClick={submitRegistration}
                  className="mt-5"
                  variant="dark"
                  type="submit"
                  disabled={disabled}
                >
                  Register Account
                </Button>
              </div>
              <Link
                    className="infoColor log-label pt-5"
                    to="/forgotpassword"
                  >
                    Forgot Password
              </Link>
            </Form>
          </Card.Body>
        </Card>
        
        <Modal size="md" show={error} onHide={() => setError(false)}>
          <Modal.Dialog className="mx-auto text-center error-font">
            <Modal.Header closeButton>
              <Modal.Title className="ms-auto ps-4 text-center error-color">
                Email Address Duplicate
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="mb-4">
                {registrationForm.email} is already in the system.
              </div>
              <div className="mb-4">
                <Button
                  onClick={handleError}
                  className="error-small"
                  variant="dark"
                >
                  Try with different email
                </Button>
              </div>
              <div className="mb-4 mt-2">
                <Link to="/">
                  <Button
                    className="error-small"
                    variant="dark"
                    disabled={disabled}
                  >
                    Go to Log In
                  </Button>
                </Link>
              </div>
            </Modal.Body>
          </Modal.Dialog>
        </Modal>
    </>
  );
};

export default RegisterCard;
