import React from "react";
import {
  Container,
  Row,
  Col,
  Image,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Trash3 } from "react-bootstrap-icons";
import ImageUpload from "./ImageUpload";

const DisplayImageLibrary = ({
  imgCollection,
  handleImageClick,
  handleDeleteClick,
  handleFileChange,
  setSelectedImage,
  imgSrcString
}) => {
  const renderTooltipDelete = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Delete Image
    </Tooltip>
  );

  return (
    <Container fluid>
      <Row className="image-row flex-nowrap overflow-auto">
        {imgCollection.map((image, index) => (
          <Col key={index} xs="3">
            <div className="text-center">
              <Image
                fluid
                key={image.id}
                src={image.imgSrc1}
                alt={`Image ${image.id}`}
                className="image-library focus-hover"
                onClick={() => handleImageClick(image.imgSrc1)}
              />
              <OverlayTrigger
                placement="left"
                delay={{ show: 100, hide: 150 }}
                overlay={renderTooltipDelete}
              >
                <Trash3
                  className="text-black"
                  onClick={() => handleDeleteClick(image.id)}
                />
              </OverlayTrigger>
            </div>
          </Col>
        ))}
        {imgCollection.length === 0 && (
          <div>
            <h3 className="text-center">No Images Uploaded</h3>
            <ImageUpload
              handleFileChange={handleFileChange}
              imgSrcString={imgSrcString}
              setSelectedImage={setSelectedImage}
            />
          </div>
        )}
      </Row>
    </Container>
  );
};

export default DisplayImageLibrary;
