import React from 'react'
import { Row, Col, Button } from 'react-bootstrap';
import { logoutUser } from '../../../Services/UserServices';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setIsLoading } from '../../../Services/loadingSlice';
import { setAuthenticated } from '../../../Services/authSlice';

const LogoutButton = ({variant}) => {
    const email = sessionStorage.getItem('email');
    const accessToken = sessionStorage.getItem('accessToken')
    const navigate = useNavigate();
    const dispatch = useDispatch();

    function handleLogout(e) {
        e.preventDefault();
        logoutUser(email, accessToken)
          .then(async response => {
              try {
                  if (response.ok) {
                    dispatch(setIsLoading(true));
                    dispatch(setAuthenticated(false));
                    sessionStorage.setItem('isAuthenticated', 'false');
                    sessionStorage.clear();
                    navigate('/');
                    console.log("Logout success");
                  } else {
                    console.log("Logout failed");
                    sessionStorage.clear();
                    navigate('/');
                  }
              } catch (error) {
                  console.log(error);
              }
          });
        }

  return (
    <Row>
        <Col className='text-center'>
            <Button onClick={handleLogout} className="mx-auto mb-4 mt-4" variant={variant} type="submit">
            Log Out
            </Button>
        </Col>
    </Row>
  )
}

export default LogoutButton