import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Nav, Container, InputGroup } from 'react-bootstrap';
import { Facebook, Linkedin, Pinterest, SendFill, Twitter, CCircle, Tiktok, Instagram } from 'react-bootstrap-icons';

const Footer9 = ({ isPreview, holderState, selectedField }) => {
  const [footer9, setFooter9] = useState({
    companyName: `ACME`,
    link1Title: "First Page",
    link1: "testhome",
    link1T1: "First Page",
    link1T1L: "testhome",
    link1T2: "Second Page",
    link1T2L: "testhome",
    link1T3: "Third Page",
    link1T3L: "testhome",
    link1T4: "Fourth Page",
    link1T4L: "testhome",
    link2Title: "Second Page",
    link2: "",
    link2T1: "First Page",
    link2T1L: "testhome",
    link2T2: "Second Page",
    link2T2L: "testhome",
    link2T3: "Third Page",
    link2T3L: "testhome",
    link3Title: "Third Page",
    link3: "",
    link3T1: "First Page",
    link3T1L: "testhome",
    link3T2: "Second Page",
    link3T2L: "testhome",
    link3T3: "Third Page",
    link3T3L: "testhome",
    link4Title: "Fourth Page",
    link4: "",
    link5Title: "Fifth Page",
    link5: "",
    link6Title: "Sixth Page",
    link6: "",
    link7Title: "Seventh Page",
    link7: "",
    streetAddress: "Street Address",
    cityStateZip: "City, State, Zip",
    phoneNumber: "800-555-5555",
    sendEmail: "info@email.com",
    privacyPolicy: "Privacy Policy",
    privacyPolicyLink:
      holderState?.privacyLink !== undefined ? holderState.privacyLink : "",
    termsOfService: "Terms of Service",
    termsOfServiceLink:
      holderState?.tosLink !== undefined ? holderState.tosLink : "",
    socialLinkIG: holderState?.igLink !== undefined ? holderState.igLink : "",
    socialLinkFB: holderState?.fbLink !== undefined ? holderState.fbLink : "",
    socialLinkX: holderState?.xLink !== undefined ? holderState.xLink : "",
    socialLinkPIN:
      holderState?.pinLink !== undefined ? holderState.pinLink : "",
    socialLinkTik:
      holderState?.tikLink !== undefined ? holderState.tikLink : "",
    socialLinkLI: holderState?.liLink !== undefined ? holderState.liLink : "",
    text1:
      holderState?.text1Value !== undefined
        ? holderState.text1Value
        : "Join our Newsletter to stay up to date on features and releases",
    companyFooter:
      holderState?.companyFooterValue !== undefined
        ? holderState.companyFooterValue
        : "2018 Acme. All rights reserved.",
    showSocials:
      holderState?.socialIconsChecked !== undefined
        ? holderState.socialIconsChecked
        : true,
    showIG:
      holderState?.showIGChecked !== undefined
        ? holderState.showIGChecked
        : true,
    showFB:
      holderState?.showFbChecked !== undefined
        ? holderState.showFbChecked
        : true,
    showX:
      holderState?.showXChecked !== undefined ? holderState.showXChecked : true,
    showPIN:
      holderState?.showPINChecked !== undefined
        ? holderState.showPINChecked
        : true,
    showTik:
      holderState?.showTikChecked !== undefined
        ? holderState.showTikChecked
        : true,
    showLI:
      holderState?.showTikChecked !== undefined
        ? holderState.showTikChecked
        : true,
    showPrivacy:
      holderState?.showPrivacy !== undefined ? holderState.showPrivacy : true,
    showCompanyFooter:
      holderState?.showCompanyFooter !== undefined
        ? holderState.showCompanyFooter
        : true,
    showTos: holderState?.showTos !== undefined ? holderState.showTos : true,
    showLegal:
      holderState?.showLegal !== undefined ? holderState.showLegal : true,
    showSiteMap:
      holderState?.showSiteMap !== undefined ? holderState.showSiteMap : true,
    showText1:
      holderState?.showText1 !== undefined ? holderState.showText1 : true,
    showSendEmail:
      holderState?.showSendEmail !== undefined
        ? holderState.showSendEmail
        : true,
  });

  useEffect(() => {
    setFooter9((prevFooter9) => ({
      ...prevFooter9,
      showSocials:
        holderState?.socialIconsChecked !== undefined
          ? holderState.socialIconsChecked
          : true,
      showIG:
        holderState?.showIGChecked !== undefined
          ? holderState.showIGChecked
          : true,
      showFB:
        holderState?.showFbChecked !== undefined
          ? holderState.showFbChecked
          : true,
      showX:
        holderState?.showXChecked !== undefined
          ? holderState.showXChecked
          : true,
      showPIN:
        holderState?.showPINChecked !== undefined
          ? holderState.showPINChecked
          : true,
      showTik:
        holderState?.showTikChecked !== undefined
          ? holderState.showTikChecked
          : true,
      showLI:
        holderState?.showLIChecked !== undefined
          ? holderState.showLIChecked
          : true,
      showPrivacy:
        holderState?.showPrivacy !== undefined ? holderState.showPrivacy : true,
      showCompanyFooter:
        holderState?.showCompanyFooter !== undefined
          ? holderState.showCompanyFooter
          : true,
      showTos: holderState?.showTos !== undefined ? holderState.showTos : true,
      showLegal:
        holderState?.showLegal !== undefined ? holderState.showLegal : true,
      showSiteMap:
        holderState?.showSiteMap !== undefined ? holderState.showSiteMap : true,
      showText1:
        holderState?.showText1 !== undefined ? holderState.showText1 : true,
      showSendEmail:
        holderState?.showSendEmail !== undefined
          ? holderState.showSendEmail
          : true,
      showEmail:
        holderState?.showEmail !== undefined ? holderState.showEmail : true,
      privacyPolicyLink:
        holderState?.privacyLink !== undefined ? holderState.privacyLink : "",
      termsOfServiceLink:
        holderState?.tosLink !== undefined ? holderState.tosLink : "",
      socialLinkIG: holderState?.igLink !== undefined ? holderState.igLink : "",
      socialLinkFB: holderState?.fbLink !== undefined ? holderState.fbLink : "",
      socialLinkX: holderState?.xLink !== undefined ? holderState.xLink : "",
      socialLinkPIN:
        holderState?.pinLink !== undefined ? holderState.pinLink : "",
      socialLinkTik:
        holderState?.tikLink !== undefined ? holderState.tikLink : "",
      socialLinkLI: holderState?.liLink !== undefined ? holderState.liLink : "",
      text1:
        holderState?.text1Value !== undefined
          ? holderState.text1Value
          : "Join our Newsletter to stay up to date on features and releases",
      companyFooter:
        holderState?.companyFooterValue !== undefined
          ? holderState.companyFooterValue
          : "2018 Acme. All rights reserved.",
    }));
  }, [
    holderState?.socialIconsChecked,
    holderState?.showIGChecked,
    holderState?.showFbChecked,
    holderState?.showXChecked,
    holderState?.showPINChecked,
    holderState?.showTikChecked,
    holderState?.showLIChecked,
    holderState?.showPrivacy,
    holderState?.showCompanyFooter,
    holderState?.showTos,
    holderState?.showLegal,
    holderState?.showSiteMap,
    holderState?.showText1,
    holderState?.showSendEmail,
    holderState?.showEmail,
    holderState?.companyFooterValue,
    holderState?.privacyLink,
    holderState?.tosLink,
    holderState?.igLink,
    holderState?.fbLink,
    holderState?.xLink,
    holderState?.pinLink,
    holderState?.tikLink,
    holderState?.liLink,
    holderState?.text1Value,
  ]);

  const handleEmailChange = (e) => {
    setFooter9({ ...footer9, sendEmail: e.target.value });
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log("Email submitted:", footer9.email);
  // };
  return (
    <Container fluid>
      <Row
        className={`bg-white p-5 justify-content-center ${
          isPreview ? "preview-scaling" : ""
        }`}
      >
        <Col sm={3} md={12} lg={2} xl={2} className="d-flex">
          <h3 className={`${isPreview ? "h6" : ""}`}>{footer9.companyName}</h3>
        </Col>
        <Col
          xs={6}
          sm={3}
          md={2}
          lg={2}
          xl={2}
          className="gap-3 d-flex flex-column py-2"
        >
          <Nav.Link href={footer9.link1}>
            <strong>{footer9.link1Title}</strong>
          </Nav.Link>
          <Nav.Link href={footer9.link1T1L}>{footer9.link1T1}</Nav.Link>
          <Nav.Link href={footer9.link1T2L}>{footer9.link1T2}</Nav.Link>
          <Nav.Link href={footer9.link1T3L}>{footer9.link1T3}</Nav.Link>
          <Nav.Link href={footer9.link1T4L}>{footer9.link1T4}</Nav.Link>
        </Col>
        <Col
          xs={6}
          sm={3}
          md={2}
          lg={2}
          xl={2}
          className="gap-3 d-flex flex-column py-2"
        >
          <Nav.Link href={footer9.link2}>
            <strong>{footer9.link2Title}</strong>
          </Nav.Link>
          <Nav.Link href={footer9.link2T1L}>{footer9.link2T1}</Nav.Link>
          <Nav.Link href={footer9.link2T2L}>{footer9.link2T2}</Nav.Link>
          <Nav.Link href={footer9.link2T3L}>{footer9.link2T3}</Nav.Link>
        </Col>
        <Col
          xs={12}
          sm={3}
          md={2}
          lg={2}
          xl={2}
          className="gap-3 d-flex flex-column py-2"
        >
          <Nav.Link href={footer9.link3}>
            <strong>{footer9.link3Title}</strong>
          </Nav.Link>
          <Nav.Link href={footer9.link3T1L}>{footer9.link3T1}</Nav.Link>
          <Nav.Link href={footer9.link3T2L}>{footer9.link3T2}</Nav.Link>
          <Nav.Link href={footer9.link3T3L}>{footer9.link3T3}</Nav.Link>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={6}
          lg={4}
          xl={3}
          className="d-flex flex-column py-2"
        >
          <h6>
            <strong>Subscribe</strong>
          </h6>
          {footer9.showSendEmail && (
            <div className="my-2">
              <InputGroup
                className={`${
                  selectedField === "Send Email Button" ? "focus-field" : ""
                }`}
              >
                <Form.Control
                  name="email"
                  size="sm"
                  type="email"
                  placeholder="Enter your email address"
                  style={{
                    backgroundColor: "#F0F0F1",
                    borderColor: "#F4F4F5",
                  }}
                  value={footer9.sendEmail}
                  onChange={handleEmailChange}
                />
                <Button
                  style={{
                    backgroundColor: "#F0F0F1",
                    borderColor: "#F4F4F5",
                  }}
                  type="submit"
                >
                  <SendFill color="black" />
                </Button>
              </InputGroup>
            </div>
          )}
          {footer9.showText1 && (
            <p className="m-0">
              <small
                className={`${selectedField === "Text 1" ? "focus-field" : ""}`}
              >
                {footer9.text1}
              </small>
            </p>
          )}
        </Col>
      </Row>
      <Row
        className={`bg-white px-5 py-3 d-flex justify-content-center ${
          isPreview ? "preview-scaling" : ""
        }`}
      >
        {(footer9.showCompanyFooter ||
          footer9.showPrivacy ||
          footer9.showTos ||
          (footer9.showSocials &&
            (footer9.showIG ||
              footer9.showFB ||
              footer9.showX ||
              footer9.showLI ||
              footer9.showPIN ||
              footer9.showTik))) && (
          <Col
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={7}
            className="border-top p-2 d-flex gap-3 align-items-center"
          >
            {footer9.showCompanyFooter && (
              <small
                className={`${
                  selectedField === "Company Footer" ? "focus-field" : ""
                }`}
              >
                <CCircle size={12} />
                {footer9.companyFooter}
              </small>
            )}
            {footer9.showPrivacy && (
              <Nav.Link
                target="_blank"
                href={"https://" + footer9.privacyPolicyLink}
              >
                <small
                  className={`${
                    selectedField === "Privacy Policy" ? "focus-field" : ""
                  }`}
                >
                  Privacy Policy
                </small>
              </Nav.Link>
            )}
            {footer9.showTos && (
              <Nav.Link
                target="_blank"
                href={"https://" + footer9.termsOfServiceLink}
              >
                <small
                  className={`${
                    selectedField === "Terms of Service" ? "focus-field" : ""
                  }`}
                >
                  Terms of Service
                </small>
              </Nav.Link>
            )}
          </Col>
        )}
        {footer9.showSocials &&
          (footer9.showIG ||
            footer9.showFB ||
            footer9.showX ||
            footer9.showLI ||
            footer9.showPIN ||
            footer9.showTik) && (
            <Col
              xs={12}
              sm={12}
              md={3}
              lg={2}
              xl={4}
              className={`border-top p-2 col-5 d-flex gap-3 align-items-center justify-content-end ${
                selectedField === "Social Media Icons" ? "focus-field" : ""
              }`}
            >
              {footer9.showIG && (
                <Nav.Link
                  href={"https://" + footer9.socialLinkIG}
                  target="_blank"
                  className="text-black"
                >
                  <Instagram
                    className={`${
                      selectedField === "Instagram" ? "focus-field" : ""
                    }`}
                  />
                </Nav.Link>
              )}
              {footer9.showFB && (
                <Nav.Link
                  href={"https://" + footer9.socialLinkFB}
                  target="_blank"
                  className="text-black"
                >
                  <Facebook
                    className={`${
                      selectedField === "Facebook" ? "focus-field" : ""
                    }`}
                  />
                </Nav.Link>
              )}
              {footer9.showX && (
                <Nav.Link
                  href={"https://" + footer9.socialLinkX}
                  target="_blank"
                  className="text-black"
                >
                  <Twitter
                    className={`${selectedField === "X" ? "focus-field" : ""}`}
                  />
                </Nav.Link>
              )}
              {footer9.showPIN && (
                <Nav.Link
                  href={"https://" + footer9.socialLinkPIN}
                  target="_blank"
                  className="text-black"
                >
                  <Pinterest
                    className={`${
                      selectedField === "Pinterest" ? "focus-field" : ""
                    }`}
                  />
                </Nav.Link>
              )}
              {footer9.showTik && (
                <Nav.Link
                  href={"https://" + footer9.socialLinkTik}
                  target="_blank"
                  className="text-black"
                >
                  <Tiktok
                    className={`${
                      selectedField === "TikTok" ? "focus-field" : ""
                    }`}
                  />
                </Nav.Link>
              )}
              {footer9.showLI && (
                <Nav.Link
                  href={"https://" + footer9.socialLinkLI}
                  target="_blank"
                  className="text-black"
                >
                  <Linkedin
                    className={`${
                      selectedField === "LinkedIn" ? "focus-field" : ""
                    }`}
                  />
                </Nav.Link>
              )}
            </Col>
          )}
      </Row>
    </Container>
  );
};

export default Footer9