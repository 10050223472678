import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const rowIdStateSlice = createSlice({
  name: 'rowIdState',
  initialState,
  reducers: {
    setRowIdState: (state, action) => {
      console.log(action.payload);
      return action.payload;
    },
    returnExistingRowIdState: (state, action) => {
      console.log(state);
      return state; // Return the existing state (no changes)
    },
    pushToRowIdState: (state, action) => {
    const { rowId, data } = action.payload;
    const existingRow = state.find((row) => row.rowId === rowId);

    if (existingRow) {
        // If the rowId exists, update the existing row with the new data
        return state.map((row) =>
        row.rowId === rowId ? { ...row, ...data } : row
        );
    } else {
        // If the rowId doesn't exist, push a new object with the rowId and data
        return [...state, { rowId, ...data }];
    }
    },
    resetRowIdState: (state) => {
      return [];
    },
  },
});

export const {
  setRowIdState,
  returnExistingRowIdState,
  pushToRowIdState,
  resetRowIdState,
} = rowIdStateSlice.actions;

export default rowIdStateSlice.reducer;
