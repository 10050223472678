import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Container, Row, Col, Card, Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { setIsLoading } from '../../../Services/loadingSlice';
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../../../Services/UserServices";

const ForgetPwCard = () => {
      const [email, setEmail] = useState("");
      const [emailError, setEmailError] = useState("");
      const [emailNotFound, setEmailNotFound] = useState(false)
      const [disabled, setDisabled] = useState(true);
      const navigate = useNavigate();
      const dispatch = useDispatch();

    const resetLink = (e) => {
      e.preventDefault()
      if (email.includes("@") && email.endsWith(".com")) {
        dispatch(setIsLoading(true))
      } else {
        alert("Email must include @ and end with .com");
      }
      sessionStorage.setItem('email', email)
      forgotPassword(email).then(async response => {
        const message = await response.text();
        if (response.ok) {
          sessionStorage.setItem('email', email);

          navigate('/resetpassword')
        } else if (message === "User with the provided email does not exist.") {
          setEmailNotFound(true);
          dispatch(setIsLoading(false));
        }
        console.log(message);
      })
    };


  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value;
    setEmail(enteredEmail);
  };

  const checkValid = (e) => {
    if (
      email.includes("@") &&
      email.endsWith(".com")
    ) {
      setEmailError("");
      setDisabled(false);
    } else {
      setEmailError("true")
    }
  };

  return (
    <>
      <Container>
          <Row>
            <Col
              lg={{ span: 4, offset: 4 }}
              md={{ span: 6, offset: 3 }}
              sm={{ span: 8, offset: 2 }}
            >
              <Card className="mx-auto mt-4 reset-card">
                <Card.Body className="">
                  <Form>
                    <Form.Group className="form-control-lg">
                      <Form.Text className="text-center reset-font d-block mt-1 mb-3">
                        Forgot Password
                      </Form.Text>
                      <Form.Label htmlFor="email" className="reset-label">
                        Username/Email
                      </Form.Label>
                      <Form.Control id="email"
                        className="reset-input"
                        onChange={handleEmailChange}
                        onKeyUp={checkValid}
                        type="email"
                        placeholder="name@example.com"
                        name="email"
                        autoComplete="email"
                        isInvalid={emailError}
                      />
                      <Form.Control.Feedback type="invalid">
                        Must include "@" and end in ".com"
                      </Form.Control.Feedback>
                      {(emailNotFound ? <p><small>email not found</small></p> : "")}
                    </Form.Group>
                    <div className="text-center mb-4 mt-4">
                      <Button
                        onClick={resetLink}
                        className="reset-label mt-2 mb-4"
                        variant="dark"
                        type="submit"
                        disabled={disabled}
                      >
                        Send Reset Password Link
                      </Button>
                    </div>
                  </Form>
                  <Link
                    className="infoColor mt-4 reset-label mb-3"
                    to="/register"
                  >
                    Sign up for Account
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
      </Container>
    </>
  );
};

export default ForgetPwCard;
