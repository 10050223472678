import React, {useEffect, useState} from 'react';
import { Row, Col, Image, Carousel } from 'react-bootstrap';

const RA15 = ({ isPreview, holderState, selectedField }) => {
  const [rightAlign15, setRightAlign15] = useState({
    text1:
      holderState?.text1Value !== undefined
        ? holderState.text1Value
        : "Medium length headline",
    showText1:
      holderState?.showText1 !== undefined ? holderState.showText1 : true,
    text2:
      holderState?.text2Value !== undefined
        ? holderState.text2Value
        : "Change the color to match your brand or vision, add your logo, choose the perfect thumbnail, remove the playbar, add speed controls, and more. Increase engagement with CTAs and custom end screens. So what are the benefits?",
    showText2:
      holderState?.showText2 !== undefined ? holderState.showText2 : true,
    text3:
      holderState?.text3Value !== undefined
        ? holderState.text3Value
        : "Gorgeous, high-quality video sharing on all devices",
    showText3:
      holderState?.showText3 !== undefined ? holderState.showText3 : true,
    text4:
      holderState?.text4Value !== undefined
        ? holderState.text4Value
        : "Gorgeous, high-quality video sharing on all devices",
    showText4:
      holderState?.showText4 !== undefined ? holderState.showText4 : true,
    header1: holderState?.header1 !== undefined ? holderState.header1 : "32",
    showHeader1:
      holderState?.showHeader1 !== undefined ? holderState.showHeader1 : true,
    header2: holderState?.header2 !== undefined ? holderState.header2 : "GB",
    showHeader2:
      holderState?.showHeader2 !== undefined ? holderState.showHeader2 : true,
    header3: holderState?.header3 !== undefined ? holderState.header3 : "2.6",
    showHeader3:
      holderState?.showHeader3 !== undefined ? holderState.showHeader3 : true,
    header4:
      holderState?.header4 !== undefined ? holderState.header4 : "Million",
    showHeader4:
      holderState?.showHeader4 !== undefined ? holderState.showHeader4 : true,
    showImg1: holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
    showImg2: holderState?.showImg2 !== undefined ? holderState.showImg2 : true,
    showImg3: holderState?.showImg3 !== undefined ? holderState.showImg3 : true,
    imgLink1: holderState?.imgLink1 !== undefined ? holderState.imgLink1 : "",
    imgLink2: holderState?.imgLink2 !== undefined ? holderState.imgLink2 : "",
    imgLink3: holderState?.imgLink3 !== undefined ? holderState.imgLink3 : "",
    imgSrc1:
      holderState?.imgSrc1 !== undefined
        ? holderState.imgSrc1
        : "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt1: "Placeholder Image",
    imgSrc2:
      holderState?.imgSrc2 !== undefined
        ? holderState.imgSrc2
        : "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt2: "Placeholder Image",
    imgSrc3:
      holderState?.imgSrc3 !== undefined
        ? holderState.imgSrc3
        : "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
    imgAlt3: "Placeholder Image",
  });

  const imageContainerStyle = {
    display: "flex",
    alignItems: "center",
    height: "100%",
    maxHeight: "100%",
    borderRadius: "1rem",
    overflow: "hidden",
  };
  const imageStyle = {
    objectFit: "cover",
    height: "22rem",
    width: "100%",
    borderRadius: "1rem",
  };

  useEffect(() => {
    setRightAlign15((prevRA15) => ({
      ...prevRA15,
      text1:
        holderState?.text1Value !== undefined
          ? holderState.text1Value
          : "Medium length headline",
      showText1:
        holderState?.showText1 !== undefined ? holderState.showText1 : true,
      text2:
        holderState?.text2Value !== undefined
          ? holderState.text2Value
          : "Change the color to match your brand or vision, add your logo, choose the perfect thumbnail, remove the playbar, add speed controls, and more. Increase engagement with CTAs and custom end screens. So what are the benefits?",
      showText2:
        holderState?.showText2 !== undefined ? holderState.showText2 : true,
      text3:
        holderState?.text3Value !== undefined
          ? holderState.text3Value
          : "Gorgeous, high-quality video sharing on all devices",
      showText3:
        holderState?.showText3 !== undefined ? holderState.showText3 : true,
      text4:
        holderState?.text4Value !== undefined
          ? holderState.text4Value
          : "Gorgeous, high-quality video sharing on all devices",
      showText4:
        holderState?.showText4 !== undefined ? holderState.showText4 : true,
      header1: holderState?.header1 !== undefined ? holderState.header1 : "32",
      showHeader1:
        holderState?.showHeader1 !== undefined ? holderState.showHeader1 : true,
      header2: holderState?.header2 !== undefined ? holderState.header2 : "GB",
      showHeader2:
        holderState?.showHeader2 !== undefined ? holderState.showHeader2 : true,
      header3: holderState?.header3 !== undefined ? holderState.header3 : "2.6",
      showHeader3:
        holderState?.showHeader3 !== undefined ? holderState.showHeader3 : true,
      header4:
        holderState?.header4 !== undefined ? holderState.header4 : "Million",
      showHeader4:
        holderState?.showHeader4 !== undefined ? holderState.showHeader4 : true,
      showImg1:
        holderState?.showImg1 !== undefined ? holderState.showImg1 : true,
      showImg2:
        holderState?.showImg2 !== undefined ? holderState.showImg2 : true,
      showImg3:
        holderState?.showImg3 !== undefined ? holderState.showImg3 : true,
      imgLink1: holderState?.imgLink1 !== undefined ? holderState.imgLink1 : "",
      imgLink2: holderState?.imgLink2 !== undefined ? holderState.imgLink2 : "",
      imgLink3: holderState?.imgLink3 !== undefined ? holderState.imgLink3 : "",
      imgSrc1:
        holderState?.imgSrc1 !== undefined
          ? holderState.imgSrc1
          : "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgAlt1: "Placeholder Image",
      imgSrc2:
        holderState?.imgSrc2 !== undefined
          ? holderState.imgSrc2
          : "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgAlt2: "Placeholder Image",
      imgSrc3:
        holderState?.imgSrc3 !== undefined
          ? holderState.imgSrc3
          : "https://placehold.jp/EAF3FF/EAF3FF/500x300.png?css=%7B%22border-radius%22%3A%225px%22%2C%22text-color%22%3A%22%23D2E3FF%22%2C%22background%22%3A%22%20-webkit-gradient(linear%2C%20left%20top%2C%20left%20bottom%2C%20from(%23EAF3FF)%2C%20to(%23D2E3FF))%22%7D",
      imgAlt3: "Placeholder Image",
    }));
  }, [
    holderState?.showText1,
    holderState?.text1Value,
    holderState?.showText2,
    holderState?.text2Value,
    holderState?.showText3,
    holderState?.text3Value,
    holderState?.showText4,
    holderState?.text4Value,
    holderState?.showImg1,
    holderState?.showImg2,
    holderState?.showImg3,
    holderState?.imgLink1,
    holderState?.imgLink2,
    holderState?.imgLink3,
    holderState?.header1,
    holderState?.showHeader1,
    holderState?.header2,
    holderState?.showHeader2,
    holderState?.header3,
    holderState?.showHeader3,
    holderState?.header4,
    holderState?.showHeader4,
    holderState?.imgSrc1,
    holderState?.imgSrc2,
    holderState?.imgSrc3,
  ]);

  return (
    <Row
      sm={12}
      md={12}
      lg={10}
      xl={10}
      xxl={8}
      style={{ "--bs-gutter-x": "0" }}
      className={`d-flex justify-content-center bg-white align-items-center gap-5 ${
        isPreview ? "p-3" : "p-5"
      }`}
    >
      <Col
        xs={12}
        sm={10}
        md={5}
        lg={5}
        xl={5}
        xxl={4}
        className={`d-flex align-items-center justify-content-center my-auto ${
          isPreview ? "preview-scaling" : ""
        }`}
      >
        <Col className="text-center h-100">
          <Carousel slide={false} interval={null} style={imageContainerStyle}>
            {rightAlign15.showImg1 && (
              <Carousel.Item>
                {rightAlign15.imgLink1 !== "" && (
                  <a
                    href={"https://" + rightAlign15.imgLink1}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      src={rightAlign15.imgSrc1}
                      text="First slide"
                      alt={rightAlign15.imgAlt1}
                      className="rounded"
                      style={imageStyle}
                    />
                  </a>
                )}
                {rightAlign15.imgLink1 === "" && (
                  <Image
                    src={rightAlign15.imgSrc1}
                    text="First slide"
                    alt={rightAlign15.imgAlt1}
                    className="rounded"
                    style={imageStyle}
                  />
                )}
              </Carousel.Item>
            )}
            {rightAlign15.showImg2 && (
              <Carousel.Item>
                {rightAlign15.imgLink2 !== "" && (
                  <a
                    href={"https://" + rightAlign15.imgLink2}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      src={rightAlign15.imgSrc2}
                      text="First slide"
                      alt={rightAlign15.imgAlt2}
                      className="rounded"
                      style={imageStyle}
                    />
                  </a>
                )}
                {rightAlign15.imgLink2 === "" && (
                  <Image
                    src={rightAlign15.imgSrc2}
                    text="First slide"
                    alt={rightAlign15.imgAlt2}
                    className="rounded"
                    style={imageStyle}
                  />
                )}
              </Carousel.Item>
            )}
            {rightAlign15.showImg3 && (
              <Carousel.Item>
                {rightAlign15.imgLink3 !== "" && (
                  <a
                    href={"https://" + rightAlign15.imgLink3}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      src={rightAlign15.imgSrc3}
                      text="First slide"
                      alt={rightAlign15.imgAlt3}
                      className="rounded"
                      style={imageStyle}
                    />
                  </a>
                )}
                {rightAlign15.imgLink3 === "" && (
                  <Image
                    src={rightAlign15.imgSrc3}
                    text="First slide"
                    alt={rightAlign15.imgAlt3}
                    className="rounded"
                    style={imageStyle}
                  />
                )}
              </Carousel.Item>
            )}
          </Carousel>
        </Col>
      </Col>
      <Col
        xs={12}
        sm={10}
        md={6}
        lg={5}
        xl={5}
        xxl={4}
        className="d-flex flex-column justify-content-center"
      >
        <Col className="d-flex flex-column justify-content-center">
          <Col className="d-flex flex-column justify-content-center mx-2 ">
            {rightAlign15.showText1 && (
              <h5
                className={`fw-bold ${isPreview ? "preview-scaling" : ""} ${
                  selectedField === "text1" ? "focus-field" : ""
                }`}
              >
                {rightAlign15.text1}
              </h5>
            )}
            {rightAlign15.showText2 && (
              <p
                className={`mb-0 ${isPreview ? "preview-scaling" : ""} ${
                  selectedField === "text2" ? "focus-field" : ""
                }`}
              >
                {rightAlign15.text2}
              </p>
            )}
          </Col>
        </Col>
        <Col
          md={10}
          className={`d-flex mx-2  mt-2 ${isPreview ? "gap-0" : "gap-4"}`}
        >
          <Col className={`${isPreview ? "pe-1" : "p-2"}`}>
            <Col>
              <p className="mb-0">
                {rightAlign15.showHeader1 && (
                  <span
                    className={`${isPreview ? "h6" : "display-3"} ${
                      selectedField === "header1" ? "focus-field" : ""
                    }`}
                  >
                    {rightAlign15.header1}
                  </span>
                )}
                {rightAlign15.showHeader2 && (
                  <span
                    className={`${isPreview ? "preview-scaling fw-bold" : ""} ${
                      selectedField === "header2" ? "focus-field" : ""
                    }`}
                  >
                    {rightAlign15.header2}
                  </span>
                )}
              </p>
              {rightAlign15.showText3 && (
                <p
                  className={`mb-0 ${isPreview ? "preview-scaling" : ""} ${
                    selectedField === "text3" ? "focus-field" : ""
                  }`}
                >
                  {rightAlign15.text3}
                </p>
              )}
            </Col>
          </Col>
          <Col className={`${isPreview ? "" : "p-2"}`}>
            <Col>
              <p className="mb-0">
                <span
                  className={`${isPreview ? "h6" : "display-3"} ${
                    selectedField === "header3" ? "focus-field" : ""
                  }`}
                >
                  {rightAlign15.header3}
                </span>
                <span
                  className={`${isPreview ? "preview-scaling fw-bold" : ""} ${
                    selectedField === "header4" ? "focus-field" : ""
                  }`}
                >
                  {rightAlign15.header4}
                </span>
              </p>
              <p
                className={`mb-0 ${isPreview ? "preview-scaling" : ""} ${
                  selectedField === "text4" ? "focus-field" : ""
                }`}
              >
                {rightAlign15.text4}
              </p>
            </Col>
          </Col>
        </Col>
      </Col>
    </Row>
  );
};

export default RA15;