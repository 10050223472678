import React from "react";
import { useState, useEffect } from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import {
  CCircle,
  Instagram,
  Facebook,
  Twitter,
  Pinterest,
  Tiktok,
  Linkedin,
} from "react-bootstrap-icons";

function Footer3({ isPreview, holderState, selectedField }) {
  const [footer3, setFooter3] = useState({
    companyName: `ACME`,
    link1Title: "First Page",
    link1: "testhome",
    link2Title: "Second Page",
    link2: "",
    link3Title: "Third Page",
    link3: "",
    link4Title: "Fourth Page",
    link4: "",
    link5Title: "Fifth Page",
    link5: "",
    language: "English Version",
    legal: "Legal",
    legalLink: holderState?.legLink !== undefined ? holderState.legLink : "",
    siteMap: "Site Map",
    siteMapLink:
      holderState?.siteLink !== undefined ? holderState.siteLink : "",
    privacyPolicy: "Privacy Policy",
    privacyPolicyLink:
      holderState?.privacyLink !== undefined ? holderState.privacyLink : "",
    termsOfService: "Terms of Service",
    termsOfServiceLink:
      holderState?.tosLink !== undefined ? holderState.tosLink : "",
    socialLinkIG: holderState?.igLink !== undefined ? holderState.igLink : "",
    socialLinkFB: holderState?.fbLink !== undefined ? holderState.fbLink : "",
    socialLinkX: holderState?.xLink !== undefined ? holderState.xLink : "",
    socialLinkPIN:
      holderState?.pinLink !== undefined ? holderState.pinLink : "",
    socialLinkTik:
      holderState?.tikLink !== undefined ? holderState.tikLink : "",
    socialLinkLI: holderState?.liLink !== undefined ? holderState.liLink : "",
    companyFooter:
      holderState?.companyFooterValue !== undefined
        ? holderState.companyFooterValue
        : "2018 Acme. All rights reserved.",
    showSocials:
      holderState?.socialIconsChecked !== undefined
        ? holderState.socialIconsChecked
        : true,
    showIG:
      holderState?.showIGChecked !== undefined
        ? holderState.showIGChecked
        : true,
    showFB:
      holderState?.showFbChecked !== undefined
        ? holderState.showFbChecked
        : true,
    showX:
      holderState?.showXChecked !== undefined ? holderState.showXChecked : true,
    showPIN:
      holderState?.showPINChecked !== undefined
        ? holderState.showPINChecked
        : true,
    showTik:
      holderState?.showTikChecked !== undefined
        ? holderState.showTikChecked
        : true,
    showLI:
      holderState?.showLIChecked !== undefined
        ? holderState.showLIChecked
        : true,
    showPrivacy:
      holderState?.showPrivacy !== undefined ? holderState.showPrivacy : true,
    showCompanyFooter:
      holderState?.showCompanyFooter !== undefined
        ? holderState.showCompanyFooter
        : true,
    showTos: holderState?.showTos !== undefined ? holderState.showTos : true,
    showLegal:
      holderState?.showLegal !== undefined ? holderState.showLegal : true,
    showSiteMap:
      holderState?.showSiteMap !== undefined ? holderState.showSiteMap : true,
    showLanguage:
      holderState?.showLanguage !== undefined ? holderState.showLanguage : true,
  });

  useEffect(() => {
    setFooter3((prevFooter3) => ({
      ...prevFooter3,
      showSocials:
        holderState?.socialIconsChecked !== undefined
          ? holderState.socialIconsChecked
          : true,
      showIG:
        holderState?.showIGChecked !== undefined
          ? holderState.showIGChecked
          : true,
      showFB:
        holderState?.showFbChecked !== undefined
          ? holderState.showFbChecked
          : true,
      showX:
        holderState?.showXChecked !== undefined
          ? holderState.showXChecked
          : true,
      showPIN:
        holderState?.showPINChecked !== undefined
          ? holderState.showPINChecked
          : true,
      showTik:
        holderState?.showTikChecked !== undefined
          ? holderState.showTikChecked
          : true,
      showLI:
        holderState?.showLIChecked !== undefined
          ? holderState.showLIChecked
          : true,
      showPrivacy:
        holderState?.showPrivacy !== undefined ? holderState.showPrivacy : true,
      showCompanyFooter:
        holderState?.showCompanyFooter !== undefined
          ? holderState.showCompanyFooter
          : true,
      showTos: holderState?.showTos !== undefined ? holderState.showTos : true,
      showLegal:
        holderState?.showLegal !== undefined ? holderState.showLegal : true,
      showSiteMap:
        holderState?.showSiteMap !== undefined ? holderState.showSiteMap : true,
      showLanguage:
        holderState?.showLanguage !== undefined
          ? holderState.showLanguage
          : true,
      privacyPolicyLink:
        holderState?.privacyLink !== undefined ? holderState.privacyLink : "",
      termsOfServiceLink:
        holderState?.tosLink !== undefined ? holderState.tosLink : "",
      socialLinkIG: holderState?.igLink !== undefined ? holderState.igLink : "",
      socialLinkFB: holderState?.fbLink !== undefined ? holderState.fbLink : "",
      socialLinkX: holderState?.xLink !== undefined ? holderState.xLink : "",
      socialLinkPIN:
        holderState?.pinLink !== undefined ? holderState.pinLink : "",
      socialLinkTik:
        holderState?.tikLink !== undefined ? holderState.tikLink : "",
      socialLinkLI: holderState?.liLink !== undefined ? holderState.liLink : "",
      companyFooter:
        holderState?.companyFooterValue !== undefined
          ? holderState.companyFooterValue
          : "2018 Acme. All rights reserved.",
      legalLink: holderState?.legLink !== undefined ? holderState.legLink : "",
      siteMapLink:
        holderState?.siteLink !== undefined ? holderState.siteLink : "",
    }));
  }, [
    holderState?.socialIconsChecked,
    holderState?.showIGChecked,
    holderState?.showFbChecked,
    holderState?.showXChecked,
    holderState?.showPINChecked,
    holderState?.showTikChecked,
    holderState?.showLIChecked,
    holderState?.showPrivacy,
    holderState?.showCompanyFooter,
    holderState?.showTos,
    holderState?.showLegal,
    holderState?.showSiteMap,
    holderState?.showLanguage,
    holderState?.igLink,
    holderState?.fbLink,
    holderState?.xLink,
    holderState?.pinLink,
    holderState?.tikLink,
    holderState?.liLink,
    holderState?.companyFooterValue,
    holderState?.privacyLink,
    holderState?.tosLink,
    holderState?.legLink,
    holderState?.siteLink,
    isPreview
  ]);

  return (
    <div className="border">
      <footer className="">
        <Container fluid>
          <Row className={`pt-4 pb-2 mx-0 align-items-center`}>
            <Col
              xs={12}
              sm={12}
              md={2}
              xl={2}
              className={`d-flex justify-content-md-end justify-content-xl-end justify-content-center ${
                isPreview ? "preview-scaling" : ""
              }`}
            >
              <h2 className={`fw-bold ${isPreview ? "h6" : ""}`}>ACME</h2>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={8}
              lg={6}
              className={`d-flex offset-lg-1 flex-column flex-md-row justify-content-center justify-content-md-evenly fw-bold align-items-center ${
                isPreview ? "preview-scaling" : ""
              }`}
            >
              <Nav href={footer3.link1} className="pe-sm-2 py-1 py-md-0">
                {footer3.link1Title}
              </Nav>
              <Nav href={footer3.link2} className="pe-sm-2 py-1 py-md-0">
                {footer3.link2Title}
              </Nav>
              <Nav href={footer3.link3} className="pe-sm-2 py-1 py-md-0">
                {footer3.link3Title}
              </Nav>
              <Nav href={footer3.link4} className="pe-sm-2 py-1 py-md-0">
                {footer3.link4Title}
              </Nav>
              <Nav href={footer3.link5} className="pe-sm-2 pt-1 pb-4 py-md-0">
                {footer3.link5Title}
              </Nav>
            </Col>
            {footer3.showLanguage && (
              <Col
                xs={12}
                sm={12}
                md={2}
                lg={2}
                className={`offset-lg-1 d-flex flex-row justify-content-sm-center ${
                  isPreview ? "preview-scaling" : ""
                }`}
              >
                <div
                  className={`text-muted ${
                    selectedField === "Language" ? "focus-field" : ""
                  }`}
                >
                  {footer3.language}
                </div>
              </Col>
            )}
            {footer3.showSocials &&
              (footer3.showIG ||
                footer3.showFB ||
                footer3.showX ||
                footer3.showLI ||
                footer3.showPIN ||
                footer3.showTik) && (
                <Col
                  xs={12}
                  className={`my-4 justify-content-center d-flex ${
                    selectedField === "Social Media Icons" ? "focus-field" : ""
                  }`}
                >
                  {footer3.showIG && (
                    <Nav.Link
                      href={"https://" + footer3.socialLinkIG}
                      target="_blank"
                      className="text-black"
                    >
                      <Instagram
                        className={`me-4 fs-4 ${
                          selectedField === "Instagram" ? "focus-field" : ""
                        }`}
                        size={`${isPreview ? 10 : 22}`}
                      />
                    </Nav.Link>
                  )}
                  {footer3.showFB && (
                    <Nav.Link
                      href={"https://" + footer3.socialLinkFB}
                      target="_blank"
                      className="text-black"
                    >
                      <Facebook
                        className={`me-4 fs-4 ${
                          selectedField === "Facebook" ? "focus-field" : ""
                        }`}
                        size={`${isPreview ? 10 : 22}`}
                      />
                    </Nav.Link>
                  )}
                  {footer3.showX && (
                    <Nav.Link
                      href={"https://" + footer3.socialLinkX}
                      target="_blank"
                      className="text-black"
                    >
                      <Twitter
                        className={`me-4 fs-4 ${
                          selectedField === "X" ? "focus-field" : ""
                        }`}
                        size={`${isPreview ? 10 : 22}`}
                      />
                    </Nav.Link>
                  )}
                  {footer3.showPIN && (
                    <Nav.Link
                      href={"https://" + footer3.socialLinkPIN}
                      target="_blank"
                      className="text-black"
                    >
                      <Pinterest
                        className={`me-4 fs-4 ${
                          selectedField === "Pinterest" ? "focus-field" : ""
                        }`}
                        size={`${isPreview ? 10 : 22}`}
                      />
                    </Nav.Link>
                  )}
                  {footer3.showLI && (
                    <Nav.Link
                      href={"https://" + footer3.socialLinkLI}
                      target="_blank"
                      className="text-black"
                    >
                      <Linkedin
                        className={`me-4 fs-4 ${
                          selectedField === "LinkedIn" ? "focus-field" : ""
                        }`}
                        size={`${isPreview ? 10 : 22}`}
                      />
                    </Nav.Link>
                  )}
                  {footer3.showTik && (
                    <Nav.Link
                      href={"https://" + footer3.socialLinkTik}
                      target="_blank"
                      className="text-black"
                    >
                      <Tiktok
                        className={`fs-4 ${
                          selectedField === "TikTok" ? "focus-field" : ""
                        }`}
                        size={`${isPreview ? 10 : 22}`}
                      />
                    </Nav.Link>
                  )}
                </Col>
              )}
            <Col
              xs={12}
              className="d-flex justify-content-center pt-4 pb-2 text-muted fs-6"
            >
              {footer3.showPrivacy && (
                <Nav.Link
                  href={"https://" + footer3.privacyPolicyLink}
                  target="_blank"
                  className={`pe-2 ${isPreview ? "preview-scaling" : ""} ${
                    selectedField === "Privacy Policy" ? "focus-field" : ""
                  }`}
                >
                  {footer3.privacyPolicy}
                </Nav.Link>
              )}
              {footer3.showTos && (
                <Nav.Link
                  href={"https://" + footer3.termsOfServiceLink}
                  target="_blank"
                  className={`px-2 ${isPreview ? "preview-scaling" : ""} ${
                    selectedField === "Terms of Service" ? "focus-field" : ""
                  }`}
                >
                  {footer3.termsOfService}
                </Nav.Link>
              )}
              {footer3.showLegal && (
                <Nav.Link
                  href={"https://" + footer3.legalLink}
                  target="_blank"
                  className={`px-2 ${isPreview ? "preview-scaling" : ""} ${
                    selectedField === "Legal" ? "focus-field" : ""
                  }`}
                >
                  {footer3.legal}
                </Nav.Link>
              )}
              {footer3.showSiteMap && (
                <Nav.Link
                  href={"https://" + footer3.siteMapLink}
                  target="_blank"
                  className={`ps-2 ${isPreview ? "preview-scaling" : ""} ${
                    selectedField === "Site Map" ? "focus-field" : ""
                  }`}
                >
                  {footer3.siteMap}
                </Nav.Link>
              )}
            </Col>
            <Col
              xs={12}
              className={`text-muted d-flex justify-content-center pb-2 ${
                isPreview ? "preview-scaling" : ""
              }`}
            >
              {footer3.showCompanyFooter && (
                <small
                  className={`${
                    selectedField === "Company Footer" ? "focus-field" : ""
                  }`}
                >
                  {<CCircle />} {footer3.companyFooter}
                </small>
              )}
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
}

export default Footer3;