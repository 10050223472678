import React from "react";
import { useSelector } from "react-redux";
import { Container, Card } from "react-bootstrap";
import '../ServiceComponents/serviceComponents.css'
import RenderNavbarComponent from "./ComponentChoices/ChoicesComponents/RenderNavbarComponent";
import RenderFooterComponent from "./ComponentChoices/ChoicesComponents/RenderFooterComponent";
import RenderRowComponent from "./ComponentChoices/ChoicesComponents/RenderRowComponent";

export const PreviewComponent = ({
  setShowPreview,
  selectedBodyRows,
  selectedFooter,
  selectedNavbar,
  passedFooterState,
  passedRowIdState,
  setIndexKey,
  indexKey,
  setPageTitle,
  pageTitle,
}) => {
  const reduxFooterState = useSelector((state) => state.footerStates);
  const footerState = passedFooterState || reduxFooterState;
  const reduxRowIdState = useSelector((state) => state.rowIdState);
  const rowIdState = passedRowIdState || reduxRowIdState;
  const openPreview = () => {
    if (setIndexKey) {
      setIndexKey(indexKey);
      setPageTitle(pageTitle);
    }
  setShowPreview(true);
  };

  // Define a max height for the Card
  const maxCardHeight = "300px"; // Set your desired max height

    const transformStyle = {
      transform: "scale(0.9)", // Adjust the scale factor as needed
      transformOrigin: "top center",
    };

  return (
    <Container onClick={openPreview} className="pe-0">
      <Card className="border" style={{ maxHeight: maxCardHeight }}>
        <Card.Body className="crud-hover">
          {!setIndexKey && (
          <Card.Subtitle className="mb-2 fw-semibold opacity-50">
            PREVIEW
          </Card.Subtitle>)}
          <div
            style={{
              maxHeight: `calc(${maxCardHeight} - 3rem)`,
              overflowY: "auto",
            }}
            className="preview-container"
          >
            <div style={transformStyle}>
              {(selectedNavbar !== null ||
                selectedBodyRows !== null ||
                selectedFooter !== null) && (
                <>
                  {selectedNavbar !== null && (
                    <>
                      {RenderNavbarComponent(
                        selectedNavbar.navComponent.componentName,
                        selectedNavbar.navComponent.props,
                        true
                      )}
                    </>
                  )}
                  {selectedBodyRows.length > 0 &&
                    selectedBodyRows
                      .slice() // Create a copy of the array to avoid modifying the original
                      .sort((a, b) => a.rowId - b.rowId) // Sort by ascending rowId
                      .map((selectedBodyRow) => (
                        <div key={selectedBodyRow.rowId} className="my-4">
                          {rowIdState.map((rowIdStateItem) => {
                            if (
                              rowIdStateItem.rowId === selectedBodyRow.rowId
                            ) {
                              return RenderRowComponent(
                                selectedBodyRow.bodyRowComponent.componentName,
                                { holderState: rowIdStateItem.bodyState },
                                true,
                                false,
                                rowIdStateItem.rowId
                              );
                            }
                            return null;
                          })}
                        </div>
                      ))}
                  {selectedFooter !== null && (
                    <>
                      {RenderFooterComponent(
                        selectedFooter.footComponent.componentName,
                        { holderState: footerState },
                        true
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};
