import React, { useState, useEffect } from "react";
import { Row, Col, Nav, Container } from "react-bootstrap";
import {
  Instagram,
  Facebook,
  Twitter,
  Pinterest,
  CCircle,
  Linkedin,
  Tiktok,
} from "react-bootstrap-icons";

const Footer2 = ({ isPreview, holderState, selectedField }) => {
  const [footer2, setFooter2] = useState({
    companyName: `ACME`,
    link1Title: "First Page",
    link1: "testhome",
    link2Title: "Second Page",
    link2: "",
    link3Title: "Third Page",
    link3: "",
    link4Title: "Fourth Page",
    link4: "",
    link5Title: "Fifth Page",
    link5: "",
    privacyPolicy: "Privacy Policy",
    privacyPolicyLink:
      holderState?.privacyLink !== undefined ? holderState.privacyLink : "",
    termsOfService: "Terms of Service",
    termsOfServiceLink:
      holderState?.tosLink !== undefined ? holderState.tosLink : "",
    socialLinkIG: holderState?.igLink !== undefined ? holderState.igLink : "",
    socialLinkFB: holderState?.fbLink !== undefined ? holderState.fbLink : "",
    socialLinkX: holderState?.xLink !== undefined ? holderState.xLink : "",
    socialLinkPIN:
      holderState?.pinLink !== undefined ? holderState.pinLink : "",
    socialLinkTik:
      holderState?.tikLink !== undefined ? holderState.tikLink : "",
    socialLinkLI: holderState?.liLink !== undefined ? holderState.liLink : "",
    showCompanyFooter:
      holderState?.showCompanyFooter !== undefined
        ? holderState.showCompanyFooter
        : true,
    showSocials:
      holderState?.socialIconsChecked !== undefined
        ? holderState.socialIconsChecked
        : true,
    showIG:
      holderState?.showIGChecked !== undefined
        ? holderState.showIGChecked
        : true,
    showFB:
      holderState?.showFbChecked !== undefined
        ? holderState.showFbChecked
        : true,
    showX:
      holderState?.showXChecked !== undefined ? holderState.showXChecked : true,
    showPIN:
      holderState?.showPINChecked !== undefined
        ? holderState.showPINChecked
        : true,
    showTik:
      holderState?.showTikChecked !== undefined
        ? holderState.showTikChecked
        : true,
    showLI:
      holderState?.showLIChecked !== undefined
        ? holderState.showLIChecked
        : true,
    showPrivacy:
      holderState?.showPrivacy !== undefined ? holderState.showPrivacy : true,
    companyFooter:
      holderState?.companyFooterValue !== undefined
        ? holderState.companyFooterValue
        : "2018 Acme. All rights reserved.",
    showTos: holderState?.showTos !== undefined ? holderState.showTos : true,
  });

  useEffect(() => {
    setFooter2((prevFooter2) => ({
      ...prevFooter2,
      showSocials:
        holderState?.socialIconsChecked !== undefined
          ? holderState.socialIconsChecked
          : true,
      showIG:
        holderState?.showIGChecked !== undefined
          ? holderState.showIGChecked
          : true,
      showFB:
        holderState?.showFbChecked !== undefined
          ? holderState.showFbChecked
          : true,
      showX:
        holderState?.showXChecked !== undefined
          ? holderState.showXChecked
          : true,
      showPIN:
        holderState?.showPINChecked !== undefined
          ? holderState.showPINChecked
          : true,
      showTik:
        holderState?.showTikChecked !== undefined
          ? holderState.showTikChecked
          : true,
      showLI:
        holderState?.showLIChecked !== undefined
          ? holderState.showLIChecked
          : true,
      showPrivacy:
        holderState?.showPrivacy !== undefined ? holderState.showPrivacy : true,
      showCompanyFooter:
        holderState?.showCompanyFooter !== undefined
          ? holderState.showCompanyFooter
          : true,
      showTos: holderState?.showTos !== undefined ? holderState.showTos : true,
      privacyPolicy: "Privacy Policy",
      privacyPolicyLink:
        holderState?.privacyLink !== undefined ? holderState.privacyLink : "",
      termsOfService: "Terms of Service",
      termsOfServiceLink:
        holderState?.tosLink !== undefined ? holderState.tosLink : "",
      socialLinkIG: holderState?.igLink !== undefined ? holderState.igLink : "",
      socialLinkFB: holderState?.fbLink !== undefined ? holderState.fbLink : "",
      socialLinkX: holderState?.xLink !== undefined ? holderState.xLink : "",
      socialLinkPIN:
        holderState?.pinLink !== undefined ? holderState.pinLink : "",
      socialLinkTik:
        holderState?.tikLink !== undefined ? holderState.tikLink : "",
      socialLinkLI: holderState?.liLink !== undefined ? holderState.liLink : "",
      companyFooter:
        holderState?.companyFooterValue !== undefined
          ? holderState.companyFooterValue
          : "2018 Acme. All rights reserved.",
    }));
  }, [
    holderState?.socialIconsChecked,
    holderState?.showIGChecked,
    holderState?.showFbChecked,
    holderState?.showXChecked,
    holderState?.showPINChecked,
    holderState?.showTikChecked,
    holderState?.showLIChecked,
    holderState?.showPrivacy,
    holderState?.showCompanyFooter,
    holderState?.showTos,
    holderState?.igLink,
    holderState?.fbLink,
    holderState?.xLink,
    holderState?.pinLink,
    holderState?.tikLink,
    holderState?.liLink,
    holderState?.companyFooterValue,
    holderState?.privacyLink,
    holderState?.tosLink,
  ]);

  return (
    <Container fluid>
      <Row className={`justify-content-center border bg-white py-4`}>
        <Col
          md={12}
          lg={2}
          className={`d-flex justify-content-center ${
            isPreview ? "preview-scaling" : ""
          }`}
        >
          <h3 className={`${isPreview ? "h6" : ""}`}>{footer2.companyName}</h3>
        </Col>
        <Col md={12} lg={8} className="text-center">
          <Col
            className={`d-flex justify-content-center gap-5 flex-wrap ${
              isPreview ? "preview-scaling" : ""
            }`}
          >
            <Nav.Link href={footer2.link1}>
              <strong>{footer2.link1Title}</strong>
            </Nav.Link>
            <Nav.Link href={footer2.link2}>
              <strong>{footer2.link2Title}</strong>
            </Nav.Link>
            <Nav.Link href={footer2.link3}>
              <strong>{footer2.link3Title}</strong>
            </Nav.Link>
            <Nav.Link href={footer2.link4}>
              <strong>{footer2.link4Title}</strong>
            </Nav.Link>
            <Nav.Link href={footer2.link5}>
              <strong>{footer2.link5Title}</strong>
            </Nav.Link>
          </Col>
          <Col
            md={12}
            className={`d-flex justify-content-center align-items-center gap-3 flex-wrap pt-5 ${
              isPreview ? "preview-scaling" : ""
            }`}
          >
            {footer2.showCompanyFooter && (
              <small
                className={`${
                  selectedField === "Company Footer" ? "focus-field" : ""
                }`}
              >
                <CCircle size={12} /> {footer2.companyFooter}
              </small>
            )}
            {footer2.showPrivacy && (
              <Nav.Link
                target="_blank"
                href={"https://" + footer2.privacyPolicyLink}
              >
                <small
                  className={`${
                    selectedField === "Privacy Policy" ? "focus-field" : ""
                  }`}
                >
                  {footer2.privacyPolicy}
                </small>
              </Nav.Link>
            )}
            {footer2.showTos && (
              <Nav.Link
                target="_blank"
                href={"https://" + footer2.termsOfServiceLink}
              >
                <small
                  className={`${
                    selectedField === "Terms of Service" ? "focus-field" : ""
                  }`}
                >
                  {footer2.termsOfService}
                </small>
              </Nav.Link>
            )}
          </Col>
        </Col>

        <Col
          md={12}
          lg={2}
          className={`d-flex justify-content-center gap-2 ${
            selectedField === "Social Media Icons" ? "focus-field" : ""
          }`}
        >
          {footer2.showSocials &&
            (footer2.showIG ||
              footer2.showFB ||
              footer2.showX ||
              footer2.showLI ||
              footer2.showPIN ||
              footer2.showTik) && (
              <>
                {footer2.showIG && (
                  <Nav.Link
                    href={"https://" + footer2.socialLinkIG}
                    target="_blank"
                    className={`text-black`}
                  >
                    <Instagram
                      className={`${
                        selectedField === "Instagram" ? "focus-field" : ""
                      }`}
                      size={`${isPreview ? 10 : 18}`}
                    />
                  </Nav.Link>
                )}
                {footer2.showFB && (
                  <Nav.Link
                    href={"https://" + footer2.socialLinkFB}
                    target="_blank"
                    className="text-black"
                  >
                    <Facebook
                      className={`${
                        selectedField === "Facebook" ? "focus-field" : ""
                      }`}
                      size={`${isPreview ? 10 : 18}`}
                    />
                  </Nav.Link>
                )}
                {footer2.showX && (
                  <Nav.Link
                    href={"https://" + footer2.socialLinkX}
                    target="_blank"
                    className="text-black"
                  >
                    <Twitter
                      className={`${
                        selectedField === "X" ? "focus-field" : ""
                      }`}
                      size={`${isPreview ? 10 : 18}`}
                    />
                  </Nav.Link>
                )}
                {footer2.showPIN && (
                  <Nav.Link
                    href={"https://" + footer2.socialLinkPIN}
                    target="_blank"
                    className="text-black"
                  >
                    <Pinterest
                      className={`${
                        selectedField === "Pinterest" ? "focus-field" : ""
                      }`}
                      size={`${isPreview ? 10 : 18}`}
                    />
                  </Nav.Link>
                )}
                {footer2.showTik && (
                  <Nav.Link
                    href={"https://" + footer2.socialLinkTik}
                    target="_blank"
                    className="text-black"
                  >
                    <Tiktok
                      className={`${
                        selectedField === "TikTok" ? "focus-field" : ""
                      }`}
                      size={`${isPreview ? 10 : 18}`}
                    />
                  </Nav.Link>
                )}
                {footer2.showLI && (
                  <Nav.Link
                    href={"https://" + footer2.socialLinkLI}
                    target="_blank"
                    className="text-black"
                  >
                    <Linkedin
                      className={`${
                        selectedField === "LinkedIn" ? "focus-field" : ""
                      }`}
                      size={`${isPreview ? 10 : 18}`}
                    />
                  </Nav.Link>
                )}
              </>
            )}
        </Col>
      </Row>
    </Container>
  );
};

export default Footer2;
