import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';

const Form7 = () => {
    const [showPassword, setShowPassword] = useState(false);

  return (
    <Row sm={12} md={12} lg={10} xl={10} xxl={8} style={{"--bs-gutter-x":'0'}} className='d-flex justify-content-center p-5 bg-white'>
        <Col xs={12} md={12} lg={10} xl={10} className="d-flex flex-column align-items-center justify-content-center">
        
            <Col xs={12} sm={8} md={8} lg={8} xl={8} xxl={5} className='d-flex flex-column gap-3'>
                <Form>
                    <Form.Group className="mb-3">
                        <div className='d-flex flex-row gap-3'>
                        <Form.Control id='firstName' type="text" placeholder="Name" className='bg-light' autoComplete="firstName" />
                        <Form.Control id='lastName' type="text" placeholder="Surname" className='bg-light' autoComplete="lastName" />
                        </div>
                        <Form.Control id='email' type="email" placeholder="Email" className='mt-3 bg-light' autoComplete="email" />
                        <InputGroup className='mt-3'>
                            <Form.Control
                                id='password'
                                aria-describedby='password'
                                type={showPassword ? 'text' : 'password'}
                                placeholder='Password'
                                className='bg-light'
                            />
                            <InputGroup.Text id='password' onClick={(e) => setShowPassword(!showPassword)}>
                                {showPassword ? <EyeSlashFill /> : <EyeFill />}
                            </InputGroup.Text>
                        </InputGroup>
                        <InputGroup className='mt-3'>
                            <Form.Control
                                id='confirmPassword'
                                aria-describedby='confirmPassword'
                                type={showPassword ? 'text' : 'password'}
                                placeholder='Confirm Password'
                                className='bg-light'
                            />
                            <InputGroup.Text id='confirmPassword' onClick={(e) => setShowPassword(!showPassword)}>
                                {showPassword ? <EyeSlashFill /> : <EyeFill />}
                            </InputGroup.Text>
                        </InputGroup>
                        <Form.Check
                            defaultChecked={true}
                            type={'checkbox'}
                            id={`default-checkbox`}
                            label={`Subscribe to the newsletter`}
                            className='my-3'
                        />
                    </Form.Group>
                    <Button type='submit' className='d-flex w-100 justify-content-center'>Sign in</Button>
                </Form>
                <p className='text-center'>
                    <small>By signing in you agree with the {}
                        <Link href='#' className='link-offset-2 link-underline link-underline-opacity-0 link-underline-opacity-75-hover text-secondary'>
                            Terms and Conditions
                        </Link> and {}
                        <Link href='#' className='link-offset-2 link-underline link-underline-opacity-0 link-underline-opacity-75-hover text-secondary'>
                            Privacy Policy
                        </Link>
                    </small>
                </p>
            </Col>

        </Col>
    </Row>
  )
}

export default Form7