import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";

const CA2 = ({ isPreview, holderState, selectedField }) => {
  const [centerAlign2, setCenterAlign2] = useState({
    text1:
      holderState?.text1Value !== undefined
        ? holderState.text1Value
        : "Long headline to turn your visitors into users",
    showText1:
      holderState?.showText1 !== undefined ? holderState.showText1 : true,
    text2:
      holderState?.text2Value !== undefined
        ? holderState.text2Value
        : "Separated they live in Bookmarksgrove right at the coast of the famous Semantics, large language ocean and many more stuff and more more more",
    showText2:
      holderState?.showText2 !== undefined ? holderState.showText2 : true,
    button1:
      holderState?.button1 !== undefined ? holderState.button1 : "Action",
    button1Link:
      holderState?.button1Link !== undefined ? holderState.button1Link : "",
    showButton1:
      holderState?.showButton1 !== undefined ? holderState.showButton1 : true,
    button2:
      holderState?.button2 !== undefined ? holderState.button2 : "Action",
    button2Link:
      holderState?.button2Link !== undefined ? holderState.button2Link : "",
    showButton2:
      holderState?.showButton2 !== undefined ? holderState.showButton2 : true,
  });

  useEffect(() => {
    setCenterAlign2((prevCA2) => ({
      ...prevCA2,
      text1:
        holderState?.text1Value !== undefined
          ? holderState.text1Value
          : "Long headline to turn your visitors into users",
      showText1:
        holderState?.showText1 !== undefined ? holderState.showText1 : true,
      text2:
        holderState?.text2Value !== undefined
          ? holderState.text2Value
          : "Separated they live in Bookmarksgrove right at the coast of the famous Semantics, large language ocean and many more stuff and more more more",
      showText2:
        holderState?.showText2 !== undefined ? holderState.showText2 : true,
      button1:
        holderState?.button1 !== undefined ? holderState.button1 : "Action",
      button1Link:
        holderState?.button1Link !== undefined ? holderState.button1Link : "",
      showButton1:
        holderState?.showButton1 !== undefined ? holderState.showButton1 : true,
      button2:
        holderState?.button2 !== undefined ? holderState.button2 : "Action",
      button2Link:
        holderState?.button2Link !== undefined ? holderState.button2Link : "",
      showButton2:
        holderState?.showButton2 !== undefined ? holderState.showButton2 : true,
    }));
  }, [
    holderState?.showText1,
    holderState?.text1Value,
    holderState?.showText2,
    holderState?.text2Value,
    holderState?.button1,
    holderState?.button1Link,
    holderState?.showButton1,
    holderState?.button2,
    holderState?.button2Link,
    holderState?.showButton2,
  ]);

  return (
    <Row
      sm={12}
      md={12}
      lg={10}
      xl={10}
      xxl={8}
      style={{ "--bs-gutter-x": "0" }}
      className={`d-flex justify-content-center bg-white ${
        isPreview ? "p-3" : "p-5"
      }`}
    >
      <Col
        xs={12}
        md={12}
        lg={10}
        xl={10}
        className="d-flex flex-column align-items-center justify-content-center"
      >
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={10}
          xl={8}
          xxl={8}
          className="pt-3 text-center"
        >
          {centerAlign2.showText1 && (
            <h1
              className={`fw-bold ${isPreview ? "preview-scaling" : "py-3"} ${
                selectedField === "text1" ? "focus-field" : ""
              }`}
            >
              {centerAlign2.text1}
            </h1>
          )}
        </Col>
        <Col
          xs={12}
          sm={12}
          md={11}
          lg={9}
          xl={6}
          xxl={5}
          className={`text-center ${isPreview ? "" : "py-3"}`}
        >
          {centerAlign2.showText2 && (
            <p
              className={`${isPreview ? "preview-scaling" : "pb-3"} ${
                selectedField === "text2" ? "focus-field" : ""
              }`}
            >
              {centerAlign2.text2}
            </p>
          )}
          {centerAlign2.showButton1 && (
            <Button
              className={`h-auto ${
                isPreview ? "preview-scaling px-1" : "me-2"
              } ${selectedField === "button1" ? "focus-field" : ""}`}
              href={"https://" + centerAlign2.button1Link}
              variant="outline-primary"
              target="_blank"
              rel="noopener noreferrer"
            >
              {centerAlign2.button1}
            </Button>
          )}
          {centerAlign2.showButton2 && (
            <Button
              className={`h-auto ${
                isPreview ? "preview-scaling px-1" : "ms-2"
              } ${selectedField === "button2" ? "focus-field" : ""}`}
              href={"https://" + centerAlign2.button2Link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {centerAlign2.button2}
            </Button>
          )}
        </Col>
      </Col>
    </Row>
  );
};

export default CA2;
