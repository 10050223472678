import React from 'react'
import { Row, Col } from 'react-bootstrap'

const DashboardHeader = ({children}) => {

  return (
    <Row>
        <Col className="bg-seconday opacity-50">
            <h5 className='p-4 text-uppercase'>{children}</h5>
        </Col>     
    </Row>
  )
}

export default DashboardHeader