import React from "react";
import '../DashboardComponents/dashboard.css';
import { Navbar, Nav, NavDropdown, Container, Button } from "react-bootstrap";
import { PersonFill, GearFill } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import LogoutButton from "../ServiceComponents/LogoutButton";

function NavBarComponent({ toggleCollapse }) {
  
  return (
    <Navbar expand="lg" className="d-flex bg-body-secondary px-2">
    <Navbar.Brand className="cursor-pointer" onClick={toggleCollapse}>
      Navbar
    </Navbar.Brand>
    <Nav className="me-auto d-flex align-items-center">
      <Link className="text-center text-decoration-none mt-1" style={{color:'black'}} to="/dashboard">Home</Link>
    </Nav>
    <Nav className="d-flexjustify-content-end">
      <NavDropdown style={{zIndex:'999'}} drop="down start" align="end" title={<PersonFill size={22} />} id="basic-nav-dropdown" aria-labelledby="dropdown">
        <Container>
          <Button href="/dashboard/settings" className='d-flex align-items-center justify-content-center m-2' variant="light">
            <GearFill size={20} /><div className="ms-1">Site Settings</div>
          </Button>
          <Button href="/dashboard/profile" className='d-flex align-items-center justify-content-center m-2' variant="light">
            <PersonFill size={20} /><div className="ms-1">User Settings</div>
          </Button>
          <NavDropdown.Divider />
          <LogoutButton variant={'light'} />
        </Container>
      </NavDropdown>
    </Nav>
  </Navbar>
  );
}

export default NavBarComponent;
